import { Injectable } from "@angular/core";
import { Simulation } from "../models/export class simulation";
import { ModelFile } from "../models/model-file";
import {
  catchError,
  EMPTY,
  first,
  forkJoin,
  from,
  interval,
  last,
  Observable,
  Observer,
  of,
  tap,
  throwError,
  timer,
  zip
} from "rxjs";
import { concatMap, delay, expand, map, mergeMap, switchMap, toArray } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { CompletedStaging } from "../models/completed-staging";
import { RevisionTrackService } from "./revision-track.service";
import { ToastrService } from "ngx-toastr";
import { ConfirmDialogService } from "../../components/shared/confirm-dialog/confirm.service";
import { SubTaskInProgress, TaskInProgress } from "../models/task-in-progress";
import { ChevronBlobStorageService } from "./chevron-blob-storage.service";
import { v4 as uuidv4 } from 'uuid';
import { BlobStorageUploaderService } from "../services/blob-storage-uploader.service";
import { CollectionFilesNeededService } from "./collection-filesneeded.service";
import { FileUploadMd5, SimulationFileUploadMd5sRequest, SimulationFileUploadFailuresRequest, SimulationService, StageSimulationRequest, StageSimulationResponse } from "./simulation.service";
import { ModelToFiles } from "src/app/components/pages/collections/view-collection/view-collection.component";
import { ModelUploadTaskInProgressService } from "../services/model-upload-task-in-progress.service";
import { ModelUploadTaskInProgress } from "../models/model-upload-task-in-progress";
import { FileUtilsService } from "../services/file-utils.service";
import { MatDialog } from "@angular/material/dialog";

export interface ModelUploadInfo {
  model: Simulation,
  selectedFiles: ModelFile[],
  allFiles: ModelFile[]
}

interface FileStatus {
  filePath: string;
  status: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class CollectionFilesUploaderService {


  constructor(
    private toastrService: ToastrService,
    private confirmDialogService: ConfirmDialogService,
    private modelUploadTaskInProgressService: ModelUploadTaskInProgressService,
    private blobStorageUploaderService: BlobStorageUploaderService,
    private firstService: CollectionFilesNeededService,
    private simulationService: SimulationService,
    private fileUtilsService: FileUtilsService
  ) {
  }

  uploadModelToFiles(modelToFiles: ModelUploadInfo, callBackAfterStage: (blobStorageDetails: StageSimulationResponse) => Observable<boolean> ): Observable<boolean> {
    return this.getBlobDetailsForUpload(modelToFiles).pipe(
      switchMap((blobStorageDetails: StageSimulationResponse) => {
        if(callBackAfterStage) return callBackAfterStage(blobStorageDetails).pipe(map(_ => blobStorageDetails));
        return of(blobStorageDetails);
      }),
      switchMap((blobStorageDetails: StageSimulationResponse) => {
        const toBeUploadedFiles = modelToFiles.selectedFiles.filter(p => p.updateInfo.mode !== 'delete');
        let updatedMd5 = [];
        const sub = timer(0, 30 * 1000).pipe(switchMap((_) => {
          const md5Files = toBeUploadedFiles.filter(p => !!p.generatedMd5 && !updatedMd5.some(x => x === p.path)).map(p => { return { path: p.path, md5: p.generatedMd5 } as FileUploadMd5 });
          if (md5Files.length === 0) return of(true);
          const request = {
            collectionId: modelToFiles.model.collectionId,
            stagingId: blobStorageDetails.stagingId,
            containerName: blobStorageDetails.containerName,
            filesMd5s: md5Files
          } as SimulationFileUploadMd5sRequest;
          return this.simulationService.updateFileMd5s(request).pipe(tap(p => {
            if (p) {
              updatedMd5 = updatedMd5.concat(md5Files.map(p => p.path));
            }
            return true;
          }));
        })).subscribe();
        return from(toBeUploadedFiles).pipe(
          // tap(console.log),
          mergeMap((modelFile: ModelFile) => {
            //upload files
            return this.blobStorageUploaderService.uploadFile(modelFile.path, modelFile.updateInfo.updatedFile, blobStorageDetails.containerName, blobStorageDetails.sasUrl).pipe(
              tap(p => {
                this.modelUploadTaskInProgressService.updateProgress(
                  {
                    Id: modelFile.path,
                    DisplayTitle: this.fileUtilsService.getFileName(modelFile.path),
                    Progress: Math.floor(environment.fileUploadProgressComplete/100 * p.progress),
                    ParentId: blobStorageDetails.containerName,
                    Status: ModelUploadTaskInProgressService.TaskInProgressStatus,
                    RootId: blobStorageDetails.containerName
                  } as ModelUploadTaskInProgress);
              }),
              last(),
              map(_ => 1),
              expand((tryCount) => {
                if (tryCount > (1 * 60 * 10)) {
                  console.error('timed out for calculating hash');
                  return EMPTY;
                }
                if (modelFile.generatedMd5 === undefined) return of(tryCount + 1).pipe(delay(1000));
                const request = {
                  collectionId: modelToFiles.model.collectionId,
                  stagingId: blobStorageDetails.stagingId,
                  containerName: blobStorageDetails.containerName,
                  filesMd5s: [{ path: modelFile.path, md5: modelFile.generatedMd5 } as FileUploadMd5],
                  allFiles: false
                } as SimulationFileUploadMd5sRequest;
                // this.simulationService.updateFileMd5s(request).pipe(first()).subscribe();
                return EMPTY;
              }),
              map(p => {
                this.modelUploadTaskInProgressService.updateProgress(
                  {
                    Id: modelFile.path,
                    DisplayTitle: this.fileUtilsService.getFileName(modelFile.path),
                    Progress: environment.fileUploadProgressComplete,
                    ParentId: blobStorageDetails.containerName,
                    Status: ModelUploadTaskInProgressService.TaskInProgressStatus,
                    RootId: blobStorageDetails.containerName
                  } as ModelUploadTaskInProgress);
                return { path: modelFile.path, status: true, mode: modelFile.updateInfo.mode, md5: modelFile.generatedMd5 };
              }),
              catchError(err => {
                console.error(`error while uploading ${modelFile.path}`);
                this.modelUploadTaskInProgressService.updateProgress(
                  {
                    Id: modelFile.path,
                    DisplayTitle: this.fileUtilsService.getFileName(modelFile.path),
                    Progress: 100.0,
                    ParentId: blobStorageDetails.containerName,
                    Status: ModelUploadTaskInProgressService.TaskFailureStatus,
                    RootId: blobStorageDetails.containerName
                  } as ModelUploadTaskInProgress);
                return of({ path: modelFile.path, status: false, mode: modelFile.updateInfo.mode, md5: modelFile.generatedMd5 });
              })
            )
          }, environment.maxFileUploadsConcurrently),
          toArray(),
          // tap(console.log),
          tap((_) => { sub.unsubscribe(); }),
          switchMap((fileStatuses: { path: string, status: boolean, md5: string }[]) => {
            //update any failures
            if (fileStatuses.some(p => p.status === false)) {
              const request = {
                collectionId: modelToFiles.model.collectionId,
                stagingId: blobStorageDetails.stagingId,
                containerName: blobStorageDetails.containerName,
                filePaths: fileStatuses.filter(p => p.status === false).map(p => p.path)
              } as SimulationFileUploadFailuresRequest;
              return this.simulationService.updateUploadFailures(request).pipe(map(p => fileStatuses));
            }
            return of(fileStatuses);
          }),
          switchMap((fileStatuses: { path: string, status: boolean, md5: string }[]) => {
            //upload manifest
            return this.getManifest(modelToFiles, fileStatuses.filter(p => p.status).map(p => p.path)).pipe(
              switchMap(manifestDetails => {
                const path = this.firstService.fileUtilsService.getFileName(manifestDetails.filePath);
                const manifestFile = new File([manifestDetails.content], path, {
                  type: "text/plain",
                });

                return this.blobStorageUploaderService.uploadFile(manifestDetails.filePath, manifestFile, blobStorageDetails.containerName, blobStorageDetails.sasUrl).pipe(
                  tap(p => {
                    this.modelUploadTaskInProgressService.updateProgress(
                      {
                        Id: manifestDetails.filePath,
                        DisplayTitle: this.fileUtilsService.getFileName(manifestDetails.filePath),
                        Progress: environment.fileUploadProgressComplete,
                        ParentId: blobStorageDetails.containerName,
                        Status: ModelUploadTaskInProgressService.TaskInProgressStatus,
                        RootId: blobStorageDetails.containerName
                      } as ModelUploadTaskInProgress);
                  }),
                  last(),
                  map(_ => {
                    return fileStatuses.concat({ path: manifestDetails.filePath, status: true, md5: this.firstService.hasherService.getHashForContent(manifestDetails.content) });
                  })
                );
              })
            )
          }),
          switchMap((fileStatuses: { path: string, status: boolean, md5: string }[]) => {
            //update md5
            if (fileStatuses.some(p => p.status)) {
              const request = {
                collectionId: modelToFiles.model.collectionId,
                stagingId: blobStorageDetails.stagingId,
                containerName: blobStorageDetails.containerName,
                filesMd5s: fileStatuses.filter(p => p.status && !updatedMd5.some(x => x === p.path)).map(p => { return { path: p.path, md5: p.md5 ?? '' } as FileUploadMd5 })
              } as SimulationFileUploadMd5sRequest;
              return this.simulationService.updateFileMd5s(request).pipe(map(p => fileStatuses));
            }
            return of(fileStatuses);
          }),
          switchMap((fileStatuses: { path: string, status: boolean, md5: string }[]) => {
            // return of(fileStatuses);
            return this.simulationService.trySubmitSimulation(blobStorageDetails.stagingId, blobStorageDetails.containerName).pipe(map(p => {
              return fileStatuses;
            }));
          }),
          switchMap( (fileStatuses: { path: string, status: boolean, md5: string }[]) => {
            if (fileStatuses.every(p => p.status)) {
              this.toastrService.success(`Model has been uploaded. Please wait until it is submitted`);
              return of(fileStatuses);
            } else {
              const errorFiles = fileStatuses.filter(p => !p.status).map(p => this.firstService.fileUtilsService.getFileName(p.path));
              const options = {
                title: `Model Upload`,
                message: `Model was uploaded, however file(s) - ${errorFiles.join(', ')} were not successful. please try adding them into the model later`,
                cancelText: 'Cancel',
                confirmText: 'Ok'
              };
              this.confirmDialogService.open(options);
              return this.confirmDialogService.confirmed().pipe(map(_ => fileStatuses));
            }
          })
        )
      }),
      tap(_ => {
        console.log('done', new Date());
      }),
      map(p => true),
      catchError(err => {
        this.toastrService.error(`error ingesting companion data: ${err.message}`);
        return of(false);
      }),
    );
  }


  private getBlobDetailsForUpload(modelToFiles: ModelUploadInfo): Observable<StageSimulationResponse> {
    let totalSize = 0;
    const stageSimulationRequest = {
      collectionId: modelToFiles.model.collectionId,
      collectionName: modelToFiles.model.collectionName,
      name: modelToFiles.model.name,
      files: modelToFiles.selectedFiles.map(p => {
        totalSize = totalSize + p.size;
        return {
          path: p.path, // p.fixedPath,
          mode: p.updateInfo.mode,
          size: p.updateInfo.updatedFile?.size ?? p.size
        };
      }),
      companionMetaData: modelToFiles.model.companionData
    } as StageSimulationRequest;

    stageSimulationRequest.files.push(
      {
        path: this.getManifestFilePath(modelToFiles),
        mode: modelToFiles.model.updateInfo.mode
      }
    );
    return this.simulationService.stageSimulation(stageSimulationRequest).pipe(tap(p => {
      this.modelUploadTaskInProgressService.updateProgress(
        {
          Id: p.containerName,
          DisplayTitle: `${modelToFiles.model.collectionName} - ${modelToFiles.model.name}`,
          Progress: environment.minSimUploadProgress,
          ParentId: '',
          Status: ModelUploadTaskInProgressService.TaskInProgressStatus,
          RootId: p.containerName,
          Size: totalSize
        } as ModelUploadTaskInProgress);

      stageSimulationRequest.files.forEach(x => {
        this.modelUploadTaskInProgressService.updateProgress(
          {
            Id: x.path,
            DisplayTitle: this.fileUtilsService.getFileName(x.path),
            Progress: 0,
            ParentId: p.containerName,
            Status: ModelUploadTaskInProgressService.TaskInProgressStatus,
            RootId: p.containerName,
            Size: x.size
          } as ModelUploadTaskInProgress);
      });
    }));
  }


  private getManifest(modelToFiles: ModelUploadInfo, successfullPaths: string[]): Observable<{ filePath: string, content: string }> {
    return of(modelToFiles.selectedFiles.filter(p => p.updateInfo.mode === 'delete' || successfullPaths.some(x => x === p.path))).pipe(switchMap(selectedFiles => {
      if (modelToFiles.model.updateInfo.mode === 'add') {
        const conflicts = modelToFiles.allFiles.filter(p => !selectedFiles.some(x => x.path === p.path) && (p.updateInfo?.conflictsWithSameSizeAndDate?.length > 0 || p.updateInfo?.conflictsWithChangesInSizeAndDate?.length > 0));
        const manifestDetails = this.firstService.modelFilesService.composeManifest(selectedFiles.concat(conflicts));
        return of(manifestDetails);
      } else {
        const manifestFile = modelToFiles.allFiles.find(p => p.path === `${modelToFiles.model.name}.manifest`);
        return this.firstService.collectionFilesService.getFileContent(modelToFiles.model.collectionId, manifestFile.path).pipe(
          map(response => {
            const updateRequired = selectedFiles.some(p => p.updateInfo.mode === 'add' || p.updateInfo.mode === 'delete');
            const updatedContent = !updateRequired ? response.content : this.firstService.modelFilesService.updateManifest(response.content, selectedFiles);
            return { filePath: manifestFile.path, content: updatedContent };
          }));
      }
    }));
  }


  private getManifestFilePath(modelToFiles: ModelUploadInfo): string {
    return (modelToFiles.allFiles.find(p => p.isPrimaryFile).path + `.manifest`);
  }



}

