<div *ngIf="!inProgress && !!modelNodesDataSource && modelNodesDataSource.data.length > 0" fxFill>
  <mat-toolbar>
    <div class="toolbar-row">
      <div class="toolbar-flex-container">
        <div class="button-container">
          <button mat-raised-button color="primary" type="button" [disabled]="!isCloseEnabled" (click)="onCloseTree()">
            <mat-icon>arrow_back</mat-icon>
          </button>
        </div>
        <div class="button-container">
          <button mat-raised-button color="primary" type="button" [disabled]="!isCollapseEnabled"
            (click)="onCollapseTree()">
            <mat-icon>fast_rewind</mat-icon>
          </button>
        </div>
        <div class="button-container">
          <button mat-raised-button color="primary" type="button" [disabled]="!isExpandEnabled"
            (click)="onExpandTree()">
            <mat-icon>fast_forward</mat-icon>
          </button>
        </div>
        <div class="button-container">
          <button mat-raised-button color="primary" type="button" [disabled]="!isExpandEnabled"
            (click)="onExpandTreeComplete()">
            <mat-icon>arrow_forward</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </mat-toolbar>
  <mat-tree [dataSource]="modelNodesDataSource" [treeControl]="modelTreeControl" class="model-tree">
    <!-- This is the tree node template for leaf nodes -->
    <!-- There is inline padding applied to this node using styles.
      This padding value depends on the mat-icon-button width. -->
    <mat-tree-node #treeSelector *matTreeNodeDef="let node">
      <div class="mat-tree-node"
        [ngClass]="(node.pathFromRoot === selectedModelNode?.pathFromRoot && node.name === selectedModelNode?.name)?'selected-model-tree-node': 'model-tree-node'">
        <mat-icon *ngIf="node.nodeType === 'model-collections-parent'">
          filter_{{node.children.length > 9 ? '9_plus' : node.children.length.toLocaleString()}}
        </mat-icon>
        <mat-icon *ngIf="node.nodeType === 'folder'" class="mat-icon-rtl-mirror folder-tree-node">
          folder
        </mat-icon>
        <button class="icon-button" *ngIf="node.nodeType === 'root-model-file'" [matBadge]="getBadgeLetter(node)"
          [matBadgeColor]="getBadgeLetterColor(node)">
          <img *ngIf="node.simulationType === 'rsl'" class="icon" src="assets/icons/ipm.png" alt="rsl model" />
          <img *ngIf="node.simulationType === 'mbal'" class="icon" src="assets/icons/ipm.png" alt="mbal model" />
          <img *ngIf="node.simulationType === 'afi'" class="icon" src="assets/icons/ix.png" alt="ix model" />
        </button>
        <div [matTooltip]="node.name" class="model-tree-node-name" (click)="selectModelNode(node)">{{node.name}}</div>
      </div>
    </mat-tree-node>
    <!-- This is the tree node template for expandable nodes -->
    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
      <div class="mat-tree-node"
        [ngClass]="(node.pathFromRoot === selectedModelNode?.pathFromRoot && node.name === selectedModelNode?.name && selectedModelNode?.simulationId === node.simulationId)?'selected-model-tree-node': 'model-tree-node'">
        <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
          <mat-icon class="mat-icon-rtl-mirror">
            {{modelTreeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
          </mat-icon>
        </button>
        <mat-icon *ngIf="node.nodeType === 'model-collections-parent'">
          filter_{{node.children.length > 9 ? '9_plus' : node.children.length.toLocaleString()}}
        </mat-icon>
        <mat-icon *ngIf="node.nodeType === 'folder'" class="mat-icon-rtl-mirror folder-tree-node">
          folder
        </mat-icon>
        <button class="icon-button" *ngIf="node.nodeType === 'root-model-file'" [matBadge]="getBadgeLetter(node)"
          [matBadgeColor]="getBadgeLetterColor(node)">
          <img *ngIf="node.simulationType === 'rsl' || node.simulationType === 'mbal'" class="icon" src="assets/icons/ipm.png" alt="rsl model" />
          <img [matTooltip]="node.simulationType | uppercase" *ngIf="!(node.simulationType === 'rsl' || node.simulationType === 'mbal') " class="icon" src="assets/icons/ix.png" alt="ix model" />
          </button>
        <div [matTooltip]="node.name" class="model-tree-node-name" (click)="selectModelNode(node)">{{node.name}}</div>
      </div>
      <!-- There is inline padding applied to this div using styles.
          This padding value depends on the mat-icon-button width.  -->
      <div [class.model-tree-invisible]="!modelTreeControl.isExpanded(node)" role="group">
        <ng-container matTreeNodeOutlet></ng-container>
      </div>
    </mat-nested-tree-node>
  </mat-tree>
</div>
<mat-spinner mode="indeterminate" *ngIf="inProgress" diameter="30"></mat-spinner>