import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ModelFilesService} from "../../../../../core/services/model-files.service";
import {ToastrService} from "ngx-toastr";
import {Simulation} from "../../../../../core/models/export class simulation";

export interface UploadModelDialogData {
  existingSimulationModels: Simulation[]
}
export interface UploadModelInfo {
  files: File[],
  selectedModel: { modelFile: File, simulationMatched: Simulation}
}
@Component({
  selector: 'app-model-upload-dialog',
  templateUrl: './upload-model.dialog.html',
  styleUrls: ['./upload-model.dialog.scss']

})
export class UploadModelDialog {
  modelFiles: { modelFile: File, simulationMatched: Simulation} [] = [];
  currentInfo: UploadModelInfo = { files: [], selectedModel: null };
  constructor(
    private modelFilesService: ModelFilesService,
    private toasterService: ToastrService,

    public dialogRef: MatDialogRef<UploadModelDialog>,
    @Inject(MAT_DIALOG_DATA) public data: UploadModelDialogData,
  ) {

  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  onModelFolderSelect(fileList: FileList): void {
    this.currentInfo.selectedModel = null;
    this.currentInfo.files = Array.from(fileList);
    const modelFiles = this.modelFilesService.getModelsInFiles(this.currentInfo.files);
    const simulations = (this.data?.existingSimulationModels ?? []);
    this.modelFiles = modelFiles.map(p => {
      return { modelFile: p, simulationMatched: simulations.find(x => x.name === p.webkitRelativePath)};
    });
    if(this.modelFiles.length === 0) {
      this.toasterService.error('did not find any known model file in the folder');
    } else if(this.modelFiles.length === 1) {
      this.currentInfo.selectedModel = this.modelFiles[0];
    }
  }

}
