import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Simulation } from '../../../../core/models/export class simulation';
import { ModelFile } from '../../../../core/models/model-file';
import { FileUtilsService } from '../../../../core/services/file-utils.service';
import { ModelFilesService } from '../../../../core/services/model-files.service';
import { MbalModelService } from '../../../../core/services/mbal-model.service';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTree, MatTreeNestedDataSource } from '@angular/material/tree';
import { BehaviorSubject, from, Observable, Subscription, tap, throwError } from 'rxjs';
import { catchError, debounceTime, first, map, switchMap, toArray } from 'rxjs/operators';
import { autoUnsubscribe } from '../../../../core/decorators/auto-unsubscribe.decorator';
import { ModelToFiles } from './view-collection.component';
import { ThemePalette } from '@angular/material/core';
import { IntersectModelService } from 'src/app/core/services/intersect-model.service';
import { CollectionFilesService } from 'src/app/core/api/collection-files.service';
import { ModelTreeCollapserService } from 'src/app/core/services/model-tree-collapser.service';

export interface ModelTreeNode {
  children: ModelTreeNode[];
  refData?: Simulation;
  name: string;
  simulationId: string;
  pathFromRoot: string;
  simulationType: 'afi' | 'fmgap' | 'ens' | 'pipes' | 'rsl' | 'mbal' | 'unknown';
  nodeType: 'model-collections-parent' | 'root-model-file' | 'folder';
  hasSimulationFile: boolean;
}

@autoUnsubscribe({ autoInclude: true })
@Component({
  selector: 'app-model-tree',
  templateUrl: './model-tree.component.html',
  styleUrls: ['./model-tree.component.scss']
})
export class ModelTreeComponent implements OnChanges, OnDestroy {
  @Input() modelToFiles: ModelToFiles[] = [];
  @Input() retrievedFiles: ModelToFiles;
  @Input() forceSelectModelId: string;
  @Output() modelsContextChange = new EventEmitter<ModelToFiles & { retrieveFiles: boolean }>();
  @Output() modelTypeChange = new EventEmitter<string>();

  modelTreeControl = new NestedTreeControl<ModelTreeNode>(node => node.children);
  modelNodesDataSource = new MatTreeNestedDataSource<ModelTreeNode>();
  inProgress: boolean = true;
  simulationType: string;

  hasChild = (_: number, node: ModelTreeNode) => !!node.children && node.children.length > 0;

  selectedModelNode: ModelTreeNode;

  @ViewChild('treeSelector') tree: MatTree<ModelTreeNode>;

  private readonly intersectModelContainerNode: ModelTreeNode = {
    name: 'Intersect Models',
    nodeType: 'model-collections-parent',
    children: [],
    pathFromRoot: '',
    simulationType: 'afi',
    simulationId: null
  } as ModelTreeNode;

  private readonly fmGapModelContainerNode: ModelTreeNode = {
    name: 'IXFMGap Models',
    nodeType: 'model-collections-parent',
    children: [],
    pathFromRoot: '',
    simulationType: 'fmgap',
    simulationId: null
  } as ModelTreeNode;

  private readonly ensModelContainerNode: ModelTreeNode = {
    name: 'IXENS Models',
    nodeType: 'model-collections-parent',
    children: [],
    pathFromRoot: '',
    simulationType: 'ens',
    simulationId: null
  } as ModelTreeNode;


  private readonly pipesModelContainerNode: ModelTreeNode = {
    name: 'IXPIPESim Models',
    nodeType: 'model-collections-parent',
    children: [],
    pathFromRoot: '',
    simulationType: 'pipes',
    simulationId: null
  } as ModelTreeNode;


  private readonly coupleModelContainerNode: ModelTreeNode = {
    name: 'Couple Models',
    nodeType: 'model-collections-parent',
    children: [],
    pathFromRoot: '',
    simulationType: 'rsl',
    simulationId: null
  } as ModelTreeNode;

  private readonly mbalModelContainerNode: ModelTreeNode = {
    name: 'MBAL Models',
    nodeType: 'model-collections-parent',
    children: [],
    pathFromRoot: '',
    simulationType: 'mbal',
    simulationId: null
  } as ModelTreeNode;
  
  private readonly unknownModelContainerNode: ModelTreeNode = {
    name: 'Unknown Model Types',
    nodeType: 'model-collections-parent',
    children: [],
    pathFromRoot: '',
    simulationType: 'unknown',
    simulationId: null
  } as ModelTreeNode;

  private inputModelToFilesChange = new BehaviorSubject(this.modelToFiles);

  private inputModelToFilesChange$: Subscription;

  constructor(
    private fileUtilsService: FileUtilsService,
    private intersectModelService: IntersectModelService,
    private collectionFilesService: CollectionFilesService,
    private modelTreeCollapserService: ModelTreeCollapserService
  ) {
    this.inputModelToFilesChange$ = this.inputModelToFilesChange
      .pipe(
        tap(() => {
          this.inProgress = true;
        }),
        debounceTime(100),
        switchMap(modelToFiles => this.buildModelNodeTree(modelToFiles)),
        tap(nodes => {
          this.selectedModelNode = null;
          this.bindModelTree(nodes);
          this.inProgress = false;
        }),
        catchError(err => {
          this.inProgress = false;
          console.log(err);
          return throwError(() => new Error(err));
        })
      )
      .subscribe();

  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes.modelToFiles?.firstChange === false && changes.modelToFiles?.currentValue !== changes.modelToFiles?.previousValue) {
      this.inputModelToFilesChange.next(changes.modelToFiles?.currentValue ?? []);
    }

    if (changes.retrievedFiles?.currentValue !== changes.modelToFiles?.previousValue && !!changes.retrievedFiles?.currentValue) {
      
      this.bindFilesIfCurrentlySelected(changes.retrievedFiles?.currentValue);
    }

  }

  private bindFilesIfCurrentlySelected(retrievedModelToFiles: ModelToFiles) {
    this.modelTypeChange.emit('');
    const modelToFiles = this.modelToFiles.find(p => p.model.id === retrievedModelToFiles.model.id);
    if (this.selectedModelNode.nodeType === 'root-model-file' && this.selectedModelNode?.simulationId === retrievedModelToFiles?.model?.id
      && this.selectedModelNode?.children?.length === 0 && retrievedModelToFiles.files.length > 0) {
      modelToFiles.model = retrievedModelToFiles.model;
      modelToFiles.files = retrievedModelToFiles.files;
      this.fillChildrenForModelNode(this.selectedModelNode, modelToFiles.files, modelToFiles.model);
      this.bindModelTree(this.modelNodesDataSource.data.slice(), this.selectedModelNode);
      const fileExtension = this.fileUtilsService.getFileExtension(modelToFiles.model.name);
      if (fileExtension === ModelFilesService.IntersectExtension) {
        this.getModelTypeFromAfiRootFile(
          modelToFiles.files.find(p => p.path === modelToFiles.model.name && p.isPrimaryFile)
        ).pipe(
          first(),
          tap(subExt => {
            if (this.selectedModelNode?.simulationId === retrievedModelToFiles?.model?.id)
              this.selectedModelNode.simulationType = subExt;
            this.modelTypeChange.emit(this.selectedModelNode.simulationType);
          })
        ).subscribe();
      } 
      else if(fileExtension === ModelFilesService.MbalExtension) {
        this.selectedModelNode.simulationType = 'mbal';
        this.modelTypeChange.emit(this.selectedModelNode.simulationType);
      }
      else {
        this.selectedModelNode.simulationType = fileExtension as any;
        this.modelTypeChange.emit(this.selectedModelNode.simulationType);
      }
    }  else {
      this.modelTypeChange.emit(this.selectedModelNode.simulationType);
    }
  }

  private buildModelNodeTree(modelToFiles: ModelToFiles[]): Observable<ModelTreeNode[]> {
    const intersectModelContainerNode = JSON.parse(JSON.stringify(this.intersectModelContainerNode));
    const coupleModelContainerNode = JSON.parse(JSON.stringify(this.coupleModelContainerNode));
    const fmGapModelContainerNode = JSON.parse(JSON.stringify(this.fmGapModelContainerNode));
    const ensModelContainerNode = JSON.parse(JSON.stringify(this.ensModelContainerNode));
    const pipesModelContainerNode = JSON.parse(JSON.stringify(this.pipesModelContainerNode));
    const mbalModelContainerNode = JSON.parse(JSON.stringify(this.mbalModelContainerNode));
    const unknownModelContainerNode = JSON.parse(JSON.stringify(this.unknownModelContainerNode));
    const modelNodes: ModelTreeNode[] = [];
    return from(modelToFiles).pipe(
      map(individualModelToFiles => {
        const fileExtension = this.fileUtilsService.getFileExtension(individualModelToFiles.model.name);
        return {
          model: individualModelToFiles.model,
          files: individualModelToFiles.files,
          fileExtension,
          subExt: ''
        };

      }),
      tap((data: { model: Simulation; files: ModelFile[]; fileExtension: string; subExt: string }) => {
        const fileName = this.fileUtilsService.getFileName(data.model.name);
        const modelNode = {
          name: fileName,
          refData: data.model,
          nodeType: 'root-model-file',
          pathFromRoot: data.model.name,
          simulationId: data.model.id,
          children: []
        } as ModelTreeNode;
        const allowedFileExtensions = [ModelFilesService.IntersectExtension, ModelFilesService.CoupledExtension,
          MbalModelService.MbalExtension1, MbalModelService.MbalExtension2, MbalModelService.MbalExtension3];
        if (allowedFileExtensions.some(p => p === data.fileExtension)) {
          if (data.fileExtension === MbalModelService.MbalExtension1 || data.fileExtension === MbalModelService.MbalExtension2 || 
            data.fileExtension === MbalModelService.MbalExtension3) {
            modelNode.simulationType = 'mbal';
          }
          else {
            modelNode.simulationType = (data.subExt || data.fileExtension) as 'afi' | 'fmgap' | 'ens' | 'pipes' | 'rsl' | 'unknown';
          }
          const containerNode = [
            intersectModelContainerNode,
            ensModelContainerNode,
            fmGapModelContainerNode,
            pipesModelContainerNode,
            coupleModelContainerNode,
            mbalModelContainerNode
          ].find(p => p.simulationType === modelNode.simulationType);
          containerNode.children.push(modelNode);
          this.fillChildrenForModelNode(modelNode, data.files, data.model);
        } else {
          console.warn('unknown model file extension', data.fileExtension);
          modelNode.simulationType = 'unknown' as 'afi' | 'rsl' | 'unknown';
          const containerNode = unknownModelContainerNode;
          containerNode.children.push(modelNode);
          this.fillChildrenForModelNode(modelNode, data.files, data.model);
        }
      }),
      toArray(),
      map(_ => {
        const modelNodes: ModelTreeNode[] = [];
        [
          intersectModelContainerNode,
          ensModelContainerNode,
          fmGapModelContainerNode,
          pipesModelContainerNode,
          coupleModelContainerNode,
          mbalModelContainerNode,
          unknownModelContainerNode
        ].forEach(modelContainerNode => {
          if (modelContainerNode.children.length > 0) {
            modelNodes.push(modelContainerNode);
            // remove the root folder
            // modelContainerNode.children.forEach(rootModelNode => {
            //   if (rootModelNode.nodeType === 'root-model-file' && rootModelNode.children.length === 1)
            //     rootModelNode.children = rootModelNode.children[0].children;
            // });
            // this.sortChildren(modelContainerNode);
          }
        });
        return modelNodes;
      })
    );
  }

  private getModelTypeFromAfiRootFile(rootFile: ModelFile): Observable<'afi' | 'fmgap' | 'ens' | 'pipes'> {
    if (rootFile.updateInfo)
      return this.intersectModelService.readAfiAndGetSimulationType(rootFile.updateInfo.updatedFile);
    else {
      return this.collectionFilesService.getFileContentByFileId(rootFile.id).pipe(
        map(p => {
          return this.intersectModelService.getSimulationType(p.content);
        })
      );
    }
  }

  private bindModelTree(modelTreeNodes: ModelTreeNode[], currentNode: ModelTreeNode = null): void {
    this.modelNodesDataSource.data = null;
    this.modelNodesDataSource.data = modelTreeNodes;
    this.modelTreeControl.dataNodes = this.modelNodesDataSource.data;


    if (modelTreeNodes.length > 0) {
      modelTreeNodes.forEach(n => this.modelTreeControl.expand(n));
      const nodeToBeSelected = currentNode ??
        this.getToBeSelectedNodeFromForceSelectedId(modelTreeNodes) ??
        this.getToBeSelectedNodeFromSelectedNode(modelTreeNodes) ??
        modelTreeNodes[0].children[0];
      if (this.selectedModelNode?.name !== nodeToBeSelected.name && this.selectedModelNode?.pathFromRoot !== nodeToBeSelected.pathFromRoot) {
        this.selectedModelNode = null;
        this.selectModelNode(nodeToBeSelected);
      }
      this.modelTreeControl.expand(nodeToBeSelected);
    }
  }

  private getToBeSelectedNodeFromForceSelectedId(modelTreeNodes: ModelTreeNode[]) {
    if (this.forceSelectModelId) {
      const nodeToBeSelected = modelTreeNodes.find(
        p => p.nodeType === 'root-model-file' && p.simulationId === this.forceSelectModelId
      );
      if (nodeToBeSelected) return nodeToBeSelected;
      else {
        for (let indNode of modelTreeNodes) {
          const nodeToBeSelected = this.getToBeSelectedNodeFromForceSelectedId(indNode.children);
          if (nodeToBeSelected) return nodeToBeSelected;
        }
      }
    }
    return null;
  }

  private getToBeSelectedNodeFromSelectedNode(modelTreeNodes: ModelTreeNode[]) {
    if (this.selectedModelNode) {
      const nodeToBeSelected = modelTreeNodes.find(
        p => p.pathFromRoot === this.selectedModelNode.pathFromRoot && this.selectedModelNode.nodeType === p.nodeType
      );
      if (nodeToBeSelected) return nodeToBeSelected;
      else {
        for (let indNode of modelTreeNodes) {
          const nodeToBeSelected = this.getToBeSelectedNodeFromSelectedNode(indNode.children);
          if (nodeToBeSelected) return nodeToBeSelected;
        }
      }
    }
    return null;
  }

  private sortChildren(parentNode: ModelTreeNode): void {
    parentNode.children.sort((a, b) => {
      if (a.hasSimulationFile && a.hasSimulationFile !== b.hasSimulationFile) return -1;
      if (b.hasSimulationFile && a.hasSimulationFile !== b.hasSimulationFile) return 1;
      return a.name.localeCompare(b.name);
    });

    parentNode.children
      .filter(p => p.children.length > 1)
      .forEach(p => {
        this.sortChildren(p);
      });
  }

  private fillChildrenForModelNode(modelNode: ModelTreeNode, files: ModelFile[], model: Simulation): void {
    files.forEach(file => {
      const isSimulationFile = model.name === file.path;
      let paths = file.path.split('/');
      paths = paths.slice(0, paths.length - 1); //only the folder

      let currentParentContext: ModelTreeNode = null;
      paths.forEach((path, idx) => {
        const childrenToBePushed = currentParentContext?.children ?? modelNode.children;
        if (childrenToBePushed.some(p => p.name === path)) {
          //the folder is  already added move on to its children
          currentParentContext = childrenToBePushed.find(p => p.name === path);
        } else {
          //push new node
          const modelFolder = {
            children: [],
            simulationType: modelNode.simulationType,
            nodeType: 'folder',
            name: path,
            simulationId: modelNode.simulationId,
            pathFromRoot: currentParentContext ? `${currentParentContext.pathFromRoot}/${path}` : path,
            hasSimulationFile: isSimulationFile
          } as ModelTreeNode;
          childrenToBePushed.push(modelFolder);
          currentParentContext = modelFolder;
        }
      });
    });
  }

  selectModelNode(modelNode: ModelTreeNode): void {
    const currentContext: ModelToFiles[] = [];
    this.selectedModelNode = modelNode;
    if (modelNode.nodeType === 'model-collections-parent') {
      const rootModelNode = modelNode.children[0];
      this.selectedModelNode = rootModelNode;
      const modelToFiles = this.modelToFiles.find(p => p.model.id === rootModelNode.simulationId);
      currentContext.push(modelToFiles);
      this.selectedModelNode = rootModelNode;
    } else if (modelNode.nodeType === 'root-model-file') {
      const modelToFiles = this.modelToFiles.find(p => p.model.id === modelNode.simulationId);
      currentContext.push(modelToFiles);
      this.modelTypeChange.emit('');
    } else {
      const modelToFiles = this.modelToFiles.find(p => p.model.id === modelNode.simulationId);
      const files = modelToFiles.files.filter(p => p.path.startsWith(`${modelNode.pathFromRoot}/`));
      currentContext.push({ model: modelToFiles.model, files: files });
    }
    this.modelsContextChange.emit(Object.assign(currentContext[0], { retrieveFiles: this.selectedModelNode.nodeType === 'root-model-file' }));
    // }
  }

  ngOnDestroy(): void {
    this.inputModelToFilesChange.complete();
  }

  getBadgeLetter(node: ModelTreeNode): string {
    if (node.nodeType === 'root-model-file' && node.refData?.updateInfo) {
      return node.refData?.updateInfo.mode === 'add' ? 'N' : 'U';
    }
    return '';
  }

  getBadgeLetterColor(node: ModelTreeNode): ThemePalette {
    if (node.nodeType === 'root-model-file' && node.refData?.updateInfo) {
      return node.refData?.updateInfo.mode === 'add' ? 'primary' : 'warn';
    }
    return 'accent';
  }


  public get isOpenEnabled(): boolean {
    return this.modelTreeCollapserService.isOpenEnabled;
  }

  public get isCloseEnabled(): boolean {
    return this.modelTreeCollapserService.isCloseEnabled;
  }

  public get isExpandEnabled(): boolean {
    return this.modelTreeCollapserService.isExpandEnabled;
  }


  public get isCollapseEnabled(): boolean {
    return this.modelTreeCollapserService.isCollapseEnabled;
  }



  onExpandTree(): void {
    this.modelTreeCollapserService.expand();
  }

  onExpandTreeComplete(): void {
    this.modelTreeCollapserService.expandComplete();
  }


  onCollapseTree(): void {
    this.modelTreeCollapserService.collapse();
  }


  onCloseTree(): void {
    this.modelTreeCollapserService.close();
  }

}
