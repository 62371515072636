<h1 mat-dialog-title>{{rootTaskInProgress.DisplayTitle}}
  <circle-progress
    [percent]="rootTaskInProgress.Progress"
    [backgroundColor]="getBackgroundColor(rootTaskInProgress)"
    [backgroundPadding]="getBackgroundPadding(rootTaskInProgress)"
    [titleFontSize]="getTitleFontSize(rootTaskInProgress)"
    [radius]="20"
    [space]="-2"
    [outerStrokeWidth]="2"
    [outerStrokeColor]="'#808080'"
    [innerStrokeWidth]="2"
    [innerStrokeColor]="'#e7e8ea'"
    [showSubtitle]="false"
    [animation]="false"
  ></circle-progress>
</h1>
<div mat-dialog-content>
  <mat-form-field appearance="fill" style="width:100%">
    <mat-label>Filter</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="enter filter text" [(ngModel)]="filterText" #input>
  </mat-form-field>

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" aria-labelledby="task progress">

    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef> Title </th>
      <td mat-cell *matCellDef="let task"> {{task.DisplayTitle}} </td>
    </ng-container>

    <ng-container matColumnDef="progress">
      <th mat-header-cell *matHeaderCellDef> Progress </th>
      <td mat-cell *matCellDef="let task">
        <circle-progress
          [percent]="task.Progress"
          [backgroundColor]="getBackgroundColor(task)"
          [backgroundPadding]="getBackgroundPadding(task)"
          [titleFontSize]="getTitleFontSize(task)"
          [radius]="20"
          [space]="-2"
          [outerStrokeWidth]="2"
          [outerStrokeColor]="'#808080'"
          [innerStrokeWidth]="2"
          [innerStrokeColor]="'#e7e8ea'"
          [showSubtitle]="false"
          [animation]="false"
        ></circle-progress> </td>

    </ng-container>


    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef> Status </th>
      <td mat-cell *matCellDef="let task"> {{task.Status}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: false"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">No data matching the filter "{{lastSearchedText}}"</td>
    </tr>
  </table>

</div>
<mat-dialog-actions>
  <button mat-raised-button mat-dialog-close color="warn">Close</button>
</mat-dialog-actions>
