import { Injectable } from '@angular/core';
import { concatMap, delay, EMPTY, expand, Observable, of, timer } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RevisionTrackService {
  static readonly COMPLETE: string = 'complete';
  static readonly UPDATED: string = 'updated';
  static readonly FAILURE: string = 'failed';
  static readonly TIMEOUT: string = 'timeout';
  static readonly MAX_TRIES: number = 10;
  static readonly TIMER: number = 5000;
  static readonly AFTER_COMPLETE_TIMER: number = 2000;

  constructor(private _httpClient: HttpClient) {}

  public trackFor(request: { collectionId: string; revisionId: string }): Observable<{ status: string, simulationId: string }> {
    let tries = 0;
    const url = `${environment.apiEndpoint}/revision/status`;
    return this._httpClient.post<{ status: string, simulationId: string }>(url, request).pipe(
      expand(({ status, simulationId }) => {
        tries++;
        if (tries === RevisionTrackService.MAX_TRIES) return of({ status: RevisionTrackService.TIMEOUT, simulationId: '' });
        return status === RevisionTrackService.FAILURE ||
          status === RevisionTrackService.TIMEOUT ||
          status === RevisionTrackService.UPDATED
          ? EMPTY
          : this.getTimeStatus(url, status, request);
      })
    );
  }

  private getTimeStatus(url, status, request)
  {
    return timer(
      status === RevisionTrackService.COMPLETE
        ? RevisionTrackService.AFTER_COMPLETE_TIMER
        : RevisionTrackService.TIMER
    ).pipe(concatMap(_ => this._httpClient.post<{ status: string, simulationId: string }>(url, request)));
  }
}
