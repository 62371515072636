import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {concatMap, EMPTY, expand, Observable, of, timer} from "rxjs";
import {environment} from "../../../environments/environment";
import {Simulation} from "../models/export class simulation";
import {RevisionChange} from "../models/revision-change";
import {ModelFile} from "../models/model-file";
import {CollectionFile} from "../models/collection-file";

@Injectable({
  providedIn: 'root'
})
export class RevisionsService {

  constructor(private _httpClient: HttpClient) {}


  getSimulationsForRevision(id: string): Observable<Simulation []> {
    const url = `${environment.apiEndpoint}/revision/${id}/simulations`;
    return this._httpClient.get<Simulation []>(url);
  }

  getRevisionChanges(id: string): Observable<RevisionChange[]> {
    const url = `${environment.apiEndpoint}/revision/${id}/changes`;
    return this._httpClient.get<RevisionChange[]>(url);
  }

  getFilesForRevision(id: string): Observable<CollectionFile []> {
    const url = `${environment.apiEndpoint}/revision/${id}/files`;
    return this._httpClient.get<CollectionFile []>(url);
  }

}
