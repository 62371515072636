import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortName'
})
export class ShortNamePipe implements PipeTransform {

  transform(value: string): string {
    let sName = value.split(" ").map(n=> n[0]).join("");
    return sName;
  }

}
