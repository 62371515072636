<h1 mat-dialog-title>{{ data.title }}</h1>
<div mat-dialog-content>

    <p *ngIf="containsHtmlTags; else nonHtmlMessage" [innerHTML]="data.message"></p>
    <ng-template #nonHtmlMessage>
        <p class="dialog-message">{{ data.message }}</p>
    </ng-template>
</div>
<div mat-dialog-actions>
    <button mat-button cdkFocusInitial (click)="confirm()">{{ data.confirmText }}</button>
    <button mat-button (click)="cancel()">{{ data.cancelText }}</button>
</div>