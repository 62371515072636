import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit, TemplateRef,
  ViewEncapsulation
} from "@angular/core";
import { LinkItem, isDefined } from '@slb-dls/angular-material/shared';
import { Router, RoutesRecognized } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { BrowserStorageService } from './core/services/storage.service';
import { CompanionDataFieldsService } from "./core/services/companion-data-fields.service";
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { SlbNotificationItem } from '@slb-dls/angular-material/notifications-panel';
import { BehaviorSubject, combineLatestWith, delay, Observable, Subscription, tap } from "rxjs";
import { AuthService } from "./core/auth/auth.service";
import { SlbBreadcrumbsItem } from "@slb-dls/angular-material/breadcrumbs";
import { BreadcrumbsService } from "./core/services/breadcrumbs.service";
import { PageHeaderService } from "./core/services/page-header.service";
import { autoUnsubscribe } from "./core/decorators/auto-unsubscribe.decorator";
import { TasksInProgressService } from "./core/services/tasks-in-progress.service";
import { TaskInProgressWithChangeSubscriptions } from "./core/models/task-in-progress";
import { MatDialog } from "@angular/material/dialog";
import { UploadTasksTableDialogComponent } from "./components/shared/tasks-in-progress/upload-tasks-table-dialog.component";
import { ConfirmDialogService } from "./components/shared/confirm-dialog/confirm.service";
import { BuService, BusinessUnit } from "./core/api/bu.service";
import cytoscape from "cytoscape";
import dagre from "cytoscape-dagre";
import { SignalRService } from "./core/api/signal-r.service";
import { ModelUploadTaskInProgressService } from "./core/services/model-upload-task-in-progress.service";
import { ModelUploadTaskInProgress } from "./core/models/model-upload-task-in-progress";
import { MonitoringService } from "./core/services/monitoring.service";
import { autoMonitorPageView } from "./core/decorators/auto-monitor-page-view.decorator";
import { ModelDownloadTaskInProgress, ModelDownloadTaskInProgressService } from "./core/services/model-download-task-in-progress.service";
import { DownloadTasksTableDialogComponent } from "./components/shared/tasks-in-progress/download-tasks-table-dialog.component";

// call to use the cytoscape dagre
cytoscape.use(dagre);

interface Food {
  value: string;
  viewValue: string;
}

@autoMonitorPageView({name: 'REDMS', trackOnInitToAfterInit: false })
@autoUnsubscribe({ autoInclude: true })
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  notificationCount: 10;
  notificationItems: SlbNotificationItem[] = [];

  showHeader: boolean = true;
  pageTitle: string = '';
  secondaryLinks: LinkItem[];
  userName: string;
  private router$ = Subscription.EMPTY;
  private authService$ = Subscription.EMPTY;
  private taskInProgressService$ = Subscription.EMPTY;
  private breadcrumbsService$ = Subscription.EMPTY;
  private pageHeaderServiceToolbarTemplate$ = Subscription.EMPTY;
  private pageHeaderServicePageTitle$ = Subscription.EMPTY;
  private busService$: Subscription = Subscription.EMPTY;



  get parentUploadTasksInProgress$(): Observable<ModelUploadTaskInProgress[]> {
    return this.modelUploadTaskInProgressService.parentTasksInProgress$;
  }

  get parentDownloadTasksInProgress$(): Observable<ModelDownloadTaskInProgress[]> {
    return this.modelDownloadTaskInProgressService.parentTasksInProgress$;
  }

  public home = BreadcrumbsService.HOME;
  public breadcrumbsItems = [] as SlbBreadcrumbsItem[];
  public headerTemplate: TemplateRef<any> = null;
  public units: BusinessUnit[];
  public selectedUnit: string;

  constructor(
    private router: Router,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private browserStorageService: BrowserStorageService,
    private companionDataFieldsService: CompanionDataFieldsService,
    private authService: AuthService,
    private breadcrumbsService: BreadcrumbsService,
    private pageHeaderService: PageHeaderService,
    private modelUploadTaskInProgressService: ModelUploadTaskInProgressService,
    private modelDownloadTaskInProgressService: ModelDownloadTaskInProgressService,
    private dialog: MatDialog,
    private confirmDialogService: ConfirmDialogService,
    private buService: BuService,
    private signalRService: SignalRService,
    private monitoringService: MonitoringService
  ) {
    matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg-symbols.svg'));

    this.router$ = router.events
      .pipe(
        filter(e => e instanceof RoutesRecognized),
        map(e => e as RoutesRecognized)
      )
      .subscribe(e => this.onRouteChange(e));
    this.userName = browserStorageService.get('userName');

    this.breadcrumbsService$ = this.breadcrumbsService.currentBreadcrumbItems.pipe(delay(0), tap(breadcrumbItems => {
      this.breadcrumbsItems = breadcrumbItems;
    })).subscribe();

    this.pageHeaderServiceToolbarTemplate$ = this.pageHeaderService.currentToolbarTemplate.pipe(delay(0), tap(template => {
      this.headerTemplate = template;
    })).subscribe();

    this.pageHeaderServicePageTitle$ = this.pageHeaderService.currentPageTitle.pipe(delay(0), tap(pageTitle => {
      this.pageTitle = pageTitle;
    })).subscribe();

    this.busService$ = this.buService.getConfiguredBuList().pipe(combineLatestWith(this.buService.currentBu),
      tap(([units, selectedUnit]) => {
        this.units = units;
        this.selectedUnit = selectedUnit ?? this.units.find(p => p.isDefault).id;
      })
    ).subscribe();

  }

  ngOnInit() {
    this.authService$ = this.authService.currentAuthStatus.subscribe(authStatus => {
      if (authStatus.userLoggedIn) {
        this.userName = this.authService.currentUser.name;
        if (authStatus.redirectTo) {
          this.router.navigateByUrl(authStatus.redirectTo);
        }
      }
    });

  }

  selectedUnitName() {
    if(this.units) {
      return this.units.find(p => p.id === this.selectedUnit)?.partitionName;
    }

    return '';
  }


  
  unitOptions() {
    if(this.units) {
      return this.units.filter(p => p.id !== this.selectedUnit);
    }

    return [];
  }

  ngAfterViewInit() {
    // call the companion data needed field data
    this.companionDataFieldsService.GetCompanionDataFields().then((result) => {
    });
    // call bu service to get all  default entitlements
    this.buService.getDefaultEntitlements().subscribe();
  }

  ngOnDestroy(): void {
    console.log('ngOnDestroy on AppComponent');
  }

  onLogOut(): void {
    this.browserStorageService.clear();
  }

  private onRouteChange(event: RoutesRecognized): void {
    const data = event.state.root.firstChild.data;
    this.showHeader = isDefined(data.showHeader) ? data.showHeader : true;
    this.pageHeaderService.setPageTitle(data.title);
    this.secondaryLinks = data.links;
    this.breadcrumbsService.setCurrentBreadcrumbItems([]);
    this.pageHeaderService.setCurrentToolbarTemplate(null);
  }

  showUploadTaskDetails(parentTask: ModelUploadTaskInProgress) {
    // if (parentTask.localTask) {
    const dialogRef = this.dialog.open(UploadTasksTableDialogComponent, {
      width: '90vw',
        height: '90vh',
      data: parentTask.RootId,
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });

  }

  
  showDownloadTaskDetails(parentTask: ModelUploadTaskInProgress) {
    // if (parentTask.localTask) {
    const dialogRef = this.dialog.open(DownloadTasksTableDialogComponent, {
      width: '90vw',
        height: '90vh',
      data: parentTask.RootId,
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });

  }

  getIconForParentTaskType(taskType: 'MODEL_UPLOAD' | 'COLLECTION_DOWNLOAD' | 'MODEL_DOWNLOAD'): string {
    return taskType === 'MODEL_UPLOAD' ? 'publish' : 'download';
  }

  onUnitChange(selectedUnit) {
    this.selectedUnit = selectedUnit;
    this.buService.setCurrentBu(this.selectedUnit);
    location.href = location.origin;
  }
}

if (typeof Worker !== 'undefined') {
  // Create a new
  const worker = new Worker(new URL('./app.worker', import.meta.url));
  worker.onmessage = ({ data }) => {
    console.log(`page got message: ${data}`);
  };
  worker.postMessage('testing a message to web worker here');
} else {
  // Web Workers are not supported in this environment.
  // You should add a fallback so that your program still executes correctly.
}
