import {AfterViewInit, Component, HostListener, Inject, OnDestroy, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {catchError, EMPTY, expand, first, of, Subject, Subscription, timeout, zip} from "rxjs";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {DOCUMENT} from "@angular/common";
import {map, switchMap} from "rxjs/operators";
import {FileUtilsService} from "../../../core/services/file-utils.service";
import {CollectionFilesService} from "../../../core/api/collection-files.service";
import {environment} from "../../../../environments/environment";
import {autoUnsubscribe} from "../../../core/decorators/auto-unsubscribe.decorator";
import { DomSanitizerService } from "src/app/core/services/domsanitizer.service";
import { autoMonitorPageView } from "src/app/core/decorators/auto-monitor-page-view.decorator";
import { MonitoringService } from "src/app/core/services/monitoring.service";

export interface CompareFilesDialogData {
  title: string,
  leftFileId: string,
  rightFileId: string
}

@Component({
  selector: 'app-compare-files-dialog',
  templateUrl: './compare-files.dialog.html',
  styleUrls: ['./compare-files.dialog.scss']
})
@autoUnsubscribe({autoInclude: true})
@autoMonitorPageView({ name: 'Compare Files Dialog', trackOnInitToAfterInit: false })
export class CompareFilesDialog implements OnInit, AfterViewInit, OnDestroy{

  inProgress = true;
  webEditorUrlSafe: SafeResourceUrl; //need safe url for iframe src
  doc: Document;

  updatedContent = new Subject<string>();
  private loadContent$: Subscription;

  constructor(@Inject(MAT_DIALOG_DATA) public data: CompareFilesDialogData, private fileUtilsService: FileUtilsService,
              @Inject(DOCUMENT) doc: Document,
              public sanitizer: DomSanitizerService, 
              private collectionFilesService: CollectionFilesService,
              private monitoringService: MonitoringService) {
    this.webEditorUrlSafe =  this.sanitizer.webDiffEditorUrlSafe; //bypassSecurityTrustResourceUrl(environment.webDiffEditorUrl)
    this.doc = doc;
    this.bindContent();
  }
  ngAfterViewInit(): void {
    // do nothing
  }
  ngOnInit(): void {
    // do nothing
  }

  private bindContent() {
    this.loadContent$ = zip([this.collectionFilesService.getFileContentByFileId(this.data.leftFileId), this.collectionFilesService.getFileContentByFileId(this.data.rightFileId)])
      .pipe(
        map(([leftResponse, rightResponse]) => [leftResponse.content, rightResponse.content]),
        switchMap(([leftContent, rightContent]) => {
          const iframe = this.doc.getElementById('ifrm') as HTMLIFrameElement;
          let retryAttempts = 0;
          return of('').pipe(expand(contentOnEditor => {
            if(contentOnEditor === leftContent || retryAttempts > 5) {
              this.inProgress = false;
              return EMPTY;
            }
            retryAttempts++;
            iframe.contentWindow.postMessage(
              {
                vsEditorData: {
                  left: leftContent,
                  right: rightContent
                },
                updateOptions: {readOnly: true}
              },
              this.getDomain() // not use '*'
            );
            iframe.contentWindow.postMessage('getOriginalDiffModel', this.getDomain());
            return this.updatedContent.pipe(first(), timeout({first: 2000}), catchError(err => {
              console.error(err);
              return of('');
            }));
          }));
        })
      ).subscribe();
  }


  refresh(): void {
    this.bindContent();
  }


  private getDomain(): string {
    const domain = new URL(environment.webEditorUrl);
    return `${domain.protocol}//${domain.hostname}`;
  }


  @HostListener('window:message', ['$event']) onPostMessage(event) {
    if (event.origin !== this.getDomain()) {
      return;
    }
    const responseType = event.data.requestType;
    if (responseType === 'getOriginalDiffModel') {
      const modifiedContent = event.data.response;
      this.updatedContent.next(modifiedContent);
    }
  }

  ngOnDestroy(): void {
    console.log('on destroy CompareFilesDialog');
  }


}
