import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { autoUnsubscribe } from '../../../../core/decorators/auto-unsubscribe.decorator';
import { Simulation } from '../../../../core/models/export class simulation';
import { BehaviorSubject, Subscription, tap } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ModelFile } from '../../../../core/models/model-file';
import { ModelToFiles } from './view-collection.component';
import { Clipboard } from '@angular/cdk/clipboard';
import { environment } from '../../../../../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { SlbSeverity } from '@slb-dls/angular-material/notification';
import { NewFileAdd } from './view-model-folders.component';
import { SelectionChange } from '@angular/cdk/collections';
import { CompanionDataComponent } from 'src/app/components/shared/companion-data/companion-data.component';
import { TasksInProgressService } from '../../../../core/services/tasks-in-progress.service';
import { CollectionsNeededService } from '../collectionsneeded.service';
import { ViewCollectionPartOneNeededService } from './view-collectionpartoneneeded.service';
import { ModelToCompare } from 'src/app/core/api/model-comparison.service';
import { ModelUploadTaskInProgressService } from 'src/app/core/services/model-upload-task-in-progress.service';
import { ModelTreeCollapserService } from 'src/app/core/services/model-tree-collapser.service';
import * as _ from 'lodash';

export interface ModelFolder {
  subFolders: ModelFolder[];
  files: ModelFolderFile[];
  name: string;
  pathFromRoot: string;
  isInModelFilePath: boolean;
}

export interface ModelFolderFile {
  name: string;
  pathFromRoot: string;
  fileInfo: ModelFile;
  simulation: Simulation;
  options: {
    view: boolean;
    edit: boolean;
    compare: boolean;
    delete: boolean;
    timeline: boolean;
    download: boolean;
  };
  isPrimaryFile?: boolean;
}

interface AdditionalTabInfo {
  title: string;
  icon: string;
  type:
    | 'file-timeline'
    | 'model-timeline'
    | 'collection-timeline'
    | 'collection-comparison'
    | 'model-comparison'
    | 'simulation-linkage';
  modelFolderFile?: ModelFolderFile;
}

@Component({
  selector: 'app-model-folders',
  templateUrl: './model-folders.component.html',
  styleUrls: ['./model-folders.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@autoUnsubscribe({ autoInclude: true })
export class ModelFoldersComponent implements OnInit, OnChanges, OnDestroy {
  private static readonly viewableExtensions = environment.viewableExtensions;
  private static readonly editableExtensions = environment.editableExtensions;

  @Input() modelToFiles: ModelToFiles = null;
  @Input() modelType: string;
  isReadOnlyValue: boolean = false;
  @Input() set isReadOnly(value: boolean) {
    this.isReadOnlyValue = value;
  }
  @Input() missingInputFiles: File[];
  @Output() public addFileToSimulation = new EventEmitter<{ model: Simulation; file: NewFileAdd }>();
  @Output() public uploadFiles = new EventEmitter<Simulation>();
  @Output() public downloadModel = new EventEmitter<ModelToFiles>();

  private inputChange$: Subscription;
  private inputChange = new BehaviorSubject(this.modelToFiles);

  modelFoldersToBeViewed: ModelFolder[];
  inProgress: boolean = true;
  uploadConflictsChanged: boolean = false;
  additionalTabs: AdditionalTabInfo[] = [];
  tabSelectedIndex: number = 0;
  uploadInProgress: boolean = false;
  downloadInProgress: boolean = false;
  searchText: string = '';

  private showCollectionTime$: Subscription;
  private tasksInProgressService$: Subscription;
  private parentTasksInProgressService$: Subscription;

  public get isOpenEnabled(): boolean {
    return this.modelTreeCollapserService.isOpenEnabled;
  }

  constructor(
    private clipboard: Clipboard,
    private cdr: ChangeDetectorRef,
    public dialog: MatDialog,
    private tasksInProgressService: TasksInProgressService,
    private parentTasksInProgressService: ModelUploadTaskInProgressService,
    private firstService: CollectionsNeededService,
    private secondService: ViewCollectionPartOneNeededService,
    private modelTreeCollapserService: ModelTreeCollapserService
  ) {
    const obs = {
      next: modelToFiles => {
        const filesNFolders = this.buildModelFolderTree(modelToFiles);
        this.modelFoldersToBeViewed = [...filesNFolders];
        this.inProgress = false;
        this.cdr.detectChanges();
      },
      error: () => {
        this.inProgress = false;
      }
    };
    this.inputChange$ = this.inputChange
      .pipe(
        tap(() => {
          this.inProgress = true;
        }),
        debounceTime(100)
      )
      .subscribe(obs);

    this.showCollectionTime$ = this.secondService.collectionsService.showTimeline
      .pipe(
        tap(show => {
          if (show) {
            this.onCollectionTimeline();
            this.cdr.detectChanges();
          }
        })
      )
      .subscribe();
  }

  getFileTypes() {
    return (this.modelToFiles?.files ?? []).map(p =>
      p.path.substring(p.path.lastIndexOf('.') > -1 ? p.path.lastIndexOf('.') : 0)
    ).filter((value, index, self) => self.indexOf(value) === index);
  }

  ngOnInit(): void {
    this.tasksInProgressService$ = this.tasksInProgressService.tasksInProgressWithChanges
      .pipe(
        tap(tasksInProgress => {
          this.downloadInProgress = tasksInProgress.some(p => p.taskType === 'MODEL_DOWNLOAD');
          this.cdr.detectChanges();
        })
      )
      .subscribe();

    this.parentTasksInProgressService$ = this.parentTasksInProgressService.parentTasksInProgress$
      .pipe(
        tap(p => {
          this.uploadInProgress = p.length > 0;
        })
      )
      .subscribe();
  }

  private buildModelFolderTree(modelToFiles: ModelToFiles): ModelFolder[] {
    if (!modelToFiles) return [];
    const modelFolders: ModelFolder[] = [];
    const modelFiles: ModelFile[] = modelToFiles.files;
    modelFiles.forEach(modelFile => {
      let paths = modelFile.path.split('/');
      let currentParentContext: ModelFolder = modelFolders.find(p => p.pathFromRoot === '') ?? null;
      paths.forEach((path, idx) => {
        if (idx === paths.length - 1) {
          // this is file name
          if (currentParentContext === null) {
            console.warn('file found in root itself', path, modelFile.path);
            const collectionFolder = {
              subFolders: [],
              files: [],
              pathFromRoot: '',
              name: '',
              isInModelFilePath: modelFile.isPrimaryFile
            } as ModelFolder;
            currentParentContext = collectionFolder;
            modelFolders.push(currentParentContext);
          }
          if (currentParentContext !== null) {
            //   const existingFile = currentParentContext.files.find(p => p.name === path && p.fileInfo.type === modelFile.type)
            //   if (!existingFile) {
            //     const ext = this.secondService.fileUtilsService.getFileExtension(path)
            //     const isViewable = modelFile.updateInfo?.mode !== 'add' && modelFile.updateInfo?.mode !== 'not-allowed' && ModelFoldersComponent.viewableExtensions.some(p => p === ext)
            //     const isEditable = modelFile.updateInfo?.mode !== 'add' && modelFile.updateInfo?.mode !== 'not-allowed' && modelFile.updateInfo?.mode !== 'update' && ModelFoldersComponent.editableExtensions.some(p => p === ext)
            //     const isComparable = (
            //       (modelFile.updateInfo?.mode === 'add' && (modelFile.updateInfo.conflictsWithSameSizeAndDate?.length > 0 ||
            //         modelFile.updateInfo.conflictsWithChangesInSizeAndDate?.length > 0)) || modelFile.updateInfo?.mode === 'update'
            //     ) && ModelFoldersComponent.viewableExtensions.some(p => p === ext)
            //     const isDeletable = !modelFile.isPrimaryFile && ext !== 'manifest' && modelFile.updateInfo?.mode !== 'add' && modelFile.updateInfo?.mode !== 'update' && modelFile.updateInfo?.mode !== 'not-allowed'
            //     const simulation = modelToFiles.model
            //     const file = {
            //       name: path,
            //       pathFromRoot: modelFile.path,
            //       fileInfo: modelFile,
            //       isPrimaryFile: modelFile.isPrimaryFile,
            //       simulation: simulation,
            //       options: {
            //         view: isViewable,
            //         edit: isEditable,
            //         compare: isComparable,
            //         delete: isDeletable,
            //         timeline: modelFile.updateInfo?.mode !== 'add' && modelFile.updateInfo?.mode !== 'not-allowed',
            //         download: modelFile.updateInfo?.mode !== 'add' && modelFile.updateInfo?.mode !== 'not-allowed'
            //       }
            //     } as ModelFolderFile;
            //     currentParentContext.files.push(file)
            //   } else {
            //     console.warn('file present twice in the same model', path, modelFile.path)
            //   }
            this.eachPathForFileNotNull(modelFile, path, currentParentContext, modelFolders, modelToFiles);
          }
        } else {
          // this is folder
          // const subFoldersToBePushed = (currentParentContext?.subFolders ?? modelFolders)
          // if (subFoldersToBePushed.some(p => p.name === path)) {
          //   //the folder is  already added move on to its children
          //   currentParentContext = subFoldersToBePushed.find(p => p.name === path)
          // } else {
          //   //push new node
          //   const collectionFolder = {
          //     subFolders: [],
          //     files: [],
          //     pathFromRoot: currentParentContext ? `${currentParentContext.pathFromRoot}/${path}` : path,
          //     name: path,
          //     isInModelFilePath: modelFile.isPrimaryFile
          //   } as ModelFolder
          //   subFoldersToBePushed.push(collectionFolder)
          //   currentParentContext = collectionFolder
          // }
          currentParentContext = this.eachPathForFolder(modelFile, path, currentParentContext, modelFolders);
        }
      });
    });

    this.sortModelFolders(modelFolders);
    return modelFolders;
  }

  private eachPathForFileNotNull(
    modelFile: ModelFile,
    path: string,
    currentParentContext: ModelFolder,
    modelFolders: ModelFolder[],
    modelToFiles: ModelToFiles
  ) {
    const existingFile = currentParentContext.files.find(p => p.name === path && p.fileInfo.type === modelFile.type);
    if (!existingFile) {
      const ext = this.secondService.fileUtilsService.getFileExtension(path);
      const isViewable =
        modelFile.updateInfo?.mode !== 'add' &&
        modelFile.updateInfo?.mode !== 'not-allowed' &&
        ModelFoldersComponent.viewableExtensions.some(p => p === ext);
      const isEditable =
        modelFile.updateInfo?.mode !== 'add' &&
        modelFile.updateInfo?.mode !== 'not-allowed' &&
        modelFile.updateInfo?.mode !== 'update' &&
        ModelFoldersComponent.editableExtensions.some(p => p === ext);
      const isComparable =
        ((modelFile.updateInfo?.mode === 'add' &&
          (modelFile.updateInfo.conflictsWithSameSizeAndDate?.length > 0 ||
            modelFile.updateInfo.conflictsWithChangesInSizeAndDate?.length > 0)) ||
          modelFile.updateInfo?.mode === 'update') &&
        ModelFoldersComponent.viewableExtensions.some(p => p === ext);
      const isDeletable =
        !modelFile.isPrimaryFile &&
        ext !== 'manifest' &&
        modelFile.updateInfo?.mode !== 'add' &&
        modelFile.updateInfo?.mode !== 'update' &&
        modelFile.updateInfo?.mode !== 'not-allowed';
      const simulation = modelToFiles.model;
      const file = {
        name: path,
        pathFromRoot: modelFile.path,
        fileInfo: modelFile,
        isPrimaryFile: modelFile.isPrimaryFile,
        simulation: simulation,
        options: {
          view: isViewable,
          edit: isEditable,
          compare: isComparable,
          delete: isDeletable,
          timeline: modelFile.updateInfo?.mode !== 'add' && modelFile.updateInfo?.mode !== 'not-allowed',
          download: modelFile.updateInfo?.mode !== 'add' && modelFile.updateInfo?.mode !== 'not-allowed'
        }
      } as ModelFolderFile;
      currentParentContext.files.push(file);
    } else {
      console.warn('file present twice in the same model', path, modelFile.path);
    }
  }

  private eachPathForFolder(
    modelFile: ModelFile,
    path: string,
    currentParentContext: ModelFolder,
    modelFolders: ModelFolder[]
  ): ModelFolder {
    const subFoldersToBePushed = currentParentContext?.subFolders ?? modelFolders;
    if (subFoldersToBePushed.some(p => p.name === path)) {
      //the folder is  already added move on to its children
      currentParentContext = subFoldersToBePushed.find(p => p.name === path);
    } else {
      //push new node
      const collectionFolder = {
        subFolders: [],
        files: [],
        pathFromRoot: currentParentContext ? `${currentParentContext.pathFromRoot}/${path}` : path,
        name: path,
        isInModelFilePath: modelFile.isPrimaryFile
      } as ModelFolder;
      subFoldersToBePushed.push(collectionFolder);
      currentParentContext = collectionFolder;
    }
    return currentParentContext;
  }

  private sortModelFolders(modelFolders: ModelFolder[]) {
    modelFolders.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });

    modelFolders.forEach(modelFolder => {
      modelFolder.files.sort((a, b) => {
        if (a.isPrimaryFile) return -1;
        if (b.isPrimaryFile) return 1;
        const aExtIsManifest = this.secondService.fileUtilsService.getFileExtension(a.name) === 'manifest';
        const bExtIsManifest = this.secondService.fileUtilsService.getFileExtension(b.name) === 'manifest';
        if ((aExtIsManifest || bExtIsManifest) && !(aExtIsManifest && bExtIsManifest)) {
          return aExtIsManifest ? -1 : 1;
        }
        if (a.fileInfo.type !== b.fileInfo.type) {
          return a.fileInfo.type.localeCompare(b.fileInfo.type);
        }
        return a.name.localeCompare(b.name);
      });
      this.sortModelFolders(modelFolder.subFolders);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.modelToFiles && !_.isEqual(changes.modelToFiles?.currentValue, changes.modelToFiles?.previousValue)) {
      this.inputChange.next(changes.modelToFiles?.currentValue);
    }
  }

  ngOnDestroy(): void {
    this.inputChange.complete();
  }

  isSaveable(): boolean {
    return !!this.modelToFiles.model?.updateInfo;
  }

  isSaveEnabled(): boolean {
    return (
      this.modelToFiles.files.some(p => p.updateInfo?.selected === true) &&
      !this.isReadOnlyValue &&
      !this.uploadInProgress
    );
  }

  isNotAddMode() {
    return this.modelToFiles.model?.updateInfo?.mode !== 'add';
  }

  modelToCompare(): ModelToCompare {
    return {
      simulationId: this.modelToFiles.model.id,
      collectionName: this.modelToFiles.model.collectionName,
      modelName: this.modelToFiles.model.name
    } as ModelToCompare;
  }

  isTimelineAvailable() {
    return this.modelToFiles.model?.updateInfo?.mode !== 'add';
  }

  isEditCompanionDataAvailable() {
    return this.modelToFiles.model?.updateInfo?.mode !== 'add';
  }

  editCompanionData() {
    this.secondService.companionDataService
      .queryCompanionDataBySimulationId({
        modelId: this.modelToFiles.model.id,
        branchSimulationId: this.modelToFiles.model.branchSimulationId
      })
      .subscribe((output: any) => {
        if (output.results.length > 0) {
          const companionData = output.results.find(x => x);
          this.showCompanionDataDialog('edit', companionData);
        } else {
          const modelNameDetail = `There is no companion data for ${this.modelToFiles.model?.name}, now add companion data!`;
          this.firstService.messageService.add({ severity: SlbSeverity.Warning, detail: modelNameDetail });
          this.showCompanionDataDialog('add_for_edit', {});
        }
      });
  }

  copySimulationLink(id: string): void {
    const simulationId = id;
    const simulationLink = environment.simulationLink;
    const simulationLinkToCopy = simulationLink.concat(simulationId);
    this.clipboard.copy(simulationLinkToCopy);
    this.firstService.toastrService.success(`simulation link has been copied to the clipboard`);
  }

  hasConflicts(): boolean {
    return (
      this.isSaveable() &&
      this.modelToFiles.files.some(x => (x.updateInfo?.conflictsWithChangesInSizeAndDate?.length ?? 0) > 0)
    );
  }

  onUploadConflictsChanged(checked: boolean): void {
    this.uploadConflictsChanged = checked;
    this.modelToFiles.files
      .filter(modelFile => {
        if (modelFile.updateInfo?.mode === 'add' || modelFile.updateInfo?.mode === 'update') {
          return (modelFile.updateInfo?.conflictsWithChangesInSizeAndDate?.length ?? 0) > 0;
        }
        return false;
      })
      .forEach(modelFile => {
        modelFile.updateInfo.selected = checked;
        modelFile.updateInfo.mode = 'update';
      });
  }

  showCompanionDataDialog(mode, companionData): void {
    const dialogRef = this.dialog.open(CompanionDataComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        mode,
        collection: { id: this.modelToFiles.model.collectionId, name: this.modelToFiles.model.collectionName },
        model: {
          id: this.modelToFiles.model.id,
          name: this.modelToFiles.model.name,
          branchSimulationId: this.modelToFiles.model.branchSimulationId
        },
        companionData
      }
    });
    dialogRef.afterClosed().subscribe(dialogSubmit => {
      if (dialogSubmit?.data) {
        this.modelToFiles.model.companionData = dialogSubmit?.data;
        this.uploadFiles.emit(this.modelToFiles.model);
      }
    });
  }

  onAddFileToFolder(addFile: NewFileAdd): void {
    this.addFileToSimulation.emit({ model: this.modelToFiles.model, file: addFile });
  }

  onSelectionChange(selectionChange: SelectionChange<ModelFolderFile>): void {
    selectionChange.added.forEach(addedModelFile => {
      addedModelFile.fileInfo.updateInfo.selected = true;
    });
    selectionChange.removed.forEach(addedModelFile => {
      addedModelFile.fileInfo.updateInfo.selected = false;
    });
  }

  onUploadFiles(): void {
    if (this.missingInputFiles?.length) {
      this.displayMissingInputFilesDialog(this.missingInputFiles);
    } else {
      this.showCompanionDataDialog('add', {});
    }
  }

  displayMissingInputFilesDialog(missingInputFiles: File[]): void {
    const isOrAre = missingInputFiles.length === 1 ? 'is' : 'are';
    const options = {
      title: `Missing Input Files`,
      message: `<b>${missingInputFiles
        .map(x => x)
        .join(', ')} </b> ${isOrAre} missing. <br><br>  Are you sure you want to upload?`,
      cancelText: 'Cancel',
      confirmText: 'Ok'
    };
    this.firstService.dialogService.open(options);
    this.firstService.dialogService.confirmed().subscribe(confirmed => {
      (async () => {
        if (confirmed) {
          this.showCompanionDataDialog('add', {});
        }
      })();
    });
  }

  onDownloadModel(): void {
    this.downloadModel.emit(this.modelToFiles);
  }

  onShowFileTimeline(modelFolderFile: ModelFolderFile): void {
    const fileName = this.secondService.fileUtilsService.getFileName(modelFolderFile.fileInfo.path);
    this.addAdditionalTab({
      title: `${fileName}`,
      type: 'file-timeline',
      modelFolderFile: modelFolderFile
    } as AdditionalTabInfo);
  }

  onModelTimeline(): void {
    this.addAdditionalTab({ title: `Model Timeline`, type: 'model-timeline' } as AdditionalTabInfo);
  }

  onCollectionTimeline(): void {
    this.addAdditionalTab({ title: `Collection Timeline`, type: 'collection-timeline' } as AdditionalTabInfo);
  }

  onSimulationLinkage(): void {
    this.addAdditionalTab({
      title: 'Simulation Linkage',
      type: 'simulation-linkage',
      icon: 'link'
    } as AdditionalTabInfo);
  }

  filterTabOfType(type) {
    return this.additionalTabs.filter(x => x.type === type);
  }
  filterTabOfTypeNot(type) {
    return this.additionalTabs.filter(x => x.type !== type);
  }

  addAdditionalTab(additionalTab: AdditionalTabInfo) {
    const existingIndex = this.additionalTabs.findIndex(p => p.type === additionalTab.type);
    if (existingIndex !== -1) {
      this.additionalTabs.splice(existingIndex, 1);
    }
    this.additionalTabs.push(additionalTab);
    this.tabSelectedIndex = this.additionalTabs.length + 1;
  }

  onCloseTab(index: number) {
    this.additionalTabs.splice(index, 1);
  }

  onClearSearch(): void {
    this.searchText = '';
  }

  onExpandTree(): void {
    this.modelTreeCollapserService.expand();
  }
}
