import { Inject, Injectable } from "@angular/core";
import { MatDrawer } from "@angular/material/sidenav";
import { SESSION_STORAGE, StorageService } from "ngx-webstorage-service";

@Injectable({
    providedIn: 'root'
})

export class ModelTreeCollapserService {
    private _currentWidth: number = 20;
    private _drawer: MatDrawer;
    private readonly INCREMENT = 20;
    private readonly MIN_WIDTH = 0;
    private readonly MAX_WIDTH = 100;
    private readonly SESSION_STORAGE_KEY = 'model-tree-collapser-width';

    constructor(
        @Inject(SESSION_STORAGE) private localStorage: StorageService<number>,
    ) {

    }

    public get width(): number {
        return this._currentWidth;
    }

    private set width(val: number) {
        this._currentWidth = val;
        this.localStorage.set(this.SESSION_STORAGE_KEY, val);
    }

    public get isOpenEnabled(): boolean {
        return this.width === this.MIN_WIDTH;
    }

    public get isCloseEnabled(): boolean {
        return this.width > this.MIN_WIDTH;
    }

    public get isExpandEnabled(): boolean {
        return this.width < this.MAX_WIDTH;
    }


    public get isCollapseEnabled(): boolean {
        return this.width > this.MIN_WIDTH;
    }

    init(drawer: MatDrawer) {
        this._drawer = drawer;
        let width = this.localStorage.get(this.SESSION_STORAGE_KEY) ?? 0;
        this.width = width;
        if (width > this.MIN_WIDTH) {
            drawer.open();
        }
    }

    open() {
        if (this.isOpenEnabled) {
            this.width += this.INCREMENT;
            this._drawer.open();
        }
    }

    close() {
        if (this.isCloseEnabled) {
            this._drawer.close();
            this.width = this.MIN_WIDTH;
        }
    }

    expand() {
        if(this.width === this.MIN_WIDTH)
            this._drawer.open();
        
        if (this.width < this.MAX_WIDTH) {
            this.width += this.INCREMENT;
        }
    }

    expandComplete() {
        
        if(this.width === this.MIN_WIDTH)
            this._drawer.open();
        
        if (this.width < this.MAX_WIDTH) {
            this.width = this.MAX_WIDTH;
        }
    }

    collapse() {
        if (this.width > this.MIN_WIDTH) {
            this.width -= this.INCREMENT;
        }
    }

}