  <h1 mat-dialog-title>Browse Model Folders</h1>
  <div mat-dialog-content>
    <div>
      <p>choose folder with model files</p>
      <button mat-raised-button color="primary" (click)="modelFolderInput.click()">
        <mat-icon class="new-folder-pad-right">create_new_folder</mat-icon>
        <span style="padding-left:0.8rem">Choose Folder</span>
      </button>
      <input type="file" style="display:none;" #modelFolderInput id="file" name="file"
             (change)="onModelFolderSelect($event.target['files'])"
             webkitdirectory webkitEntries/>
    </div>
   <div *ngIf="modelFiles.length > 0">
     <mat-divider></mat-divider>
     <label id="model-radio-group-label">Pick the model to preview</label>
     <mat-radio-group
       aria-labelledby="model-radio-group-label"
       class="model-radio-group" [(ngModel)]="currentInfo.selectedModel">
       <mat-radio-button class="model-radio-button" *ngFor="let modelFile of modelFiles" [value]="modelFile">
         <div fxLayout="row" fxLayoutAlign="start center">
           <div *ngIf="!modelFile.simulationMatched">
             {{modelFile.modelFile.name}}
           </div>
           <div class="update-warn" *ngIf="modelFile.simulationMatched">
             {{modelFile.modelFile.name}} (Update Mode)
           </div>
         </div>
       </mat-radio-button>
     </mat-radio-group>
   </div>

  </div>
  <div mat-dialog-actions>
    <button mat-raised-button (click)="onCancelClick()" color="warn">Cancel</button>
    <button *ngIf="currentInfo.selectedModel && !currentInfo.selectedModel.simulationMatched" color="primary" mat-raised-button [mat-dialog-close]="currentInfo"  cdkFocusInitial>Preview</button>
    <button *ngIf="currentInfo.selectedModel?.simulationMatched" [mat-dialog-close]="currentInfo" mat-raised-button color="primary"  cdkFocusInitial>Preview Update</button>
  </div>

