import { Injectable } from "@angular/core";
import { filter, first, map, Observable, of, ReplaySubject, Subject } from "rxjs";
import * as SparkMD5 from 'spark-md5';

@Injectable({
  providedIn: 'root'
})
export class MD5HasherService {
  private readonly fileHashWorker: Worker;
  private notificationsSubject = new Subject<{ id: string, hash: string }>();


  constructor() {
    this.fileHashWorker = new Worker(new URL(`./md5-file-hasher.worker`, import.meta.url));

    this.fileHashWorker.onmessage = (message: { data: { id: string, hash: string } }) => {
      this.notificationsSubject.next(message.data);
    };

    this.fileHashWorker.onerror = (ev: ErrorEvent) => {
      console.error(`hashWorker got error`, ev);
    };

    this.fileHashWorker.onmessageerror = (ev: MessageEvent) => {
      console.error(`hashWorker got message error`, ev);
    };
  }


  public getHashForContent(content: string) {
    return SparkMD5.hash(content);
  }

  public calculateForFile(file: File): Observable<string> {
    const hashFileId = this.getIdForFile(file);
    try {
      this.fileHashWorker.postMessage({ command: 'calculate', file: file, id: hashFileId });
      return this.notificationsSubject.pipe(filter(p => p.id === hashFileId), first(), map(p => p.hash));
    } catch (e) {
      console.error(`error in hashWorker`, e, file);
      return of('');
    }
  }

  public cleanupForFile(file: File): void {
    const hashFileId = this.getIdForFile(file);
    this.fileHashWorker.postMessage({ command: 'cancel', id: hashFileId });
  }


  public cleanUpAll() {
    this.fileHashWorker.postMessage({ command: 'cancelAll' });
  }

  private getIdForFile(file: File): string {
    const spark = new SparkMD5();
    spark.append(file.name);
    spark.append(file.webkitRelativePath);
    spark.append(`${file.size}`);
    spark.append(`${file.lastModified}`);
    return spark.end();
  }


  

}
