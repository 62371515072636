import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { autoUnsubscribe } from 'src/app/core/decorators/auto-unsubscribe.decorator';
import { FlatTreeControl, NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener, MatTreeNestedDataSource } from '@angular/material/tree';
import { first, Observable, switchMap, tap, zip, of } from 'rxjs';
import { ModelFile } from 'src/app/core/models/model-file';
import { FileUtilsService } from 'src/app/core/services/file-utils.service';
import { ModelComparisonService, ModelToCompare } from 'src/app/core/api/model-comparison.service';
import { MatDialog } from '@angular/material/dialog';
import { CompareFilesDialog, CompareFilesDialogData } from './compare-files.dialog';
import { ModelService } from 'src/app/core/api/model.service';
import { autoMonitorPageView } from 'src/app/core/decorators/auto-monitor-page-view.decorator';
import { MonitoringService } from 'src/app/core/services/monitoring.service';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';
import { OpenFileDialog, OpenFileDialogData } from './open-file.dialog';
import { SimulationDownloadService } from 'src/app/core/api/simulation-download.service';

interface ComparisonDetails {
  path: string;
  parentFolder: string;
  fileName: string;
  differenceType: DifferenceType;
  viewable: boolean;
  leftFile?: ModelFile;
  rightFile?: ModelFile;
  order: number;
}

enum DifferenceType {
  Similar,
  Different,
  LeftOnly,
  RightOnly
}

@autoMonitorPageView({ name: 'Model Comparison Tree Dialog', trackOnInitToAfterInit: false })
@Component({
  selector: 'app-model-comparison-tree-dialog',
  templateUrl: './model-comparison-tree.dialog.html',
  styleUrls: ['./model-comparison-tree.dialog.scss'],
})
export class ModelComparisonTreeDialog implements OnInit, AfterViewInit {
  leftSimulation: ModelToCompare;
  rightSimulation: ModelToCompare;
  inProgress: boolean = false;
  files: ComparisonDetails[] = [];
  searchResults: ComparisonDetails[] = [];
  folderDisplayedColumns = ['index', 'options', 'name', 'changeType'];
  searchText = '';
  filter = -1;

  constructor(
    private modelService: ModelService,
    modelComparisonService: ModelComparisonService,
    private dialog: MatDialog,
    private fileUtilsService: FileUtilsService,
    private monitoringService: MonitoringService,
    private simulationDownloadService: SimulationDownloadService
  ) {
    this.inProgress = true;
    modelComparisonService.currentModelsForComparison
      .pipe(
        first(),
        switchMap(models => {
          this.leftSimulation = models[0];
          this.rightSimulation = models[1];
          return of('');
        })
      )
      .subscribe(_ => {
        this.inProgress = false;
      });
  }

  ngAfterViewInit(): void {
    // do nothing
  }

  ngOnInit(): void {
    this.inProgress = true;
    this.bindData().subscribe(_ => (this.inProgress = false));
  }

  bindData(): Observable<any> {
    return zip([
      this.modelService.getFilesForModel(this.leftSimulation.simulationId),
      this.modelService.getFilesForModel(this.rightSimulation.simulationId)
    ]).pipe(
      tap(([leftSimulationFiles, rightSimulationFiles]) => {
        this.files = this.getAllDistinctfiles(leftSimulationFiles, rightSimulationFiles).sort((a, b) => {
          return a.order - b.order;
        });
        this.onSearch();
      })
    );
  }

  getAllDistinctfiles(leftSimulationFiles: ModelFile[], rightSimulationFiles: ModelFile[]): ComparisonDetails[] {
    const distinctfiles: ComparisonDetails[] = leftSimulationFiles.map((p, idx) => {
      return {
        path: p.likelyPath ?? p.path,
        parentFolder: this.fileUtilsService.getParentFolderPath(p.likelyPath ?? p.path),
        fileName: this.fileUtilsService.getFileName(p.path),
        differenceType: DifferenceType.LeftOnly,
        viewable: environment.viewableExtensions.some(x => x === this.fileUtilsService.getFileExtension(p.path)),
        leftFile: p,
        order: p.path.split('/').length
      };
    });

    rightSimulationFiles.forEach((p, idx) => {
      const existingFile = distinctfiles.find(
        x => (p.likelyPath ?? p.path).toLocaleLowerCase() === x.path.toLocaleLowerCase()
      );
      if (existingFile) {
        existingFile.rightFile = p;
        if (existingFile.leftFile.md5 === p.md5) {
          existingFile.differenceType = DifferenceType.Similar;
        } else {
          existingFile.differenceType = DifferenceType.Different;
        }
      } else {
        distinctfiles.push({
          path: p.likelyPath ?? p.path,
          parentFolder: this.fileUtilsService.getParentFolderPath(p.likelyPath ?? p.path),
          fileName: this.fileUtilsService.getFileName(p.path),
          differenceType: DifferenceType.RightOnly,
          viewable: environment.viewableExtensions.some(x => x === this.fileUtilsService.getFileExtension(p.path)),
          rightFile: p,
          order: p.path.split('/').length
        });
      }
    });
    return distinctfiles;
  }

  getFolders(): string[] {
    return _.uniq(this.searchResults.map(p => p.parentFolder));
  }

  getFilesToBeDisplayed(folder: string): ComparisonDetails[] {
    return this.searchResults.filter(p => p.parentFolder === folder);
  }

  onSearch() {
    this.searchResults = (this.files ?? []).filter(p =>
      p.path.toLocaleLowerCase().search(this.searchText.toLocaleLowerCase()) > -1 && this.filter === -1
        ? true
        : p.differenceType === this.filter
    );
  }

  onClearSearch() {
    this.searchText = '';
    this.searchResults = this.files ?? [];
  }

  onApplyFilter(filter: number) {
    this.filter = filter;
    this.onSearch();
  }

  getColor(filter: number) {
    if (this.filter === filter) return 'black';
    return '';
  }

  onView(modelFile: ModelFile): void {
    const dialogRef = this.dialog.open(OpenFileDialog, {
      data: {
        fileId: modelFile.id,
        fileName: modelFile.path,
        allowEdit: false
      } as OpenFileDialogData
    });

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(result => {});
  }

  onDownload(modelFile: ModelFile): void {
    this.simulationDownloadService
      .getOneDownLoadedFile(modelFile.id)
      .pipe(first())
      .subscribe((response: { url: string }) => {
        window.open(response.url);
      });
  }

  onCompare(leftFile: ModelFile, rightFile: ModelFile) {
    const fileName = this.fileUtilsService.getFileName(leftFile.path);
    this.dialog.open(CompareFilesDialog, {
      width: '80vw',
      height: '80vh',
      data: {
        title: `${fileName}`,
        leftFileId: leftFile.id,
        rightFileId: rightFile.id
      } as CompareFilesDialogData
    });
  }
}
