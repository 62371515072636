import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable, of, Subject, switchMap, throwError} from "rxjs";
import * as SparkMD5 from "spark-md5";
import {FileUtilsService} from "./file-utils.service";
import {environment} from "../../../environments/environment";


export interface FileUploadStatus {
  progress: number,
  containerName: string,
  filePath: string,
  status: 'complete' | 'error' | 'progress'
}

//this service depends on navigator['locks-BlobStorageUploaderService'] which is experimental
@Injectable({
  providedIn: 'root'
})
export class BlobStorageUploaderService {

  private uploadingFiles: Map<string, BehaviorSubject<FileUploadStatus>>;
  private readonly fileUploadWorker: Worker;

  constructor(private fileUtilsService: FileUtilsService) {
    this.fileUploadWorker = new Worker(new URL(`./blob-storage-uploader.worker`, import.meta.url));
    this.uploadingFiles = new Map<string, BehaviorSubject<FileUploadStatus>>();

    this.fileUploadWorker.onmessage = (message: { data: { id: string, status: 'complete' | 'error' | 'progress', progress?: number } }) => {
      navigator['locks'].request(message.data.id, (_) => {
        const subscriber = this.uploadingFiles.get(message.data.id);
        if (subscriber && subscriber.value.status !== 'error' && message.data.status !== 'error') {
          subscriber.next(Object.assign(subscriber.value, message.data) as FileUploadStatus);
          if(message.data.status === 'complete') {
            subscriber.complete();
            this.uploadingFiles.delete(message.data.id);
          }
        }
        else if (subscriber && message.data.status === 'error') {
          subscriber.error( { message: `could not upload the file`, lastVal: subscriber.value});
        }
        else {
          console.error('could not find subscriber for ', message);
        }
      });
    };
  }

  private getIdForFile(completeFilePath: string, file: File, parentId: string): string {
    const spark = new SparkMD5();
    spark.append(parentId);
    spark.append(completeFilePath);
    spark.append(`${file.size}`);
    spark.append(`${file.lastModified}`);
    return spark.end();
  }

  public uploadFile(completeFilePath: string, file: File, parentId: string, sasContainerUrl: string): Observable<FileUploadStatus> {
    const sasContainerUrlParts = sasContainerUrl.split('?');
    const containerUrl = sasContainerUrlParts[0];
    const sasToken = sasContainerUrlParts[1];
    return of(true).pipe(switchMap(_ => {
      const hashFileId = this.getIdForFile(completeFilePath, file, parentId);
      const subscriber = this.uploadingFiles.get(hashFileId);
      if (subscriber) {
        console.warn(`this file is already being uploaded`, file);
        return subscriber.asObservable();
      }
      const statusSubject = new BehaviorSubject<FileUploadStatus>({
        status: 'progress',
        progress: 0,
      } as FileUploadStatus);
      navigator['locks'].request(hashFileId, (_) => {
        this.uploadingFiles.set(hashFileId, statusSubject);
        this.fileUploadWorker.postMessage({
          command: 'upload',
          id: hashFileId,
          containerUrl: containerUrl,
          sasToken: sasToken,
          file: file,
          completeFilePath: completeFilePath,
          blocksToBeRead: this.fileUtilsService.getBlocks(file.size, environment.maxFileUploadChunkSize)
        });
      });
      return statusSubject.asObservable();
    }));

  }

  public cleanUpAll() {
    this.uploadingFiles.forEach((val, key) => {
      this.cleanupForKey(key);
    });
  }

  public cleanupForFile(filePath: string, file: File, parentId: string): void {
    const hashFileId = this.getIdForFile(filePath, file, parentId);
    this.cleanupForKey(hashFileId);
  }


  private cleanupForKey(hashFileId: string): void {
    navigator['locks'].request(hashFileId, (_) => {
      this.fileUploadWorker.postMessage({command: 'cancel', id: hashFileId});
      let subscriber = this.uploadingFiles.get(hashFileId);
      if (subscriber) {
        subscriber.complete();
        this.uploadingFiles.delete(hashFileId);
      } else {
        console.error('could not find subscriber to destroy for ', hashFileId);
      }
    });
  }

}
