import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, SimpleChanges} from "@angular/core";
import {SlbLoadingService} from "@slb-dls/angular-material/loading";
import {MatDialog} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {BehaviorSubject, Observable, of, Subscription, switchMap, tap} from "rxjs";
import {debounceTime, map} from "rxjs/operators";
import {OpenRevisionChangesData, OpenRevisionChangesDialog} from "../dialogs/open-revision-changes.dialog";
import {CollectionRevision} from "../../../core/models/collection-revision";
import {CollectionsService} from "../../../core/api/collections.service";
import {CompareCollectionsDialog, CompareCollectionsDialogData} from "../dialogs/compare-collections.dialog";

@Component({
  selector: 'app-collection-timeline',
  templateUrl: './collection-timeline.component.html',
  styleUrls: ['./collection-timeline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CollectionTimelineComponent {

  @Input() collectionId: string;

  private inputChange = new BehaviorSubject(false);
  private inputChange$: Subscription;


  inProgress: boolean;
  collectionRevisions: CollectionRevision[];

  constructor(private collectionsService: CollectionsService,
              private cdr: ChangeDetectorRef,
              private loadingService: SlbLoadingService,
              private dialog: MatDialog,
              private router: Router) {
    this.inputChange$ = this.inputChange.pipe(tap(() => {
      this.inProgress = true;
    }), debounceTime(100), switchMap(hasValue => {
      if(!hasValue) return of(false);
      return this.getRevisions().pipe(map(p => {
        this.collectionRevisions = [...p].sort((a, b) => a.index - b.index).map((value, idx) => {
          return Object.assign({idx: idx + 1}, value);
        });
        this.inProgress = false;
        this.cdr.detectChanges();
        return true;
      }));
    })).subscribe();
  }


  getRevisions(): Observable<CollectionRevision[]> {
    return this.collectionsService.getRevisionsForCollection(this.collectionId);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.inputChange.next(!!this.collectionId);
  }

  ngOnDestroy(): void {
    console.log('CollectionTimelineComponent OnDestroy');
  }

  onView(collectionRevision: CollectionRevision) {
    this.router.navigateByUrl(`/collection/${this.collectionId}?r=${collectionRevision.id}`);
  }

  onViewRevisionChanges(collectionRevision: CollectionRevision) {
    this.dialog.open(OpenRevisionChangesDialog, {
      data: {
        revisionId: collectionRevision.id
      } as OpenRevisionChangesData,
    });
  }

  getRevisionsThatCanBeCompared(collectionRevision: CollectionRevision): CollectionRevision[] {
    return this.collectionRevisions.filter(p => p.id !== collectionRevision.id);
  }

  onCompare(collectionRevision: CollectionRevision, compareRevision: CollectionRevision): void {
    let leftRevision = collectionRevision, rightRevision = compareRevision;
    if(leftRevision.createdDateTime > rightRevision.createdDateTime) {
      leftRevision = compareRevision;
      rightRevision = collectionRevision;
    }

    this.dialog.open(CompareCollectionsDialog, {
      width: '80vw',
      height: '80vh',
      data: {
        leftRevision,
        rightRevision,
      } as CompareCollectionsDialogData,
    });

  }
}
