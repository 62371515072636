import {BehaviorSubject, Observable, Subject, tap, timer} from 'rxjs';
import {Injectable, TemplateRef} from '@angular/core';
import {SubTaskInProgress, TaskInProgress, TaskInProgressWithChangeSubscriptions} from '../models/task-in-progress';
import {v4 as uuidv4} from 'uuid';
import * as moment from "moment";


@Injectable({
  providedIn: 'root'
})

export class TasksInProgressService {

  private _tasksInProgress: TaskInProgressWithChangeSubscriptions[] = [];
  private _tasksInProgressWithChanges: BehaviorSubject<TaskInProgressWithChangeSubscriptions[]> = new BehaviorSubject([]);
  get tasksInProgressWithChanges(): Observable<TaskInProgressWithChangeSubscriptions[]> {
    return this._tasksInProgressWithChanges.asObservable();
  }
  private _otherChannelTasksInProgress: (TaskInProgressWithChangeSubscriptions & {lastUpdated: Date})[] = [];

  private channel = new BroadcastChannel('redms-tasks-in-progress');

  constructor() {
    this.channel.addEventListener ('message', (event) => {
      const channelData = event.data as {event: string, data: any};
      if(channelData.event === 'task-change') {
        this.taskChange(channelData);
        // leave for comparing as sonarqube scan
        // const otherChannelTasks = channelData.data as TaskInProgressWithChangeSubscriptions[]
        // otherChannelTasks.forEach(p => {
        //   let task = this._otherChannelTasksInProgress.find(x => x.id === p.id)
        //   if(!task) {
        //     this._otherChannelTasksInProgress.push(Object.assign(p, {lastUpdated: new Date()}))
        //   } else {
        //     for(let key in task) {
        //       if(key !== 'id' && key !== 'taskChange' && key !== 'lastUpdated')
        //         task[key] = p[key]
        //     }
        //   }
        // })
        // this._tasksInProgressWithChanges.next(this._tasksInProgress.concat(this._otherChannelTasksInProgress))
      } else if(channelData.event === 'task-delete') {
        this.taskDelete(channelData);
        // leave for comparing as sonarqube scan
        // const idx = this._otherChannelTasksInProgress.findIndex(x => x.id === channelData.data)
        // if(idx > -1) this._otherChannelTasksInProgress.splice(idx, 1)
        // this._tasksInProgressWithChanges.next(this._tasksInProgress.concat(this._otherChannelTasksInProgress))
      } else if(channelData.event === 'task-revert-back-status') {
        this.taskRevertBack(channelData);
        // leave for comparing as sonarqube scan
        // const idleTasks = channelData.data
        // const tasks = this._tasksInProgress.filter(p => !idleTasks.some(x => x.id === p.id))
        // if(tasks.length > 0) {
        //   //send the latest progress
        //   this.channel.postMessage({event: 'task-change', data: tasks.map(p => {
        //       const { id, progress, taskType, status, title} = p;
        //       return {id, progress, taskType, status, title, lastUpdated: new Date(), localTask: false}
        //     })})
        // }
      }
    });
    this.channel.postMessage({event: 'task-change', data: this._tasksInProgress});


    timer(1000, 10000).pipe(tap(_ => {
      const idleTasks = this._otherChannelTasksInProgress.filter(p => moment(new Date()).diff(moment(p.lastUpdated), 'seconds') > 10);
      if(idleTasks.length > 0) {
        this.channel.postMessage({event: 'task-revert-back-status', data: idleTasks});
        this._otherChannelTasksInProgress = this._otherChannelTasksInProgress.filter(p => !idleTasks.some(x => x.id === p.id));
        this._tasksInProgressWithChanges.next(this._tasksInProgress.concat(this._otherChannelTasksInProgress));
      }
    })).subscribe();
  }

  private taskChange(channelData)
  {
    const otherChannelTasks = channelData.data as TaskInProgressWithChangeSubscriptions[];
    otherChannelTasks.forEach(p => {
      let task = this._otherChannelTasksInProgress.find(x => x.id === p.id);
      if(!task) {
        this._otherChannelTasksInProgress.push(Object.assign(p, {lastUpdated: new Date()}));
      } else {
        for(let key in task) {
          if(key !== 'id' && key !== 'taskChange' && key !== 'lastUpdated')
            task[key] = p[key];
        }
      }
    });
    this._tasksInProgressWithChanges.next(this._tasksInProgress.concat(this._otherChannelTasksInProgress));
  }

  private taskDelete(channelData)
  {
    const idx = this._otherChannelTasksInProgress.findIndex(x => x.id === channelData.data);
    if(idx > -1) this._otherChannelTasksInProgress.splice(idx, 1);
    this._tasksInProgressWithChanges.next(this._tasksInProgress.concat(this._otherChannelTasksInProgress));
  }

  private taskRevertBack(channelData)
  {
    const idleTasks = channelData.data;
    const tasks = this._tasksInProgress.filter(p => !idleTasks.some(x => x.id === p.id));
    if(tasks.length > 0) {
      //send the latest progress
      this.channel.postMessage({event: 'task-change', data: tasks.map(p => {
        const { id, progress, taskType, status, title} = p;
        return {id, progress, taskType, status, title, lastUpdated: new Date(), localTask: false};
      })});
    }
  }

  public startTask(task: TaskInProgress): Subject<SubTaskInProgress | {id:  string, progress: number, status?: 'NOT_STARTED' | 'IN_PROGRESS' | 'COMPLETED' | 'FAILED'}> {
    if(this._tasksInProgress.some(p => p.id === task.id))
      throw new Error(`task ${task.id} already exists`);
    const parentTaskInProgress = Object.assign(task, { localTask: true, taskChange: new BehaviorSubject<TaskInProgress>(task)});
    this._tasksInProgress.push(parentTaskInProgress);
    parentTaskInProgress.subTasks = [];
    this.broadcastChanges(parentTaskInProgress);
    const subTasksSubject = new Subject<SubTaskInProgress>();
    subTasksSubject.subscribe({
      next: (subTask: SubTaskInProgress | {id:  string, progress: number, status?: 'NOT_STARTED' | 'IN_PROGRESS' | 'COMPLETED' | 'FAILED'}) => {
        let existingTask = parentTaskInProgress.subTasks.find(p => p.id === subTask.id);
        if(existingTask) {
          existingTask.progress = subTask.progress;
          if(existingTask.status) existingTask.status = subTask.status;
        } else {
          parentTaskInProgress.subTasks.push(subTask as SubTaskInProgress);
        }
        parentTaskInProgress.progress = parentTaskInProgress.subTasks.map(p => p.progress).reduce((a, b) => a + b) / task.subTasks.length;
        this.broadcastChanges(parentTaskInProgress);
      },
      complete: () => {
        parentTaskInProgress.taskChange.complete();
        const id = parentTaskInProgress.id;
        const idx = this._tasksInProgress.findIndex(p => p.id === id);
        if(idx > -1) this._tasksInProgress.splice(idx, 1);
        this.channel.postMessage({event: 'task-delete', data: id});
        this.broadcastChanges();
      }
    });
    return subTasksSubject;
  }

  updateTask(task: {id: string, status: 'NOT_STARTED' | 'IN_PROGRESS' | 'COMPLETED' | 'FAILED' }): void {
    let existingTask = this._tasksInProgress.find(p => p.id === task.id);
    if(existingTask) {
      existingTask.status = task.status
      this.broadcastChanges(existingTask);

    } else {
      throw new Error(`task ${task.id} not found`);
    }
  }

  private broadcastChanges(task?: TaskInProgressWithChangeSubscriptions): void {
    if(task) {
      task.taskChange.next(task);
    }
    this._tasksInProgressWithChanges.next(this._tasksInProgress.concat(this._otherChannelTasksInProgress));
    this.channel.postMessage({event: 'task-change', data: this._tasksInProgress.map(p => {
        const { id, progress, taskType, status, title} = p;
        return {id, progress, taskType, status, title, lastUpdated: new Date(), localTask: false};
      })});
  }

  getTask(taskId: string) : TaskInProgressWithChangeSubscriptions {
    let existingTask = this._tasksInProgress.find(p => p.id === taskId);
    return existingTask!;
  }

}

