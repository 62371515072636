<h1 mat-dialog-title>Delete file</h1>
<div mat-dialog-content>
  Are you sure you want to delete {{data.fileName}}?
  <div *ngIf="warningMessage()"><button mat-icon-button aria-label="conflict" color="warn">
    <mat-icon>warning</mat-icon>
  </button>it is being referred in other models - {{warningMessage()}}
  </div>
</div>
<div mat-dialog-actions>
  <button mat-raised-button mat-dialog-close>Cancel</button>
  <button mat-raised-button color="warn" mat-dialog-close="true" cdkFocusInitial>Ok</button>
</div>
