import {Injectable} from "@angular/core";

import {CollectionsService} from "../../../../core/api/collections.service";
import {FileUtilsService} from "../../../../core/services/file-utils.service";
import {FilesWriterService} from "../../../../core/services/files-writer.service";
import {CollectionFilesService} from "../../../../core/api/collection-files.service";
import {CompanionDataService} from "src/app/core/api/companion-data.service";
import {ModelService} from "../../../../core/api/model.service";
import {ModelFilesService} from "../../../../core/services/model-files.service";



@Injectable({
    providedIn: 'root'
})
export class ViewCollectionPartOneNeededService {
  constructor(public collectionsService: CollectionsService,
    public fileUtilsService: FileUtilsService,
    public filesWriterService: FilesWriterService,
    public collectionFilesService: CollectionFilesService,
    public companionDataService: CompanionDataService,
    public modelsService: ModelService,
    public modelFilesService: ModelFilesService)
  {}
}