import {AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {autoUnsubscribe} from "../../../core/decorators/auto-unsubscribe.decorator";
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {CompareFilesDialog, CompareFilesDialogData} from "./compare-files.dialog";
import {first, tap, zip} from "rxjs";
import {MatTable} from "@angular/material/table";
import {FileUtilsService} from "../../../core/services/file-utils.service";
import {ModelVersion} from "../../../core/models/model-version";
import {ModelService} from "../../../core/api/model.service";
import {environment} from "../../../../environments/environment";
import { autoMonitorPageView } from "src/app/core/decorators/auto-monitor-page-view.decorator";
import { MonitoringService } from "src/app/core/services/monitoring.service";


export interface CompareModelsDialogData {
  leftVersion: ModelVersion,
  rightVersion: ModelVersion
}



interface FileComparison {
  idx: number,
  leftPath: string,
  leftName: string,
  leftSize: number,
  leftFileId: string,
  leftFolder: string,
  leftDate: Date,
  rightPath: string,
  rightName: string,
  rightSize: number,
  rightFileId: string,
  rightFolder: string,
  rightDate: Date,
  changeType: 'no-change' | 'modified' | 'deleted' | 'added',
  comparisonViewable: boolean
}


@Component({
  selector: 'app-compare-models-dialog',
  templateUrl: './compare-models.dialog.html',
  styleUrls: ['./compare-models.dialog.scss']
})
@autoMonitorPageView({ name: 'Compare Models Dialog', trackOnInitToAfterInit: false })
@autoUnsubscribe({autoInclude: true})
export class CompareModelsDialog implements OnInit, AfterViewInit, OnDestroy{
  inProgress = true;
  fileComparisons: FileComparison[] = [];
  displayedColumns: string[] = ['idx', 'leftName', 'leftFolder', 'leftSize', 'leftDate', 'rightName', 'rightFolder', 'rightSize', 'rightDate'];
  @ViewChild(MatTable) table: MatTable<FileComparison>;


  constructor(@Inject(MAT_DIALOG_DATA) public data: CompareModelsDialogData, private modelsService: ModelService,
              private fileUtilsService: FileUtilsService,
              private dialog: MatDialog, private monitoringService: MonitoringService) {

  }
  ngAfterViewInit(): void {
    // do nothing
  }

  ngOnInit(): void {
    this.inProgress = true;
    this.fileComparisons = [];
    zip(
      this.modelsService.getFilesForModel(this.data.leftVersion.id).pipe(first()),
      this.modelsService.getFilesForModel(this.data.rightVersion.id).pipe(first())
    ).pipe(first(), tap(([leftModelFiles, rightModelFiles]) => {
      leftModelFiles.forEach(collFile => {
        const rightFile = rightModelFiles.find(p => collFile.path === p.path);
        const comparisonViewable = environment.viewableExtensions.some(p => p === this.fileUtilsService.getFileExtension(collFile.path));
        const fileComparison: FileComparison = {
          idx: this.fileComparisons.length + 1,
          leftPath: collFile.path,
          leftName: this.fileUtilsService.getFileName(collFile.path),
          leftFileId: collFile.id,
          leftFolder: this.fileUtilsService.getParentFolderPath(collFile.path),
          leftSize: collFile.size / 1024,
          leftDate: collFile.fileModifiedDateTime,
          rightName: rightFile && this.fileUtilsService.getFileName(rightFile.path),
          rightFileId: rightFile?.id,
          rightFolder: rightFile && this.fileUtilsService.getParentFolderPath(rightFile.path),
          rightSize: !!rightFile ? rightFile.size / 1024 : null,
          rightPath: rightFile?.path,
          rightDate: collFile.fileModifiedDateTime,
          changeType: this.getChangeType(rightFile, collFile),
          comparisonViewable
        };
        this.fileComparisons.push(fileComparison);
      });

      rightModelFiles.forEach(collFile => {
        const rightFileExist = this.fileComparisons.find(p => collFile.path === p.leftPath) as FileComparison;
        if(!rightFileExist) {
          const comparisonViewable = environment.viewableExtensions.some(p => p === this.fileUtilsService.getFileExtension(collFile.path));
          this.fileComparisons.push({
            idx: this.fileComparisons.length + 1,
            leftPath: null,
            leftName: null,
            leftFileId: null,
            leftFolder: null,
            leftSize: null,
            leftDate: null,
            rightName: this.fileUtilsService.getFileName(collFile.path),
            rightFileId: collFile.id,
            rightFolder: this.fileUtilsService.getParentFolderPath(collFile.path),
            rightSize: collFile.size / 1024,
            rightPath: collFile.path,
            rightDate: collFile.fileModifiedDateTime,
            changeType: 'added',
            comparisonViewable
          } as FileComparison);
        }
      });

      this.inProgress = false;
      this.table.renderRows();
    })).subscribe();
  }

  ngOnDestroy(): void {
    console.log('on destroy CompareModelsDialog');
  }

  private getChangeType(rightFile, collFile)
  {
     if(!rightFile) {
       return 'deleted';
     }

     return (collFile.md5 === rightFile.md5)? 'no-change': 'modified';
  }

  onCompareFileVersions(fileName: string, leftFileId: string, rightFileId: string) {
    this.dialog.open(CompareFilesDialog, {
      width: '80vw',
      height: '80vh',
      data: {
        title: `${fileName}`,
        leftFileId: leftFileId,
        rightFileId: rightFileId
      } as CompareFilesDialogData,
    });
  }

}
