import {Inject, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {BehaviorSubject, Observable, of} from "rxjs";
import {AuthStatus} from "../models/auth-status";
import {LOCAL_STORAGE, StorageService} from "ngx-webstorage-service";
import {map} from "rxjs/operators";

export interface BusinessUnit {
  id: string;
  partitionId: string;
  partitionName: string;
  isDefault: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class BuService {
  private _currentBu = new BehaviorSubject<string>('');

  constructor(private _httpClient: HttpClient,
              @Inject(LOCAL_STORAGE) private localStorage: StorageService<string>
  ) {
    const buSelected = this.localStorage.get('selectedBu') ?? '';
    this._currentBu.next(buSelected);
  }

  get currentBu(): Observable<string> {
    return this._currentBu.asObservable();
  }

  getConfiguredBuList(): Observable<BusinessUnit[]> {
    const url = `${environment.apiEndpoint}/bu`;
    return this._httpClient.get<BusinessUnit[]>(url);
  }

  setCurrentBu(id: string) {
    this.localStorage.set('selectedBu', id);
    this._currentBu.next(id);
  }

  setDefaultCurrentBuIfNotSet(): Observable<boolean> {
    const buSelected = this.localStorage.get('selectedBu');
    if(buSelected) {
      return of(true);
    }
    return this.getConfiguredBuList().pipe(map(units => {
      const defaultSelectedBu = units.find(p => p.isDefault) ?? units[0];
      this.localStorage.set('selectedBu', defaultSelectedBu.id);
      this._currentBu.next(defaultSelectedBu.id);
      return true;
    }));
  }
  
  getDefaultEntitlements(): Observable<boolean> {
    const url = `${environment.apiEndpoint}/bu/defaultentitlements`;
    return this._httpClient.get<string>(url).pipe(map(values => {
      this.localStorage.set("defaultEntitlements", values);
      return true;
    }));
  }

}
