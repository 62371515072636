import { Injectable, TemplateRef } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import {SlbBreadcrumbsItem} from "@slb-dls/angular-material/breadcrumbs";

@Injectable({
  providedIn: 'root'
})
export class PageHeaderService {
  private toolbarTemplate: BehaviorSubject<TemplateRef<any>> = new BehaviorSubject(null);
  private pageTitle: BehaviorSubject<string> = new BehaviorSubject('');


  public setCurrentToolbarTemplate(val: TemplateRef<any>) {
    this.toolbarTemplate.next(val);
  }

  get currentToolbarTemplate(): Observable<TemplateRef<any>> {
    return this.toolbarTemplate.asObservable();
  }

  public setPageTitle(val: string) {
    this.pageTitle.next(val);
  }

  get currentPageTitle(): Observable<string> {
    return this.pageTitle.asObservable();
  }

}
