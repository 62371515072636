import { AfterViewInit, Component, OnInit, ViewEncapsulation } from "@angular/core";
import { SlbLoadingService } from "@slb-dls/angular-material/loading";
import { first } from "rxjs";
import * as _ from 'lodash';
import { CompanionDataService, ModelInCompanionData } from "src/app/core/api/companion-data.service";
import { MonitoringService } from "src/app/core/services/monitoring.service";
import { autoMonitorPageView } from "src/app/core/decorators/auto-monitor-page-view.decorator";
declare function initializeComponentDataTree(initData: any, nodeCallback?: (node: CompanionDataNode) => boolean): any;
declare function updateComponentDataNode(node: CompanionDataNode, d3Data: any, nodeCallback?: (node: CompanionDataNode) => boolean): void;
declare function toggleComponentDataNode(node: CompanionDataNode): void;

interface CompanionDataNode {
  name: string;
  description: string;
  type: 'root' | 'bu' | 'companion-data-category' | 'companion-data-category-value' | 'companion-data-category-next' | 'companion-data-collection' | 'companion-data-collection-next' | 'companion-data-model-folder' | 'companion-data-model',
  data?: any,
  children?: CompanionDataNode[],
  _children?: CompanionDataNode[]
}

@Component({
  selector: 'app-companion-data-tree',
  templateUrl: './companion-data-tree.component.html',
  styleUrls: ['./companion-data-tree.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@autoMonitorPageView({name: 'Companion Data Tree', trackOnInitToAfterInit: false })
export class CompanionDataTreeComponent implements OnInit, AfterViewInit {
  private chunkSize = 10;
  private d3Data: any;

  private initData = {
    name: "Chevron",
    description: "Root Node",
    type: "root",
    children: [
      {
        name: "GOM",
        description: "BU",
        type: "bu",
        children: [
          {
            name: "Asset",
            data: "data.AssetName",
            type: "companion-data-category",
            children: [
              {
                name: "Tahiti",
                data: "data.Reservoir",
                type: "companion-data-category",
                children: [
                  {
                    name: "N70 - Reservoir",
                    data: "data.BusinessPlanCase",
                    type: "companion-data-category",
                    children: [
                      {
                        name: "Business Plan Case",
                        data: "data.BusinessPlanCase",
                        type: "companion-data-category",
                        children: []
                      },
                      {
                        name: "Decision Frame",
                        data: "data.DecisionFrame",
                        type: "companion-data-category",
                        children: []
                      }
                      ,
                      {
                        name: "Model Objectives",
                        data: "data.ModelObjective",
                        type: "companion-data-category",
                        children: []
                      }
                    ]
                  },
                  {
                    name: "N90 - Reservoir",
                    data: "data.BusinessPlanCase",
                    type: "companion-data-category",
                    children: [
                      {
                        name: "Business Plan Case",
                        data: "data.BusinessPlanCase",
                        type: "companion-data-category",
                        children: []
                      },
                      {
                        name: "Decision Frame",
                        data: "data.DecisionFrame",
                        type: "companion-data-category",
                        children: []
                      }
                      ,
                      {
                        name: "Model Objectives",
                        data: "data.ModelObjective",
                        type: "companion-data-category",
                        children: []
                      }
                    ]
                  }
                  
                ]
              },
              {
                name: "Blind Faith",
                data: "data.Reservoir",
                type: "companion-data-category" 
              }
            ]
          }

        ]
      }
    ]
  }


  constructor(private companionDataService: CompanionDataService, private loadingService: SlbLoadingService, private  monitoringService: MonitoringService) {
  }
  ngOnInit(): void {
    // throw new Error("Method not implemented.");
  }

  ngAfterViewInit(): void {
    this.d3Data = initializeComponentDataTree(this.initData, this.nodeSelected.bind(this));
    updateComponentDataNode(this.d3Data.root, this.d3Data, this.nodeSelected.bind(this));
  }

  nodeSelected(node: CompanionDataNode): boolean {
    if (node.children || node._children) {
      return false;
    } else {
      if (node.type === "companion-data-category") {
        this.loadCategoryValues(node);
      } else if (node.type === "companion-data-category-value") {
        this.loadingService.showSpinner({ text: `retrieving model(s) with ${node.data.category}=${node.data.value} ` });
        this.companionDataService.getModelsFor(node.data.category, node.data.value).pipe(first()).subscribe((p: ModelInCompanionData[]) => {
          const byCollectionName = _.groupBy(p, 'collectionName');
          const arrByCollectionName = [];
          for (const [key, value] of Object.entries(byCollectionName)) {
            arrByCollectionName.push(value);
          }
          const totalLength = arrByCollectionName.length;
          const chunks = this.spliceIntoChunks(arrByCollectionName, this.chunkSize);
          let parentNode = node;
          let i = 0;
          chunks.forEach((chunk, idx, arr) => {
            parentNode._children = chunk.map((x: ModelInCompanionData[]) => {
              i++;
              return {
                name: `${i}. ${x[0].collectionName}`,
                description: `${x[0].collectionName}`,
                data: x[0],
                type: "companion-data-collection",
                _children: x.map(n => {
                  return {
                    name: `${n.modelName}`,
                    description: `${x[0].collectionName} - ${n.modelName}`,
                    data: n,
                    type: "companion-data-model",
                  };
                })
              };
            });

            if (idx < arr.length - 1) {
              let nextPageStart = ((idx + 1) * this.chunkSize) + 1;
              let nextPageEnd = (nextPageStart + this.chunkSize - 1);
              if (nextPageEnd > totalLength) {
                nextPageEnd = totalLength;
              }
              const nextNode = {
                name: 'Next',
                description: (nextPageStart === nextPageEnd) ? `Collection ${nextPageStart}` : `Collection ${nextPageStart} - ${nextPageEnd}`,
                type: "companion-data-collection-next"
              } as CompanionDataNode;
              parentNode._children.push(nextNode);
              parentNode = nextNode;
            }

          });

          toggleComponentDataNode(node);
          updateComponentDataNode(node, this.d3Data, this.nodeSelected.bind(this));
          this.loadingService.closeSpinner();

        });
      } else {


      }
      return true;
    }

  }

  loadCategoryValues(node: CompanionDataNode) {
    this.loadingService.showSpinner({ text: `retrieving ${node.name} value(s)` });
    this.companionDataService.getCompanionDataValuesFor(node.data).pipe(first()).subscribe((p: string[]) => {
      const totalLength = p.length;
      const chunks = this.spliceIntoChunks(p, this.chunkSize);
      let parentNode = node;
      let i = 0;
      chunks.forEach((chunk, idx, arr) => {
        parentNode._children = chunk.map(x => {
          i++;
          return {
            name: `${i}. ${x}`,
            description: `${x}`,
            data: { category: node.data, value: x },
            type: "companion-data-category-value"
          };
        });

        if (idx < arr.length - 1) {
          let nextPageStart = ((idx + 1) * this.chunkSize) + 1;
          let nextPageEnd = (nextPageStart + this.chunkSize - 1);
          if (nextPageEnd > totalLength) {
            nextPageEnd = totalLength;
          }
          const nextNode = {
            name: 'Next',
            description: (nextPageStart === nextPageEnd) ? `Value ${nextPageStart}` : `Values ${nextPageStart} - ${nextPageEnd}`,
            type: "companion-data-category-next",
            children: []
          } as CompanionDataNode;
          parentNode._children.push(nextNode);
          parentNode = nextNode;
        }

      });

      toggleComponentDataNode(node);
      updateComponentDataNode(node, this.d3Data, this.nodeSelected.bind(this));
      this.loadingService.closeSpinner();
    });
  }

  spliceIntoChunks(arr: any[], chunkSize): any[] {
    const res = [];
    while (arr.length > 0) {
      const chunk = arr.splice(0, chunkSize);
      res.push(chunk);
    }
    return res;
  }

}


