import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { HomePageComponent } from './components/pages/home/home.component';
import {ThemeSwitcherComponent} from "./theme-switcher/theme-switcher.component";
import { SearchCollectionsComponent } from './components/pages/collections/collections/search-collections.component';
import { ModelsComponent } from './components/pages/models/models/models.component';
import { CompanionDataTreeComponent } from './components/pages/models/companion-data-tree/companion-data-tree.component';



const routes: Routes = [
  {
    path: 'home',
    component: HomePageComponent,
    data: {
      title: 'Home',
    }
  },
  {
    path: 'theme',
    component: ThemeSwitcherComponent,
    data: {
      title: 'Home',
    }
  },
  {
    path: 'collections',
    component: SearchCollectionsComponent,
    loadChildren: () => import('./components/pages/collections/collections.module').then(m => m.CollectionsModule),
    data: {
      title: 'Collections',
    }
  },
  {
    path: 'models',
    component: ModelsComponent,
    loadChildren: () => import('./components/pages/models/models.module').then(m => m.ModelsModule),
    data: {
      title: 'Models',
    }
  },
  {
    path: 'companion-data-tree',
    component: CompanionDataTreeComponent,
    loadChildren: () => import('./components/pages/models/models.module').then(m => m.ModelsModule),
    data: {
      title: 'Companion Data Tree',
    }
  },
  {
    path: 'shared',
    loadChildren: () => import('./components/shared/shared.module').then(m => m.SharedModule)
  },
  { path: '',   redirectTo: '/home', pathMatch: 'full' }, // redirect to `first-component`
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
