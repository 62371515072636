import {Injectable} from "@angular/core";

import {RevisionsService} from "../../../../core/api/revisions.service";
import {CollectionFilesUploaderService} from "../../../../core/api/collection-files-uploader.service";
import {CollectionFilesDownloaderService} from "../../../../core/api/collection-files-downloader.service";
import {MD5HasherService} from "../../../../core/services/md5-hasher.service";
import { ModelUploadTaskInProgressService } from "src/app/core/services/model-upload-task-in-progress.service";
import { SimulationDownloadService } from "src/app/core/api/simulation-download.service";
import { ModelDownloadTaskInProgressService } from "src/app/core/services/model-download-task-in-progress.service";

@Injectable({
    providedIn: 'root'
})
export class ViewCollectionPartTwoNeededService {
  constructor(public revisionsService: RevisionsService,
    public collectionFilesUploaderService: CollectionFilesUploaderService,
    public collectionFilesDownloaderService: CollectionFilesDownloaderService,
    public modelDownloadTaskInProgressService: ModelDownloadTaskInProgressService,
    public modelUploadTaskInProgressService: ModelUploadTaskInProgressService,
    public md5HashService: MD5HasherService,
    public simulationDownloadService: SimulationDownloadService)
  {}
}