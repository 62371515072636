<!-- <div fxFill fxLayout="row" fxLayoutAlign="space-around center">
  <div fxLayout="column" fxLayoutAlign="start center" class="mat-elevation-z8 content-container">
    <div fxLayout="column" fxFill fxFlex="10">
      <h4 fxFlexAlign="center">Welcome to Chevron RE-DMS</h4>
      <mat-divider fxFlexFill fxFlexAlign="start"></mat-divider>
    </div>
    <div fxFlex="70" fxLayout="row" fxFill fxLayoutAlign="space-evenly center">
      <div fxLayout="column" fxLayoutAlign="start start" *ngFor="let infoCard of infoCards">
        <a [routerLink]="infoCard.infoCardRouterLink">
          <img class="item-width img-height" alt="info-card" src="{{ infoCard.infoCardImageUrl }}"/>
        </a>
        <button class="item-width" mat-raised-button color="primary" [routerLink]="infoCard.infoCardRouterLink">
            {{ infoCard.infoCardButtonText }}
          </button>
      </div>
    </div>
    <div fxFlex></div>
  </div>
</div>
 -->

<div class="container">
  <diV class="root-item mat-elevation-z8 content-container">
    <div class="header-item">
      <h4>Welcome to Chevron RE-DMS</h4>
      <mat-divider></mat-divider>
    </div>
    <div *ngFor="let infoCard of infoCards" class="item item-{{infoCard.id}}">
      <a [routerLink]="infoCard.infoCardRouterLink">
        <img class="item-width img-height" alt="info-card" src="{{ infoCard.infoCardImageUrl }}" />
      </a>
      <button class="item-width" mat-raised-button color="primary" [routerLink]="infoCard.infoCardRouterLink">
        {{ infoCard.infoCardButtonText }}
      </button>
    </div>
  </diV>
</div>