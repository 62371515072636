import {AfterViewInit, Component, OnInit, OnDestroy, ViewChild} from "@angular/core";
import {BreadcrumbsService} from "../../../../core/services/breadcrumbs.service";
import {ModelFromQuery, QueryModelsResponse, ModelWithCompanionData} from "../../../../core/models/query-models-response";
import {mergeModelCompanionData} from "../../../../util/model-companion-data.util";
import { getCompanionFields } from 'src/app/util/companion-data.util';
import { MatTableDataSource } from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {BehaviorSubject, catchError, first, mergeWith, of, Subscription, tap, throwError} from "rxjs";
import {autoUnsubscribe} from "../../../../core/decorators/auto-unsubscribe.decorator";
import {debounceTime, filter, map, switchMap} from "rxjs/operators";
import {ModelService} from "../../../../core/api/model.service";
import {environment} from "../../../../../environments/environment.prod";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {ActivatedRoute, Router} from "@angular/router";
import {Clipboard} from "@angular/cdk/clipboard";
import { FormControl, FormGroup } from "@angular/forms";
import { ModelsNeededService } from "./modelsneeded.service";
import { ModelToCompare } from "src/app/core/api/model-comparison.service";
import { MonitoringService } from "src/app/core/services/monitoring.service";
import { autoMonitorPageView } from "src/app/core/decorators/auto-monitor-page-view.decorator";

@autoUnsubscribe({autoInclude: true})
@autoMonitorPageView({ name: 'Models', trackOnInitToAfterInit: false })
@Component(
  {
    selector: 'app-models',
    templateUrl: './models.component.html',
    styleUrls: ['./models.component.scss'],
    animations: [
      trigger('detailExpand', [
        state('collapsed', style({height: '0px', minHeight: '0'})),
        state('expanded', style({height: '*'})),
        transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      ]),
    ],
  }
)
export class ModelsComponent implements OnInit, AfterViewInit, OnDestroy {

  displayedColumns: string[] = ['index', 'name', 'modelType', 'collectionName', 'modifiedUserId', 'modifiedDateTime', 'modelTags', 'expand'];
  dataSource = new MatTableDataSource<ModelWithCompanionData>();
  dataNew: ModelWithCompanionData[];
  savedFilter: any = {};
  response: QueryModelsResponse;
  companionOptions = {}; 
  companionFields : any;
  companionOperators = ['And', 'Or'];
  selectedCompanionOperator = 'And';
  pageSizeOptions: number[] = environment.pageSizeOptions;
  resultsLength: number;
  dataPageIndex: number;
  dataPageSize: number;
  currentFilterText: string;
  filterTextToBeSearched: string;
  studyId: string;
  dataTypeId: string;
  expandedElement: ModelFromQuery | null;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public formGroup: FormGroup;
  public companionDataFilters: boolean = false;
  private sort$: Subscription;
  private merge$: Subscription;

  searchTextChanged: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private activatedRouteParams$: Subscription;

  constructor(private modelsService: ModelService, 
              private router: Router, 
              private clipboard: Clipboard, 
              private activatedRoute: ActivatedRoute,
              private oneService: ModelsNeededService,
              private monitoringService: MonitoringService ) {
    this.oneService.breadcrumbsService.setCurrentBreadcrumbItems(
      [
        Object.assign(BreadcrumbsService.MODELS, {disabled: true})
      ]);

    this.activatedRouteParams$ = this.activatedRoute.queryParams.pipe(
      filter(p => p.sid && p.dtid),
      tap(p => {
        this.studyId = p.sid;
        this.dataTypeId = p.dtid;
    })).subscribe();
  }

  ngOnInit(): void {
    this.getCompanionFields();
    this.buildForm();
    let that = this;
    this.dataSource.filterPredicate = function(record, filter) {
      let filterFieldAndValues = JSON.parse(filter);
      if(!filterFieldAndValues || Object.keys(filterFieldAndValues).length < 1) {
        return true;
      }
      let filterResult: boolean = true;
      if(that.selectedCompanionOperator === 'Or') {
        filterResult = false;
      }
      Object.entries(filterFieldAndValues).forEach(fieldAndValue => {
        const [key, value] = fieldAndValue;
        let keyValue = record[key];
        if(!keyValue) {
          keyValue = 'NullValue';
        }
        // this for and
        if(that.selectedCompanionOperator  !== 'Or') {
          if((value as any).indexOf(keyValue) < 0) {
            filterResult = false;
            return;
          }
        }
        else {
          // this is for or
          if((value as any).indexOf(keyValue) > -1) {
            filterResult = true;
            return;
          }
        }
      });
      return filterResult;
    };
  }

  ngAfterViewInit() {
    this.sort$ = this.sort.sortChange.pipe(tap(() => (
      this.paginator.pageIndex = 0
    ))).subscribe();

    this.merge$ = this.searchTextChanged.pipe(debounceTime(500), tap(p => {
        this.paginator.pageIndex = 0;
        this.filterTextToBeSearched = p;
      }), mergeWith(this.sort.sortChange, this.paginator.page),
        switchMap(() => {
          this.oneService.loadingService.showSpinner({text: 'retrieving models'});
          let request = this.createRequest();
          return this.modelsService.getModels(request).pipe(catchError((err) => {
            console.error(err);
            return of({});
          }));
        }),
        map(data => {
          this.oneService.loadingService.closeSpinner(true);
          return this.applyMap(data);
        }),
      )
      .subscribe(data => {
        this.getSubscribeData(data);
      });


  }

  createRequest() {
    let request = {
      cursor: '',
      searchText: this.filterTextToBeSearched,
      limit: this.paginator.pageSize,
      sort: `${this.sort.direction}:${this.sort.active}`,
      companionDataFields: null
    };
    if(this.dataPageSize !== this.paginator.pageSize) {
      this.paginator.pageIndex = 0;
    }
    if(this.paginator.pageIndex === 0) {
      request.cursor = '';
    } else if(this.paginator.pageIndex < this.dataPageIndex && this.response.previousCursor) {
      request.cursor = this.response.previousCursor;
    } else if(this.paginator.pageIndex > this.dataPageIndex && this.response.nextCursor) {
      request.cursor = this.response.nextCursor;
    } else {
      request.cursor = '';
    }
    return request;
  }

  applyMap(data) {
    this.response = data;
    this.dataPageSize = this.paginator.pageSize;
    if (data === null) {
      this.resultsLength = 0;
      this.dataPageIndex = 0;
      return {};    }
    this.dataPageIndex = this.paginator.pageIndex;
    this.resultsLength = (this.paginator.pageSize * (this.paginator.pageIndex + 1)) + (data.nextCursor?1:0);
    return data;
  }

  // since data is the query response, need to make array of ModelWithCompanionData which comes from
  // QueryModelsResponse.models and companionDatas
  getSubscribeData(data: QueryModelsResponse) {
    this.dataNew = mergeModelCompanionData(data);
    this.dataNew.forEach((value, index) => {
      value['index'] = index;
    });
    
    // now create the options
    this.getCompanionOptions();

    this.dataSource.data = this.dataNew;
    this.dataSource.filter = JSON.stringify(this.savedFilter);
  }


  getSimulationType(modelName: string): string {
    return this.oneService.fileUtilService.getFileExtension(modelName);
  }

  ngOnDestroy(): void {
    console.log('models component on destroy called')
    this.searchTextChanged.complete();
  }

  applySearch(event: KeyboardEvent): void {
    const filterValue = (event.target as HTMLInputElement).value;
    if(event.key.length === 1 || filterValue !== this.currentFilterText) {
      this.currentFilterText = filterValue;
      this.searchTextChanged.next(filterValue);
    }
  }

  viewModel(element: ModelFromQuery): void {
    this.router.navigateByUrl(`/collection/${element.collectionId}?m=${element.id}`);
  }

  copyModelLink(element: ModelFromQuery): void {
    const simulationLinkToCopy = this.getSimulationLink(element.id);
    this.clipboard.copy(simulationLinkToCopy);
    this.oneService.toastrService.success(`simulation link has been copied to the clipboard`);
  }

  private getSimulationLink(simulationId: string): string {
    const simulationLink = environment.simulationLink;
    const simulationLinkToCopy = simulationLink.concat(simulationId);
    return simulationLinkToCopy;
  }

  pushToFDPlan(element: ModelFromQuery): void {
    this.oneService.loadingService.showSpinner({text: 'pushing details to FDPlan'});
    const simulationLink = this.getSimulationLink(element.id);
    this.modelsService.pushToFDPlan(element.id, { studyId: '', dataTypeId: '', modelUrl: simulationLink } )
      .pipe(
        first(),
        tap(_ => {
          this.oneService.loadingService.closeSpinner(true);
          this.oneService.toastrService.success('saved successfully');
        }),
        catchError(err => {
          this.oneService.toastrService.error('could not be saved');
          console.error(err);
          this.oneService.loadingService.closeSpinner(true);
          return throwError(err);
        })
      )
      .subscribe();
  }

  modelToCompare(element: ModelFromQuery): ModelToCompare {
    return {
      simulationId: element.revisionId,
      collectionName: element.collectionName,
      modelName: element.name
    };
  }

  buildForm() {
    const group: any = {};
    this.companionFields.forEach(control => {
      group[control.modelField] = new FormControl();
    });
    group['CompanionOperator'] = new FormControl();
    this.formGroup = new FormGroup(group);
  }

  // get the companion data fields which will show on the view
  getCompanionFields()
  {
    let companionFields = getCompanionFields();
    // filter out those does not have modelField
    companionFields = companionFields.filter(x => !!x.modelField);
    this.companionFields = companionFields;
    let companionDataFields = JSON.parse(this.oneService.storageService.get("companionDataFields"))
    let buName = companionDataFields?.buName;
    if(!!buName) {
      this.companionFields = companionFields.filter(x => this.filterFunction(x?.buName, buName))
    }
    else {
      this.companionFields = companionFields.filter(x => !x.buName)
    }
  }

  filterFunction(field: string[], buName: string) {
    if(!field) {
      return true;
    }
    if(field.includes(buName)) {
      return true;
    }
    return false;
  }

  // the options is for select choices for all the companion data field, get unique values for each modelField inside
  // the companion data
  getCompanionOptions()
  {
    this.companionOptions = {};
    this.companionFields.forEach(field => {
      let eachFieldValues = this.dataNew
                 .map(item => item[field.modelField])
                 .filter((value, index, self) => self.indexOf(value) === index);
      // fix the null or underfined in eachfieldvalues;
      eachFieldValues.push('NullValue');
      eachFieldValues = eachFieldValues
                 .map(item => item)
                 .filter((value, index, self) => {
                   if(!value) {
                     value = 'NullValue'
                   }
                   return self.indexOf(value) === index
                 });
      this.companionOptions[field.modelField] = eachFieldValues;
    });

    //if saved filter is not null, need to set selections
    if(Object.keys(this.savedFilter).length > 0) {
      Object.entries(this.savedFilter).forEach(fieldAndValue => {
        const [key, value] = fieldAndValue;
        let keyValues = this.companionOptions[key] as [];
        let selectedValues = [];
        keyValues.forEach(keyValue => {
          if((value as any).indexOf(keyValue) > -1) {
            selectedValues.push(keyValue);
          }
        });
        if(selectedValues.length > 0) {
          this.formGroup.controls[key].setValue(selectedValues);
        }
        else {
          this.formGroup.controls[key].setValue('');
        }
      });

      
    }
  }


  showCompanionDataFilters() {
    this.companionDataFilters = !this.companionDataFilters;
  }

  companionOperatorChanged(data) {
    this.selectedCompanionOperator = data;
  }

  public onFilterFormSubmit = (event: Event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  applyFilters() {
    this.savedFilter = this.getFilterObject();
    // only show page size option when no specific filter, next/previous page arrow still show
    let pageSize = this.paginator.pageSize;
    if(Object.keys(this.savedFilter).length < 1 ) {
      this.paginator.hidePageSize = false;
    }
    else {
      this.paginator.hidePageSize = true;
    }
    // have to set it to let it refresh
    this.paginator.pageSize = pageSize;
    this.dataSource.filter = JSON.stringify(this.savedFilter);
  }

  getFilterObject()
  {
    let filterObject = {}
    for(const controlField in this.formGroup.controls) {
      let selectedValues = this.formGroup.controls[controlField].value;
      if(!!selectedValues && selectedValues.length > 0 && controlField !== 'CompanionOperator') {
        filterObject[controlField] = selectedValues;
      }
    }
    return filterObject;
  }

  clearFilters() {
    for(const controlField in this.formGroup.controls) {
      if(controlField !== 'CompanionOperator') {
        this.formGroup.controls[controlField].setValue('');
      }
    }
    this.applyFilters();
  }
}
