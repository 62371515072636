import {AfterViewInit, ChangeDetectionStrategy, Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import { autoMonitorPageView } from "src/app/core/decorators/auto-monitor-page-view.decorator";
import { MonitoringService } from "src/app/core/services/monitoring.service";

@Component({
  selector: 'app-delete-file-confirmation-dialog',
  templateUrl: './delete-file-confirmation.dialog.html',
  styleUrls: ['./delete-file-confirmation.dialog.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@autoMonitorPageView({name: 'Delete File Dialog', trackOnInitToAfterInit: false })
export class DeleteFileConfirmationDialog implements OnInit, AfterViewInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: {fileName: string, referredSimulations: string[]},
  private monitoringService: MonitoringService ){

  }
  ngAfterViewInit(): void {
    // do nothing
  }
  ngOnInit(): void {
    // do nothing
  }

  warningMessage() {
    if(this.data.referredSimulations?.length > 0)
      return this.data.referredSimulations.join(',');
    return '';
  }
}
