import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SlbLoadingService } from '@slb-dls/angular-material/loading';
import { MessageService, SlbSeverity } from '@slb-dls/angular-material/notification';
import { CompanionDataService } from 'src/app/core/api/companion-data.service';
import { BrowserStorageService } from 'src/app/core/services/storage.service';
import { getCompanionFields } from 'src/app/util/companion-data.util';
import * as _ from 'lodash';
import { CollectionsService } from 'src/app/core/api/collections.service';
import { EntitlementsService } from "src/app/core/api/entitlements.service";
import { first, tap,pairwise, startWith, } from "rxjs";
import { EntitlementGroup } from "src/app/core/models/entitlement-group";
import { CompanionDataNeededService } from './companion-data-needed.service';
import { ModelFilesService } from 'src/app/core/services/model-files.service';

export enum EntitlementTypes {
  OWNERS = 'owners',
  VIEWERS = 'viewers'
}


@Component({
  selector: 'app-companion-data',
  templateUrl: './companion-data.component.html',
  styleUrls: ['./companion-data.component.scss']
})
export class CompanionDataComponent implements OnInit {
  companionDataForm: FormGroup;
  options = {};
  selectedValue: string;
  addCompanionData: boolean = false;
  color: any = 'primary';
  cancelColor: any = 'warn'
  companionFields : any;
  myEntitlementGroups: EntitlementGroup [] = [];
  assets: any;

  constructor(
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialog: MatDialogRef<CompanionDataComponent>,
              private oneService: CompanionDataNeededService) { }

  ngOnInit() {
    this.oneService.loadingService.showSpinner({ text: 'Loading...' });
    this.companionFields = this.getCompanionFieldsWithBU();
    this.getOptionsAndBuildForm();
  }

  // get options and buld the form froup
  getOptionsAndBuildForm()
  {
    // get options
    let companionDataFields = JSON.parse(this.oneService.storageService.get("companionDataFields"))
    this.options['Legal'] = companionDataFields["legalTags"];
    this.options['Countries'] = companionDataFields["countries"];
    const optionsBu =  this.getBuOptions(companionDataFields["companionDataSettings"]);
    this.options = {...this.options,...optionsBu};
    this.companionFields.forEach(field => {
      if ((field.type === 'select' || field.type === 'radio') && field?.options && field?.options.length !== 0) {
        this.options[field.key] = this.companionFields
          .filter(x => x.name === field.name)
          .find(x => x)
          .options.map(x => ({ name: x }));
      }
    });

    // get entitlementgroups from collection query directly
    this.oneService.collectionsService.getCollection(this.data?.collection.id).subscribe(
      myCollection => {
        myCollection.owners.forEach(email => {
          let owner: EntitlementGroup = {
            email: email,
            name: email.split("@", 2)[0],
            isDefault: true
          }
          this.myEntitlementGroups.push(owner);
        });
        myCollection.viewers.forEach(email => {
          let viewer: EntitlementGroup = {
            email: email,
            name: email.split("@", 2)[0],
            isDefault: true
          }
          this.myEntitlementGroups.push(viewer);
        });
        this.options['Entitlements'] = this.myEntitlementGroups;

         // create form group
         this.createFormGroup();
         this.oneService.loadingService.closeSpinner();
      });
  }

  // create form group
  createFormGroup() {
    if(this.data?.mode === 'edit') {
      this.fixAssetRelated();
      this.createFormGroupForEdit();
    }
    else {
      this.createFormGroupForAdd();
    }

    // register for asset event handling, update the value for reservoir and field
    const assetControl = this.companionDataForm.get('AssetName');
    const reservoirControl = this.companionDataForm.get('Reservoir');
    const fieldControl = this.companionDataForm.get('Field');
    assetControl.valueChanges.pipe(
      startWith(assetControl.value),
      pairwise()
    ).subscribe(
      ([old,value])=>{
        // check console.log(old,value)
        const thatAsset = this.assets.find(x => x.AssetName === value);
        if(!!thatAsset) {
          this.options['Reservoir'] = thatAsset['Reservoir'].map(x => ({ name: x }));
          this.options['Field'] = thatAsset['Field'].map(x => ({ name: x }));
          reservoirControl.setValue(this.options['Reservoir'][0].name);
          fieldControl.setValue(this.options['Field'][0].name);
        }
      }
    )
  }

  fixAssetRelated()
  {
    let assetName = this.data?.companionData?.data?.AssetName;
    if(!!assetName) {
      const companionDataFields = JSON.parse(this.oneService.storageService.get("companionDataFields"))
      const dataSetting = companionDataFields["companionDataSettings"]
      const thatAsset = dataSetting['Assets'].find(x => x['AssetName'] === assetName)
      if(!!thatAsset) {
        // put reservoir and field
        this.options['Reservoir'] = thatAsset?.Reservoir.map(x => ({ name: x }));
        this.options['Field'] = thatAsset?.Field.map(x => ({ name: x }));
      }
    }
  }

  // create individual control as their type and required different
  createIndividualControl(control:any, defaultValue:string) {
    if(control.type === 'text') {
      return new FormControl(
        defaultValue,
        [companionDataTextFunctionValidator(control.key)]
      );
    }
    else {
      return  new FormControl({value:defaultValue, disabled:this.companionFields.find(x => x.key === control.key)?.disabled});
    }
  }

  // edit mode
  createFormGroupForEdit() {
    const group: any = {};
    let defaultEditValue;
    this.companionFields.forEach(control => {
      defaultEditValue = this.getValueForEditMode(control);
      group[control.key] = this.createIndividualControl(control, defaultEditValue);
      if(control.type === 'selectAndTypein') {
        group[control.key +'input'] = this.createIndividualControl(control, defaultEditValue);
      }
      // keep this just for reference
      //group[control.key]  control.required
      //   new FormControl({value:defaultEditValue, disabled:this.companionFields.find(x => x.key === control.key)?.disabled }, Validators.required)
      //  new FormControl({value:defaultEditValue, disabled:this.companionFields.find(x => x.key === control.key)?.disabled})
    });
    this.companionDataForm = new FormGroup(group);
  }

  getValueForEditMode(control): string{
    let companionEditValue;
    if(control.key === 'ModelFilename' || control.key === 'ModelName'){
      companionEditValue = this.data?.model?.name;
    } else if(control.key === 'ModelIdentifier'){
      companionEditValue = this.data?.model.id;
    } else if(control.key === 'CollectionName'){
      companionEditValue = this.data?.collection.name;
    } else if(control.key === 'CollectionIdentifier'){
      companionEditValue = this.data?.collection.id;
    } else if(control.key === 'Entitlements'){
      // use collection entitlements as first choice 
      companionEditValue = this.myEntitlementGroups?.filter(x => x.isDefault )?.map(x=>x.name);
      if(companionEditValue.length < 1 || companionEditValue.filter(x => x !== undefined || x !== null).length < 1) {
        let entitlementValue = [];
        const companionDataFields = this.companionFields.find(x => x.key === control.key)?.apiField.split(',');
        companionDataFields.forEach(field => {
          const email = _.get(this.data?.companionData, field);
          entitlementValue.push(this.getEntitlementName(email?.find(x=>x)));
        });
        companionEditValue =  entitlementValue;
      }
    } else if(control.key === 'Countries') {
      const apiField = this.companionFields.find(x => x.key === control.key)?.apiField;
      const code = _.get(this.data?.companionData, apiField);
      companionEditValue = this.getCountryName(code.find(x=>x));
    }
    else{
      const editValue = _.get(this.data?.companionData, this.companionFields.find(x => x.key === control.key)?.apiField);
      companionEditValue= _.isArray(editValue) ? editValue[0] : editValue?.toString();
    }
    return companionEditValue;
  }

  // add mode
  createFormGroupForAdd() {
    const group: any = {};
    let defaultAddValue;
    this.setCompanionFieldsDefault();
    this.companionFields.forEach(control => {
      defaultAddValue = this.getValueForAddMode(control);
      group[control.key] = this.createIndividualControl(control, defaultAddValue);
      if(control.type === 'selectAndTypein') {
        group[control.key +'input'] = this.createIndividualControl(control, defaultAddValue);
      }
    });
    this.companionDataForm = new FormGroup(group);
  }

  setCompanionFieldsDefault()
  {
    let companionFields = JSON.parse(this.oneService.storageService.get("companionDataFields"))
    this.companionFields.find(x => x.key === 'CollectionName').default = this.data?.collection?.name;
    this.companionFields.find(x => x.key === 'CollectionIdentifier').default = this.data?.collection?.id;
    this.companionFields.find(x => x.key === 'ModelName').default = this.data?.model?.name;
    this.companionFields.find(x => x.key === 'ModelIdentifier').default =  this.data?.model?.id === ModelFilesService.newId ? '' : this.data?.model?.id;
    this.companionFields.find(x => x.key === 'Legal').default = companionFields["legalTags"].filter( x=> x.isDefault).find(x=>x)?.name
    //this.companionFields.find(x => x.key === 'Entitlements').default  companionFields["entitlements"].filter( x=> x.isDefault)?.map(x=>x.name)
    this.companionFields.find(x => x.key === 'Entitlements').default = this.myEntitlementGroups?.filter( x=> x.isDefault )?.map(x=>x.name);
  }

  getValueForAddMode(control){
    let defaultValue  = {};
    defaultValue = this.companionFields.find(x => x.key === control.key)?.default;
    if ((control.type === 'select') && control?.options && control?.options.length === 0) {
        defaultValue = this.options[control.key].map(x=> x.name).find(x=>x);
    }
    return defaultValue;
  }

  getmodelFileNameWithoutExtension(){
    const afiFileName =  this.data?.model?.name?.split('/').pop();
    return afiFileName.substring(0, afiFileName?.lastIndexOf('.'));
  }

  getCompanionFieldsWithBU() {
    let companionFields = getCompanionFields();
    let companionDataFields = JSON.parse(this.oneService.storageService.get("companionDataFields"))
    let buName = companionDataFields.buName;
    if(!!buName) {
      return  companionFields.filter(x => this.filterFunction(x?.buName, buName))
    }
    else {
      return  companionFields.filter(x => !x.buName)
    }
  }

  filterFunction(field: string[], buName: string) {
    if(!field) {
      return true;
    }
    if(field.includes(buName)) {
      return true;
    }
    return false;
  }


  getOptions(){
    let companionDataFields = JSON.parse(this.oneService.storageService.get("companionDataFields"))
    this.options['Legal'] = companionDataFields["legalTags"];
    this.options['Countries'] = companionDataFields["countries"];
    this.options['Entitlements'] = companionDataFields["entitlements"].filter( field =>
                                    field.name?.toLowerCase().includes(EntitlementTypes.OWNERS) ||
                                    field.name?.toLowerCase().includes(EntitlementTypes.VIEWERS) )
    const optionsBu =  this.getBuOptions(companionDataFields["companionDataSettings"]);
    this.options = {...this.options,...optionsBu};
    this.companionFields.forEach(field => {
      if ((field.type === 'select' || field.type === 'radio') && field?.options && field?.options.length !== 0) {
        this.options[field.key] = this.companionFields
          .filter(x => x.name === field.name)
          .find(x => x)
          .options.map(x => ({ name: x }));
      }
    });
  }

  getBuOptions(companionBuFields){
    const optionsBu = {};
    const that = this;
    Object.keys(companionBuFields).forEach(function(key) {
      // treay assets special
      if(key === 'Assets') {
        that.assets = companionBuFields['Assets'];
        optionsBu['AssetName'] = companionBuFields['Assets'].map(x => ({name: x['AssetName']}));
        // take first element's reservoir and field
        optionsBu['Reservoir'] = companionBuFields['Assets'][0]?.Reservoir.map(x => ({ name: x }));
        optionsBu['Field'] = companionBuFields['Assets'][0]?.Field.map(x => ({ name: x }));
      }
      else if(companionBuFields[key]){
        optionsBu[key] = companionBuFields[key].map(x => ({ name: x }));
      }
    });
    return optionsBu;
  }

  // get entitlementgroup name from email
  getEntitlementName(email){
    //let companionDataFields JSON.parse(this.oneService.storageService.get("companionDataFields"))
    //return companionDataFields["entitlements"].filter( field => field.email === email).find(x=>x)?.name
    return this.myEntitlementGroups.filter( field => field.email === email).find(x=>x)?.name;
  }

  // get entitlement name email from name
  getEntitlementEmail(name){
    //let companionDataFields  JSON.parse(this.oneService.storageService.get("companionDataFields"))
    //return companionDataFields.filter( field => field.name === name).find(x=>x)?.email
    return this.myEntitlementGroups.filter( field => field.name === name).find(x=>x)?.email;
  }

  getCountryName(code){
    let companionDataFields = JSON.parse(this.oneService.storageService.get("companionDataFields"));
    return companionDataFields['countries'].filter( field => field.code === code).find(x=>x)?.name;
  }

  getCountryCode(name){
    let companionDataFields = JSON.parse(this.oneService.storageService.get("companionDataFields"));
    return companionDataFields['countries'].filter( field => field.name === name).find(x=>x)?.code;
  }

  public getData(){
    let companionDataFieldsObject:any = {valid:false};
    //companionDataFieldsObject.invalidControls  (!this.companionDataForm.valid)? this.findInvalidControls(): []
    Object.keys(this.companionDataForm.controls).forEach(controlName =>  {
      if(controlName === 'Countries'){
        companionDataFieldsObject[controlName] = this.getCountryCode(this.companionDataForm.get(controlName).value)
      }
      else if(controlName === 'Entitlements'){
        const entitlementArray = [];
        const entitlementValue = this.companionDataForm.get(controlName).value;
        entitlementValue?.forEach( eValue => {
          entitlementArray.push(this.getEntitlementEmail(eValue));
        });
        companionDataFieldsObject[controlName] = entitlementArray;
      }
      else if(controlName === 'ModelName') {
        companionDataFieldsObject[controlName] = this.companionDataForm.get(controlName).value
        companionDataFieldsObject['ModelFilename'] = this.companionDataForm.get(controlName).value
      }
      // do not take modelIdentidier
      else if(controlName !== 'ModelIdentifier') {
        companionDataFieldsObject[controlName] = this.companionDataForm.get(controlName).value;
      }
    });
    companionDataFieldsObject.valid = true;

    // replace the xxx with xxxinput value and remove xxxinput value
    Array.from(this.companionFields).forEach((companionField: any) => {
      if(companionField.type === "selectAndTypein") {
        let inputValue = this.companionDataForm.get(companionField.key+"input").value;
        companionDataFieldsObject[companionField.key] = inputValue;
        delete companionDataFieldsObject[companionField.key+"input"];
      }
    });

    return companionDataFieldsObject;
  }

  public findInvalidControls() {
    const invalidControls = [];
    const controls = this.companionDataForm.controls;
    for (const name in controls) {
        if (controls[name].invalid) {
            invalidControls.push(name);
        }
    }
    return this.getCompanionFieldsName(invalidControls);
  }

  getCompanionFieldsName(invalidControls){
    let companionFieldNames = []
    Array.from(this.companionFields).forEach((companionField: any) => {
      invalidControls.some((invalidControl: any) => {
        if(invalidControl === companionField.key){
          companionFieldNames.push(companionField.name)
        }
      });
    });
    return companionFieldNames;
  }

  onSubmit(){
    const companionData = this.getData();
    if(this.data.mode === "edit" || this.data.mode === "add_for_edit"){
        this.oneService.loadingService.showSpinner({ text: "Updating..." });
        const headerInfo = {
          id: this.data?.companionData?.id,
          fileName: this.data?.model?.name,
          modelId: this.data?.model?.id,
          branchSimulationId: this.data?.model?.branchSimulationId,
          companionMetaData: companionData
        }
        this.oneService.companionDataService.upsetCompanionData(headerInfo).subscribe({
          next: ingestOutput => {
            this.dialog.close();
            this.oneService.collectionsService.setRefreshModelFolders(true);
            this.oneService.loadingService.closeSpinner();
          },
          error: err => {
            this.dialog.close();
            this.oneService.loadingService.closeSpinner();
            this.oneService.messageService.add({ severity: SlbSeverity.Warning, detail: err.message});
          }
        });
    }
    else if(this.data.mode === "add"){
      this.dialog.close({data: companionData})
    }
  }

  // key left in the input
  selectAndTypeinDataChanged(event) {
    // set xxxinput control value
    let controlName = event.target.id;
    this.companionDataForm.get(controlName.substring(0, controlName.indexOf("input"))).setValue(event.target.value);
  }

  // only for forcastyear to accept numbers
  selectAndTypeinKeyPress(event, controlName) {
    if(controlName === 'ForecastYear') {
      const charCode = (event.which) ? event.which : event.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
      }
    }
    return true;
  }

  // selection is made, need to set it
  selectAndTypeinOptionSelected(event, controlName) {
    // if a selection is choosed, set xxx and xxxinput control value
    this.companionDataForm.get(controlName).setValue(event.option.value);
  }

  hasErrors = (): boolean =>  {
    if(this.companionDataForm.controls['Downtime']?.hasError('Downtime') ||
       this.companionDataForm.controls['RunNumber']?.hasError('RunNumber')) {
      return true;
    }
    return false;
  };

  onCancel()
  {
    const companionData = this.getData();
    console.log("companion data:", companionData);
  }
}

// validate text control's value
export function companionDataTextFunctionValidator(key: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let value = control.value as string;
    if(value === null || value === undefined) {
      return null;
    }
    value = value.toLocaleLowerCase().trim();
    
    // we do not validate all text fields, just some of them
    if(key === 'Downtime') {
      if(value === "" || value === 'no' || 
        value.match(/\d{1,10}\s{0,2}(y|w|d|h|m|s|year|month|week|day|hr|hour|min|minute|sec|second)s{0,1}/)) {
        return null;
      }
      else {
        return { 'Downtime': true }
      }
    }
    else if(key === 'RunNumber') {
      if(value === "" || value.match(/\d{1-10}/)) {
        return null;
      }
      else {
        return { 'RunNumber': true }
      }
    }
    
    return null;
  };
}
