import {Injectable} from "@angular/core";
import {ModelFile} from "../models/model-file";
import {ModelFilesService} from "./model-files.service";
import {AuthService} from "../auth/auth.service";
import {Observable, of} from "rxjs";
import {FileBlock} from "../models/file-block";



@Injectable({
  providedIn: 'root'
})
export class FileUtilsService {

  constructor(private authService: AuthService) {
  }

  public static readonly PATH_SEPARATOR = '/';

  public getFileExtension(fileName: string): string {
    const parts = fileName.split('.');
    if (parts.length === 1)
      return '';
    else
      return parts[parts.length - 1].toLocaleLowerCase();
  }

  public getFileName(fileName: string): string {
    const parts = fileName.split(FileUtilsService.PATH_SEPARATOR);
    return parts[parts.length - 1];
  }

  // escape those special characters which are not normal ascii, not space, not dash dot slash to dash
  public getNameNoSpecialCharacter(name: string) : string {
    return name.replace(/[^\w\s-_\.\/]/gi, '-');
  }

  // get the path without topmost folder path 
  public getFilNameWithoutTopmost(fileNameWithPath: string) : string {
    const parts = fileNameWithPath.split(FileUtilsService.PATH_SEPARATOR);
    return parts.length > 1 ? parts.slice(1).join(FileUtilsService.PATH_SEPARATOR) : fileNameWithPath;
  }

  public getFileNameWithoutExtension(fileName: string): string {
    const name = this.getFileName(fileName);
    const parts = name.split('.');
    return parts.length > 1 ? parts.slice(0, parts.length - 1).join('.') : name;
  }

  public getParentFolderPath(fileNameWithPath: string): string {
    const parts = fileNameWithPath.split(FileUtilsService.PATH_SEPARATOR);
    return parts.slice(0, parts.length - 1).join(FileUtilsService.PATH_SEPARATOR);
  }

  public getFileContent(file: File): Observable<string> {
    return new Observable(subscriber => {
      try {
        const reader = new FileReader();
        reader.onerror = () => {
          reader.abort();
          console.error('problem parsing file.', file);
          subscriber.error(new Error('Problem parsing file.'));
        };
        reader.onload = () => {
          //extracting json from text https://stackoverflow.com/questions/21994677/find-json-strings-in-a-string
          const result: any = reader.result;
          // add parent path
          subscriber.next(
            result as string
          );
          subscriber.complete();
        };
      } catch (e) {
        console.error('error reading file.', file);
        subscriber.error(e);
      }
    });
  }

  public getRootsForFiles(files: File[]): string[] {
    const allRoots = files.map(p => {
      const path = p.webkitRelativePath ?? '';
      return path.indexOf(FileUtilsService.PATH_SEPARATOR) > -1 ? path.substring(0, path.indexOf(FileUtilsService.PATH_SEPARATOR)) : path;
    });
    return allRoots.filter((val, idx, self) => self.indexOf(val) === idx);
  }

  public getFilesAtRootFolder(files: File []): File[] {
    return files.filter(p => p.webkitRelativePath.split(FileUtilsService.PATH_SEPARATOR).length === 2);
  }


  //todo: this method does not belong to this service
  // default is keep the path as webkitRelativePath, but for some model, need to remove special characters
  public getModelFileForAdd(file: File, type: 'input' | 'output', keepPath = true): Observable<ModelFile> {
    return of({
      id: ModelFilesService.newId,
      //for mbal model path has illegal character path: file.webkitRelativePath,
      path: keepPath ? file.webkitRelativePath : this.getNameNoSpecialCharacter(file.webkitRelativePath),
      md5: '',
      createdDateTime: new Date(),
      size: file.size,
      fileModifiedDateTime: new Date(file.lastModified),
      createdUserId: this.authService.currentUser.username,
      type: type,
      updateInfo: {mode: 'add', updatedFile: file, optional: false}
    } as ModelFile);
  }


  public fileToChunks(file: File, maxSize: number): Blob [] {
    const sizesToBeRead = this.getSizesToBeReadInChunksToSlice(file.size, maxSize);
    return sizesToBeRead.map(([startRead, endRead]) => file.slice(startRead, endRead, file.type));
  }

  public getSizesToBeReadInChunksToSlice(fileSize: number, maxSize: number): number [][] {
    let startRead = 0, endRead = 0;
    const chunks = [];
    while (startRead < fileSize) {
      let endRead = startRead + maxSize - 1;
      if (endRead >= fileSize) {
        endRead = fileSize - 1;
      }
      const chunk = [startRead, endRead];
      chunks.push(chunk);
      startRead = endRead + 1;
    }
    return chunks;
  }

  public getSizesToBeReadInChunks(fileSize: number, maxSize: number): number [][] {
    let sizeRead = 0;
    const chunks = [];
    while(sizeRead < fileSize) {
      let endRead = sizeRead + maxSize;
      if (sizeRead + maxSize >= fileSize) {
        endRead = fileSize;
      }
      const chunk = [sizeRead, endRead];
      chunks.push(chunk);
      sizeRead = endRead;
    }
    return chunks;
  }

  public getBlocks(fileSize: number, maxSize: number): FileBlock [] {
    const chunks = this.getSizesToBeReadInChunksToSlice(fileSize, maxSize);
    return chunks.map(([startRead, endRead], idx) => {
      return {
        id: btoa('block-' + this.pad(idx, 6)),
        blockStart: startRead,
        blockEnd: endRead
      } as FileBlock;
    });
  }

  public pad(number, length): string {
    let str = '' + number;
    while (str.length < length) {
      str = '0' + str;
    }
    return str;
  };


  public readFile(fileToRead: File): Observable<string> {
    return new Observable((subscriber) => {
      try {
        const reader = new FileReader();
        reader.onerror = () => {
          reader.abort();
          console.error('problem parsing input file.', fileToRead);
          subscriber.error(new Error('Problem parsing input file.'));
        };
        reader.onload = () => {
          const result: string = reader.result.toString();
          subscriber.next(result)
          subscriber.complete();
        };
        reader.readAsText(fileToRead);
      } catch (e) {
        console.error('error reading input file.', fileToRead);
        subscriber.error(e);
      }
    });
  }

}
