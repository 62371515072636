import {Inject, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {LOCAL_STORAGE, StorageService} from "ngx-webstorage-service";
import {Observable, tap} from "rxjs";
import {environment} from "../../../environments/environment";
import {ModifyFileRequest} from "../models/modify-file-request";
import {CompletedStaging} from "../models/completed-staging";
import {DeleteFileRequest} from "../models/delete-file-request";
import {FileVersion} from "../models/file-version";

@Injectable({
  providedIn: 'root'
})
export class CollectionFilesService {


  constructor(private _httpClient: HttpClient) {
  }

  getFileContent(collectionId: string, path: string): Observable<{ content: string }> {
    const url = `${environment.apiEndpoint}/collectionFile/content`;
    return this._httpClient.post<{ content: string }>(url, { collectionId: collectionId, filePath: path});
  }

  getFileContentByFileId(fileId: string): Observable<{ content: string }> {
    const url = `${environment.apiEndpoint}/collectionFile/${fileId}/content`;
    return this._httpClient.get<{ content: string }>(url).pipe(tap(console.log));
  }

  getFileUrlByFileId(fileId: string): Observable<{ url: string }> {
    const url = `${environment.apiEndpoint}/collectionFile/${fileId}/blob-url`;
    return this._httpClient.get<{ url: string }>(url);
  }

  getFileVersions(collectionId: string, path:string) : Observable<FileVersion[]> {
    const url = `${environment.apiEndpoint}/collectionFile/versions`;
    return this._httpClient.post<FileVersion[]>(url, { collectionId: collectionId, filePath: path});
  }

  updateFile(modifyFileRequest: ModifyFileRequest): Observable<CompletedStaging> {
    const url = `${environment.apiEndpoint}/collectionFile/modifyFile`;
    return this._httpClient.post<CompletedStaging>(url, modifyFileRequest);
  }

  deleteFile(deleteFileRequest: DeleteFileRequest): Observable<CompletedStaging> {
    const url = `${environment.apiEndpoint}/collectionFile/deleteFile`;
    return this._httpClient.post<CompletedStaging>(url, deleteFileRequest);
  }



}
