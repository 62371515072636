import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CollectionUserAccessService {

  constructor(private _httpClient: HttpClient) { }

  recordAccess(access: {collectionId: string, userId: string, accessType: 'view', accessDateTime: Date}): Observable<Boolean> {
    const url = `${environment.apiEndpoint}/collection/${access.collectionId}/UserAccess`;
    return this._httpClient.post<{ status: Boolean }>(url, access).pipe(map(p => p.status));
  }
}
