<slb-navigation-framework [applicationLogo]="'/assets/logos/cvx.png'" [applicationName]="' Chevron RE-DMS'"
  [menu]="menu" [hasSidenav]="true" [notifications]="true" [pageTitle]="pageTitle">
  <slb-framework-toolbar>
    <ng-content *ngTemplateOutlet="toolbar"></ng-content>
  </slb-framework-toolbar>

  <slb-framework-sidenav>
    <ng-content *ngTemplateOutlet="sidenav"></ng-content>
  </slb-framework-sidenav>

  <slb-framework-content>
    <ng-content *ngTemplateOutlet="content"></ng-content>
  </slb-framework-content>

  <slb-breadcrumbs [breadcrumbsItems]="breadcrumbsItems" [home]="home"></slb-breadcrumbs>
  <slb-framework-page-header [pageTitle]="pageTitle">
    <ng-container *ngIf="headerTemplate">
      <ng-container #toolbar *ngTemplateOutlet="headerTemplate">
      </ng-container>
    </ng-container>
  </slb-framework-page-header>

</slb-navigation-framework>

<ng-template #toolbar>
  <button slb-icon-button title="in progress" [matMenuTriggerFor]="uploadInProgressMenu" matBadgePosition="below before"
    matBadgeSize="small" [matBadge]="(parentUploadTasksInProgress$ | async).length"
    *ngIf="(parentUploadTasksInProgress$ | async).length > 0">
    <mat-progress-spinner mode="indeterminate" diameter="26">
    </mat-progress-spinner>
  </button>
  <mat-menu #uploadInProgressMenu="matMenu">
    <button mat-menu-item *ngFor="let parentTask of parentUploadTasksInProgress$ | async;"
      (click)="showUploadTaskDetails(parentTask)">
      <!-- <mat-icon>{{getIconForParentTaskType(parentTask.taskType)}}</mat-icon> -->
      <span class="clamp">{{parentTask.DisplayTitle}}</span>
      <span><circle-progress [percent]="parentTask.Progress" [backgroundPadding]="7" [radius]="20" [space]="-2"
          [outerStrokeWidth]="2" [outerStrokeColor]="'#808080'" [innerStrokeWidth]="2" [innerStrokeColor]="'#e7e8ea'"
          [showSubtitle]="false" [animation]="false"></circle-progress></span>
    </button>

  </mat-menu>

  <button slb-icon-button title="in progress" [matMenuTriggerFor]="downloadInProgressMenu"
    matBadgePosition="below before" matBadgeSize="small" [matBadge]="(parentDownloadTasksInProgress$ | async).length"
    *ngIf="(parentDownloadTasksInProgress$ | async).length > 0">
    <mat-progress-spinner mode="indeterminate" diameter="26">
    </mat-progress-spinner>
  </button>
  <mat-menu #downloadInProgressMenu="matMenu">
    <button mat-menu-item *ngFor="let parentTask of parentDownloadTasksInProgress$ | async;"
      (click)="showDownloadTaskDetails(parentTask)">
      <!-- <mat-icon>{{getIconForParentTaskType(parentTask.taskType)}}</mat-icon> -->
      <span class="clamp">{{parentTask.DisplayTitle}}</span>
      <span><circle-progress [percent]="parentTask.Progress" [backgroundPadding]="7" [radius]="20" [space]="-2"
          [outerStrokeWidth]="2" [outerStrokeColor]="'#808080'" [innerStrokeWidth]="2" [innerStrokeColor]="'#e7e8ea'"
          [showSubtitle]="false" [animation]="false"></circle-progress></span>
    </button>
  </mat-menu>
  <!-- <button slb-icon-button title="Help">
    <mat-icon svgIcon="help"></mat-icon>
  </button> -->
  <slb-notifications-panel [capacity]="notificationCount" [notificationItems]="notificationItems"
    [viewAllButtonVisible]="true">
  </slb-notifications-panel>
  <slb-logout [disabled]="true" [userName]="userName" (click)="onLogOut()">
  </slb-logout>
  <button *ngIf="unitOptions().length === 0;else buOptions" mat-button>{{selectedUnitName()}}</button>
  <ng-template #buOptions>
    <button mat-button [matMenuTriggerFor]="partitionMenu">{{selectedUnitName()}}</button>
    <mat-menu #partitionMenu="matMenu">
      <ng-container *ngFor="let bu of unitOptions()">
        <button mat-menu-item *ngIf="bu.id !== selectedUnit" (click)="onUnitChange(bu.id)">{{bu.partitionName}}</button>
      </ng-container>
    </mat-menu>
  </ng-template>
 

</ng-template>  

<ng-template #sidenav>
  <mat-nav-list class="app-sidenav">
    <a class="dls-navigation-item" title="Home" [routerLink]="['/home']">
      <mat-icon svgIcon="home">Home</mat-icon>
    </a>
    <a class="dls-navigation-item" title="Collections" [routerLink]="['/collections']">
      <mat-icon svgIcon="list">Collections</mat-icon>
    </a>
    <a class="dls-navigation-item" title="Models" [routerLink]="['/models']">
      <span class="material-icons">source</span>
    </a>
    <a class="dls-navigation-item" title="Companion Data Tree" [routerLink]="['/companion-data-tree']">
      <span class="material-icons">table_chart</span>
    </a>
  </mat-nav-list>
</ng-template>

<mat-menu #menu="matMenu">
  <button mat-menu-item [routerLink]="'/home'">Home</button>
  <button mat-menu-item [routerLink]="'/collections'">Collections</button>
  <button mat-menu-item [routerLink]="'/collections'">Models</button>
  <button mat-menu-item [routerLink]="'/collections'">Companion data</button>
  <button mat-menu-item [routerLink]="'/collections'">Studies</button>
</mat-menu>


<ng-template #content>
  <router-outlet></router-outlet>
</ng-template>