<div fxFill fxLayout="column">
  <mat-spinner diameter="30" *ngIf="inProgress"></mat-spinner>
  <mat-vertical-stepper *ngIf="!inProgress" [linear]="false">
    <mat-step [completed]="false" *ngFor="let fileVersion of fileVersions">
      <ng-template matStepLabel>{{ fileVersion.createdDateTime |  date:'medium'}}</ng-template>
      <div fxflexfill fxLayout="row wrap">
        <div class="element-detail-item">
          <div class="element-detail-item-header" mat-line>Version Id</div>
          <div mat-line> {{fileVersion.id}}</div>
        </div>
        <div class="element-detail-item">
          <div class="element-detail-item-header" mat-line>Version</div>
          <div mat-line> {{fileVersion.versionNumber}}</div>
        </div>
        <div class="element-detail-item">
          <div class="element-detail-item-header" mat-line>File Path</div>
          <div mat-line>{{fileVersion.path}}</div>
        </div>

        <div class="element-detail-item">
          <div class="element-detail-item-header" mat-line>File Size(in kb)</div>
          <div mat-line>{{fileVersion.size * 0.001 | number:'1.0-2'}}</div>
        </div>
        <div class="element-detail-item">
          <div class="element-detail-item-header" mat-line>User</div>
          <div mat-line>{{fileVersion.createdUser}}</div>
        </div>

        <div class="element-detail-item">
          <div class="element-detail-item-header" mat-line>File Modified Date</div>
          <div mat-line> {{ fileVersion.fileModifiedDateTime |  date:'medium'}} </div>
        </div>

        <div class="element-detail-item" *ngIf="allowView">
          <button mat-raised-button color="primary" (click)="onView(fileVersion)">
            <mat-icon>remove_red_eye</mat-icon>
            <span>View</span>
          </button>
        </div>
        <div class="element-detail-item" *ngIf="allowView">
          <button mat-raised-button color="primary" [disabled]="(fileVersions?.length ?? 0) <= 1" [matMenuTriggerFor]="menu">
            <mat-icon>compare</mat-icon>
            <span>Compare With</span></button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item *ngFor="let compareVersion of getFileVersionThatCanBeCompared(fileVersion)"
                    (click)="onCompare(fileVersion, compareVersion)">
              {{compareVersion['idx']}} {{ compareVersion.createdDateTime |  date:'medium'}}
            </button>
          </mat-menu>
        </div>
        <div class="element-detail-item">
          <button mat-raised-button color="primary" (click)="onDownload(fileVersion)">
            <mat-icon>download</mat-icon>
            <span>Download</span>
          </button>
        </div>

        <div class="element-detail-item">
          <button mat-raised-button color="primary" (click)="onViewRevisionChanges(fileVersion)">
            <mat-icon>remove_red_eye</mat-icon>
            <span>View Changes in this revision</span>
          </button>
        </div>

        <div class="element-detail-item">
          <button mat-raised-button color="primary" (click)="onViewRevision(fileVersion)">
            <mat-icon>remove_red_eye</mat-icon>
            <span>View Collection at this revision</span>
          </button>
        </div>
      </div>
    </mat-step>
  </mat-vertical-stepper>
</div>
