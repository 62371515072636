import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Collection } from "../models/collection";


export interface FilterForCollectionsSearchResults {
    values: string[];
};

export interface SearchForCollectionsRequest {
    searchText?: string;
    collectionsQueryCursor?: string;
    modelsQueryCursor?: string;
    limitOnClient: number;
    indexOnClient: number;
    collectionFilters?: SearchForCollectionsFilters;
    modelFilters?: SearchForCollectionsModelFilters;
    companionDataFilters?: SearchForCollectionsCompanionDataFilters;
}

export interface SearchForCollectionsFilters {
    collectionName?: string;
    collectionUsers?: string[];
    viewers?: string[];
    owners?: string[];
    createDateRanges?: Date[];
    modifiedDateRanges?: Date[];
}

export interface SearchForCollectionsModelFilters {
    modelName?: string;
    modifiedUsers?: string;
    modifiedDateRanges?: Date[];
}

export interface SearchForCollectionsCompanionDataFilters {
    remarks?: string;
    genOptManifestFileName?: string;
    owners?: string[];
    viewers?: string[];
    legalTags?: string[];
    countries?: string[];
    modelVersionNumbers?: number[];
    businessPlanCases?: string[];
    reservesCases?: string[];
    reservoirs?: string[];
    decisionFrames?: string[];
    modelObjectives?: string[];
    projectPhases?: string[];
    fields?: string[];
    forecastYears?: string[];
    simulationApplications?: string[];
    simulationApplicationVersions?: string[];
}


export interface SearchCollectionsResults {
    results: Collection[];
    nextPageCollectionsQueryCursor: string;
    nextPageModelsQueryCursor: string;
    nextIndexForCompanionDataQuery: number;
}

@Injectable({
    providedIn: 'root'
  })
  export class SearchCollectionsService {

    constructor(private _httpClient: HttpClient) {
    }

    search(request: SearchForCollectionsRequest): Observable<any> {
        const url = `${environment.apiEndpoint}/search/collections`;
        return this._httpClient.post<FilterForCollectionsSearchResults>(url, request);
    }

    getAllCollectionUsers(searchText: string): Observable<FilterForCollectionsSearchResults> {
        const url = `${environment.apiEndpoint}/search/filters/collectionUsers`;
        return this._httpClient.post<FilterForCollectionsSearchResults>(url, {searchText});
    }

    getAllCollectionOwners(searchText: string): Observable<FilterForCollectionsSearchResults> {
        const url = `${environment.apiEndpoint}/search/filters/collectionOwners`;
        return this._httpClient.post<FilterForCollectionsSearchResults>(url, {searchText});
    }

    getAllCollectionViewers(searchText: string): Observable<FilterForCollectionsSearchResults> {
        const url = `${environment.apiEndpoint}/search/filters/collectionViewers`;
        return this._httpClient.post<FilterForCollectionsSearchResults>(url, {searchText});
    }

    
    getAllModelUsers(searchText: string): Observable<FilterForCollectionsSearchResults> {
        const url = `${environment.apiEndpoint}/search/filters/modelUsers`;
        return this._httpClient.post<FilterForCollectionsSearchResults>(url, {searchText});
    }
    
    getAllCompanionDataOwners(searchText: string): Observable<FilterForCollectionsSearchResults> {
        const url = `${environment.apiEndpoint}/search/filters/companionData/owners`;
        return this._httpClient.post<FilterForCollectionsSearchResults>(url, {searchText});
    }

    getAllCompanionDataViewers(searchText: string): Observable<FilterForCollectionsSearchResults> {
        const url = `${environment.apiEndpoint}/search/filters/companionData/viewers`;
        return this._httpClient.post<FilterForCollectionsSearchResults>(url, {searchText});
    }

    getAllCompanionDataLegalTags(searchText: string): Observable<FilterForCollectionsSearchResults> {
        const url = `${environment.apiEndpoint}/search/filters/companionData/legalTags`;
        return this._httpClient.post<FilterForCollectionsSearchResults>(url, {searchText});
    }

    getAllCompanionDataCountries(searchText: string): Observable<FilterForCollectionsSearchResults> {
        const url = `${environment.apiEndpoint}/search/filters/companionData/countries`;
        return this._httpClient.post<FilterForCollectionsSearchResults>(url, {searchText});
    }

    getAllCompanionDataModelVersions(searchText: string): Observable<FilterForCollectionsSearchResults> {
        const url = `${environment.apiEndpoint}/search/filters/companionData/modelVersions`;
        return this._httpClient.post<FilterForCollectionsSearchResults>(url, {searchText});
    }

    getAllCompanionDataBusinessPlanCases(searchText: string): Observable<FilterForCollectionsSearchResults> {
        const url = `${environment.apiEndpoint}/search/filters/companionData/businessPlanCases`;
        return this._httpClient.post<FilterForCollectionsSearchResults>(url, {searchText});
    }

    getAllCompanionDataReservesCases(searchText: string): Observable<FilterForCollectionsSearchResults> {
        const url = `${environment.apiEndpoint}/search/filters/companionData/reservesCases`;
        return this._httpClient.post<FilterForCollectionsSearchResults>(url, {searchText});
    }

    getAllCompanionDataReservoirs(searchText: string): Observable<FilterForCollectionsSearchResults> {
        const url = `${environment.apiEndpoint}/search/filters/companionData/reservoirs`;
        return this._httpClient.post<FilterForCollectionsSearchResults>(url, {searchText});
    }

    getAllCompanionDataDecisionFrames(searchText: string): Observable<FilterForCollectionsSearchResults> {
        const url = `${environment.apiEndpoint}/search/filters/companionData/decisionFrames`;
        return this._httpClient.post<FilterForCollectionsSearchResults>(url, {searchText});
    }

    getAllCompanionDataModelObjectives(searchText: string): Observable<FilterForCollectionsSearchResults> {
        const url = `${environment.apiEndpoint}/search/filters/companionData/modelObjectives`;
        return this._httpClient.post<FilterForCollectionsSearchResults>(url, {searchText});
    }

    getAllCompanionDataProjectPhases(searchText: string): Observable<FilterForCollectionsSearchResults> {
        const url = `${environment.apiEndpoint}/search/filters/companionData/projectPhases`;
        return this._httpClient.post<FilterForCollectionsSearchResults>(url, {searchText});
    }

    getAllCompanionDataFields(searchText: string): Observable<FilterForCollectionsSearchResults> {
        const url = `${environment.apiEndpoint}/search/filters/companionData/fields`;
        return this._httpClient.post<FilterForCollectionsSearchResults>(url, {searchText});
    }

    getAllCompanionDataForecastYears(searchText: string): Observable<FilterForCollectionsSearchResults> {
        const url = `${environment.apiEndpoint}/search/filters/companionData/forecastYears`;
        return this._httpClient.post<FilterForCollectionsSearchResults>(url, {searchText});
    }

    getAllCompanionDataSimulationApplications(searchText: string): Observable<FilterForCollectionsSearchResults> {
        const url = `${environment.apiEndpoint}/search/filters/companionData/simulationApplications`;
        return this._httpClient.post<FilterForCollectionsSearchResults>(url, {searchText});
    }

    getAllCompanionDataSimulationApplicationVersions(searchText: string): Observable<FilterForCollectionsSearchResults> {
        const url = `${environment.apiEndpoint}/search/filters/companionData/simulationApplicationVersions`;
        return this._httpClient.post<FilterForCollectionsSearchResults>(url, {searchText});
    }

  }
  