import { BehaviorSubject, filter, first, forkJoin, Observable, Subject, tap, timer } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { ModelUploadTaskInProgress } from '../models/model-upload-task-in-progress';
import { SignalRService } from '../api/signal-r.service';
import { TaskInProgress } from '../models/task-in-progress';
import { SimulationService } from '../api/simulation.service';
import { LOCAL_STORAGE, StorageService } from "ngx-webstorage-service";
import { MonitoringService } from './monitoring.service';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})

export class ModelUploadTaskInProgressService {

  static TaskInProgressStatus = "in-progress";
  static TaskSuccessStatus = "success";
  static TaskFailureStatus = "failure";

  public tasksInProgress: ModelUploadTaskInProgress[] = [];
  private parentTasks: ModelUploadTaskInProgress[] = [];

  private _taskInProgressSub: Subject<ModelUploadTaskInProgress> = new Subject();
  get taskInProgress$(): Observable<ModelUploadTaskInProgress> {
    return this._taskInProgressSub.asObservable();
  }

  private _parentTasksSub = new BehaviorSubject<ModelUploadTaskInProgress[]>([]);
  get parentTasksInProgress$(): Observable<ModelUploadTaskInProgress[]> {
    return this._parentTasksSub.asObservable();
  }

  private _completedTaskSub = new BehaviorSubject<ModelUploadTaskInProgress>(null);
  get completedTask$(): Observable<ModelUploadTaskInProgress> {
    return this._completedTaskSub.asObservable();
  }

  constructor(signalRService: SignalRService, private simulationService: SimulationService,
    @Inject(LOCAL_STORAGE) private localStorage: StorageService<string[]>, private monitoringService: MonitoringService) {
    signalRService.startListening<ModelUploadTaskInProgress>('simulation_uploads').subscribe(p => {
      // comments console.log('server', p)
      this.updateProgress(p);
    });

    //put in localstorage if the root task in not complete so that when refreshed  we get the status of each root task
    this._taskInProgressSub.pipe(
      tap(p => {
        this.updateMonitoring(p);
        if (!p.ParentId) {
          if (p.Progress >= 100)
            this._completedTaskSub.next(p);

          //parent tasks
          this.updateTaskProgress(p, this.parentTasks);
          this.parentTasks = this.parentTasks.filter(p => p.Progress < 100);
          this._parentTasksSub.next(this.parentTasks);
        }
      })).subscribe();

    //parent tasks
    this.parentTasks = this.tasksInProgress.filter(p => !p.ParentId && p.Progress < 100);
    this._parentTasksSub.next(this.parentTasks);
  }

  private updateMonitoring(task: ModelUploadTaskInProgress) {

    if (task.ParentId) {
      if (task.Progress == 0) {
        this.monitoringService.appInsights.startTrackEvent(`${task.DisplayTitle}`);
        this.monitoringService.appInsights.startTrackEvent(`ClientFileUpload-${task.DisplayTitle}`);
      } else if (task.Progress == environment.fileUploadProgressComplete) {
        this.monitoringService.appInsights.stopTrackEvent(`ClientFileUpload-${task.DisplayTitle}`, Object.assign({ type: 'ClientFileUpload' }, task) as any);
      }
      else if (task.Progress == 100) {
        this.monitoringService.appInsights.stopTrackEvent(`${task.DisplayTitle}`, Object.assign({ type: 'FileUpload' }, task) as any);
      }
    }
    else {
      const min = environment.minSimUploadProgress;
      const max = environment.maxSimUploadProgress;
      const avg = environment.fileUploadProgressComplete;
      const progress = min + Math.floor((max - min) * avg / 100);
      if (task.Progress === environment.minSimUploadProgress) {
        this.monitoringService.appInsights.startTrackEvent(`${task.DisplayTitle}`);
        this.monitoringService.appInsights.startTrackEvent(`ClientSimulationUpload-${task.DisplayTitle}`);
        this.monitoringService.appInsights.startTrackEvent(`SimulationSubmission-${task.DisplayTitle}`);
      } else if (task.Progress == progress) {
        this.monitoringService.appInsights.stopTrackEvent(`ClientSimulationUpload-${task.DisplayTitle}`, Object.assign({ type: 'ClientSimulationUpload' }, task) as any);
      } else if (task.Progress == 95) {
        this.monitoringService.appInsights.stopTrackEvent(`SimulationSubmission-${task.DisplayTitle}`, Object.assign({ type: 'SimulationSubmission' }, task) as any);
      } else if (task.Progress == 100) {
        this.monitoringService.appInsights.stopTrackEvent(`${task.DisplayTitle}`, Object.assign({ type: 'SimulationUpload' }, task) as any);
      }
    }
  }


  updateProgress(updatedTask: ModelUploadTaskInProgress): void {
    // comments console.log('client', taskInProgress)
    const updated = this.updateTaskProgress(updatedTask, this.tasksInProgress);

    if (updated) {
      let currentTask = this.tasksInProgress.find(x => x.Id === updatedTask.Id);
      this._taskInProgressSub.next(currentTask);
      if (!!currentTask.ParentId) {
        const childTasksProgress = this.tasksInProgress.filter(p => p.ParentId === currentTask.ParentId).map(p => p.Progress);
        const parentTask = this.tasksInProgress.find(p => p.Id === currentTask.ParentId);
        if (parentTask) {
          // comments console.log('parent task found', JSON.stringify(parentTask), JSON.stringify(childTasksProgress))
          const min = environment.minSimUploadProgress;
          const max = environment.maxSimUploadProgress;
          let avg = 0;
          childTasksProgress.forEach(p => { avg = avg + p; });
          avg = avg / childTasksProgress.length;
          // comments console.log('min, max, avg', min, max, avg)
          const progress = min + Math.floor((max - min) * avg / 100);
          // comments console.log('progress', progress, parentTask.Progress)
          if (progress > parentTask.Progress) {
            parentTask.Progress = progress;
            this._taskInProgressSub.next(parentTask);
          }
        }
      }
    }
  }

  public updateTaskProgress(updatedTask: ModelUploadTaskInProgress, tasksInProgress: ModelUploadTaskInProgress[]): boolean {
    let currentTask = tasksInProgress.find(x => x.Id === updatedTask.Id);
    let updated = false;
    if (!currentTask) {
      tasksInProgress.push(updatedTask);
      updated = true;
    } else if (currentTask.Progress <= updatedTask.Progress) {
      currentTask.Progress = updatedTask.Progress;
      currentTask.Status = updatedTask.Status;
      if (updatedTask.Size && !currentTask.Size)
        currentTask.Size = updatedTask.Size;
      updated = true;
    }
    return updated;
  }


  checkForRootTaskInUploads(updatedTask: ModelUploadTaskInProgress) {
    let someTasksInProgressAlreadyForThisRoot = this.tasksInProgress.some(p => p.RootId === updatedTask.RootId);
    if (!someTasksInProgressAlreadyForThisRoot) {
      this.simulationService.getSimulationProgress(updatedTask.RootId).pipe(
        first()
      ).subscribe();
      return false;
    }
    return true;
  }


}

