import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {concatMap, EMPTY, expand, Observable, of, timer} from "rxjs";
import {environment} from "../../../environments/environment";
import {Simulation} from "../models/export class simulation";
import {RevisionChange} from "../models/revision-change";
import {ModelFile} from "../models/model-file";
import {CollectionFile} from "../models/collection-file";



@Injectable({
  providedIn: 'root'
})
export class SimulationDownloadService {

  constructor(private _httpClient: HttpClient) {}

  getDownloadedFiles(collectionId: string, revisionId:  string, id: string): Observable<CollectionFile []> {
    const url = `${environment.apiEndpoint}/simulation/${id}/downloaded-files?collectionId=${collectionId ?? ''}&revisionId=${revisionId ?? ''}`;
    return this._httpClient.get<CollectionFile []>(url);
  }

  getOneDownLoadedFile(fileId: string): Observable<{ url: string }>{
    const url = `${environment.apiEndpoint}/simulation/downloaded-onefile?fileId=${fileId}`;
    return this._httpClient.get<{ url: string }>(url);
  }

}
