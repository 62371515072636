import {Injectable} from "@angular/core";

import { SlbLoadingService } from "@slb-dls/angular-material/loading";
import { ToastrService } from "ngx-toastr";
import { BrowserStorageService } from 'src/app/core/services/storage.service';
import {FileUtilsService} from "../../../../core/services/file-utils.service";
import {BreadcrumbsService} from "../../../../core/services/breadcrumbs.service";


@Injectable({
    providedIn: 'root'
})
export class ModelsNeededService {
  constructor(public toastrService: ToastrService,
    public fileUtilService: FileUtilsService,
    public storageService: BrowserStorageService, 
    public loadingService: SlbLoadingService,
    public breadcrumbsService: BreadcrumbsService,)
  {}
}