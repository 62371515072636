<ng-container>
  <h2 mat-dialog-title>{{data.fileName}}
    <button mat-mini-fab color="primary" (click)="refresh()" aria-label="refresh content">
      <mat-icon>refresh</mat-icon>
    </button>
    <mat-spinner mode="indeterminate" *ngIf="inProgress" diameter="20"></mat-spinner>
  </h2>
  <mat-dialog-content class="mat-typography">
    <iframe [src]="webEditorUrlSafe" id="ifrm" title="Edit File"></iframe>
  </mat-dialog-content>
  <mat-dialog-actions align="end" *ngIf="!inProgress">
    <button mat-raised-button mat-dialog-close  color="warn">Discard</button>
    <button mat-raised-button  color="primary" *ngIf="data.allowEdit" (click)="update()" cdkFocusInitial>Update</button>
  </mat-dialog-actions>
</ng-container>
