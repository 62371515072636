import { AutoMonitorPageViewData } from "../models/auto-monitor-page-view-data";
import { MonitoringService } from "../services/monitoring.service";

export function autoMonitorPageView(args: AutoMonitorPageViewData) {
    return function (target: any) {
        //disabled for now
        return;
    };
}

function origOnInitCase(origOnInit: any, target: any, monitoringServiceName: string, args: AutoMonitorPageViewData)
{
    if (origOnInit) {
        //the below should not be an arrow function else this will be undefined
        target.prototype.ngOnInit = function () {
            try {
                const monitoringService = this[monitoringServiceName] as MonitoringService;
                if (!monitoringService) {
                    throw new Error(`did not find any property ${monitoringServiceName} in ${target.name}`);
                }
                monitoringService.appInsights.trackPageView({ name: `${args.name!}`, uri: document.location.href, properties: { type: 'PageView'} });
                if (args.trackOnInitToAfterInit)
                    monitoringService.appInsights.startTrackPage(`${args.name!}`);
            }
            catch (err) {
                console.error(`error while monitoring in ngOnInit for  ${target.name}`, { err })
            }
            origOnInit.apply(this);
        };
    } else {
        throw new Error(`ngOnInit not defined in ${target.name}. autoMonitorPageView decorator requires this to be defined`);
    }
}

export function autoMonitorPageViewBak(args: AutoMonitorPageViewData) {
    return function (target: any) {
        const origOnInit = target.prototype.ngOnInit;
        const origAfterViewInit = target.prototype.ngAfterViewInit;
        const monitoringServiceName = args.monitoringServiceName || 'monitoringService';

        origOnInitCase(origOnInit, target, monitoringServiceName, args);
        // if (origOnInit) {
        //     //the below should not be an arrow function else this will be undefined
        //     target.prototype.ngOnInit = function () {
        //         try {
        //             const monitoringService = this[monitoringServiceName] as MonitoringService;
        //             if (!monitoringService) {
        //                 throw new Error(`did not find any property ${monitoringServiceName} in ${target.name}`);
        //             }
        //             monitoringService.appInsights.trackPageView({ name: `${args.name!}`, uri: document.location.href, properties: { type: 'PageView'} });
        //             if (args.trackOnInitToAfterInit)
        //                 monitoringService.appInsights.startTrackPage(`${args.name!}`);
        //         }
        //         catch (err) {
        //             console.error(`error while monitoring in ngOnInit for  ${target.name}`, { err })
        //         }
        //         origOnInit.apply(this);
        //     };
        // } else {
        //     throw new Error(`ngOnInit not defined in ${target.name}. autoMonitorPageView decorator requires this to be defined`);
        // }

        if (args.trackOnInitToAfterInit) {
            if (origAfterViewInit) {
                //the below should not be an arrow function else this will be undefined
                target.prototype.ngAfterViewInit = function () {
                    origAfterViewInit.apply(this);
                    try {
                        const monitoringService = this[monitoringServiceName] as MonitoringService;
                        if (!monitoringService) {
                            throw new Error(`did not find any property ${monitoringServiceName} in ${target.name}`);
                        }
                        monitoringService.appInsights.stopTrackPage(`${args.name!}`, document.location.href, {type: 'TrackPageView'});
                    }
                    catch (err) {
                        console.error(`error while monitoring in ngAfterViewInit for ${target.name}`, { err })
                    }
                };
            } else {
                throw new Error(`ngAfterViewInit not defined in ${target.name}. autoMonitorPageView decorator requires this to be defined`);
            }
        }


    };
}


