import * as _ from 'lodash';

export function transformLegalTagCountries(countries: any = {}): any[] {
   let {countriesOfOrigin, otherRelevantDataCountries} = countries;
  
    const combinedObj = { ...countriesOfOrigin, ...otherRelevantDataCountries };
    const results = [];
  
    for (const country in combinedObj) {
      if (combinedObj.hasOwnProperty(country)) {
        const obj = { name: combinedObj[country], code: country };
  
        results.push(obj);
      }
    }
    
    return _.sortBy(results,'name');
  }

  export function getCompanionFields(){
    return [
      { 
        key: 'ModelName', 
        name: 'Model Name', 
        type: 'text', 
        description: 'The name given to the Simulation model.', 
        default: '', 
        disabled: true,
        modelField: 'modelName'
      },
      {
        key: 'ModelIdentifier',
        name: 'Model Identifier',
        type: 'text',
        description: 'The Identifier of the Simulation Model.',
        default: '',
        required: '',
        disabled: true
      },
      {
        key: 'CollectionName',
        name: 'Collection Name',
        type: 'text',
        description: 'The collection name for a given Simulation Model',
        default: '',
        apiField: 'data.CollectionName',
        disabled: true,
        modelField: 'collectionName',
      },
      {
        key: 'CollectionIdentifier',
        name: 'Collection Identifier',
        type: 'text',
        description: 'The identifier of collection of the Simulation Model',
        default: '',
        apiField: 'data.CollectionIdentifier',
        disabled: true
      },
      {
        key: 'Remark',
        name: 'Remark',
        type: 'text',
        apiField: 'data.Remark',
        description:
          'Additional information used in describing or providing more context on the construction or assumptions used in the Simulation model.',
        placeholder: 'M21 P90 w/Acid w/PIC'
      },
      {
        key: 'GenOptManifestFileName',
        name: 'GenOpt Manifest FileName',
        type: 'text',
        description: 'The name of the GenOpt Manifest file used in orchestrating the model.',
        apiField: 'data.GenOptManifestFileName',
        placeholder: 'Manifest.gen'
      },
      {
        key: 'GenOptManifestFileIdentifier',
        name: 'GenOpt Manifest File Identifier',
        type: 'text',
        apiField: 'data.GenOptManifestFileIdentifier',
        description: 'The external iodentifier from RE-DMS of the GenOpt Manifest file used in orchestrating the model.'
      },
      {
        key: 'Entitlements',
        name: 'Entitlements',
        type: 'select',
        apiField: 'acl.viewers,acl.owners',
        multiple: true,
        disabled: true
      },
      {
        key: 'Legal',
        name: 'Legal',
        title: 'Legal Tags',
        type: 'select',
        apiField: 'legal.legaltags',
        description:
          "The entity's legal tags and compliance status. The actual contents associated with the legal tags is managed by the Compliance Service."
      },
      { key: 'Countries', 
        name: 'Countries', 
        type: 'select', 
        default: 'United States', 
        apiField: 'legal.otherRelevantDataCountries' 
      },
      {
        key: 'ModelVersionNumber',
        name: 'Model Version Number',
        type: 'select',
        options: [],
        description: 'The version identifier / number for a given Simulation Model',
        default: '',
        apiField: 'data.ModelVersionNumber',
      },
      {
        key: 'BusinessPlanCase',
        name: 'Business Plan Case',
        type: 'selectAndTypein',
        options: [],
        default: '',
        apiField: 'data.BusinessPlanCase',
        description: 'The name of the reservoir.',
        modelField: 'businessPlanCase'
      },
      {
        key: 'ReservesCase',
        name: 'Reserves Case',
        type: 'selectAndTypein',
        options: [],
        default: '',
        apiField: 'data.ReservesCase',
        description: 'The Reserves Case for which the Simulation model is applicable.',
        modelField: 'reservesCase'
      },
      { 
        key: 'AssetName', 
        name: 'Asset', 
        type: 'select', 
        options: [],
        default: '',
        apiField: 'data.AssetName', 
        description: 'The name of the Asset.',
        modelField: 'assetName' 
      },
      { 
        key: 'Reservoir', 
        name: 'Reservoir', 
        type: 'select', 
        options: [],
        default: '',
        apiField: 'data.Reservoir', 
        description: 'The name of the reservoir.',
        modelField: 'reservoir' 
      },
      {
        key: 'Field',
        name: 'Field',
        type: 'select',
        options: [],
        default: '',
        apiField: 'data.Field',
        description: 'Name of the Operational Field.',
        modelField: 'field'
      },
      {
        key: 'DecisionFrame',
        name: 'Decision Frame',
        type: 'selectAndTypein',
        options: ['2018_BPlan'],
        default: '2018_BPlan',
        apiField: 'data.DecisionFrame',
        description: 'A combination of the Forecast Year and the Case.',
        modelField: 'decisonFrame'
      },
      {
        key: 'ModelObjective',
        name: 'Model Objective',
        type: 'select',
        options: [],
        default: '',
        apiField: 'data.ModelObjective',
        description: 'The objective driving the development of the Simulation model.'
      },
      {
        key: 'ProjectPhase',
        name: 'Project Phase',
        type: 'select',
        options: [],
        default: '',
        apiField: 'data.ProjectPhase',
        description: 'The CPDEP Phase in which the Simulation model was developed and is applicable.',
        modelField: 'projectPhase'
      },
      {
        key: 'ForecastYear',
        name: 'Forecast Year',
        type: 'selectAndTypein',
        options: [],
        apiField: 'data.ForecastYear',
        description: 'The target year used in generating forecasts from the Simulation model.',
        modelField: 'forecaseYear'
      },
      {
        key: 'SimulationApplication',
        name: 'Simulation Application',
        type: 'select',
        options: ['Intersect'],
        default: 'Intersect',
        apiField: 'data.SimulationApplication',
        description: 'This represents the primary application used in building the simulation model.',
        modelField: 'simulationApplication'
      },
      {
        key: 'SimulationApplicationVersion',
        name: 'Simulation Application Version',
        type: 'selectAndTypein',
        options: [],
        default: '',
        apiField: 'data.SimulationApplicationVersion',
        description: 'The version of the primary application used in building the simulation model.',
        modelField: 'simulationApplicationVersion'
      },
      //gom
      {
        key: 'OriginalOilInPlace',
        name: 'Original Oil In Place',
        type: 'number',
        apiField: 'data.OriginalOilInPlace',
        buName: ['general','gom'],
        description: 'The OOIP estimated from the Simulation Model run for the particular case.',
        placeholder:'9000000000',
        modelField: 'originalOilInPlace'
      },
      {
        key: 'Downtime',
        name: 'Downtime',
        type: 'text',
        apiField: 'data.Downtime',
        buName: ['general','gom'],
        description: 'The downtime for the Simulation Model.',
        placeholder: 'No',
        modelField: 'downtime'
      },
      {
        key: 'HistoryMatchedDate',
        name: 'History Matched Date',
        type: 'date',
        apiField: 'data.HistoryMatchedDate',
        buName: ['general','gom'],
        description:
          'This represents the last date the simulation model was calibrated against the actual/observed production history.',
        modelField: 'historyMatchedDate'
      },
      //abu
      {
        key: 'DC3SU',
        name: 'DS3SU',
        type: 'text',
        apiField: 'data.DS3SU',
        buName: ['abu'],
        description: 'DC3SU of the simulation model',
        placeholder: 'su',
        modelField: 'dc3su'
      },
      {
        key: 'RunNumber',
        name: 'Run Number',
        type: 'number',
        apiField: 'data.RunNumber',
        buName: ['abu'],
        description: 'Run number of the simulation model',
        placeholder: '1',
        modelField: 'runNumber'
      },
      {
        key: 'Schedule',
        name: 'Schedule',
        type: 'text',
        apiField: 'data.Schedule',
        buName: ['abu'],
        description: 'Schedule of the simulation model',
        placeholder: 'once a week',
        modelField: 'schedule'
      },
      {
        key: 'TurndownRate',
        name: 'Turndown Rate',
        type: 'number',
        apiField: 'data.TurndownRate',
        buName: ['abu'],
        description: 'TurndownRate of the simulation model',
        placeholder: '1',
        modelField: 'turndownRate'
      },
      {
        key: 'IsGenOptTemplateUsed',
        name: 'Is GenOpt Template Used',
        type: 'radio',
        options: ['true', 'false'],
        default: 'true',
        apiField: 'data.IsGenOptTemplateUsed',
        description:
          'Flag indicating if GenOpt Template (file having placeholders for variables) was used in creating, evaluating and post processing simulation Models systematically.'
      },
      {
        key: 'IsGenOptGeneralUsed',
        name: 'Is GenOpt General Used',
        type: 'radio',
        options: ['true', 'false'],
        default: 'true',
        apiField: 'data.IsGenOptGeneralUsed',
        description:
          'Flag indicating if GenOpt General was used in creating, evaluating and post processing simulation Models systematically.'
      }
    ];
  }