import {autoUnsubscribe} from "../../../../../core/decorators/auto-unsubscribe.decorator";
import {AfterViewInit, Component, HostListener, Inject, OnDestroy, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {catchError, EMPTY, expand, first, last, Observable, of, Subject, Subscription, timeout} from "rxjs";
import {SafeResourceUrl} from "@angular/platform-browser";
import {environment} from "../../../../../../environments/environment";
import {DOCUMENT} from "@angular/common";
import {ToastrService} from "ngx-toastr";
import {map, switchMap} from "rxjs/operators";
import {ModifyFileRequest} from "../../../../../core/models/modify-file-request";
import * as CryptoJS from 'crypto-js';
import {RevisionTrackService} from "../../../../../core/api/revision-track.service";
import {CompletedStaging} from "../../../../../core/models/completed-staging";
import { ViewCollectionPartOneNeededService } from "../view-collectionpartoneneeded.service";
import { DomSanitizerService } from "src/app/core/services/domsanitizer.service";
import { autoMonitorPageView } from "src/app/core/decorators/auto-monitor-page-view.decorator";
import { MonitoringService } from "src/app/core/services/monitoring.service";

export interface EditFileDialogData {
  collectionId: string,
  filePath: string,
  fileName: string,
  allowEdit: boolean
}
@autoMonitorPageView({name: 'Edit File Dialog', trackOnInitToAfterInit: false })
@Component({
  selector: 'app-edit-file-dialog',
  templateUrl: './edit-file.dialog.html',
  styleUrls: ['./edit-file.dialog.scss']
})
@autoUnsubscribe({autoInclude: true})
export class EditFileDialog implements OnInit, AfterViewInit, OnDestroy {
  private fileService$: Subscription;

  inProgress = true;
  content: string = '';
  webEditorUrlSafe: SafeResourceUrl; //need safe url for iframe src
  doc: Document;

  updatedContent = new Subject<string>();
  private collectionFilesService$: Subscription;

  constructor(@Inject(MAT_DIALOG_DATA) public data: EditFileDialogData,
              //public sanitizer: DomSanitizer,
              public sanitizer: DomSanitizerService, 
              @Inject(DOCUMENT) doc: Document,
              private toastrService: ToastrService,
              public dialogRef: MatDialogRef<EditFileDialog>,
              private revisionTrackService: RevisionTrackService,
              private oneService: ViewCollectionPartOneNeededService,
              private monitoringService: MonitoringService) {

    this.webEditorUrlSafe = this.sanitizer.webEditorUrlSafe; //bypassSecurityTrustResourceUrl(environment.webEditorUrl)
    this.doc = doc;

    this.getContent();
  }
  ngAfterViewInit(): void {
    // do nothing
  }
  ngOnInit(): void {
    // do nothing
  }

  private getContent() {
    this.collectionFilesService$ = this.oneService.collectionFilesService.getFileContent(this.data.collectionId, this.data.filePath).pipe(
      map(response => response.content),
      switchMap(content => {
        const iframe = this.doc.getElementById('ifrm') as HTMLIFrameElement;
        let retryAttempts = 0;
        return of('').pipe(expand(contentOnEditor => {
          if(contentOnEditor === content || retryAttempts > 5) {
            this.inProgress = false;
            return EMPTY;
          }
          retryAttempts++;
          iframe.contentWindow.postMessage(
            {
              vsEditorData: content,
              updateOptions: {readOnly: false}
            },
            this.getDomain() // not use '*'
          );
          iframe.contentWindow.postMessage('getEditorModel', this.getDomain());
          return this.updatedContent.pipe(first(), timeout({first: 2000}), catchError(err => {
            console.error(err);
            return of('');
          }));
        }));
      })
    ).subscribe();
  }

  ngOnDestroy(): void {
    console.log('ondestroy EditFileDialog');
  }

  refresh(): void {
    this.getContent();
  }


  private getDomain(): string {
    const domain = new URL(environment.webEditorUrl);
    return `${domain.protocol}//${domain.hostname}`;
  }

  update(): void {
    const iframe = this.doc.getElementById('ifrm') as HTMLIFrameElement;
    iframe.contentWindow.postMessage('getEditorModel', this.getDomain());

    this.inProgress = true;
    const obs = {
      next: content => {
        this.inProgress = false;
        this.dialogRef.close();
      }, error: () => {
        this.inProgress = false;
      }
    };

    this.updatedContent.pipe(first(), timeout({first: 5000}), catchError(err => {
      this.toastrService.error('was not able to retrieve the modified content in a timely manner. please try again');
      return of('');
    }), switchMap(content => {
      if (content) return this.updateModifiedContent(content);
      return of(false);
    })).subscribe(obs);
  }

  private updateModifiedContent(content: string): Observable<boolean> {
    return this.oneService.collectionFilesService.updateFile({
      collectionId: this.data.collectionId,
      filePath: this.data.filePath,
      fileName: this.data.fileName,
      content: content,
      md5: CryptoJS.MD5(content).toString(CryptoJS.enc.Hex)
    } as ModifyFileRequest).pipe(switchMap(response => {
      return this.trackUpdate(response);
    }))
  }

  private trackUpdate(revisionDetails: CompletedStaging): Observable<boolean> {
    return this.revisionTrackService.trackFor({
      collectionId: this.data.collectionId,
      revisionId: revisionDetails.revisionId
    })
      .pipe(last(), map(response => {
        if (response.status === RevisionTrackService.UPDATED) {
          this.toastrService.success('files was updated successfully');
          this.oneService.collectionsService.setRefreshModelFolders(true);
          return true;
        }
        console.warn('modify file did not finish', response);
        this.toastrService.error('was not able to save the modified content in a timely manner. please try again');
        return false;
      }));
  }


  @HostListener('window:message', ['$event']) onPostMessage(event) {
    if (event.origin !== this.getDomain()) {
      return;
    }

    const responseType = event.data.requestType;
    if (responseType === 'getEditorModel') {
      const modifiedContent = event.data.response;
      this.updatedContent.next(modifiedContent);
    }
  }

}
