import { Injectable } from '@angular/core';
import { CollectionUserAccessService } from 'src/app/core/api/collection-user-access.service';
import { AuthService } from 'src/app/core/auth/auth.service';
import { first, of, switchMap } from 'rxjs';
import { ModelTreeCollapserService } from 'src/app/core/services/model-tree-collapser.service';

@Injectable({
  providedIn: 'root'
})
export class ViewCollectionPartThreeNeededService {
  constructor(public collectionUserAccessService: CollectionUserAccessService, public authService: AuthService, public modelTreeCollapserService: ModelTreeCollapserService) {}

  public recordAccess(collectionId: string): void {
    this.authService.currentAuthStatus.pipe(
      first(),
      switchMap(p => {
        if (p.userLoggedIn) {
          return this.collectionUserAccessService.recordAccess({collectionId: collectionId,
            userId: this.authService.currentUser.username, accessType: 'view', accessDateTime: new Date()});
        }
          return of(false);
      })
    ).subscribe();
  }
}
