<mat-dialog-content>
  <h1 mat-dialog-title>Simulation Linkage</h1>
  <form [formGroup]="modelLinkageForm">
    <!--current model-->
    <h4>Current Model</h4>
    <mat-form-field appearance="fill">
      <mat-label>Current Model Name</mat-label>
      <textarea matInput formControlName="currentModelName" placeholder="currentModelName"></textarea>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label floatLabel="always">Current Model ID</mat-label>
      <textarea matInput formControlName="currentModelID" placeholder="currentModelID"></textarea>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Current Collection Name</mat-label>
      <textarea matInput formControlName="currentCollectionName" placeholder="currentCollectionName"></textarea>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Current Collection ID</mat-label>
      <textarea matInput formControlName="currentCollectionID" placeholder="currentCollectionID"></textarea>
    </mat-form-field>

    
    <mat-divider></mat-divider>
    <h4>RelationShip</h4>
    <mat-form-field>
      <mat-label>Current model is --- of Linked model </mat-label>
      <textarea matInput formControlName="relationship" placeholder="relationship"></textarea>
    </mat-form-field>

    <mat-divider></mat-divider>
    <h4>Linked Model</h4>
    <!-- linked to model-->
    <mat-form-field appearance="fill">
      <mat-label>Linked Model Name</mat-label>
      <textarea matInput formControlName="linkedModelName" placeholder="linkedModelName"></textarea>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Linked Model ID</mat-label>
      <textarea matInput formControlName="linkedModelID" placeholder="linkedModelID"></textarea>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Linked Collection Name</mat-label>
      <textarea matInput formControlName="linkedCollectionName" placeholder="linkedCollectionName"></textarea>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Linked Collection ID</mat-label>
      <textarea matInput formControlName="linkedCollectionID" placeholder="linkedCollectionID"></textarea>
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button color="warn" mat-dialog-close type="button">Cancel </button>
  <button mat-raised-button color="primary" class="mr-5" (click) = "onApply()">Apply</button>
  <button mat-raised-button color="primary" class="mr-5" (click) = "onClose()">Create</button>
</mat-dialog-actions>


