import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { CreateCollectionDto, UpdateCollectionDto } from '../models/create-collection-dto';
import { Collection } from "../models/collection";
import { environment } from "../../../environments/environment";
import { ModelFile } from "../models/model-file";
import { Simulation } from "../models/export class simulation";
import { CompletedStaging } from "../models/completed-staging";
import { CollectionRevision } from "../models/collection-revision";
import { ChunkDetails } from '../models/chunk-details';

@Injectable({
  providedIn: 'root'
})
export class CollectionsService {
  private _collectionIdToDelete = new BehaviorSubject<string>("");
  private _refreshModelFolders = new Subject<boolean>();
  private _showTimeline = new Subject<boolean>();

  get refreshModelFolders(): Observable<boolean> {
    return this._refreshModelFolders.asObservable();
  }

  get showTimeline(): Observable<boolean> {
    return this._showTimeline.asObservable();
  }

  get collectionIdToDelete(): Observable<string> {
    return this._collectionIdToDelete.asObservable();
  }

  setRefreshModelFolders(refreshModelFolderValue: boolean) {
    this._refreshModelFolders.next(refreshModelFolderValue);
  }

  setCollectionIdToDelete(collectionIdToDeleteValue) {
    this._collectionIdToDelete.next(collectionIdToDeleteValue);
  }

  constructor(protected _httpClient: HttpClient) {
  }

  createCollection(collection: CreateCollectionDto): Observable<{ id: string }> {
    const url = `${environment.apiEndpoint}/collections/CreateCollection`;
    return this._httpClient.post<{ id: string }>(url, collection);
  }

  updateCollection(collection: UpdateCollectionDto): Observable<Collection> {
    const url = `${environment.apiEndpoint}/collection/${collection.id}`;
    return this._httpClient.put<Collection>(url, collection);
  }

  getCollection(id: string): Observable<Collection> {
    const url = `${environment.apiEndpoint}/collection/${id}`;
    return this._httpClient.get<Collection>(url);
  }

  getFilesForCollection(id: string): Observable<ModelFile[]> {
    const url = `${environment.apiEndpoint}/collection/${id}/files`;
    return this._httpClient.get<ModelFile[]>(url);
  }

  getSimulationsForCollection(id: string): Observable<Simulation[]> {
    const url = `${environment.apiEndpoint}/collection/${id}/simulations`;
    return this._httpClient.get<Simulation[]>(url);
  }

  getRevisionsForCollection(id: string): Observable<CollectionRevision[]> {
    const url = `${environment.apiEndpoint}/collection/${id}/revisions`;
    return this._httpClient.get<CollectionRevision[]>(url);
  }

  createStagingForCollection(id: string): Observable<{ stagingId: string }> {
    const url = `${environment.apiEndpoint}/collection/${id}/staging`;
    return this._httpClient.post<{ stagingId: string }>(url, {});
  }

  completeStaging(id: string, stagingId: string): Observable<CompletedStaging> {
    const url = `${environment.apiEndpoint}/collection/${id}/completeStaging/${stagingId}`;
    return this._httpClient.post<CompletedStaging>(url, {});
  }

  uploadManifest(id: string, stagingId: string, manifestFilePath: string, manifestFileContent: string, isCreate: boolean): Observable<{ status: boolean }> {
    const url = `${environment.apiEndpoint}/collection/${id}/uploadManifest`;
    return this._httpClient.post<{ status: boolean }>(url, { stagingId, manifestFilePath, manifestFileContent, mode: isCreate ? 0 : 1 });
  }

  stageFileUpload(id: string, stagingId: string, filePath: string, fileModifiedDateTime: Date, isCreate: boolean): Observable<{ changeId: string }> {
    const url = `${environment.apiEndpoint}/collection/${id}/stageFileUpload`;
    return this._httpClient.post<{ changeId: string }>(url, {
      stagingId,
      filePath,
      fileModifiedDateTime,
      mode: isCreate ? 0 : 1
    });
  }

  uploadChunk(id: string, changeId: string, chunkDetails: ChunkDetails, size: number,): Observable<{ status: boolean }> {
    const url = `${environment.apiEndpoint}/collection/${id}/fileUpload/${changeId}`;
    const request = {
      changeId: changeId,
      fileName: chunkDetails.fileName,
      chunkIndex: chunkDetails.chunkIndex,
      totalChunks: chunkDetails.totalChunks,
      blockId: chunkDetails.blockId,
      prevBlockIds: chunkDetails.prevBlockIds,
      size: size,
      fileMd5: chunkDetails.fileMd5
    }
    const formData: FormData = new FormData();
    formData.append('request', JSON.stringify(request));
    formData.append('chunk', chunkDetails.chunk);
    return this._httpClient.post<{ status: boolean }>(url, formData);
  }



  fileComplete(request: { collectionId: string, changeId: string, size: number, fileMd5: string }): Observable<{ status: boolean }> {
    const url = `${environment.apiEndpoint}/collection/${request.collectionId}/fileUpload/${request.changeId}/complete`;
    return this._httpClient.post<{ status: boolean }>(url, request);
  }


  deleteFileFromStaging(id: string, stagingId: string, filePath: string): Observable<{ status: boolean }> {
    const url = `${environment.apiEndpoint}/collection/${id}/deleteFileInStaging`;
    return this._httpClient.post<{ status: boolean }>(url, {
      stagingId,
      filePath,
      collectionId: id
    });
  }


  queryCollections(constraints: any) {
    const url = `${environment.apiEndpoint}/collections/QueryCollections`;
    return this._httpClient.post<any>(url, constraints);
  }

  deleteCollectionsById(deleteRequest: { collectionId: string }): Observable<any> {
    const url = `${environment.apiEndpoint}/collections/DeleteCollectionById`;
    return this._httpClient.post<any>(url, deleteRequest);
  }

  viewTimeline() {
    this._showTimeline.next(true);
  }


}
