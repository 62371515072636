import { OnInit, AfterViewInit, Component, ElementRef, ViewChild, Input, ErrorHandler } from "@angular/core";
import {MatDialog } from "@angular/material/dialog";
import cytoscape from "cytoscape";
import { CompanionData } from "src/app/core/models/query-models-response";
import { CompanionDataService } from '../../../core/api/companion-data.service';
import { ModelLinkageDialog } from "../dialogs/model-linkage.dialog";


@Component({
  selector: "app-model-linkage",
  templateUrl: "./model-linkage.component.html",
  styleUrls: ["./model-linkage.component.scss"]
})
export class ModelLinkageComponent implements OnInit {
  
  @Input() collectionId: string;
  @Input() collectionName: string;
  @Input() modelId: string;
  @Input() modelName: string;
  @Input() branchSimulationId: string;
  @ViewChild("cy") el: ElementRef;

  constructor(public dialog: MatDialog, private companionDataService: CompanionDataService)
  {
    console.log("constructor of model linkage");
  }

  ngOnInit() {
    this.companionDataService.queryCompanionDataBySimulationId(
      {modelId: this.modelId, branchSimulationId: this.branchSimulationId}
      )
      .subscribe((output: any) => {
        if (output.results.length > 0) {
          const data = output.results.find(x => x);
          this.fillGraph(data);
        }
      });
  }

  fillGraph(data: any) {
    let companionData = {
      modelId: "1111",
      branchSimulationId: "b111"
    } as CompanionData;
    let cy = cytoscape({
      container: document.getElementById('cy'),
      elements: {
        nodes: [
          {
            data: { id: "Customers" }
          },
          {
            data: { id: "Orders" }
          },
          {
            data: { id: "Items" }
          },
          {
            data: { id: "ItemsTest" }
          },
          {
            data: { id: "Shipping" }
          },
          {
            data: { id: "Returns" }
          },
          {
            data: {
              id: "Name",
              parent: "Customers",
              type: "rect"
            }
          },
          {
            data: {
              id: "Address",
              parent: "Customers",
              type: "rect"
            }
          },
          {
            data: {
              id: "Address5",
              parent: "Customers",
              type: "rect"
            }
          },
          {
            data: {
              id: "ShipTest",
              parent: "Customers",
              type: "rect"
            }
          },
          {
            data: {
              id: "Heading",
              parent: "Orders",
              type: "head"
            }
          },
          {
            data: {
              id: "Id",
              parent: "Orders",
              type: "rect"
            }
          },
          {
            data: {
              id: "Quantity",
              parent: "Items",
              type: "rect"
            }
          },
          {
            data: {
              id: "Item1",
              parent: "Items",
              type: "rect"
            }
          },
          {
            data: {
              id: "Item5",
              parent: "Items",
              type: "rect"
            }
          },
          {
            data: {
              id: "Ship",
              parent: "Items",
              type: "rect"
            }
          },
          {
            data: {
              id: "Return1",
              parent: "Returns",
              type: "rect"
            }
          }
        ],
        edges: [
          {
            data: {
              id: "ad",
              source: "Name",
              target: "Id",
              companiondata: companionData
            }
          },
          {
            data: {
              id: "ad3",
              source: "Address",
              target: "Quantity",
              companiondata: companionData
            }
          },
          {
            data: {
              id: "ad6",
              source: "Address5",
              target: "Item5"
            }
          },

          {
            data: {
              id: "fd8",
              source: "Item5",
              target: "Return1"
            }
          }
        ]
      },
      style: [
        // for node
        {
          selector: 'node',
          style: {
            //'color': 'blue',
            //'background-color': '#11479e',
            'label': 'data(id)'
          }
        },
        // for edge
        {
          selector: 'edge',
          style: {
            'width': 4,
            //'line-color': '#ccc',
            //'target-arrow-color': '#ccc',
            'target-arrow-shape': 'triangle',
            'line-color': '#9dbaea',
            'target-arrow-color': '#9dbaea',
            'curve-style': 'bezier',
            'label': 'data(id)'
          }
        }
      ],
      minZoom: 0.2,
      maxZoom: 5,
    });

    cy.layout({
      name: "dagre",
      rankDir: "LR",
      nodeSep: 10,
      edgeSep: 20,
      rankSep: 100,
      spacingFactor: 0,
      minLen: function(edge) {
        return 1;
      },
      fit: false,
      zoom: 1.0,
      pan: {x: 300, y: 100}
    }).run();

    let that = this;
    cy.on("tap", "node", function(evt) {
      let node = evt.target;
      console.log("tapped node " + node.id());
      // set classes if possible cy.nodes(node).classes("foo")
      that.onCreateLink();
    });

    cy.on("tap", "edge", function(evt) {
      let edge = evt.target;
      console.log("tapped edge=" + edge.id() + "; source=" + edge.data().source + "; target=" + edge.data().target);
      edge.remove();
    });
  }

  onCreateLink()
  {
    const dialogRef = this.dialog.open(ModelLinkageDialog, {
      width: 'auto',
      height: 'auto',
      data: {
        collectionName: this.collectionName, collectionId: this.collectionName,
        modelId: this.modelId, modelName: this.modelName
      }
    });
    dialogRef.afterClosed().subscribe(dialogSubmit => {
      if (dialogSubmit?.data) {
        console.log("create linke succeed");
        // add to graph node/edge
      }
    });

    this.companionDataService.queryCompanionData(
      {kind: '*', query: '*', limit: 75}
      )
      .subscribe((output: any) => {
        if (output.results.length > 0) {
          console.log("query companion data:" + output.results);
        }
      });
  }
}
