<h2 mat-dialog-title>
  {{title}}
</h2>
<mat-dialog-content>
  <form [formGroup]="collectionform">
    <p>
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput formControlName="name" placeholder="Collection Name"
          [ngClass]="{'is-invalid': (f.name.dirty || submitted) && f.name.errors}" />

        <mat-error *ngIf="(f.name.dirty || submitted) && f.name.errors" class="invalid-feedback">
          <div *ngIf="f.name.errors.required" class="txt-red">
            Collection Name is required
          </div>
        </mat-error>

      </mat-form-field>
    </p>
    <p>
      <mat-form-field>
        <mat-label>Description</mat-label>
        <textarea matInput formControlName="description" placeholder="Description">
                </textarea>
      </mat-form-field>
    </p>
    <p>
      <mat-form-field appearance="fill">
        <mat-chip-list #tagsChipList>
          <mat-chip *ngFor="let tag of collectionform.controls['tags'].value" (removed)="removeTag(tag)">
            {{tag}}
            <mat-icon matChipRemove svgIcon="close"></mat-icon>
          </mat-chip>
        </mat-chip-list>
        <input matInput [matChipInputFor]="tagsChipList" (matChipInputTokenEnd)="addTag($event)" [formControl]="tagCtrl"
          placeholder="New Tag..." [matChipInputSeparatorKeyCodes]="separatorKeysCodes" />
          <span matTextPrefix>Tags:&nbsp;</span>
        <div *ngIf="tagCtrl.errors" class="txt-red">
          whitespace not allowed
        </div>
      </mat-form-field>
    </p>
    <p>
      <mat-form-field appearance="fill">
        <mat-chip-list #ownersChipList>
          <mat-chip *ngFor="let owner of collectionform.controls['owners'].value" (removed)="removeOwner(owner)">
            {{owner}}
            <mat-icon matChipRemove svgIcon="close"></mat-icon>
          </mat-chip>
        </mat-chip-list>
        <input matInput #ownerInput [matChipInputFor]="ownersChipList" [formControl]="ownerCtrl" [matAutocomplete]="ownerAuto"
          (matChipInputTokenEnd)="addOwner($event)" class="width_100" placeholder="New Owner..."
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes" />
          <span matTextPrefix>Owners:&nbsp;</span>
        <mat-autocomplete #ownerAuto="matAutocomplete" (optionSelected)="selectedOwner($event)">
          <mat-option *ngFor="let group of filteredOwnerEntitlementGroups | async" [value]="group.email">
            {{group.text}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </p>
    <p>
      <mat-form-field appearance="fill">
        <mat-chip-list #viewersChipList>
          <mat-chip *ngFor="let viewer of collectionform.controls['viewers'].value" (removed)="removeViewer(viewer)"
            (editable)="false">
            {{viewer}}
            <mat-icon matChipRemove svgIcon="close"></mat-icon>
          </mat-chip>
        </mat-chip-list>
        <input matInput #viewerInput [matChipInputFor]="viewersChipList" [formControl]="viewerCtrl"
          [matAutocomplete]="viewerAuto" (matChipInputTokenEnd)="addViewer($event)" placeholder="New Viewer..."
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes" class="width_100" />
          <span matTextPrefix>Viewers:&nbsp;</span>
        <mat-autocomplete #viewerAuto="matAutocomplete" (optionSelected)="selectedViewer($event)">
          <mat-option *ngFor="let group of filteredViewerEntitlementGroups | async" [value]="group.email">
            {{group.text}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </p>

  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button [disabled]="isDisabled" mat-raised-button mat-dialog-close type="button" color="warn"
    (click)="onCancel()">
    CANCEL
  </button>
  <button [disabled]="isDisabled" class="mr-5" mat-raised-button color="primary"
    [disabled]="collectionform.invalid" (click)="onSave(collectionform.value)">
    SAVE
  </button>
</mat-dialog-actions>