<ng-container *ngFor="let modelFolder of modelFolders">
  <div *ngIf="getFilesToBeDisplayed(modelFolder.files).length > 0" class="folder-container">
    <div class="mat-h3" fxLayout="row" fxLayoutAlign="space-between center">
      <div class="folder-name">{{modelFolder.pathFromRoot}}</div>
      <div>
        <button mat-raised-button color="primary" (click)="addFilesToFolder(modelFolder)" [disabled]="isReadOnly">
          <mat-icon>note_add</mat-icon>
          <span>Add file(s)</span></button>
      </div>

    </div>

    <mat-divider></mat-divider>
    <table mat-table [dataSource]="getFilesToBeDisplayed(modelFolder.files)" class="mat-elevation-z8"
      aria-labelledby="model folders">

      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle(modelFolder) : null"
            [checked]="folderSelection.get(modelFolder.pathFromRoot).hasValue() && isAllSelected(modelFolder)"
            [indeterminate]="folderSelection.get(modelFolder.pathFromRoot).hasValue() && !isAllSelected(modelFolder)"
            [aria-label]="checkboxLabel(modelFolder)">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let modelFolderFile">
          <mat-checkbox *ngIf="modelFolderFile?.fileInfo?.updateInfo?.mode !== 'not-allowed'"
            (click)="$event.stopPropagation()" (change)="$event ? onSelect(modelFolder, modelFolderFile) : null"
            [checked]="folderSelection.get(modelFolder.pathFromRoot).isSelected(modelFolderFile)"
            [disabled]="modelFolderFile?.fileInfo?.updateInfo?.mode === 'add' && modelFolderFile.fileInfo.isPrimaryFile"
            [aria-label]="checkboxLabel(modelFolder, modelFolderFile)">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="conflict">
        <th mat-header-cell *matHeaderCellDef> Conflict</th>
        <td mat-cell *matCellDef="let modelFolderFile">
          <button mat-icon-button aria-label="conflict" color="warn"
            *ngIf="modelFolderFile?.fileInfo?.updateInfo?.conflictsWithChangesInSizeAndDate?.length > 0"
            [matTooltip]="getTooltipForConflicts(modelFolderFile?.fileInfo?.updateInfo?.conflictsWithChangesInSizeAndDate)">
            <mat-icon>new_releases</mat-icon>
          </button>
          <button mat-icon-button aria-label="conflict"
            *ngIf="((modelFolderFile?.fileInfo?.updateInfo?.conflictsWithChangesInSizeAndDate?.length ?? 0) === 0) && modelFolderFile?.fileInfo?.updateInfo?.conflictsWithSameSizeAndDate?.length > 0"
            [matTooltip]="getTooltipForConflictsButMostLikelyNotModified(modelFolderFile?.fileInfo?.updateInfo?.conflictsWithSameSizeAndDate)">
            <mat-icon>report</mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="changeType">
        <th mat-header-cell *matHeaderCellDef> Type</th>
        <td mat-cell *matCellDef="let modelFolderFile">
          <mat-icon aria-label="add" color="primary" *ngIf="modelFolderFile?.fileInfo?.updateInfo?.mode === 'add'"
            matTooltip="file is being added">
            add
          </mat-icon>
          <mat-icon color="warn" *ngIf="modelFolderFile?.fileInfo?.updateInfo?.mode === 'delete'"
            matTooltip="file is being removed">
            remove
          </mat-icon>
          <mat-icon
            *ngIf="modelFolderFile?.fileInfo?.updateInfo?.mode === 'update' && modelFolderFile?.fileInfo?.updateInfo?.sizeOrDateDifferenceWithUpdatedFile"
            matTooltip="file is being updated">
            upgrade
          </mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="index">
        <th mat-header-cell *matHeaderCellDef> No.</th>
        <td mat-cell *matCellDef="let index = index"> {{index + 1}} </td>
      </ng-container>

      <ng-container matColumnDef="options">
        <th mat-header-cell *matHeaderCellDef>

        </th>
        <td mat-cell *matCellDef="let modelFolderFile">
          <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="options"
            *ngIf="anyOptionExist(modelFolderFile)">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item *ngIf="modelFolderFile.options.view" (click)="onView(modelFolderFile)">
              <mat-icon>visibility</mat-icon>
              <span>View</span>
            </button>
            <button mat-menu-item *ngIf="modelFolderFile.options.edit" (click)="onEdit(modelFolderFile)"
              [disabled]="isReadOnlyValue">
              <mat-icon>edit</mat-icon>
              <span>Edit</span>
            </button>
            <button mat-menu-item *ngIf="modelFolderFile.options.compare" (click)="onCompare(modelFolderFile)"
              [disabled]="isReadOnlyValue">
              <mat-icon>compare</mat-icon>
              <span>Compare</span>
            </button>
            <button mat-menu-item *ngIf="modelFolderFile.options.delete" (click)="onDelete(modelFolderFile)"
              [disabled]="isReadOnlyValue">
              <mat-icon>delete</mat-icon>
              <span>Delete</span>
            </button>
            <button mat-menu-item *ngIf="modelFolderFile.options.timeline" (click)="onTimeline(modelFolderFile)"
              [disabled]="isReadOnlyValue">
              <mat-icon>history</mat-icon>
              <span>Timeline</span>
            </button>
            <button mat-menu-item *ngIf="modelFolderFile.options.download" (click)="onDownload(modelFolderFile)">
              <mat-icon>download</mat-icon>
              <span>Download</span>
            </button>
          </mat-menu>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name</th>
        <td mat-cell *matCellDef="let modelFolderFile">
          {{modelFolderFile.name}}
        </td>
      </ng-container>

      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef> Type</th>
        <td mat-cell *matCellDef="let modelFolderFile">
          <mat-button-toggle-group *ngIf="modelFolderFile?.fileInfo?.updateInfo?.optional" name="fontStyle" [disabled]="!modelFolderFile?.fileInfo?.updateInfo?.optional"
            [value]="modelFolderFile.fileInfo.type"
            (change)="onModelFolderFileTypeChange($event.value, modelFolderFile)"
             aria-label="Font Style">
            <mat-button-toggle value="input">input</mat-button-toggle>
            <mat-button-toggle value="output">output</mat-button-toggle>
          </mat-button-toggle-group>
          <span *ngIf="!modelFolderFile?.fileInfo?.updateInfo?.optional">  {{modelFolderFile.fileInfo.type}} </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="size">
        <th mat-header-cell *matHeaderCellDef> Size(in kb)</th>
        <td mat-cell *matCellDef="let modelFolderFile"> {{modelFolderFile.fileInfo.size / 1024 | number:'1.0-2'}} </td>
      </ng-container>

      <ng-container matColumnDef="createdUser">
        <th mat-header-cell *matHeaderCellDef> Created By</th>
        <td mat-cell *matCellDef="let modelFolderFile"> {{modelFolderFile.fileInfo.createdUserId}} </td>
      </ng-container>

      <ng-container matColumnDef="createdDate">
        <th mat-header-cell *matHeaderCellDef> Created Date</th>
        <td mat-cell *matCellDef="let modelFolderFile"> {{modelFolderFile.fileInfo.createdDateTime | date : 'short'}}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="folderDisplayedColumns.get(modelFolder.pathFromRoot)"></tr>
      <tr mat-row *matRowDef="let modelFolderFile; columns: folderDisplayedColumns.get(modelFolder.pathFromRoot);"
        [ngClass]="modelFolderFile.fileInfo.type" [class.removed]="modelFolderFile.options.undoRemove"></tr>
    </table>
  </div>
  <app-view-model-folders [searchText]="searchText" [isReadOnly]="isReadOnlyValue"
    [modelFolders]="modelFolder.subFolders" (addFile)="onAddFileFromChildren($event)"
    (selectionChange)="onSubFolderSelectionChange($event)"
    (showFileTimeline)="onShowFileTimelineFromSubFolder($event)"></app-view-model-folders>
</ng-container>
