import { Observable, defer } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  constructor(protected _http: HttpClient) {}

  downloadChunk(request: {
    signedUrl: string;
    fileName: string;
    start: number;
    end: number;
  }): Observable<HttpResponse<Object>> {
    const url = `${environment.apiEndpoint}/file/DownloadChunk`;
    return this._http.post(url, request, { observe: 'response', responseType: 'blob' });
  }

  copyBlob(request: {
    containerName: string;
    fileName: string;
    filePath: string;
    fileSize: number;
    changeId: string;
  }): Observable<any> {
    const url = `${environment.apiEndpoint}/file/copy`;
    return this._http.post(url, request);
  }

  getChunk(signedUrl: string, startIndex: number, endIndex: number): Observable<Response> {
    const url = `${signedUrl}`;
    return defer(() => fetch(url, {
      headers: { 'x-ms-range': `bytes=${startIndex}-${endIndex <= 0 ? '' : endIndex}` }
    }));
  }
}
