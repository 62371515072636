<ng-container>
  <h2 mat-dialog-title>{{data.fileName}}</h2>
  <button mat-mini-fab color="primary" (click)="refresh()" aria-label="refresh content">
    <mat-icon>refresh</mat-icon>
  </button>
  <mat-spinner mode="indeterminate" *ngIf="inProgress" diameter="20"></mat-spinner>
  <mat-dialog-content class="mat-typography">
    <iframe [src]="webEditorUrlSafe" id="ifrm" title="Open Files"></iframe>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-raised-button mat-dialog-close  color="warn">Close</button>
    <button mat-raised-button *ngIf="data.allowEdit" [mat-dialog-close]="'edit'" cdkFocusInitial  color="primary">Edit</button>
  </mat-dialog-actions>
</ng-container>
