import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject, first, Observable, ReplaySubject, tap, zip} from "rxjs";
import {environment} from "../../../environments/environment";
import {EntitlementGroup} from "../models/entitlement-group";
import { BrowserStorageService } from '../services/storage.service';


@Injectable({
  providedIn: 'root'
})
export class EntitlementsService {

  private myGroups = new ReplaySubject<EntitlementGroup[]>(1);
  private myGroupsWithBuInfo = new ReplaySubject<(EntitlementGroup & {text: string, isConfiguredInBu: boolean})[]>(1);
  private buGroups = new ReplaySubject<EntitlementGroup[]>(1);

  constructor(private httpClient: HttpClient,
              private storage: BrowserStorageService
    ) {
    zip(this.httpClient.get<EntitlementGroup[]>(`${environment.apiEndpoint}/entitlements/myGroups`),
      this.httpClient.get<EntitlementGroup[]>(`${environment.apiEndpoint}/entitlements/buGroups`)).pipe(tap(([mg, bg]) => {
        const ovg = mg.filter(p => p.email.includes('.owners') || p.email.includes('.viewers'));
        this.myGroups.next(ovg);
        this.buGroups.next(bg);

        const withBuInfo =   ovg.map(p => {
        const isConfiguredInBu =bg.some(x => x.email === p.email);
        const isDefault = bg.some(x => x.email === p.email && x.isDefault);
        const text = `${p.email}${isConfiguredInBu?' (bu)':''}${isConfiguredInBu?' (default)':''}`;
        return Object.assign(p, {
          isConfiguredInBu,
          isDefault,
          text
        });
      });
      this.myGroupsWithBuInfo.next(withBuInfo);
    })).subscribe();
  }

  public getMyGroups = (): Observable<EntitlementGroup[]> => this.myGroups.asObservable();

  public getBuGroups = (): Observable<EntitlementGroup[]> => this.buGroups.asObservable();

  public getMyGroupsWithBuInfo = (): Observable<(EntitlementGroup & {text: string, isConfiguredInBu: boolean})[]> => this.myGroupsWithBuInfo.asObservable();

}
