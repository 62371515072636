/**
 * @title Dialog Overview
 */
import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { BehaviorSubject, of, Subscription, switchMap, tap, throttleTime } from "rxjs";
import { TasksInProgressService } from "../../../core/services/tasks-in-progress.service";
import { SubTaskInProgress, TaskInProgress } from "../../../core/models/task-in-progress";
import { MatTableDataSource } from "@angular/material/table";
import { debounceTime, filter, map } from "rxjs/operators";
import { ModelUploadTaskInProgress } from "src/app/core/models/model-upload-task-in-progress";
import { ModelUploadTaskInProgressService } from "src/app/core/services/model-upload-task-in-progress.service";
import { autoMonitorPageView } from "src/app/core/decorators/auto-monitor-page-view.decorator";
import { MonitoringService } from "src/app/core/services/monitoring.service";



@Component({
  selector: 'app-upload-tasks-table-dialog',
  templateUrl: './upload-tasks-table-dialog.html',
  styleUrls: ['./upload-tasks-table-dialog.component.scss']
})
@autoMonitorPageView({ name: 'Upload Tasks In Progress Dialog', trackOnInitToAfterInit: false })
export class UploadTasksTableDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  private tasksInProgressService$ = Subscription.EMPTY;
  rootTaskInProgress: ModelUploadTaskInProgress;
  tasksInProgress: ModelUploadTaskInProgress[];
  displayedColumns: string[] = ['title', 'progress', 'status'];
  dataSource = new MatTableDataSource([]);
  filterText: string = 'in-progress';
  lastSearchedText: string;

  private inputChange = new BehaviorSubject(false);
  private inputChange$: Subscription;

  constructor(
    public dialogRef: MatDialogRef<UploadTasksTableDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string,
    private modelUploadTaskInProgressService: ModelUploadTaskInProgressService,
    private monitoringService: MonitoringService
  ) {
    this.inputChange$ = this.inputChange.pipe(debounceTime(100), tap(inputRecd => {
      if (inputRecd) {
        const filterValue = this.filterText || '';
        this.lastSearchedText = filterValue;
        this.dataSource.filter = filterValue.trim().toLowerCase();
      }
    })).subscribe();
  }
  ngAfterViewInit(): void {
    // do nothing
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    console.log('ngOnDestroy of UploadTasksTableDialogComponent');
  }

  ngOnInit(): void {
    if (this.data) {
      this.rootTaskInProgress = this.modelUploadTaskInProgressService.tasksInProgress.find(p => p.RootId === this.data && !p.ParentId);
      this.tasksInProgress = this.modelUploadTaskInProgressService.tasksInProgress.filter(p => p.RootId === this.data && !!p.ParentId);
      this.dataSource.data = this.tasksInProgress;
      this.tasksInProgressService$ = this.modelUploadTaskInProgressService.taskInProgress$
        .pipe(
          // throttleTime(1000),
          filter(p => p.RootId === this.data),
          tap(p => {
            this.modelUploadTaskInProgressService.updateTaskProgress(p, this.tasksInProgress);
            this.dataSource.data = this.tasksInProgress.filter(p => p.Id !== this.data && p.RootId === this.data);
          }))
        .subscribe();
        this.inputChange.next(true);
    }
  }

  applyFilter(event: KeyboardEvent) {
    this.inputChange.next(true);
  }

  getBackgroundColor(taskInProgress: ModelUploadTaskInProgress): string {
    switch (taskInProgress.Status) {
      // case 'NOT_STARTED':
      //   return '#f1f0f0';
      case ModelUploadTaskInProgressService.TaskInProgressStatus:
        return '#ffffff';
      case ModelUploadTaskInProgressService.TaskSuccessStatus:
        return '#d9e8c0';
      case ModelUploadTaskInProgressService.TaskFailureStatus:
        return '#f3c7cb';
    }

  }

  getBackgroundPadding(taskInProgress: ModelUploadTaskInProgress): number {
    if (taskInProgress.Status === ModelUploadTaskInProgressService.TaskSuccessStatus || taskInProgress.Status === ModelUploadTaskInProgressService.TaskFailureStatus)
      return 0;
    else
      return 7;
  }

  getTitleFontSize(taskInProgress: ModelUploadTaskInProgress): string {
    return taskInProgress.Progress < 100 ? "20" : "14";
  }
}

