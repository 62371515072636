<div fxFill fxLayout="column">
  <mat-spinner diameter="30" *ngIf="inProgress"></mat-spinner>
  <mat-vertical-stepper *ngIf="!inProgress" [linear]="false">
    <mat-step [completed]="false" *ngFor="let collectionRevision of collectionRevisions">
      <ng-template matStepLabel>{{ collectionRevision.createdDateTime |  date:'medium'}}</ng-template>
      <div fxflexfill fxLayout="row wrap">
        <div class="element-detail-item">
          <div class="element-detail-item-header" mat-line>Id</div>
          <div mat-line> {{collectionRevision.id}}</div>
        </div>
        <div class="element-detail-item">
          <div class="element-detail-item-header" mat-line>Index</div>
          <div mat-line>{{collectionRevision.index}}</div>
        </div>

        <div class="element-detail-item">
          <div class="element-detail-item-header" mat-line>User</div>
          <div mat-line>{{collectionRevision.createdUser}}</div>
        </div>

        <div class="element-detail-item">
          <div class="element-detail-item-header" mat-line>Changed Files</div>
          <div mat-line> {{ collectionRevision.numberOfChangedFiles}} </div>
        </div>

        <div class="element-detail-item">
          <div class="element-detail-item-header" mat-line>Changed Simulations</div>
          <div mat-line> {{ collectionRevision.numberOfChangedSimulations}} </div>
        </div>

        <div class="element-detail-item">
          <div class="element-detail-item-header" mat-line>Created Files</div>
          <div mat-line> {{ collectionRevision.numberOfCreatedFiles}} </div>
        </div>

        <div class="element-detail-item">
          <div class="element-detail-item-header" mat-line>Created Simulations</div>
          <div mat-line> {{ collectionRevision.numberOfCreatedSimulations}} </div>
        </div>

        <div class="element-detail-item">
          <div class="element-detail-item-header" mat-line>Deleted Files</div>
          <div mat-line> {{ collectionRevision.numberOfDeletedFiles}} </div>
        </div>

        <div class="element-detail-item">
          <div class="element-detail-item-header" mat-line>Deleted Simulations</div>
          <div mat-line> {{ collectionRevision.numberOfDeletedSimulations}} </div>
        </div>

        <div class="element-detail-item">
          <button mat-raised-button color="primary" (click)="onView(collectionRevision)">
            <mat-icon>remove_red_eye</mat-icon>
            <span>View</span>
          </button>
        </div>

        <div class="element-detail-item">
          <button mat-raised-button [disabled]="(collectionRevisions?.length ?? 0) <= 1" color="primary" [matMenuTriggerFor]="menu">
            <mat-icon>compare</mat-icon>
            <span>Compare With</span></button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item *ngFor="let compareVersion of getRevisionsThatCanBeCompared(collectionRevision)"
                    (click)="onCompare(collectionRevision, compareVersion)">
              {{compareVersion['idx']}} {{ compareVersion.createdDateTime |  date:'medium'}}
            </button>
          </mat-menu>
        </div>

        <div class="element-detail-item">
          <button mat-raised-button color="primary" (click)="onViewRevisionChanges(collectionRevision)">
            <mat-icon>remove_red_eye</mat-icon>
            <span>View Changes in this revision</span>
          </button>
        </div>


      </div>
    </mat-step>
  </mat-vertical-stepper>
</div>
