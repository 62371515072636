/**
 * @title Dialog Overview
 */
import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { BehaviorSubject, of, Subscription, switchMap, tap, throttleTime } from "rxjs";
import { MatTableDataSource } from "@angular/material/table";
import { debounceTime, filter, map } from "rxjs/operators";
import { autoMonitorPageView } from "src/app/core/decorators/auto-monitor-page-view.decorator";
import { MonitoringService } from "src/app/core/services/monitoring.service";
import { ModelDownloadTaskInProgress, ModelDownloadTaskInProgressService } from "src/app/core/services/model-download-task-in-progress.service";



@Component({
  selector: 'app-download-tasks-table-dialog',
  templateUrl: './download-tasks-table-dialog.html',
  styleUrls: ['./download-tasks-table-dialog.component.scss']
})
@autoMonitorPageView({ name: 'Download Tasks In Progress Dialog', trackOnInitToAfterInit: false })
export class DownloadTasksTableDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  private tasksInProgressService$ = Subscription.EMPTY;
  rootTaskInProgress: ModelDownloadTaskInProgress;
  tasksInProgress: ModelDownloadTaskInProgress[];
  displayedColumns: string[] = ['title', 'progress', 'status'];
  dataSource = new MatTableDataSource([]);
  filterText: string = 'in-progress';
  lastSearchedText: string;

  private inputChange = new BehaviorSubject(false);
  private inputChange$: Subscription;

  constructor(
    public dialogRef: MatDialogRef<DownloadTasksTableDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string,
    private modelDownloadTaskInProgressService: ModelDownloadTaskInProgressService,
    private monitoringService: MonitoringService
  ) {
    this.inputChange$ = this.inputChange.pipe(debounceTime(100), tap(inputRecd => {
      if (inputRecd) {
        const filterValue = this.filterText || '';
        this.lastSearchedText = filterValue;
        this.dataSource.filter = filterValue.trim().toLowerCase();
      }
    })).subscribe();
  }
  ngAfterViewInit(): void {
    // do nothing
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    console.log('ngOnDestroy of DownloadTasksTableDialogComponent');
  }

  ngOnInit(): void {
    if (this.data) {
      this.rootTaskInProgress = this.modelDownloadTaskInProgressService.tasksInProgress.find(p => p.Id === this.data && p.RootId === this.data);
      this.tasksInProgress = this.modelDownloadTaskInProgressService.tasksInProgress.filter(p => p.Id !== this.data && p.RootId === this.data);
      this.dataSource.data = this.tasksInProgress;
      this.tasksInProgressService$ = this.modelDownloadTaskInProgressService.taskInProgress$
        .pipe(
          // throttleTime(1000),
          filter(p => p.RootId === this.data),
          tap(p => {
            this.modelDownloadTaskInProgressService.updateTaskProgress(p, this.tasksInProgress);
            this.dataSource.data = this.tasksInProgress.filter(p => p.Id !== this.data && p.RootId === this.data);
          }))
        .subscribe();
        this.inputChange.next(true);
    }
  }

  applyFilter(event: KeyboardEvent) {
    this.inputChange.next(true);
  }

  getBackgroundColor(taskInProgress: ModelDownloadTaskInProgress): string {
    switch (taskInProgress.Status) {
      // case 'NOT_STARTED':
      //   return '#f1f0f0';
      case ModelDownloadTaskInProgressService.TaskInProgressStatus:
        return '#ffffff';
      case ModelDownloadTaskInProgressService.TaskSuccessStatus:
        return '#d9e8c0';
      case ModelDownloadTaskInProgressService.TaskFailureStatus:
        return '#f3c7cb';
    }

  }

  getBackgroundPadding(taskInProgress: ModelDownloadTaskInProgress): number {
    if (taskInProgress.Status === ModelDownloadTaskInProgressService.TaskSuccessStatus || taskInProgress.Status === ModelDownloadTaskInProgressService.TaskFailureStatus)
      return 0;
    else
      return 7;
  }

  getTitleFontSize(taskInProgress: ModelDownloadTaskInProgress): string {
    return taskInProgress.Progress < 100 ? "20" : "14";
  }
}

