<ng-container>
  <h2 mat-dialog-title>
    Collection Revision Comparison {{data.leftRevision.index + 1}} <==> {{data.rightRevision.index + 1}}
    <mat-spinner mode="indeterminate" *ngIf="inProgress" diameter="20"></mat-spinner>
  </h2>
  <mat-dialog-content class="mat-typography">
    <table mat-table [dataSource]="fileComparisons" class="mat-elevation-z8" aria-labelledby="compare collections">

      <ng-container matColumnDef="idx">
        <th mat-header-cell *matHeaderCellDef> No.</th>
        <td mat-cell *matCellDef="let comparison"> {{comparison.idx}} </td>
      </ng-container>

      <ng-container matColumnDef="leftName">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let comparison" [class.modified]="comparison.changeType === 'modified'" [class.deleted]="comparison.changeType === 'deleted'">
          <button *ngIf="comparison.changeType === 'modified' && comparison.comparisonViewable;else content" mat-button color="warn"
                  (click)="onCompareFileVersions(comparison.leftName, comparison.leftFileId, comparison.rightFileId)">{{comparison.leftName}}</button>
          <ng-template #content>{{comparison.leftName}}</ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="leftFolder">
        <th mat-header-cell *matHeaderCellDef> Path </th>
        <td mat-cell *matCellDef="let comparison" [class.modified]="comparison.changeType === 'modified'" [class.deleted]="comparison.changeType === 'deleted'"> {{comparison.leftFolder}} </td>
      </ng-container>

      <ng-container matColumnDef="leftSize">
        <th mat-header-cell *matHeaderCellDef> Size </th>
        <td mat-cell *matCellDef="let comparison" [class.deleted]="comparison.changeType === 'deleted'"> {{comparison.leftSize  | number: '1.0-2'}} </td>
      </ng-container>

      <ng-container matColumnDef="leftDate">
        <th mat-header-cell *matHeaderCellDef> Modified On </th>
        <td mat-cell *matCellDef="let comparison" [class.deleted]="comparison.changeType === 'deleted'"> {{comparison.leftDate | date: 'mediumDate'}} </td>
      </ng-container>

      <ng-container matColumnDef="rightName">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let comparison" [class.modified]="comparison.changeType === 'modified'">
          <button *ngIf="comparison.changeType === 'modified' && comparison.comparisonViewable;else content" mat-button color="warn"
                  (click)="onCompareFileVersions(comparison.rightName, comparison.leftFileId, comparison.rightFileId)"
          >{{comparison.rightName}}</button>
          <ng-template #content>{{comparison.rightName}}</ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="rightFolder">
        <th mat-header-cell *matHeaderCellDef> Path </th>
        <td mat-cell *matCellDef="let comparison" [class.modified]="comparison.changeType === 'modified'"> {{comparison.rightFolder}} </td>
      </ng-container>

      <ng-container matColumnDef="rightSize">
        <th mat-header-cell *matHeaderCellDef> Size </th>
        <td mat-cell *matCellDef="let comparison"> {{comparison.rightSize | number: '1.0-2'}} </td>
      </ng-container>

      <ng-container matColumnDef="rightDate">
        <th mat-header-cell *matHeaderCellDef> Modified On </th>
        <td mat-cell *matCellDef="let comparison"> {{comparison.rightDate | date: 'mediumDate'}} </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </mat-dialog-content>
  <mat-dialog-actions align="end" *ngIf="!inProgress">
    <button mat-raised-button mat-dialog-close color="warn">Close</button>
  </mat-dialog-actions>
</ng-container>
