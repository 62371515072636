import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatSelectionListChange } from "@angular/material/list";
import { SlbLoadingService } from "@slb-dls/angular-material/loading";
import { combineLatest, Observable, of, startWith, Subscription, tap, zip } from "rxjs";
import { FilterForCollectionsSearchResults, SearchCollectionsService, SearchForCollectionsCompanionDataFilters, SearchForCollectionsFilters, SearchForCollectionsModelFilters } from "src/app/core/api/search-collections.service";
import { autoUnsubscribe } from "src/app/core/decorators/auto-unsubscribe.decorator";

@autoUnsubscribe({ autoInclude: true })
@Component({
  selector: 'app-search-collections-filters',
  templateUrl: './search-collections-filters.html',
  styleUrls: ['./search-collections-filters.scss']
})

export class SearchCollectionsFiltersComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  @Input() searchText: string = '';
  @Input() reset: any;
  @Output() public filtersChange = new EventEmitter<{
    collectionFilters?: SearchForCollectionsFilters,
    modelFilters?: SearchForCollectionsModelFilters,
    companionDataFilters?: SearchForCollectionsCompanionDataFilters
  }>();

  inProgress = false;

  collectionUsers: FilterForCollectionsSearchResults;
  collectionOwners: FilterForCollectionsSearchResults;
  collectionViewers: FilterForCollectionsSearchResults;
  modelUsers: FilterForCollectionsSearchResults;
  companionDataOwners: FilterForCollectionsSearchResults;
  companionDataViewers: FilterForCollectionsSearchResults;
  companionDataLegalTags: FilterForCollectionsSearchResults;
  companionDataCountries: FilterForCollectionsSearchResults;
  companionDataModelVersions: FilterForCollectionsSearchResults;
  companionDataBusinessPlanCases: FilterForCollectionsSearchResults;
  companionDataReservesCases: FilterForCollectionsSearchResults;
  companionDataReservoirs: FilterForCollectionsSearchResults;
  companionDataDecisionFrames: FilterForCollectionsSearchResults;
  companionDataModelObjectives: FilterForCollectionsSearchResults;
  companionDataProjectPhases: FilterForCollectionsSearchResults;
  companionDataFields: FilterForCollectionsSearchResults;
  companionDataForecastYears: FilterForCollectionsSearchResults;
  companionDataSimulationApplications: FilterForCollectionsSearchResults;
  companionDataSimulationApplicationVersions: FilterForCollectionsSearchResults;

  currentDate = new Date();

  collectionNameFilterControl = new FormControl('');
  collectionNameFilterControl$: Subscription;

  collectionUsersFilterControl = new FormControl([]);
  collectionUsersFilterControl$: Subscription;

  collectionOwnersFilterControl = new FormControl([]);
  collectionOwnersFilterControl$: Subscription;

  collectionViewersFilterControl = new FormControl([]);
  collectionViewersFilterControl$: Subscription;

  collectionStartDateRange = new FormGroup({
    start: new FormControl(null),
    end: new FormControl(null),
  });
  collectionStartDateRangeStartFilterControl$: Subscription;
  collectionStartDateRangeEndFilterControl$: Subscription;

  collectionUpdatedDateRange = new FormGroup({
    start: new FormControl(null),
    end: new FormControl(null),
  });
  collectionUpdatedDateRangeStartFilterControl$: Subscription;
  collectionUpdatedDateRangeEndFilterControl$: Subscription;


  modelNameFilterControl = new FormControl('');
  modelNameFilterControl$: Subscription;

  modelUsersFilterControl = new FormControl([]);
  modelUsersFilterControl$: Subscription;

  modelLastUpdatedDateRange = new FormGroup({
    start: new FormControl(null),
    end: new FormControl(null),
  });
  modelLastUpdatedDateRangeStartFilterControl$: Subscription;
  modelLastUpdatedDateRangeEndFilterControl$: Subscription;

  remarksFilterControl = new FormControl('');
  remarksFilterControl$: Subscription;

  genOptManifestFileNameFilterControl = new FormControl('');
  genOptManifestFileNameFilterControl$: Subscription;

  companionDataOwnersFilterControl = new FormControl([]);
  companionDataOwnersFilterControl$: Subscription;

  companionDataViewersFilterControl = new FormControl([]);
  companionDataViewersFilterControl$: Subscription;

  companionDataLegalTagsFilterControl = new FormControl([]);
  companionDataLegalTagsFilterControl$: Subscription;

  companionDataCountriesFilterControl = new FormControl([]);
  companionDataCountriesFilterControl$: Subscription;

  companionDataModelVersionsFilterControl = new FormControl([]);
  companionDataModelVersionsFilterControl$: Subscription;

  companionDataBusinessPlanCasesFilterControl = new FormControl([]);
  companionDataBusinessPlanCasesFilterControl$: Subscription;

  companionDataReservesCasesFilterControl = new FormControl([]);
  companionDataReservesCasesFilterControl$: Subscription;

  companionDataReservoirsFilterControl = new FormControl([]);
  companionDataReservoirsFilterControl$: Subscription;

  companionDataDecisionFramesFilterControl = new FormControl([]);
  companionDataDecisionFramesFilterControl$: Subscription;

  companionDataModelObjectivesFilterControl = new FormControl([]);
  companionDataModelObjectivesFilterControl$: Subscription;

  companionDataProjectPhasesFilterControl = new FormControl([]);
  companionDataProjectPhasesFilterControl$: Subscription;

  companionDataFieldsFilterControl = new FormControl([]);
  companionDataFieldsFilterControl$: Subscription;

  companionDataForecastYearsFilterControl = new FormControl([]);
  companionDataForecastYearsFilterControl$: Subscription;

  companionDataSimulationApplicationsFilterControl = new FormControl([]);
  companionDataSimulationApplicationsFilterControl$: Subscription;

  companionDataSimulationApplicationVersionsFilterControl = new FormControl([]);
  companionDataSimulationApplicationVersionsFilterControl$: Subscription;

  constructor(private searchCollectionsService: SearchCollectionsService, private loadingService: SlbLoadingService,) {
  }
  
  ngAfterViewInit(): void {
    // throw new Error("Method not implemented.");
  }


  ngOnInit(): void {
    this.emitFilterChange();
    this.collectionNameFilterControl$ = this.collectionNameFilterControl.valueChanges.subscribe(this.emitFilterChange.bind(this));
    this.collectionUsersFilterControl$ = this.collectionUsersFilterControl.valueChanges.subscribe(this.emitFilterChange.bind(this));
    this.collectionOwnersFilterControl$ = this.collectionOwnersFilterControl.valueChanges.subscribe(this.emitFilterChange.bind(this));
    this.collectionViewersFilterControl$ = this.collectionViewersFilterControl.valueChanges.subscribe(this.emitFilterChange.bind(this));
    this.collectionStartDateRangeStartFilterControl$ = this.collectionStartDateRange.controls['start'].valueChanges.subscribe(this.emitFilterChange.bind(this));
    this.collectionStartDateRangeEndFilterControl$ = this.collectionStartDateRange.controls['end'].valueChanges.subscribe(this.emitFilterChange.bind(this));
    this.collectionUpdatedDateRangeStartFilterControl$ = this.collectionUpdatedDateRange.controls['start'].valueChanges.subscribe(this.emitFilterChange.bind(this));
    this.collectionUpdatedDateRangeEndFilterControl$ = this.collectionUpdatedDateRange.controls['end'].valueChanges.subscribe(this.emitFilterChange.bind(this));
    this.modelNameFilterControl$ = this.modelNameFilterControl.valueChanges.subscribe(this.emitFilterChange.bind(this));
    this.modelUsersFilterControl$ = this.modelUsersFilterControl.valueChanges.subscribe(this.emitFilterChange.bind(this));
    this.modelLastUpdatedDateRangeStartFilterControl$ = this.modelLastUpdatedDateRange.controls['start'].valueChanges.subscribe(this.emitFilterChange.bind(this));
    this.modelLastUpdatedDateRangeEndFilterControl$ = this.modelLastUpdatedDateRange.controls['end'].valueChanges.subscribe(this.emitFilterChange.bind(this));
    this.remarksFilterControl$ = this.remarksFilterControl.valueChanges.subscribe(this.emitFilterChange.bind(this));
    this.genOptManifestFileNameFilterControl$ = this.genOptManifestFileNameFilterControl.valueChanges.subscribe(this.emitFilterChange.bind(this));
    this.companionDataOwnersFilterControl$ = this.companionDataOwnersFilterControl.valueChanges.subscribe(this.emitFilterChange.bind(this));
    this.companionDataViewersFilterControl$ = this.companionDataViewersFilterControl.valueChanges.subscribe(this.emitFilterChange.bind(this));
    this.companionDataLegalTagsFilterControl$ = this.companionDataLegalTagsFilterControl.valueChanges.subscribe(this.emitFilterChange.bind(this));
    this.companionDataCountriesFilterControl$ = this.companionDataCountriesFilterControl.valueChanges.subscribe(this.emitFilterChange.bind(this));
    this.companionDataModelVersionsFilterControl$ = this.companionDataModelVersionsFilterControl.valueChanges.subscribe(this.emitFilterChange.bind(this));
    this.companionDataBusinessPlanCasesFilterControl$ = this.companionDataBusinessPlanCasesFilterControl.valueChanges.subscribe(this.emitFilterChange.bind(this));
    this.companionDataReservesCasesFilterControl$ = this.companionDataReservesCasesFilterControl.valueChanges.subscribe(this.emitFilterChange.bind(this));
    this.companionDataReservoirsFilterControl$ = this.companionDataReservoirsFilterControl.valueChanges.subscribe(this.emitFilterChange.bind(this));
    this.companionDataDecisionFramesFilterControl$ = this.companionDataDecisionFramesFilterControl.valueChanges.subscribe(this.emitFilterChange.bind(this));
    this.companionDataModelObjectivesFilterControl$ = this.companionDataModelObjectivesFilterControl.valueChanges.subscribe(this.emitFilterChange.bind(this));
    this.companionDataProjectPhasesFilterControl$ = this.companionDataProjectPhasesFilterControl.valueChanges.subscribe(this.emitFilterChange.bind(this));
    this.companionDataFieldsFilterControl$ = this.companionDataFieldsFilterControl.valueChanges.subscribe(this.emitFilterChange.bind(this));
    this.companionDataForecastYearsFilterControl$ = this.companionDataForecastYearsFilterControl.valueChanges.subscribe(this.emitFilterChange.bind(this));
    this.companionDataSimulationApplicationsFilterControl$ = this.companionDataSimulationApplicationsFilterControl.valueChanges.subscribe(this.emitFilterChange.bind(this));
    this.companionDataSimulationApplicationVersionsFilterControl$ = this.companionDataSimulationApplicationVersionsFilterControl.valueChanges.subscribe(this.emitFilterChange.bind(this));
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.clearFilters();

    if (changes['searchText']) {
      const searchText = changes['searchText'].currentValue || '';

      this.inProgress = true;
      combineLatest([
        this.searchCollectionsService.getAllCollectionUsers(searchText).pipe(startWith(undefined)),
        this.searchCollectionsService.getAllCollectionOwners(searchText).pipe(startWith(undefined)),
        this.searchCollectionsService.getAllCollectionViewers(searchText).pipe(startWith(undefined)),
        this.searchCollectionsService.getAllModelUsers(searchText).pipe(startWith(undefined)),
        this.searchCollectionsService.getAllCompanionDataOwners(searchText).pipe(startWith(undefined)),
        this.searchCollectionsService.getAllCompanionDataViewers(searchText).pipe(startWith(undefined)),
        this.searchCollectionsService.getAllCompanionDataLegalTags(searchText).pipe(startWith(undefined)),
        this.searchCollectionsService.getAllCompanionDataCountries(searchText).pipe(startWith(undefined)),
        this.searchCollectionsService.getAllCompanionDataModelVersions(searchText).pipe(startWith(undefined)),
        this.searchCollectionsService.getAllCompanionDataBusinessPlanCases(searchText).pipe(startWith(undefined)),
        this.searchCollectionsService.getAllCompanionDataReservesCases(searchText).pipe(startWith(undefined)),
        this.searchCollectionsService.getAllCompanionDataReservoirs(searchText).pipe(startWith(undefined)),
        this.searchCollectionsService.getAllCompanionDataDecisionFrames(searchText).pipe(startWith(undefined)),
        this.searchCollectionsService.getAllCompanionDataModelObjectives(searchText).pipe(startWith(undefined)),
        this.searchCollectionsService.getAllCompanionDataProjectPhases(searchText).pipe(startWith(undefined)),
        this.searchCollectionsService.getAllCompanionDataFields(searchText).pipe(startWith(undefined)),
        this.searchCollectionsService.getAllCompanionDataForecastYears(searchText).pipe(startWith(undefined)),
        this.searchCollectionsService.getAllCompanionDataSimulationApplications(searchText).pipe(startWith(undefined)),
        this.searchCollectionsService.getAllCompanionDataSimulationApplicationVersions(searchText).pipe(startWith(undefined))
      ]
      ).pipe(
        tap(([
          collectionUsers, collectionOwners, collectionViewers, modelUsers, companionDataOwners, companionDataViewers,
          companionDataLegalTags, companionDataCountries, companionDataModelVersions, companionDataBusinessPlanCases,
          companionDataReservesCases, companionDataReservoirs, companionDataDecisionFrames, companionDataModelObjectives,
          companionDataProjectPhases, companionDataFields, companionDataForecastYears, companionDataSimulationApplications,
          companionDataSimulationApplicationVersions
        ]) => {
          this.collectionUsers = collectionUsers || {values: []};
          this.collectionOwners = collectionOwners || {values: []};
          this.collectionViewers = collectionViewers || {values: []};
          this.modelUsers = modelUsers || {values: []};
          this.companionDataOwners = companionDataOwners || {values: []};
          this.companionDataViewers = companionDataViewers || {values: []};
          this.companionDataLegalTags = companionDataLegalTags || {values: []};
          this.companionDataCountries = companionDataCountries || {values: []};
          this.companionDataModelVersions = companionDataModelVersions || {values: []};
          this.companionDataBusinessPlanCases = companionDataBusinessPlanCases || {values: []};
          this.companionDataReservesCases = companionDataReservesCases || {values: []};
          this.companionDataReservoirs = companionDataReservoirs || {values: []};
          this.companionDataDecisionFrames = companionDataDecisionFrames || {values: []};
          this.companionDataModelObjectives = companionDataModelObjectives || {values: []};
          this.companionDataProjectPhases = companionDataProjectPhases || {values: []};
          this.companionDataFields = companionDataFields || {values: []};
          this.companionDataForecastYears = companionDataForecastYears || {values: []};
          this.companionDataSimulationApplications = companionDataSimulationApplications || {values: []};
          this.companionDataSimulationApplicationVersions = companionDataSimulationApplicationVersions || {values: []};
          this.inProgress = !(collectionUsers && collectionOwners && collectionViewers && modelUsers && 
            companionDataOwners && companionDataViewers && companionDataLegalTags && companionDataCountries &&
            companionDataModelVersions && companionDataBusinessPlanCases && companionDataReservesCases && 
            companionDataReservoirs && companionDataDecisionFrames && companionDataModelObjectives &&
            companionDataProjectPhases && companionDataFields && companionDataForecastYears && 
            companionDataSimulationApplications && companionDataSimulationApplicationVersions);
        })
      ).subscribe(_ => {
      });
    }
  }


  ngOnDestroy(): void {
    console.log('on destroy being called for search collections filterscomponent');
  }

  onSelectionListChange(selList: MatSelectionListChange, fieldName: string) {
    const option = selList.options[0];
    if (option.selected)
      this[fieldName].push(option.value);
    else {
      this[fieldName].splice(this[fieldName].indexOf(option.value), 1);
    }
    this.emitFilterChange();
  }

  clearFilters() {
    this.collectionNameFilterControl.setValue('');
    this.collectionUsersFilterControl.setValue([]);
    this.collectionOwnersFilterControl.setValue([]);
    this.collectionViewersFilterControl.setValue([]);
    this.collectionStartDateRange.controls['start'].setValue(null);
    this.collectionStartDateRange.controls['end'].setValue(null);
    this.collectionUpdatedDateRange.controls['start'].setValue(null);
    this.collectionUpdatedDateRange.controls['end'].setValue(null);
    this.modelNameFilterControl.setValue('');
    this.modelUsersFilterControl.setValue([]);
    this.modelLastUpdatedDateRange.controls['start'].setValue(null);
    this.modelLastUpdatedDateRange.controls['end'].setValue(null);
    this.remarksFilterControl.setValue('');
    this.genOptManifestFileNameFilterControl.setValue('');
    this.companionDataOwnersFilterControl.setValue([]);
    this.companionDataOwnersFilterControl.setValue([]);
    this.companionDataViewersFilterControl.setValue([]);
    this.emitFilterChange();
  }

  emitFilterChange() {
    const filters = {
      collectionFilters: null,
      modelFilters: null,
      companionDataFilters: null
    } as {
      collectionFilters?: SearchForCollectionsFilters;
      modelFilters?: SearchForCollectionsModelFilters;
      companionDataFilters?: SearchForCollectionsCompanionDataFilters;
    };

    if (this.collectionNameFilterControl.value.trim().length > 0)
      filters.collectionFilters = Object.assign(filters.collectionFilters || {}, { collectionName: this.collectionNameFilterControl.value.trim() });

    if (this.collectionUsersFilterControl.value.length > 0)
      filters.collectionFilters = Object.assign(filters.collectionFilters || {}, { collectionUsers: this.collectionUsersFilterControl.value });

    if (this.collectionOwnersFilterControl.value.length > 0)
      filters.collectionFilters = Object.assign(filters.collectionFilters || {}, { owners: this.collectionOwnersFilterControl.value });

    if (this.collectionViewersFilterControl.value.length > 0)
      filters.collectionFilters = Object.assign(filters.collectionFilters || {}, { viewers: this.collectionViewersFilterControl.value });

    if (this.collectionStartDateRange.controls['start'].value)
      filters.collectionFilters = Object.assign(filters.collectionFilters || {}, { createDateRanges: [this.collectionStartDateRange.controls['start'].value, this.collectionStartDateRange.controls['end'].value || new Date()] });

    if (this.collectionUpdatedDateRange.controls['start'].value)
      filters.collectionFilters = Object.assign(filters.collectionFilters || {}, { createDateRanges: [this.collectionUpdatedDateRange.controls['start'].value, this.collectionUpdatedDateRange.controls['end'].value || new Date()] });

    if (this.modelNameFilterControl.value.trim().length > 0)
      filters.modelFilters = Object.assign(filters.modelFilters || {}, { modelName: this.modelNameFilterControl.value.trim() });

    if (this.modelUsersFilterControl.value.length > 0)
      filters.modelFilters = Object.assign(filters.modelFilters || {}, { modifiedUsers: this.modelUsersFilterControl.value });

    if (this.modelLastUpdatedDateRange.controls['start'].value)
      filters.modelFilters = Object.assign(filters.modelFilters || {}, { modifiedDateRanges: [this.modelLastUpdatedDateRange.controls['start'].value, this.modelLastUpdatedDateRange.controls['end'].value || new Date()] });

    if (this.remarksFilterControl.value.trim().length > 0)
      filters.companionDataFilters = Object.assign(filters.companionDataFilters || {}, { remarks: this.remarksFilterControl.value.trim() });

    if (this.genOptManifestFileNameFilterControl.value.trim().length > 0)
      filters.companionDataFilters = Object.assign(filters.companionDataFilters || {}, { genOptManifestFileName: this.genOptManifestFileNameFilterControl.value.trim() });

    if (this.companionDataOwnersFilterControl.value.length > 0)
      filters.companionDataFilters = Object.assign(filters.companionDataFilters || {}, { owners: this.companionDataOwnersFilterControl.value });

    if (this.companionDataOwnersFilterControl.value.length > 0)
      filters.companionDataFilters = Object.assign(filters.companionDataFilters || {}, { owners: this.companionDataOwnersFilterControl.value });

    if (this.companionDataViewersFilterControl.value.length > 0)
      filters.companionDataFilters = Object.assign(filters.companionDataFilters || {}, { viewers: this.companionDataViewersFilterControl.value });

    if (this.companionDataLegalTagsFilterControl.value.length > 0)
      filters.companionDataFilters = Object.assign(filters.companionDataFilters || {}, { legalTags: this.companionDataLegalTagsFilterControl.value });

    if (this.companionDataCountriesFilterControl.value.length > 0)
      filters.companionDataFilters = Object.assign(filters.companionDataFilters || {}, { countries: this.companionDataCountriesFilterControl.value });

    if (this.companionDataModelVersionsFilterControl.value.length > 0)
      filters.companionDataFilters = Object.assign(filters.companionDataFilters || {}, { modelVersionNumbers: this.companionDataModelVersionsFilterControl.value });

    if (this.companionDataBusinessPlanCasesFilterControl.value.length > 0)
      filters.companionDataFilters = Object.assign(filters.companionDataFilters || {}, { businessPlanCases: this.companionDataBusinessPlanCasesFilterControl.value });

    if (this.companionDataReservesCasesFilterControl.value.length > 0)
      filters.companionDataFilters = Object.assign(filters.companionDataFilters || {}, { reservesCases: this.companionDataReservesCasesFilterControl.value });

    if (this.companionDataReservoirsFilterControl.value.length > 0)
      filters.companionDataFilters = Object.assign(filters.companionDataFilters || {}, { reservoirs: this.companionDataReservoirsFilterControl.value });

    if (this.companionDataDecisionFramesFilterControl.value.length > 0)
      filters.companionDataFilters = Object.assign(filters.companionDataFilters || {}, { decisionFrames: this.companionDataDecisionFramesFilterControl.value });

    if (this.companionDataModelObjectivesFilterControl.value.length > 0)
      filters.companionDataFilters = Object.assign(filters.companionDataFilters || {}, { modelObjectives: this.companionDataModelObjectivesFilterControl.value });

    if (this.companionDataProjectPhasesFilterControl.value.length > 0)
      filters.companionDataFilters = Object.assign(filters.companionDataFilters || {}, { modelObjectives: this.companionDataProjectPhasesFilterControl.value });

    if (this.companionDataFieldsFilterControl.value.length > 0)
      filters.companionDataFilters = Object.assign(filters.companionDataFilters || {}, { fields: this.companionDataFieldsFilterControl.value });

    if (this.companionDataForecastYearsFilterControl.value.length > 0)
      filters.companionDataFilters = Object.assign(filters.companionDataFilters || {}, { forecastYears: this.companionDataForecastYearsFilterControl.value });

    if (this.companionDataSimulationApplicationsFilterControl.value.length > 0)
      filters.companionDataFilters = Object.assign(filters.companionDataFilters || {}, { simulationApplications: this.companionDataSimulationApplicationsFilterControl.value });

    if (this.companionDataSimulationApplicationVersionsFilterControl.value.length > 0)
      filters.companionDataFilters = Object.assign(filters.companionDataFilters || {}, { simulationApplicationVersions: this.companionDataSimulationApplicationVersionsFilterControl.value });

    this.filtersChange.emit(filters);
  }


}