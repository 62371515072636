import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges
} from "@angular/core";
import {ModelFile} from "../../../core/models/model-file";
import {CollectionFilesService} from "../../../core/api/collection-files.service";
import {BehaviorSubject, first, Observable, of, Subscription, switchMap, tap} from "rxjs";
import {debounceTime, map} from "rxjs/operators";
import {autoUnsubscribe} from "../../../core/decorators/auto-unsubscribe.decorator";
import {FileVersion} from "../../../core/models/file-version";
import {SlbLoadingService} from "@slb-dls/angular-material/loading";
import {ModelFolderFile} from "../../pages/collections/view-collection/model-folders.component";
import {OpenFileDialog, OpenFileDialogData} from "../dialogs/open-file.dialog";
import {MatDialog} from "@angular/material/dialog";
import {FileUtilsService} from "../../../core/services/file-utils.service";
import {CompareFilesDialog, CompareFilesDialogData} from "../dialogs/compare-files.dialog";
import {Router} from "@angular/router";
import {OpenRevisionChangesData, OpenRevisionChangesDialog} from "../dialogs/open-revision-changes.dialog";

@autoUnsubscribe({autoInclude: true})
@Component({
  selector: 'app-file-timeline',
  templateUrl: './file-timeline.component.html',
  styleUrls: ['./file-timeline.component.scss']
})
export class FileTimelineComponent implements OnChanges, OnDestroy{
  @Input() filePath: string;
  @Input() collectionId: string;
  @Input() allowView: boolean;

  private inputChange = new BehaviorSubject(false);
  private inputChange$: Subscription;

  inProgress: boolean;
  fileVersions: FileVersion[];

  constructor(private collectionFilesService: CollectionFilesService,
              private cdr: ChangeDetectorRef,
              private loadingService: SlbLoadingService,
              private dialog: MatDialog,
              private fileUtilsService: FileUtilsService,
              private router: Router) {

    this.inputChange$ = this.inputChange.pipe(tap(() => {
      this.inProgress = true;
    }), debounceTime(100), switchMap(hasValue => {
      if(!hasValue) return of(false);
      return this.getVersionsForModelFile().pipe(map(p => {
        this.fileVersions = [...p].sort((a, b) => a.versionNumber - b.versionNumber).map((value, idx) => {
          return Object.assign({idx: idx + 1}, value);
        });
        this.inProgress = false;
        this.cdr.detectChanges();
        return true;
      }));
    })).subscribe();
  }

  getVersionsForModelFile(): Observable<FileVersion[]> {
    return this.collectionFilesService.getFileVersions(this.collectionId, this.filePath);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.inputChange.next(!!this.filePath && !!this.collectionId && this.allowView !== undefined);
  }

  ngOnDestroy(): void {
    console.log('FileTimelineComponent OnDestroy');
  }

  getFileVersionThatCanBeCompared(fileVersion: FileVersion): FileVersion[] {
    return this.fileVersions.filter(p => p.id !== fileVersion.id);
  }

  onDownload(fileVersion: FileVersion): void {
    this.loadingService.showSpinner({text: 'downloading file'});
    this.collectionFilesService.getFileUrlByFileId(fileVersion.id).pipe(tap(response => {
      window.open(response.url);
      this.loadingService.closeSpinner(true);
      this.cdr.detectChanges();
      })).subscribe();
  }

  onView(fileVersion: FileVersion): void {
    this.dialog.open(OpenFileDialog, {
      data: {
        fileId: fileVersion.id,
        fileName: this.fileUtilsService.getFileName(fileVersion.path),
        allowEdit: false
      } as OpenFileDialogData,
    });
  }

  onCompare(currentFileVersion: FileVersion, toBeComparedFileVersion: FileVersion): void {
    const fileName = this.fileUtilsService.getFileName(currentFileVersion.path);
    if(currentFileVersion.createdDateTime < toBeComparedFileVersion.createdDateTime) {
      this.dialog.open(CompareFilesDialog, {
        data: {
          title: `${fileName} ${currentFileVersion['idx']} <==> ${toBeComparedFileVersion['idx']}`,
          leftFileId: currentFileVersion.id,
          rightFileId: toBeComparedFileVersion.id
        } as CompareFilesDialogData,
      });
    } else {
      this.dialog.open(CompareFilesDialog, {
        data: {
          title: `${fileName}  ${toBeComparedFileVersion['idx']} <==> ${currentFileVersion['idx']}`,
          leftFileId: toBeComparedFileVersion.id,
          rightFileId: currentFileVersion.id
        } as CompareFilesDialogData,
      });
    }

  }

  onViewRevision(fileVersion: FileVersion) {
    this.router.navigateByUrl(`/collection/${this.collectionId}?r=${fileVersion.revisionId}`);
  }

  onViewRevisionChanges(fileVersion: FileVersion) {
    this.dialog.open(OpenRevisionChangesDialog, {
      data: {
        revisionId: fileVersion.revisionId
      } as OpenRevisionChangesData,
    });
  }
}
