import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { interval } from "rxjs";
import { AuthService } from "../auth/auth.service";

@Injectable({
    providedIn: 'root'
})
export class MonitoringService {
    appInsights: ApplicationInsights;
    constructor(authService: AuthService) {
        this.appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: environment.appInsights.instrumentationKey,
                enableAutoRouteTracking: true // option to log all route changes
            }
        });
        this.appInsights.loadAppInsights();

        authService.currentAuthStatus.subscribe(p => {
            if (p.userLoggedIn) {
                this.appInsights.setAuthenticatedUserContext(authService.currentUser.username, authService.currentUser.name, true);
                const telemetryInitializer = (envelope) => {
                    envelope.data.currentUserName = authService.currentUser.username;
                    envelope.data.currentUserFullName = authService.currentUser.name;
                };
                this.appInsights.addTelemetryInitializer(telemetryInitializer);
            }
        }); 
    }

}
