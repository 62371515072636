import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";

export interface ModelInCompanionData { modelId: string, collectionId: string, modelName: string, collectionName: string }
@Injectable({
  providedIn: 'root'
})
export class CompanionDataService {

  constructor(protected _http: HttpClient) {
  }

  upsetCompanionData(requestBody: { id: string, fileName: string, modelId: string, branchSimulationId: string, companionMetaData: object }): Observable<any> {
    const url = `${environment.apiEndpoint}/CompanionData/UpsetCompanionData`;
    return this._http.post(url, requestBody);
  }

  //updateCompanionData(requestBody: { id: string, fileName: string, modelId: string, branchSimulationId: string, companionMetaData: object }): Observable<any> {
  //  const url = `${environment.apiEndpoint}/CompanionData/UpdateCompanionData`;
  //  return this._http.post(url, requestBody);
  //}

  // query companinion data broadly
  queryCompanionData(queryRequest: { kind: string, query: string, limit: number }) {
    const url = `${environment.apiEndpoint}/CompanionData/QueryCompanionData`;
    return this._http.post(url, queryRequest);
  }

  queryCompanionDataBySimulationId(companionDataQueryRequest: { modelId: string, branchSimulationId: string }) {
    const url = `${environment.apiEndpoint}/CompanionData/QueryCompanionDataById`;
    return this._http.post(url, companionDataQueryRequest);
  }

  getLegalTags(): Observable<any> {
    const url = `${environment.apiEndpoint}/CompanionData/GetLegalTags`;
    return this._http.get(url);
  }

  getCountries(): Observable<any> {
    const url = `${environment.apiEndpoint}/CompanionData/GetLegalTagsProperties`;
    return this._http.get(url);
  }

  getEntitlementGroups(): Observable<any> {
    const url = `${environment.apiEndpoint}/CompanionData/GetEntitlementGroups`;
    return this._http.get(url);
  }

  getCompanionDataSettings(): Observable<any> {
    const url = `${environment.apiEndpoint}/CompanionData/GetCompanionDataSettings`;
    return this._http.get(url);
  }

  getCompanionDataValuesFor(category: string): Observable<string[]> {
    const url = `${environment.apiEndpoint}/CompanionData/AllPossibleValues?category=${category}`;
    return this._http.get<string[]>(url);
  }

  getModelsFor(category: string, value: string): Observable<ModelInCompanionData[]> {
    const url = `${environment.apiEndpoint}/CompanionData/Models?category=${category}&value=${value}`;
    return this._http.get<ModelInCompanionData[]>(url);
  }

}
