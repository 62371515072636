import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { environment } from "src/environments/environment";
import { ModelUploadTaskInProgress } from "../models/model-upload-task-in-progress";
import { TaskInProgress } from "../models/task-in-progress";

@Injectable({
    providedIn: 'root'
})
export class SimulationService {

    constructor(protected _http: HttpClient) {
    }

    stageSimulation(request: StageSimulationRequest): Observable<StageSimulationResponse> {
        const url = `${environment.simulationApiEndpoint}/simulation`;
        return this._http.post<StageSimulationResponse>(url, request);
    }

    getSimulationProgress(rootId: string): Observable<boolean> {
        const url = `${environment.simulationApiEndpoint}/simulation/${rootId}/progress`;
        return this._http.put<boolean>(url, {});
    }

    updateUploadFailures(request: SimulationFileUploadFailuresRequest): Observable<boolean> {
        const url = `${environment.simulationApiEndpoint}/simulation/staged/${request.stagingId}/fileUploadFailures`;
        return this._http.put<boolean>(url, request);
    }

    updateFileMd5s(request: SimulationFileUploadMd5sRequest): Observable<boolean> {
        const url = `${environment.simulationApiEndpoint}/simulation/staged/${request.stagingId}/fileUploadMd5s`;
        return this._http.put<boolean>(url, request);
    }

    trySubmitSimulation(stagingId: string, containerName: string): Observable<StageSimulationResponse> {
        const url = `${environment.simulationApiEndpoint}/simulation/TrySubmit`;
        return this._http.post<StageSimulationResponse>(url, {stagingId, containerName});
    }

}


export interface StageSimulationRequest {
    collectionId: string;
    files: StageSimulationRequestFile[];
}


export interface StageSimulationRequestFile {
    path: string;
    mode: string;
    size?: number;
}

export interface StageSimulationResponse {
    stagingId: string;
    containerName:  string;
    sasUrl: string;
}

export interface SimulationFileUploadFailuresRequest {
    collectionId: string;
    stagingId: string;
    containerName:  string;
    filePaths: string[]
}

export interface SimulationFileUploadMd5sRequest {
    collectionId: string;
    stagingId: string;
    containerName:  string;
    filesMd5s: FileUploadMd5[]
}

export interface FileUploadMd5 {
    path: string,
    md5: string
}