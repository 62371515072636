<div *ngIf="!inProgress && modelFoldersToBeViewed && modelToFiles" class="root-container">
  <mat-tab-group [selectedIndex]="tabSelectedIndex" (selectedIndexChange)="tabSelectedIndex = $event"
    animationDuration="0ms">
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="tab-icon">list_alt</mat-icon>
        {{modelType | uppercase}} Model Files
      </ng-template>
      <div class="actions-container">
        <div *ngIf="isOpenEnabled">
          <button mat-mini-fab color="primary" (click)="onExpandTree()"><mat-icon>arrow_forward</mat-icon></button>
        </div>
        <div *ngIf="isEditCompanionDataAvailable()">
          <button mat-raised-button color="primary" (click)="editCompanionData()"
            [disabled]="isReadOnlyValue"><mat-icon>edit</mat-icon><span>Edit Companion Data</span></button>
        </div>
        <div *ngIf="isNotAddMode()">
          <button mat-raised-button color="primary" [disabled]="downloadInProgress"
            (click)="onDownloadModel()"><mat-icon>download</mat-icon><span>Download Model</span>
          </button>
          <mat-progress-spinner mode="indeterminate" *ngIf="downloadInProgress" diameter="20">
          </mat-progress-spinner>
        </div>
        <div *ngIf="isSaveable()">
          <button mat-raised-button color="primary" [disabled]="!isSaveEnabled()"
            (click)="onUploadFiles()"><mat-icon>publish</mat-icon><span>Upload</span></button>
          <mat-progress-spinner mode="indeterminate" *ngIf="uploadInProgress" diameter="20">
          </mat-progress-spinner>
        </div>
        <div *ngIf="hasConflicts()">
          <mat-slide-toggle (change)="onUploadConflictsChanged($event.checked)">
            <button mat-icon-button aria-label="conflict" color="warn">
              <mat-icon>new_releases</mat-icon>
            </button>
            select changed conflicts
          </mat-slide-toggle>
        </div>
        <div *ngIf="isNotAddMode() && (modelToFiles.model?.branchSimulationId === modelToFiles.model?.id)">
          <button mat-raised-button color="primary"
            (click)="copySimulationLink(modelToFiles.model?.branchSimulationId)"><mat-icon>link</mat-icon><span>Copy
              Base Model Link</span></button>
        </div>

        <div *ngIf="isNotAddMode() && (modelToFiles.model?.branchSimulationId !== modelToFiles.model?.id)">
          <button mat-raised-button color="primary" [matMenuTriggerFor]="menu">
            <mat-icon>link</mat-icon>
            <span>Copy Model Link</span></button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item
              (click)="copySimulationLink(modelToFiles.model?.branchSimulationId)"><mat-icon>link</mat-icon><span>Base
                Model</span></button>
            <button mat-menu-item
              (click)="copySimulationLink(modelToFiles.model?.id)"><mat-icon>link</mat-icon><span>Current
                Model</span></button>
          </mat-menu>
        </div>

        <div *ngIf="isTimelineAvailable()">
          <button mat-raised-button color="primary" (click)="onModelTimeline()"
            [disabled]="isReadOnlyValue"><mat-icon>history</mat-icon><span>Timeline</span></button>
        </div>
        <div *ngIf="isNotAddMode()">
          <app-model-comparison-button [currentModelToCompare]="modelToCompare()"></app-model-comparison-button>
        </div>
        <!-- for cyptospace tab -->
        <!--
            <div class="folder-view-button-container" >
              <button mat-raised-button color="primary" (click)="onSimulationLinkage()" [disabled]="isReadOnlyValue"><mat-icon>link</mat-icon><span>Simulation Linkage</span></button>
            </div>
            -->
      </div>
      <div fxLayout="column" fxLayoutAlign=" stretch" class="folder-content">
        <div fxLayout="row wrap">
          <div fxFlex="nogrow">
            <button (click)="getFileTypes()" mat-raised-button color="primary" [matMenuTriggerFor]="menu">All
              Files</button>
            <mat-menu #menu="matMenu">
              <ng-container *ngFor="let fileType of getFileTypes()">
                <button *ngIf="fileType === searchText" color="primary" (click)="searchText = fileType">{{fileType}}</button>
                <button *ngIf="fileType !== searchText" (click)="searchText = fileType">{{fileType}}</button>
              </ng-container>
            </mat-menu>
          </div>
          <div fxFlex>
            <mat-form-field appearance="fill" style="width:100%">
              <mat-label>Search File</mat-label>
              <input matInput placeholder="enter search text" [(ngModel)]="searchText">
              <button matSuffix mat-button *ngIf="searchText" (click)="onClearSearch()">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </div>
        <div>

          <slb-messages [enableService]="true"></slb-messages>
        </div>
        <div>

          <app-view-model-folders [searchText]="searchText"
            [isReadOnly]="isReadOnlyValue || !modelToFiles?.model?.hasManifest" [modelFolders]="modelFoldersToBeViewed"
            [checkConflictsChanged]="uploadConflictsChanged" (addFile)="onAddFileToFolder($event)"
            (selectionChange)="onSelectionChange($event)"
            (showFileTimeline)="onShowFileTimeline($event)"></app-view-model-folders>
        </div>

      </div>
    </mat-tab>
    <mat-tab *ngFor="let additionalTab of filterTabOfTypeNot('simulation-linkage'); let index = index">
      <ng-template mat-tab-label>
        <mat-icon class="tab-icon">history</mat-icon>
        {{additionalTab.title}}
        <mat-icon class="tab-icon" color="warn" (click)="onCloseTab(index)">close</mat-icon>
      </ng-template>
      <app-file-timeline *ngIf="additionalTab.type === 'file-timeline'"
        [filePath]="additionalTab.modelFolderFile?.fileInfo?.path"
        [allowView]="additionalTab.modelFolderFile?.options?.view"
        [collectionId]="modelToFiles.model.collectionId"></app-file-timeline>
      <app-model-timeline *ngIf="additionalTab.type === 'model-timeline'"
        [collectionId]="modelToFiles.model.collectionId" [modelId]="modelToFiles.model.id"></app-model-timeline>
      <app-collection-timeline *ngIf="additionalTab.type === 'collection-timeline'"
        [collectionId]="modelToFiles.model.collectionId"></app-collection-timeline>
    </mat-tab>
    <mat-tab *ngFor="let additionalTab of filterTabOfType('simulation-linkage'); let index = index">
      <ng-template mat-tab-label>
        <mat-icon class="tab-icon">{{ additionalTab.icon }}</mat-icon>
        {{additionalTab.title}}
        <mat-icon class="tab-icon" color="warn" (click)="onCloseTab(index)">close</mat-icon>
      </ng-template>
      <ng-template matTabContent>
        <app-model-linkage [collectionId]="modelToFiles.model.collectionId"
          [collectionName]="modelToFiles.model.collectionName" [modelId]="modelToFiles.model.id"
          [modelName]="modelToFiles.model.name"
          [branchSimulationId]="modelToFiles.model.branchSimulationId"></app-model-linkage>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
<mat-spinner mode="indeterminate" *ngIf="inProgress" diameter="30"></mat-spinner>