<div fxFill fxLayout="column">
  <mat-spinner diameter="30" *ngIf="inProgress"></mat-spinner>
  <mat-vertical-stepper *ngIf="!inProgress" [linear]="false">
    <mat-step [completed]="false" *ngFor="let modelVersion of modelVersions">
      <ng-template matStepLabel>{{ modelVersion.createdDateTime |  date:'medium'}}</ng-template>
      <div fxflexfill fxLayout="row wrap">
        <div class="element-detail-item">
          <div class="element-detail-item-header" mat-line>Simulation Id</div>
          <div mat-line> {{modelVersion.id}}</div>
        </div>
        <div class="element-detail-item">
          <div class="element-detail-item-header" mat-line>Version</div>
          <div mat-line> {{modelVersion.versionNumber}}</div>
        </div>
        <div class="element-detail-item">
          <div class="element-detail-item-header" mat-line>Path</div>
          <div mat-line>{{modelVersion.path}}</div>
        </div>

        <div class="element-detail-item">
          <div class="element-detail-item-header" mat-line>User</div>
          <div mat-line>{{modelVersion.createdUser}}</div>
        </div>

        <div class="element-detail-item">
          <div class="element-detail-item-header" mat-line>Latest Input File Modified Date</div>
          <div mat-line> {{ modelVersion.latestInputFileModifiedDateTime |  date:'medium'}} </div>
        </div>

        <div class="element-detail-item">
          <button mat-raised-button color="primary" (click)="onView(modelVersion)">
            <mat-icon>remove_red_eye</mat-icon>
            <span>View Model</span>
          </button>
        </div>

        <div class="element-detail-item">
          <button mat-raised-button color="primary" [disabled]="(modelVersions?.length ?? 0) <= 1" [matMenuTriggerFor]="menu">
            <mat-icon>compare</mat-icon>
            <span>Compare With</span></button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item *ngFor="let compareVersion of getVersionsThatCanBeCompared(modelVersion)"
                    (click)="onCompare(modelVersion, compareVersion)">
              {{compareVersion['idx']}} {{ compareVersion.createdDateTime |  date:'medium'}}
            </button>
          </mat-menu>
        </div>

        <div class="element-detail-item">
          <button mat-raised-button color="primary" (click)="onViewRevisionChanges(modelVersion)">
            <mat-icon>remove_red_eye</mat-icon>
            <span>View Changes in this revision</span>
          </button>
        </div>

        <div class="element-detail-item">
          <button mat-raised-button color="primary" (click)="copySimulationLink(modelVersion.id)">
            <mat-icon>link</mat-icon>
            <span>Copy Model Link</span>
          </button>
        </div>

      </div>
    </mat-step>
  </mat-vertical-stepper>
</div>
