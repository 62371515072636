<ng-container>
  <mat-spinner diameter="20" *ngIf="inProgress"></mat-spinner>
  <mat-dialog-content class="mat-typography">
    <mat-list role="list">
      <mat-list-item role="listitem" *ngFor="let revisionChange of revisionChanges">
        <mat-icon aria-hidden="false" aria-label="file added" *ngIf="revisionChange.changeType==='created'" class="add-icon">add</mat-icon>
        <mat-icon aria-hidden="false" aria-label="file added" *ngIf="revisionChange.changeType==='changed'" color="primary">edit</mat-icon>
        <mat-icon aria-hidden="false" aria-label="file added" *ngIf="revisionChange.changeType==='deleted'" color="warn">remove</mat-icon>
        <span class="left-padding">   {{revisionChange.changeType}} - {{revisionChange.fileInfo.path}} at {{revisionChange.createdDateTime | date:'medium'}}</span>
      </mat-list-item>
    </mat-list>
  </mat-dialog-content>
  <mat-dialog-actions align="end" *ngIf="!inProgress">
    <button mat-raised-button mat-dialog-close  color="warn">Close</button>
  </mat-dialog-actions>
</ng-container>
