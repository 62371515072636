export const environment = {
  production: true,
  apiEndpoint: 'https://fdplan-redms-api.azure.chevron.com/api',
  simulationApiEndpoint: 'https://fdplan-redms-sim-prod-cvx.azurewebsites.net/api',
  signalREndpoint: 'https://fdplan-redms-sign-prod-cvx.azurewebsites.net/api',
  apiFailureRetryCount: 3,
  apiFailureRetryDelayInSeconds: 2,
  refreshTokenFailureRetryDelayInSeconds: 10,
  refreshTokenIntervalInMillis: 5 * 60 * 1000,
  maxFileUploadChunkSize: 4 * 1024 * 1024,
  maxFileUploadsConcurrently: 3,
  maxFileChunkUploadsConcurrently: 3,
  calTenantId: 'fd799da1-bfc1-4234-a91c-72b3a1cb9e26',
  calClientId: '87ab9fe9-1751-4896-9a1d-988c19fd581c',
  simulationLink: 'https://reservoir-engineering.cvx.osdu.slb.com/api/simstore/v1/simulations/',
  webEditorUrl: 'https://fdplan-redms-webixc-dev.azure.chevron.com/vs/basic-editor.html',
  webDiffEditorUrl: 'https://fdplan-redms-webixc-dev.azure.chevron.com/vs/diff.html',
  pageSizeOptions: [10,25,50,100],
  redmsDateTimeFormat: "MM/DD/YYYY HH:mm:ss",
  viewableExtensions: ['manifest', 'afi', 'csv', 'ixf', 'py', 'txt', 'json'],
  editableExtensions: ['afi', 'csv', 'ixf', 'py', 'txt', 'json'],
  appInsights: {
    instrumentationKey: 'ae25f9b1-f0b9-4b56-8fdc-c91954371cab'
  },
  minSimUploadProgress: 5,
  maxSimUploadProgress: 95,
  fileUploadProgressComplete: 70.0
};
