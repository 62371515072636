import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {ViewCollectionComponent} from "./view-collection/view-collection.component";
import { SearchCollectionsComponent } from './collections/search-collections.component';

const routes: Routes = [
  {
    path: '', component: SearchCollectionsComponent,
    data: {
      title: 'Collections',
    }
  },
  {
    path: 'collection/:id',
    component: ViewCollectionComponent,
    data: {
      title: 'Collection',
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CollectionsRoutingModule { }
