import { AutoUnsubscribeData } from "../models/auto-unsubscribe-data";

export function autoUnsubscribe(args: AutoUnsubscribeData) {
  return function(target: any) {
    const origOnDestroy = target.prototype.ngOnDestroy;

    if (origOnDestroy) {
      args.autoInclude = !!args.autoInclude;
      args.include = args.include || [];
      args.exclude = args.exclude || [];

      //the below should not be an arrow function else this will be undefined
      target.prototype.ngOnDestroy = function() {
        try{
          if(args.autoInclude) {
            for (const prop in this) {
              const property = this[prop];
              if (property && typeof property.unsubscribe === "function")
                args.include.push(prop);
            }
          }

          for (const prop in args.include) {
            const property = this[prop];
            if (property && typeof property.unsubscribe === "function" && !args.exclude.includes(prop)) {
              console.log('unsubscribing ', {
                member: prop,
                component: target.name
              });
              property.unsubscribe();
            }
          }

        }
        catch (err) {
          console.error('error while unsubscribing in autoUnsubscribe', {err})
        }
        origOnDestroy.apply(this);
      };
    } else {
      throw new Error(`ngOnDestroy not defined in ${target.name}. autoUnsubscribe decorator requires this to be defined`);
    }
  };
}
