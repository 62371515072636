import { Injectable } from "@angular/core";
import * as signalR from "@microsoft/signalr";
import { HubConnection } from "@microsoft/signalr";
import { BehaviorSubject, Observable, of, Subject, Subscriber, Subscription, switchMap, tap } from "rxjs";
import { environment } from "src/environments/environment";
import { AuthService } from "../auth/auth.service";
import { CalAngularService } from '@cvx/cal-angular';

@Injectable({
    providedIn: 'root'
})
export class SignalRService {

    private _hubConnection: HubConnection;
    private _hubSubscriptions: Map<string, Subscription> = new Map();
    private _hubStart = new BehaviorSubject(false);
    constructor(authService: AuthService, private srvAuth: AuthService, private srvCal: CalAngularService) {
        authService.currentAuthStatus.pipe(
            switchMap(p => {
                if (p.userLoggedIn) return this.srvCal.getAADToken(this.srvAuth.getScopes());
                else return of('');
            }),
            tap(token => {
                if (token) {
                    this._hubConnection = new signalR.HubConnectionBuilder()
                        .withUrl(environment.signalREndpoint, { headers: { 'x-ms-client-principal-name': authService.currentUser.username, 'Authorization': `Bearer ${token}` } })
                        .configureLogging(signalR.LogLevel.Information)
                        .build();

                    this._hubConnection.start().then(_ => {
                        this._hubStart.next(true);
                    })
                        .catch(console.error);

                    this._hubConnection.onclose(() => {
                        (async() => {
                            console.log('connection closed');
                            this._hubStart.next(false);
                            this._hubConnection.start().then(_ => {
                                this._hubStart.next(true);
                            }).catch(console.error);
                        })()
                    });

                }
            })).subscribe();
    }

    startListening<T>(target: string): Observable<T> {
        if (!this._hubSubscriptions.has(target)) {
            this._hubSubscriptions[target] = new Subject();
            this._hubStart.subscribe(p => {
                if (p && this._hubConnection) {
                    this._hubConnection.on(target, (message) => {
                        this._hubSubscriptions[target].next(message);
                    });
                }
            });
        }
        return this._hubSubscriptions[target].asObservable();
    }
}
