<div *ngIf="companionDataForm">
  <mat-dialog-content >
    <h1 mat-dialog-title>Companion Data</h1>
    <form [formGroup]="companionDataForm">
      <div fxLayout="row wrap" fxLayoutAlign="space-evenly center" >
        <div  *ngFor="let field of companionFields; let fieldIndex = index ">
          <div fxFlex = "0 1 50%" *ngIf="field.type === 'text'" >
            <mat-form-field appearance="fill">
              <mat-label title="{{ field.description }}">{{ field.name }}</mat-label>
              <input
                matInput
                placeholder="{{ field.placeholder }}"
                [formControlName]="field.key"
                name={{field.key}}
                [id]="field.key"
                [required]="field.required"
                ngDefaultControl
              />
            </mat-form-field>
          </div>
          <div fxFlex = "0 1 50%" *ngIf="field.type === 'number'" >
            <mat-form-field appearance="fill">
              <mat-label title="{{ field.description }}">{{ field.name }}</mat-label>
              <input
                type="number"
                matInput
                placeholder="{{ field.placeholder }}"
                [formControlName]="field.key"
                name={{field.key}}
                [id]="field.key"
                [required]="field.required"
                ngDefaultControl
              />
            </mat-form-field>
          </div>
          <div fxflex = "0 1 50%" *ngIf="field.type === 'select'" >
            <mat-form-field appearance="fill">
            <mat-label>{{ field.name }}</mat-label>
            <mat-select [id]="field.key" [formControlName]="field.key" [multiple]="field?.multiple" ngDefaultControl>
              <mat-option *ngFor="let option of options[field.key]" [value]="option.name">
                {{ option.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          </div>
          <div fxflex = "0 1 50%" *ngIf="field.type === 'selectAndTypein'" >
            <mat-form-field appearance="fill">
              <mat-label>{{ field.name }}</mat-label>
              <input type="text"
                placeholder="field.key"
                matInput
                [id]="[field.key + 'input']"
                [formControlName]="[field.key + 'input']"
                [matAutocomplete]="auto"
                (change)="selectAndTypeinDataChanged($event)"
                (keypress)="selectAndTypeinKeyPress($event, field.key)"
                ngDefaultControl>
              <mat-autocomplete [id]="field.key" [formControlName]="field.key" autoActiveFirstOption #auto="matAutocomplete" 
                (optionSelected)='selectAndTypeinOptionSelected($event, field.key)' ngDefaultControl>
                <mat-option *ngFor="let option of options[field.key]" [value]="option.name">
                  {{ option.name }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div fxflex = "0 1 50%" *ngIf="field.type === 'date'" >
            <mat-form-field appearance="fill" matDateFormat>
              <mat-label>{{ field.name }}</mat-label>
              <input
                [id]="field.key"
                [formControlName]="field.key"
                matInput
                [matDatepicker]="picker"
                placeholder="MM/DD/YYYY"
                ngDefaultControl
              />

              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>
          <div  *ngIf="field.type === 'radio'" >
            <div fxflex = "0 1 50%" >
              <mat-label>{{ field.name }}</mat-label>
              <mat-radio-group aria-labelledby="group-radio" [id]="field.key" [formControlName]="field.key" ngDefaultControl>
                <mat-radio-button
                  *ngFor="let option of options[field.key]"
                  [value]="option.name"
                  color="primary"
                  style="margin-bottom:5vh; margin-right:3vh; margin-left:3vh"
                >
                  {{ option.name }}
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </div>

        </div>

        <div *ngIf="hasErrors()" class="ui error message">
          <b style="color:red;">Input has error</b>
        </div> 
      </div>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions>
  <button mat-raised-button color="warn" mat-dialog-close type="button">
    Cancel
  </button>
  <button  mat-raised-button color="primary" class="mr-5" [disabled]="hasErrors()"  (click) = "onSubmit()" >
    Save
  </button>
  </mat-dialog-actions>
</div>


