import {autoUnsubscribe} from "../../../core/decorators/auto-unsubscribe.decorator";
import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Inject, OnDestroy, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {ModelFolderFile} from "../../pages/collections/view-collection/model-folders.component";
import {FileService} from "../../../core/api/file.service";
import {catchError, EMPTY, expand, first, of, Subject, Subscription, tap, timeout} from "rxjs";
import {CollectionsService} from "../../../core/api/collections.service";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {environment} from "../../../../environments/environment";
import {DOCUMENT} from "@angular/common";
import {CollectionFilesService} from "../../../core/api/collection-files.service";
import {map, switchMap} from "rxjs/operators";
import {RevisionsService} from "../../../core/api/revisions.service";
import {RevisionChange} from "../../../core/models/revision-change";
import { autoMonitorPageView } from "src/app/core/decorators/auto-monitor-page-view.decorator";


export interface OpenRevisionChangesData {
  revisionId: string,
}
@autoMonitorPageView({ name: 'Revision Changes Dialog', trackOnInitToAfterInit: false })
@Component({
  selector: 'app-open-revision-changes-dialog',
  templateUrl: './open-revision-changes.dialog.html',
  styleUrls: ['./open-revision-changes.dialog.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OpenRevisionChangesDialog implements OnInit, AfterViewInit, OnDestroy {

  inProgress: boolean = true;
  revisionChanges: RevisionChange[];

  constructor(@Inject(MAT_DIALOG_DATA) public data: OpenRevisionChangesData,
              private cdr: ChangeDetectorRef,
              private revisionsService: RevisionsService
  ) {
    this.bindChanges();
  }
  ngAfterViewInit(): void {
    // do nothing
  }
  ngOnInit(): void {
    // do nothing
  }
  private bindChanges(): void {
    this.inProgress = true;
    this.revisionsService.getRevisionChanges(this.data.revisionId).pipe(tap(changes => {
      this.revisionChanges = changes;
      this.inProgress = false;
      this.cdr.detectChanges();
    })).subscribe();
  }

  ngOnDestroy(): void {
        console.log('ondestroy OpenRevisionChangesDialog');
    }

  private getDomain(): string {
    const domain = new URL(environment.webEditorUrl);
    return `${domain.protocol}//${domain.hostname}`;
  }

}
