import * as _ from 'lodash';
import {QueryModelsResponse, CompanionData, ModelWithCompanionData, ModelWithCompanionDataType2} from "../core/models/query-models-response";

export function mergeModelCompanionData(response: QueryModelsResponse): ModelWithCompanionData[] {
  if(!response || Object.keys(response).length < 1) {
    return [];
  }
  let results: ModelWithCompanionData[] = [];
  
  response.models.forEach( oneModel => {
    let modelCompanionData = { ...oneModel} as ModelWithCompanionData;

    let companionDataRaw = response.companionDatas?.results?.find(x => x.data.ExternalModelIdentifier === oneModel.id);
    if(!!companionDataRaw) {
      let companionData: CompanionData = {
        modelId : companionDataRaw.data.ExternalModelIdentifier,
        branchSimulationId : companionDataRaw.data.BranchSimulationIdentifier,
        modelName: companionDataRaw.data.ModelName,
        modelVersion: companionDataRaw.data.ModelVersionNumber,
        assetName: companionDataRaw.data.AssetName,
        field: companionDataRaw.data.Field,
        reservoir: companionDataRaw.data.Reservoir,
        reservesCase: companionDataRaw.data.ReservesCase,
        businessPlanCase: companionDataRaw.data.BusinessPlanCase,
        projectPhase: companionDataRaw.data.ProjectPhase,
        forcastYear: companionDataRaw.data.ForecastYear,
        decisionFrame: companionDataRaw.data.DecisonFrame,
        simulationApplication: companionDataRaw.data.SimulationApplication,
        simulationApplicationVersion: companionDataRaw.data.SimulationApplicationVersion,
        // bu part
        originalOilInPlace: companionDataRaw.data.OriginalOilInPlace,
        historyMatchedDate: companionDataRaw.data.HistoryMatchedDate,
        downTime: companionDataRaw.data.DownTime,
        ds3su: companionDataRaw.data.DS3SU,
        runNumber: companionDataRaw.data.RunTime,
        schedule: companionDataRaw.data.Schedule,
        turndownRate: companionDataRaw.data.TurndownRate
      }
      modelCompanionData = {...modelCompanionData, ...companionData};
    };
    
    results.push(modelCompanionData);
  })
  
  return results;
  // if need sort return _.sortBy(results, [function(o) {o.createdDateTime}])
}

// for type2 merge
export function mergeModelCompanionDataType2(response: QueryModelsResponse): ModelWithCompanionDataType2[] {
  if(!response || Object.keys(response).length < 1) {
    return [];
  }
  let results: ModelWithCompanionDataType2[] = [];
  
  response.models.forEach( oneModel => {
    let modelCompanionData: ModelWithCompanionDataType2 = {
      model: oneModel,
      companionData: null
    }

    let companionDataRaw = response.companionDatas?.results?.find(x => x.data.ExternalModelIdentifier === oneModel.id);
    if(!!companionDataRaw) {
      let companionData: CompanionData = {
        modelId : companionDataRaw.data.ExternalModelIdentifier,
        branchSimulationId : companionDataRaw.data.BranchSimulationIdentifier,
        modelName: companionDataRaw.data.ModelName,
        modelVersion: companionDataRaw.data.ModelVersionNumber,
        assetName: companionDataRaw.data.AssetName,
        field: companionDataRaw.data.Field,
        reservoir: companionDataRaw.data.Reservoir,
        reservesCase: companionDataRaw.data.ReservesCase,
        businessPlanCase: companionDataRaw.data.BusinessPlanCase,
        projectPhase: companionDataRaw.data.ProjectPhase,
        forcastYear: companionDataRaw.data.ForecastYear,
        decisionFrame: companionDataRaw.data.DecisonFrame,
        simulationApplication: companionDataRaw.data.SimulationApplication,
        simulationApplicationVersion: companionDataRaw.data.SimulationApplicationVersion,
        // bu part
        originalOilInPlace: companionDataRaw.data.OriginalOilInPlace,
        historyMatchedDate: companionDataRaw.data.HistoryMatchedDate,
        downTime: companionDataRaw.data.DownTime,
        ds3su: companionDataRaw.data.DS3SU,
        runNumber: companionDataRaw.data.RunTime,
        schedule: companionDataRaw.data.Schedule,
        turndownRate: companionDataRaw.data.TurndownRate
      }
      modelCompanionData.companionData = companionData;
    };
    
    results.push(modelCompanionData);
  })
  
  return results;
}

