import {Injectable} from "@angular/core";

import {CollectionsService} from "./collections.service";
import {FileUtilsService} from "../services/file-utils.service";
import {Simulation} from "../models/export class simulation";
import {ModelFile} from "../models/model-file";

import {ModelFilesService} from "../services/model-files.service";
import {CollectionFilesService} from "./collection-files.service";
import {CompletedStaging} from "../models/completed-staging";
import {RevisionTrackService} from "./revision-track.service";
import {CompanionDataService} from "./companion-data.service";
import {ToastrService} from "ngx-toastr";
import {ConfirmDialogService} from "../../components/shared/confirm-dialog/confirm.service";
import {SubTaskInProgress} from "../models/task-in-progress";
import {MD5HasherService} from "../services/md5-hasher.service";
import {ChevronBlobStorageService} from "./chevron-blob-storage.service";
import {v4 as uuidv4} from 'uuid';
import {BlobStorageUploaderService} from "../services/blob-storage-uploader.service";
import {FileService} from "./file.service";
import { SignalRService } from "./signal-r.service";


@Injectable({
    providedIn: 'root'
})
export class CollectionFilesNeededService {
  constructor(public collectionsService: CollectionsService,
    public fileUtilsService: FileUtilsService,
    public modelFilesService: ModelFilesService,
    public collectionFilesService: CollectionFilesService,
    public fileService: FileService,
    public companionDataService: CompanionDataService,
    public revisionTrackService: RevisionTrackService,
    public signalRService: SignalRService,
    public hasherService: MD5HasherService
    )
  {}
}