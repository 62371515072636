import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { CommonModule } from '@angular/common';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions} from '@angular/material/form-field';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MAT_CHECKBOX_DEFAULT_OPTIONS} from '@angular/material/checkbox';
import { MAT_RADIO_DEFAULT_OPTIONS} from '@angular/material/radio';
import { AgGridModule } from 'ag-grid-angular';
import { MessageService} from '@slb-dls/angular-material/notification';
import { SLB_MOMENT_DATE_FORMATS, SLB_THEMING_OPTIONS } from '@slb-dls/angular-material/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ThemeSwitcherComponent } from './theme-switcher/theme-switcher.component';
import { themeConfig } from '../themes/theme.config';
import { CollectionsModule } from './components/pages/collections/collections.module';
import { SharedModule } from './components/shared/shared.module';
import { ShortNamePipe } from './core/pipes/short-name.pipe';
import { ToastrModule } from "ngx-toastr";
import { CoreModule } from './core/core.module';
import {CalAngularModule, CalAngularService} from "@cvx/cal-angular";
import { Observable } from "rxjs";
import { AuthService } from "./core/auth/auth.service";
import { MaterialModule } from "./core/module/material.module";
import {UploadTasksTableDialogComponent} from "./components/shared/tasks-in-progress/upload-tasks-table-dialog.component";
import { HomePageComponent } from './components/pages/home/home.component';
import {SlbModule} from "./core/module/slb.module";
import {NgCircleProgressModule} from "ng-circle-progress";
import { SearchCollectionsComponent } from './components/pages/collections/collections/search-collections.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { SearchCollectionsFiltersComponent } from './components/pages/collections/collections/search-collections-filters.component';
import { ErrorHandlerService } from './core/services/error-handler.service';
import { DownloadTasksTableDialogComponent } from './components/shared/tasks-in-progress/download-tasks-table-dialog.component';

const appearance: MatFormFieldDefaultOptions = {
  appearance: 'outline',
};

const defaultColor = {
  color: 'primary',
};

function initializeAppFactory(authService: AuthService, httpClient: HttpClient, calAngularService: CalAngularService): () => Observable<boolean> {
  return () => {
     return authService.authenticateUser();
  }
}


@NgModule({
  declarations: [
    AppComponent,
    ThemeSwitcherComponent,
    SearchCollectionsComponent,
    SearchCollectionsFiltersComponent,
    ShortNamePipe,
    UploadTasksTableDialogComponent,
    DownloadTasksTableDialogComponent,
    HomePageComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    CommonModule,
    BrowserAnimationsModule,
    MaterialModule,
    SlbModule,
    SharedModule,
    ToastrModule.forRoot(),
    CoreModule,
    ScrollingModule,
    CollectionsModule,
    AgGridModule,
    NgCircleProgressModule.forRoot({
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: "#78C000",
      innerStrokeColor: "#C7E596",
      animationDuration: 300,
    }),
    CalAngularModule.forRoot(AuthService.calConfig)
  ],
  providers: [
    { provide: Window, useValue: window },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [AuthService],
      multi: true
    },
    { provide: SLB_THEMING_OPTIONS, useValue: themeConfig },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: appearance },
    { provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: defaultColor },
    { provide: MAT_RADIO_DEFAULT_OPTIONS, useValue: defaultColor },
    { provide: MAT_DATE_FORMATS, useValue: SLB_MOMENT_DATE_FORMATS },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    { provide: MessageService, useClass: MessageService },
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService 
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
