import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatCardModule, MatCardActions, MatCardContent } from '@angular/material/card';
import {Router} from "@angular/router";
import { autoMonitorPageView } from 'src/app/core/decorators/auto-monitor-page-view.decorator';
import { MonitoringService } from 'src/app/core/services/monitoring.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
@autoMonitorPageView({name: 'Home', trackOnInitToAfterInit: false })
export class HomePageComponent implements OnInit {
  public infoCards = [
    {
      id: 'collections',
      infoCardTitle: 'Collections',
      infoCardSubtitle: 'Collections list',
      infoCardImageUrl: '/assets/images/collections.png',
      infoCardButtonText: 'Collections',
      infoCardRouterLink: '/collections'
    },
    {
      id: 'models',
      infoCardTitle: 'Models',
      infoCardSubtitle: 'Models list',
      infoCardImageUrl: '/assets/images/models.png',
      infoCardButtonText: 'Models',
      infoCardRouterLink: '/models'
    },
    {
      id: 'companion-data-tree',
      infoCardTitle: 'Companion Data Tree',
      infoCardSubtitle: 'Companion Data Tree',
      infoCardImageUrl: '/assets/images/companion-data-tree.png',
      infoCardButtonText: 'Companion Data Tree',
      infoCardRouterLink: '/companion-data-tree'
    }
  ];

  constructor(private router: Router, private monitoringService: MonitoringService) {

  }
  ngOnInit(): void {
    // do nothing
  }

  onClick(infoCardRouterLink: string): void {
    this.router.navigateByUrl(infoCardRouterLink);
  }
}
