import {Injectable} from "@angular/core";
import { SlbLoadingService } from '@slb-dls/angular-material/loading';
import { MessageService } from '@slb-dls/angular-material/notification';
import { CompanionDataService } from 'src/app/core/api/companion-data.service';
import { BrowserStorageService } from 'src/app/core/services/storage.service';
import { CollectionsService } from 'src/app/core/api/collections.service';
import { EntitlementsService } from "src/app/core/api/entitlements.service";


@Injectable({
    providedIn: 'root'
})
export class CompanionDataNeededService {
  constructor(
    public storageService: BrowserStorageService,
    public loadingService: SlbLoadingService,
    public companionDataService: CompanionDataService,
    public messageService: MessageService,
    public collectionsService: CollectionsService,
    public entitlementsService: EntitlementsService )
  {}
}