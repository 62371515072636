import { Injectable } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import {environment} from "../../../environments/environment";

@Injectable({
    providedIn: 'root'
  })
  export class DomSanitizerService {
    constructor(private sanitizer: DomSanitizer) {}

    // for edit and differ edit url
    public webEditorUrlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(environment.webEditorUrl);
    public webDiffEditorUrlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(environment.webDiffEditorUrl);

    public bypassSecurityTrustResourceUrl(url: string) {
      return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
}  