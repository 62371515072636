import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CalAngularService } from '@cvx/cal-angular';
import { Observable, from, retry, RetryConfig, throwError, of } from 'rxjs';
import { catchError, delay, switchMap, tap } from 'rxjs/operators';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { AuthService } from '../auth/auth.service';
import { MonitoringService } from 'src/app/core/services/monitoring.service';

export const maxRetryCount = 3;
export const retryWaitMilliSeconds = 1000;

@Injectable()
export class HttpCallInterceptor implements HttpInterceptor {
  constructor(
    private srvAuth: AuthService,
    private srvCal: CalAngularService,
    private monitoringService: MonitoringService,
    @Inject(LOCAL_STORAGE) private localStorage: StorageService<string>
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.srvCal.getAADToken(this.srvAuth.getScopes())).pipe(
      catchError(err => {
        console.error('error in interceptor while  getting token', err);
        return throwError(() => err);
      }),
      switchMap(token => {
        let nextHandler = next.handle(req);
        if (req.headers.has('no-headers')) {
          req.headers.delete('no-headers');
        } else {
          const slbToken = this.localStorage.get('slbToken');
          const bu = this.localStorage.get('selectedBu') ?? '';
          const headers = req.headers
            .set('Authorization', `Bearer ${token}`)
            .set('slb-token', !!slbToken ? `Bearer ${slbToken}` : '')
            .set('bu', `${bu}`);
          const requestClone = req.clone({
            headers
          });
          nextHandler = next.handle(requestClone);
        }
        this.monitoringService.appInsights.startTrackEvent(`${req.url}`);
        return nextHandler.pipe(
          retry({
            delay: (err: any, retryCount: number) => {
              if (retryCount >= maxRetryCount) return throwError(() => err);
              return of(err).pipe(delay(retryCount * retryWaitMilliSeconds));
            }
          } as RetryConfig)
        );
      }),
      tap(p => {
        this.monitoringService.appInsights.stopTrackEvent(`${req.url}`, { type: 'RequestTracking', status: 'success' });
      }),
      catchError(err => {
        this.monitoringService.appInsights.stopTrackEvent(`${req.url}`, {
          type: 'RequestTracking',
          status: 'failure',
          err: err
        });
        console.error('error in interceptor while making the call', err);
        return throwError(() => err);
      })
    );
  }
}
