<h2 mat-dialog-title>
  Model Comparison
</h2>
<div class="header-title">
  <div>
    {{leftSimulation?.collectionName}}
  </div>
  <div class="wide">
    <span class="material-icons">compare_arrows</span>
  </div>
  <div>
    {{rightSimulation?.collectionName}}
  </div>
  <div>
    {{leftSimulation?.modelName}}
  </div>
  <div>
    {{rightSimulation?.modelName}}
  </div>
</div>
<div class="search-container">
  <mat-form-field appearance="fill" subscriptSizing="dynamic">
    <mat-label>Search</mat-label>
    <input matInput (keyup.enter)="onSearch()" placeholder="enter search text" [(ngModel)]="searchText" #input>
    <button matSuffix mat-icon-button (click)="onSearch()">
      <mat-icon>search</mat-icon>
    </button>
    <button matSuffix mat-icon-button *ngIf="searchText" (click)="onClearSearch()">
      <mat-icon>close</mat-icon>
    </button>
    <button matSuffix mat-icon-button [matMenuTriggerFor]="filterMenu">
      <mat-icon>filter_list</mat-icon>
    </button>
    <mat-menu #filterMenu="matMenu">
      <button mat-menu-item (click)="onApplyFilter(0)" [style.background-color]="getColor(0)">
        <mat-icon color="accent">check_circle</mat-icon>
        <span>Similar</span>
      </button>
      <button mat-menu-item (click)="onApplyFilter(1)" [style.background-color]="getColor(1)">
        <mat-icon color="warn">new_releases</mat-icon>
        <span>Differences</span>
      </button>
      <button mat-menu-item (click)="onApplyFilter(2)" [style.background-color]="getColor(2)">
        <mat-icon color="warn">exposure_neg_1</mat-icon>
        <span>Left only</span>
      </button>
      <button mat-menu-item (click)="onApplyFilter(3)" [style.background-color]="getColor(3)">
        <mat-icon color="primary">exposure_plus_1</mat-icon>
        <span>Right only</span>
      </button>
      <button mat-menu-item (click)="onApplyFilter(-1)">
        <mat-icon>clear</mat-icon>
        <span>Clear filter</span>
      </button>
    </mat-menu>
  </mat-form-field>
</div>
<mat-dialog-content class="mat-typography">

  <div class="folder" *ngFor="let folder of getFolders()">
    <div>{{folder}}</div>
    <mat-divider></mat-divider>
    <table mat-table [dataSource]="getFilesToBeDisplayed(folder)" class="mat-elevation-z8">
      <ng-container matColumnDef="index">
        <th mat-header-cell *matHeaderCellDef> No.</th>
        <td mat-cell *matCellDef="let index = index"> {{index + 1}} </td>
      </ng-container>
      <ng-container matColumnDef="options">
        <th mat-header-cell *matHeaderCellDef>

        </th>
        <td mat-cell *matCellDef="let modelFile">
          <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="options">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item *ngIf="modelFile.viewable && !!modelFile.leftFile"
              (click)="onView(modelFile.leftFile)">
              <mat-icon>visibility</mat-icon>
              <span>Left View</span>
            </button>
            <button mat-menu-item *ngIf="!!modelFile.leftFile" (click)="onDownload(modelFile.leftFile)">
              <mat-icon>download</mat-icon>
              <span>Left Download</span>
            </button>
            <button mat-menu-item *ngIf="modelFile.viewable && !!modelFile.leftFile && !!modelFile.rightFile"
              (click)="onCompare(modelFile.leftFile, modelFile.rightFile)">
              <mat-icon>compare</mat-icon>
              <span>Compare</span>
            </button>
            <button mat-menu-item *ngIf="modelFile.viewable && !!modelFile.rightFile"
              (click)="onView(modelFile.rightFile)">
              <mat-icon>visibility</mat-icon>
              <span>Right View</span>
            </button>
            <button mat-menu-item *ngIf="!!modelFile.rightFile" (click)="onDownload(modelFile.rightFile)">
              <mat-icon>download</mat-icon>
              <span>Right Download</span>
            </button>
          </mat-menu>
        </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name</th>
        <td mat-cell *matCellDef="let modelFile">
          {{modelFile.fileName}}
        </td>
      </ng-container>
      <ng-container matColumnDef="changeType">
        <th mat-header-cell *matHeaderCellDef> Type</th>
        <td mat-cell *matCellDef="let modelFile">
          <mat-icon color="accent" *ngIf="modelFile.differenceType === 0"
            [matTooltip]="'Both are the same'">check_circle</mat-icon> <!-- Similar -->
          <mat-icon color="warn" *ngIf="modelFile.differenceType === 1"
            [matTooltip]="'Both are the different'">new_releases</mat-icon> <!-- Different -->
          <mat-icon color="warn" *ngIf="modelFile.differenceType === 2"
            [matTooltip]="'File only found on the left model. Not found on the right model'">exposure_neg_1</mat-icon>
          <!-- LeftOnly -->
          <mat-icon color="primary" *ngIf="modelFile.differenceType === 3"
            [matTooltip]="'File only found on the right model'">exposure_plus_1</mat-icon> <!-- RightOnly -->
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="folderDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let modelFile; columns: folderDisplayedColumns;"></tr>
    </table>
    <mat-divider></mat-divider>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close color="warn">Close</button>
</mat-dialog-actions>