import {
  ChangeDetectionStrategy,
  Component, EventEmitter,
  Input,
  OnChanges,
  OnDestroy, Output,
  SimpleChanges
} from "@angular/core";
import {autoUnsubscribe} from "../../../../core/decorators/auto-unsubscribe.decorator";
import {ModelFolder, ModelFolderFile} from "./model-folders.component";
import {FileUtilsService} from "../../../../core/services/file-utils.service";
import {FileService} from "../../../../core/api/file.service";
import { SimulationDownloadService } from "../../../../core/api/simulation-download.service";
import {
  BehaviorSubject,
  combineLatestWith,
  concatMap,
  first,
  last,
  Observable,
  of,
  Subject,
  Subscription,
  tap
} from "rxjs";
import {debounceTime, map, switchMap} from "rxjs/operators";
import {MatDialog} from "@angular/material/dialog";
import {OpenFileDialog, OpenFileDialogData} from "../../../shared/dialogs/open-file.dialog";
import {EditFileDialog, EditFileDialogData} from "./dialogs/edit-file.dialog";
import {CollectionsService} from "../../../../core/api/collections.service";
import {CompletedStaging} from "../../../../core/models/completed-staging";
import {DeleteFileRequest} from "../../../../core/models/delete-file-request";
import {SlbLoadingService} from "@slb-dls/angular-material/loading";
import {RevisionTrackService} from "../../../../core/api/revision-track.service";
import {ToastrService} from "ngx-toastr";
import {DeleteFileConfirmationDialog} from "./dialogs/delete-file-confirmation.dialog";
import {SelectionModel} from "@angular/cdk/collections";
import {CompareFileDialog, CompareFileDialogData} from "./dialogs/compare-file.dialog";
import {SelectionChange} from "@angular/cdk/collections";
import {ModelFile} from "../../../../core/models/model-file";
import {CollectionFilesService} from "../../../../core/api/collection-files.service";

export interface NewFileAdd {
  path: string;
  file: File;
}

@Component({
  selector: 'app-view-model-folders',
  templateUrl: './view-model-folders.component.html',
  styleUrls: ['./view-model-folders.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewModelFoldersComponent implements OnChanges, OnDestroy{



  folderDisplayedColumns: Map<string, string[]> = new Map<string, string[]>();
  @Input() modelFolders: ModelFolder[] = [];
  @Input() checkConflictsChanged: boolean = false;
  @Output() public selectionChange = new EventEmitter<SelectionChange<ModelFolderFile>>();
  @Output() public showFileTimeline = new EventEmitter<ModelFolderFile>();
  @Output() public addFile = new EventEmitter<NewFileAdd>();
  isReadOnlyValue:boolean = false;
  @Input() set isReadOnly(value: boolean) {
    this.isReadOnlyValue = value;
  }
  @Input() searchText: string = '';
  folderSelection: Map<string, SelectionModel<ModelFolderFile>> = new Map<string, SelectionModel<ModelFolderFile>>();
  selectionChange$: Subscription[] = [];


  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected(modelFolder: ModelFolder) {
    const selection = this.folderSelection.get(modelFolder.pathFromRoot);
    const numSelected = selection.selected.length;
    const numRows = modelFolder.files.filter(p => p.fileInfo.updateInfo?.mode !== 'not-allowed').length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(modelFolder: ModelFolder) {
    const selection = this.folderSelection.get(modelFolder.pathFromRoot);
    if (this.isAllSelected(modelFolder)) {
      selection.clear();
      selection.select(...modelFolder.files.filter(p => p.fileInfo.updateInfo?.mode === 'add' && p.fileInfo.isPrimaryFile));
      return;
    }
    selection.select(...modelFolder.files.filter(p => p.fileInfo.updateInfo?.mode !== 'not-allowed'));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(modelFolder: ModelFolder, modelFolderFile?: ModelFolderFile): string {
    if (!modelFolderFile) {
      return `${this.isAllSelected(modelFolder) ? 'deselect' : 'select'} all`;
    }

    const selection = this.folderSelection.get(modelFolder.pathFromRoot);
    const position = modelFolder.files.findIndex(p => p.pathFromRoot === modelFolderFile?.pathFromRoot);
    return `${selection.isSelected(modelFolderFile) ? 'deselect' : 'select'} row ${position + 1}`;
  }


  constructor(private dialog: MatDialog,
              private collectionsService: CollectionsService,
              private loadingService: SlbLoadingService,
              private revisionTrackService: RevisionTrackService,
              private toastrService: ToastrService,
              private fileUtilsService: FileUtilsService,
              private collectionFilesService: CollectionFilesService,
              private simulationDownloadService: SimulationDownloadService,
  ) {

  }

  ngOnDestroy(): void {
        if(this.selectionChange$) {
          this.selectionChange$.forEach($ => {
            $.unsubscribe();
          })
        }
    }


  onView(modelFolderFile: ModelFolderFile): void {
    const dialogRef = this.dialog.open(OpenFileDialog, {
      data: {
        fileId: modelFolderFile.fileInfo.id,
        fileName: modelFolderFile.name,
        allowEdit: modelFolderFile.options.edit && !this.isReadOnlyValue
      } as OpenFileDialogData,
    });

    dialogRef.afterClosed().pipe(first()).subscribe(result => {
      if (result === 'edit') {
        this.onEdit(modelFolderFile);
      }
    });
  }

  onEdit(modelFolderFile: ModelFolderFile): void {
    this.dialog.open(EditFileDialog, {
      data: {
        collectionId: modelFolderFile.simulation.collectionId,
        filePath: modelFolderFile.pathFromRoot,
        fileName: modelFolderFile.name,
        allowEdit: modelFolderFile.options.edit
      } as EditFileDialogData,
    });
  }


  onCompare(modelFolderFile: ModelFolderFile) {
    this.dialog.open(CompareFileDialog, {
      data: {
        collectionId: modelFolderFile.simulation.collectionId,
        filePath: modelFolderFile.pathFromRoot,
        fileName: modelFolderFile.name,
        updatedFile: modelFolderFile.fileInfo.updateInfo?.updatedFile
      } as CompareFileDialogData,
    });
  }

  onDelete(modelFolderFile: ModelFolderFile) {
    const dialogRef = this.dialog.open(DeleteFileConfirmationDialog, {
      data: {
        fileName: modelFolderFile.name,
        referredSimulations: modelFolderFile.fileInfo.references??[]
      },
    });
    dialogRef.afterClosed().pipe(first()).subscribe(result => {
      if (result) {
        this.loadingService.showSpinner({text: 'file removal in progress......'});
        this.deleteFile(modelFolderFile).pipe(switchMap((delResponse: any) => {
          if (delResponse?.revisionId) {
            this.loadingService.closeSpinner(true);
            this.loadingService.showSpinner({text: 'checking for status....'});
            return this.revisionTrackService.trackFor({
              collectionId: modelFolderFile.simulation.collectionId,
              revisionId: delResponse?.revisionId
            }).pipe(last())
          }
        }))
          .subscribe({
            next: ({status, simulationId}) => {
              if (status === RevisionTrackService.FAILURE) {
                this.toastrService.error('there was an error in deletion');
              } else if (status === RevisionTrackService.TIMEOUT) {
                this.toastrService.error('could not get the status of the deletion in a timely manner');
              } else if (status === RevisionTrackService.UPDATED || status === RevisionTrackService.COMPLETE) {
                this.toastrService.success('successfully deleted the file');
              }
            },
            error: (e) => {
              this.toastrService.error('there was an error in deleting the file')
            },
            complete: () => {
              this.loadingService.closeSpinner(true);
              this.collectionsService.setRefreshModelFolders(true);
            }
          });
      }
    });
  }

  deleteFile(modelFolderFile): Observable<CompletedStaging> {
    return this.collectionFilesService.deleteFile({
      collectionId: modelFolderFile.simulation.collectionId,
      simulationId: modelFolderFile.simulation.id,
      filePath: modelFolderFile.fileInfo.path
    } as DeleteFileRequest)
  }


  onDownload(modelFolderFile: ModelFolderFile): void {
    this.simulationDownloadService.getOneDownLoadedFile(modelFolderFile.fileInfo.id).
      pipe(first()).subscribe(
        (response: {url : string}) => {
          window.open(response.url);
        });
    
    // original way and or use the anchor directly with url, but file name is not right
    // second is use anchor direct set url, but does not support by browser
    // this.collectionFilesService.getFileUrlByFileId(modelFolderFile.fileInfo.id).
    //   pipe(first()).subscribe(
    //     (response: { url: string }) => {
    //       window.open(response.url);
    //       //const downloadLink = document.createElement("a");
    //       //downloadLink.href = response.url;
    //       ////downloadLink.setAttribute('download', modelFolderFile.name)
    //       //downloadLink.download = modelFolderFile.name;
    //       //document.body.appendChild(downloadLink);
    //       //downloadLink.click();
    //       //document.body.removeChild(downloadLink);
    //     });
  }

  anyOptionExist(modelFolderFile: ModelFolderFile): boolean {
   return modelFolderFile.options.view || modelFolderFile.options.edit || modelFolderFile.options.delete || modelFolderFile.options.download
     || modelFolderFile.options.compare;
  }

  getTooltipForConflicts(conflicts: string[]): string {
    if (conflicts.length == 1)
      return `conflict! the file is referenced in the simulation ${conflicts[0]}. uploading this file will update the file in that simulation as well`;
    else
      return `conflict! the file is referenced in simulations ${conflicts.join(',')}. uploading this file will update the file in those simulations as well`;
  }

  getTooltipForConflictsButMostLikelyNotModified(conflicts: string[]): string {
    if (conflicts.length == 1)
      return `based on the size the file seems to have the same size as is referenced in the simulation ${conflicts[0]}. upload only if you are sure the file has been modified`;
    else
      return `based on the size the file seems to have the same size as is referenced in the simulations ${conflicts.join(',')}. upload only if you are sure the file has been modified`;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.modelFolders?.currentValue?.length > 0 || (changes.checkConflictsChanged && this.modelFolders?.length > 0)) {
      this.assignDefaultSelection(this.modelFolders);
    }


  }


  private assignDefaultSelection(modelFolders: ModelFolder[]): void {
    let filesToBeSelected = [];
    modelFolders.forEach(modelFolder => {
      if(modelFolder.files.some(p => !!p.fileInfo.updateInfo)){
        this.folderSelection.set(modelFolder.pathFromRoot, new  SelectionModel<ModelFolderFile>(true, []));
        this.selectionChange$.push(
          this.folderSelection.get(modelFolder.pathFromRoot).changed.pipe(tap(s => {
            this.selectionChange.emit(s);
          })).subscribe()
        );
        if(modelFolder.files.every(p => p.fileInfo.updateInfo?.mode === 'add')) { //upload new model mode
          this.folderDisplayedColumns.set(modelFolder.pathFromRoot, ['select', 'conflict', 'index', 'options',  'name', 'type', 'size', 'createdUser', 'createdDate']);
          const selection = this.folderSelection.get(modelFolder.pathFromRoot);
          filesToBeSelected = modelFolder.files.filter(p => p.fileInfo.updateInfo?.selected === true);
          selection.select(...filesToBeSelected);
        }
        else if(modelFolder.files.every(p => !!p.fileInfo.updateInfo)) { // edit mode
          this.folderDisplayedColumns.set(modelFolder.pathFromRoot, ['select', 'changeType', 'conflict', 'index', 'options',  'name', 'type', 'size', 'createdUser', 'createdDate']);
          const selection = this.folderSelection.get(modelFolder.pathFromRoot);
          filesToBeSelected = modelFolder.files.filter(p => p.fileInfo.updateInfo?.selected === true);
          selection.select(...filesToBeSelected);
        }
      }
      else
        this.folderDisplayedColumns.set(modelFolder.pathFromRoot, ['index', 'options', 'name', 'type', 'size', 'createdUser', 'createdDate']);
    });

  }

  addFilesToFolder(modelFolder: ModelFolder): void {
    window['showOpenFilePicker']({ multiple: true }).then((handles) => {
      handles.forEach(p => {
        return p.getFile().then((f: File) => {
          if(this.fileUtilsService.getFileExtension(f.name) !== 'manifest') {
            this.addFile.emit({ path: modelFolder.pathFromRoot, file: f});
          }
        });
      });
    });
  }

  onAddFileFromChildren(addFile: NewFileAdd) {
    this.addFile.emit(addFile);
  }


  onSelect(modelFolder: ModelFolder, modelFolderFile: ModelFolderFile) {
    this.folderSelection.get(modelFolder.pathFromRoot).toggle(modelFolderFile);
  }

  onSubFolderSelectionChange(selectionChange: SelectionChange<ModelFolderFile>) {
    this.selectionChange.emit(selectionChange);
  }

  onTimeline(modelFolderFile: ModelFolderFile) {
    this.showFileTimeline.emit(modelFolderFile);
  }

  onShowFileTimelineFromSubFolder(modelFolderFile: ModelFolderFile) {
    this.showFileTimeline.emit(modelFolderFile);
  }

  onModelFolderFileTypeChange(type: 'input' | 'output', modelFolderFile: ModelFolderFile) {
    modelFolderFile.fileInfo.type = type;
  }

  getFilesToBeDisplayed(files: ModelFolderFile[]): ModelFolderFile[] {
    if(this.searchText.trim()) {
      const searchText = this.searchText;
      return files.filter(p => p.name.indexOf(searchText) > -1 || p.pathFromRoot.indexOf(searchText) > -1);
    }
    return files;
  }
}
