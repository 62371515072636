import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';



export interface ModelToCompare {
  simulationId: string;
  collectionName: string;
  modelName: string;
}

@Injectable({
  providedIn: 'root'
})
export class ModelComparisonService {

  private currentItems: ModelToCompare[] = [];

  private currentItemsSub = new BehaviorSubject(this.currentItems);

  get currentModelsForComparison(): Observable<ModelToCompare[]> {
    return this.currentItemsSub.asObservable();
  }


  addToCompare(modelToCompare: ModelToCompare) {
    if (modelToCompare) {
      if (this.currentItems.length > 1)
        this.currentItems.splice(0, 1);
      this.currentItems.push(modelToCompare);
      this.currentItemsSub.next(this.currentItems);
    }
  }


  removeFromCompare(simulationId: string) {
    if (simulationId) {
      const idx = this.currentItems.findIndex(p => p.simulationId === simulationId);
      if (idx > -1) {
        this.currentItems.splice(idx, 1);
        this.currentItemsSub.next(this.currentItems);
      }
    }
  }

}
