import { Injectable } from "@angular/core";
import { catchError, EMPTY, first, from, Observable, of, tap, zip } from "rxjs";
import { last, map, mergeMap, switchMap, toArray } from "rxjs/operators";
import { FileSaveRequest, FilesWriterService } from "../services/files-writer.service";
import { environment } from "../../../environments/environment";
import { FileUtilsService } from "../services/file-utils.service";
import { ToastrService } from "ngx-toastr";
import { v4 as uuidv4 } from 'uuid';
import { SubTaskInProgress } from "../models/task-in-progress";
import { CollectionFile } from "../models/collection-file";
import { ModelDownloadTaskInProgress, ModelDownloadTaskInProgressService } from "../services/model-download-task-in-progress.service";
import { ConfirmDialogService } from "src/app/components/shared/confirm-dialog/confirm.service";
import { SimulationDownloadService } from "./simulation-download.service";
import { DownloadTasksTableDialogComponent } from "src/app/components/shared/tasks-in-progress/download-tasks-table-dialog.component";
import { MatDialog } from "@angular/material/dialog";


@Injectable({
  providedIn: 'root'
})
export class CollectionFilesDownloaderService {


  constructor(private filesWriterService: FilesWriterService,
    private fileUtilsService: FileUtilsService, private toastrService: ToastrService,
    public dialogService: ConfirmDialogService,
    private dialog: MatDialog,
    private simulationDownloadService: SimulationDownloadService,
    public modelDownloadTaskInProgressService: ModelDownloadTaskInProgressService,) {
  }

  downloadFiles(collectionId: string, revisionId: string, title: string, nameInMessage: string, simulationIds: string[]) {

    const options = {
      title: title,
      message: `You are about to download files for ${nameInMessage}. Please select the folder where the files will be downloaded to`,
      cancelText: "Cancel",
      confirmText: "Ok"
    };
    this.dialogService.open(options);

    this.dialogService.confirmed().pipe(last(), switchMap(confirmed => {
      if (confirmed) {
        return this.filesWriterService.getDirectoryHandleWithPermission({ mode: 'readwrite' })
          .pipe(
            switchMap(dirHandle => {
              this.modelDownloadTaskInProgressService.updateProgress(
                {
                  Id: collectionId,
                  DisplayTitle: `${title}`,
                  Progress: 2,
                  ParentId: '',
                  Status: ModelDownloadTaskInProgressService.TaskInProgressStatus,
                  RootId: collectionId,
                } as ModelDownloadTaskInProgress);

              const dialogRef = this.dialog.open(DownloadTasksTableDialogComponent, {
                width: '90vw',
                height: '90vh',
                data: collectionId,
              });

              dialogRef.afterClosed().subscribe(result => {
                console.log('The dialog was closed');
              });

              return zip(simulationIds.map(p => this.simulationDownloadService.getDownloadedFiles(collectionId, revisionId, p))).pipe(
                tap(p => {
                  this.modelDownloadTaskInProgressService.updateProgress(
                    {
                      Id: collectionId,
                      DisplayTitle: `${title}`,
                      Progress: 4,
                      ParentId: '',
                      Status: ModelDownloadTaskInProgressService.TaskInProgressStatus,
                      RootId: collectionId,
                    } as ModelDownloadTaskInProgress);
                }),
                map(p => {
                  const filesToBeDownloaded = p.flat();
                  const unique = [...new Set(filesToBeDownloaded.map(item => item.id))];
                  return unique.map(p => filesToBeDownloaded.find(x => x.id === p));
                }),
                tap(p => {
                  p.forEach(x => {
                    this.modelDownloadTaskInProgressService.updateProgress(
                      {
                        Id: x.id,
                        DisplayTitle: `${x.path}`,
                        Progress: 0,
                        ParentId: collectionId,
                        Status: ModelDownloadTaskInProgressService.TaskInProgressStatus,
                        RootId: collectionId,
                      } as ModelDownloadTaskInProgress);
                  });
                }),
                map(p => [p, dirHandle])
              )
            }),
            switchMap(([filesToBeDownloaded, dirHandle]) => {
              const topUuid = uuidv4();
              const needTopFolder: boolean = this.checkNeedTopFolder(filesToBeDownloaded);
              return of(filesToBeDownloaded.map(p => {
                let filePath = this.fileUtilsService.getParentFolderPath(p.path);
                if (needTopFolder) {
                  filePath = filePath === '' ? topUuid : topUuid + FileUtilsService.PATH_SEPARATOR + filePath;
                }
                let result = ({
                  id: p.id,
                  filePath: filePath,
                  fileName: this.fileUtilsService.getFileName(p.path),
                  fileSize: p.size,
                  blobUrl: p.blobUrl
                } as FileSaveRequest);
                return result;
              }))
                .pipe(
                  switchMap((files: FileSaveRequest[]) => {
                    return this.fileSaveRequestMap(dirHandle, collectionId, files);
                  })
                  // switchMap((files: FileSaveRequest[]) => {
                  //   return this.filesWriterService.writeFiles(dirHandle, files, (subTaskInProgress: ModelDownloadTaskInProgress) => {
                  //     this.modelDownloadTaskInProgressService.updateProgress(subTaskInProgress);
                  //   }).pipe(
                  //     map((savedFiles: FileSaveRequest[]) => {
                  //       if (savedFiles.length === files.length) {
                  //         this.toastrService.success(`${files.length} downloaded successfully`)
                  //         this.modelDownloadTaskInProgressService.updateProgress({ Id: collectionId, Progress: 100, Status: ModelDownloadTaskInProgressService.TaskSuccessStatus } as ModelDownloadTaskInProgress);
                  //         return true;
                  //       } else if (savedFiles.length > 0) {
                  //         this.modelDownloadTaskInProgressService.updateProgress({ Id: collectionId, Progress: 100, Status: ModelDownloadTaskInProgressService.TaskFailureStatus } as ModelDownloadTaskInProgress);
                  //         this.toastrService.error(`${savedFiles.length}/${files.length} downloaded successfully`)
                  //       } else {
                  //         this.modelDownloadTaskInProgressService.updateProgress({ Id: collectionId, Progress: 100, Status: ModelDownloadTaskInProgressService.TaskFailureStatus } as ModelDownloadTaskInProgress);
                  //         this.toastrService.error('files download failed');
                  //       }
                  //       return false;
                  //     }));
                  // })
                );
            }),
            catchError(err => {
              this.modelDownloadTaskInProgressService.updateProgress({ Id: collectionId, Progress: 100, Status: ModelDownloadTaskInProgressService.TaskFailureStatus } as ModelDownloadTaskInProgress);
              this.toastrService.error('files download failed');
              console.error('error downloading files');
              return of(false);
            })
          )
      } else return EMPTY;
    })).pipe(first()).subscribe();


  }

  private checkNeedTopFolder(filesToBeDownloaded: CollectionFile[]): boolean {
    let needTopFolder: boolean = false;
    for (let dFile of filesToBeDownloaded) {
      let filePath = this.fileUtilsService.getParentFolderPath(dFile.path);
      if (filePath === '') {
        needTopFolder = true;
        break;
      }
    }
    console.log("need top folder:" + needTopFolder);
    return needTopFolder;
  }

  private fileSaveRequestMap(dirHandle, collectionId, files: FileSaveRequest[]): Observable<boolean> {
    return this.filesWriterService.writeFiles(dirHandle, files, (subTaskInProgress: ModelDownloadTaskInProgress) => {
      this.modelDownloadTaskInProgressService.updateProgress(subTaskInProgress);
    }).pipe(
      map((savedFiles: FileSaveRequest[]) => {
        if (savedFiles.length === files.length) {
          this.toastrService.success(`${files.length} downloaded successfully`)
          this.modelDownloadTaskInProgressService.updateProgress({ Id: collectionId, Progress: 100, Status: ModelDownloadTaskInProgressService.TaskSuccessStatus } as ModelDownloadTaskInProgress);
          return true;
        } else if (savedFiles.length > 0) {
          this.modelDownloadTaskInProgressService.updateProgress({ Id: collectionId, Progress: 100, Status: ModelDownloadTaskInProgressService.TaskFailureStatus } as ModelDownloadTaskInProgress);
          this.toastrService.error(`${savedFiles.length}/${files.length} downloaded successfully`)
        } else {
          this.modelDownloadTaskInProgressService.updateProgress({ Id: collectionId, Progress: 100, Status: ModelDownloadTaskInProgressService.TaskFailureStatus } as ModelDownloadTaskInProgress);
          this.toastrService.error('files download failed');
        }
        return false;
      }));
  }

}
