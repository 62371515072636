import { Injectable } from '@angular/core';
import { combineLatest, lastValueFrom, map, Observable, of } from 'rxjs';
import { SlbLoadingService } from '@slb-dls/angular-material/loading';
import { BrowserStorageService } from './storage.service';
import { CompanionDataService } from '../api/companion-data.service';
import { transformLegalTagCountries } from '../../util/companion-data.util';


@Injectable({
  providedIn: 'root'
})
export class CompanionDataFieldsService {
  constructor(private storage: BrowserStorageService, private companionDataService: CompanionDataService) {
   }

  public async GetCompanionDataFields()  {
    const companionDataSettings$ = this.companionDataService.getCompanionDataSettings();
    const legalTags$ = this.companionDataService.getLegalTags();
    const countries$ = this.companionDataService.getCountries();
    const entitlementGroups$ = this.companionDataService.getEntitlementGroups();
    combineLatest([legalTags$, countries$, entitlementGroups$, companionDataSettings$ ]).pipe().subscribe({
      next: ([resultLegalTags, resultCountries, resultEntitlementGroups, resultCompanionDataSettings]) => {
          const legalTags = resultLegalTags?.legalTags;
          const countries = transformLegalTagCountries(resultCountries);
          const entitlements = resultEntitlementGroups?.groups;
          const buName = resultCompanionDataSettings['buName'];
          const companionDataSettings = resultCompanionDataSettings['companionData'];
        // put into storage 
          this.storage.set("companionDataFields", JSON.stringify({legalTags,countries,entitlements, buName, companionDataSettings}));
      }
    });
  }
}
