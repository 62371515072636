<div>
  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Collection Name
        </mat-panel-title>
        <mat-panel-description>
          <mat-spinner mode="indeterminate" *ngIf="inProgress" diameter="25"></mat-spinner>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <mat-form-field appearance="fill" class="width_100">
        <mat-label>Name</mat-label>
        <input type="text" matInput [formControl]="collectionNameFilterControl">
        <button matSuffix mat-button *ngIf="!!collectionNameFilterControl.value?.trim()"
          (click)="collectionNameFilterControl.setValue('')">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Created/Updated User
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-selection-list *ngIf="collectionUsers?.values?.length > 0" [formControl]="collectionUsersFilterControl">
        <mat-list-option *ngFor="let modifiedUser of collectionUsers?.values" checkboxPosition="before"
          [value]="modifiedUser">
          {{modifiedUser}}
        </mat-list-option>
      </mat-selection-list>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Owners
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-selection-list *ngIf="collectionOwners?.values?.length > 0" [formControl]="collectionOwnersFilterControl">
        <mat-list-option *ngFor="let collectionOwner of collectionOwners?.values" checkboxPosition="before"
          [value]="collectionOwner">
          {{collectionOwner}}
        </mat-list-option>
      </mat-selection-list>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Viewers
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-selection-list *ngIf="collectionViewers?.values?.length > 0" [formControl]="collectionViewersFilterControl">
        <mat-list-option *ngFor="let collectionViewer of collectionViewers?.values" checkboxPosition="before"
          [value]="collectionViewer">
          {{collectionViewer}}
        </mat-list-option>
      </mat-selection-list>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Created Date
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-form-field appearance="fill" class="width_100">
        <mat-label>Enter a date range</mat-label>
        <mat-date-range-input [max]="currentDate" [formGroup]="collectionStartDateRange"
          [rangePicker]="collectionStartDatePicker">
          <input matStartDate formControlName="start" placeholder="Start date">
          <input matEndDate formControlName="end" placeholder="End date">
        </mat-date-range-input>
        <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle matIconSuffix [for]="collectionStartDatePicker"></mat-datepicker-toggle>
        <mat-date-range-picker #collectionStartDatePicker></mat-date-range-picker>
      </mat-form-field>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Updated Date
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-form-field appearance="fill" class="width_100">
        <mat-label>Enter a date range</mat-label>
        <mat-date-range-input [max]="currentDate" [formGroup]="collectionUpdatedDateRange"
          [rangePicker]="collectionUpdatedDatePicker">
          <input matStartDate formControlName="start" placeholder="Start date">
          <input matEndDate formControlName="end" placeholder="End date">
        </mat-date-range-input>
        <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle matIconSuffix [for]="collectionUpdatedDatePicker"></mat-datepicker-toggle>
        <mat-date-range-picker #collectionUpdatedDatePicker></mat-date-range-picker>
      </mat-form-field>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Model Name
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-form-field appearance="fill" class="width_100">
        <mat-label>Model Name</mat-label>
        <input type="text" matInput [formControl]="modelNameFilterControl">
        <button matSuffix mat-button *ngIf="!!modelNameFilterControl.value?.trim()"
          (click)="modelNameFilterControl.setValue('')">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Model Modified User
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-selection-list *ngIf="modelUsers?.values?.length > 0" [formControl]="modelUsersFilterControl">
        <mat-list-option *ngFor="let modelUser of modelUsers?.values" checkboxPosition="before" [value]="modelUser">
          {{modelUser}}
        </mat-list-option>
      </mat-selection-list>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Model Last Updated Date
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-form-field appearance="fill" class="width_100">
        <mat-label>Enter a date range</mat-label>
        <mat-date-range-input [max]="currentDate" [formGroup]="modelLastUpdatedDateRange"
          [rangePicker]="modelLastUpdatedPicker">
          <input matStartDate formControlName="start" placeholder="Start date">
          <input matEndDate formControlName="end" placeholder="End date">
        </mat-date-range-input>
        <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle matIconSuffix [for]="modelLastUpdatedPicker"></mat-datepicker-toggle>
        <mat-date-range-picker #modelLastUpdatedPicker></mat-date-range-picker>
      </mat-form-field>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Remarks
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-form-field appearance="fill" class="width_100">
        <mat-label>Remarks</mat-label>
        <input type="text" matInput [formControl]="remarksFilterControl">
        <button matSuffix mat-button *ngIf="!!remarksFilterControl.value?.trim()"
          (click)="remarksFilterControl.setValue('')">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          GenOpt Manifest Filename
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-form-field appearance="fill" class="width_100">
        <mat-label>GenOpt Manifest Filename</mat-label>
        <input type="text" matInput [formControl]="genOptManifestFileNameFilterControl">
        <button matSuffix mat-button *ngIf="!!genOptManifestFileNameFilterControl.value?.trim()"
          (click)="genOptManifestFileNameFilterControl.setValue('')">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Owners
        </mat-panel-title>
        <mat-panel-description>
          as entered in companion data
        </mat-panel-description>
      </mat-expansion-panel-header>
      <mat-selection-list *ngIf="companionDataOwners?.values?.length > 0" [formControl]="companionDataOwnersFilterControl">
        <mat-list-option *ngFor="let companionDataOwner of companionDataOwners?.values" checkboxPosition="before" [value]="companionDataOwner">
          {{companionDataOwner}}
        </mat-list-option>
      </mat-selection-list>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Viewers
        </mat-panel-title>
        <mat-panel-description>
          as entered in companion data
        </mat-panel-description>
      </mat-expansion-panel-header>
      <mat-selection-list *ngIf="companionDataViewers?.values?.length > 0" [formControl]="companionDataViewersFilterControl">
        <mat-list-option *ngFor="let companionDataViewer of companionDataViewers?.values" checkboxPosition="before" [value]="companionDataViewer">
          {{companionDataViewer}}
        </mat-list-option>
      </mat-selection-list>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Legal Tags
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-selection-list *ngIf="companionDataLegalTags?.values?.length > 0" [formControl]="companionDataLegalTagsFilterControl">
        <mat-list-option *ngFor="let companionDataLegalTag of companionDataLegalTags?.values" checkboxPosition="before" [value]="companionDataLegalTag">
          {{companionDataLegalTag}}
        </mat-list-option>
      </mat-selection-list>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Countries
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-selection-list *ngIf="companionDataCountries?.values?.length > 0" [formControl]="companionDataCountriesFilterControl">
        <mat-list-option *ngFor="let companionDataCountry of companionDataCountries?.values" checkboxPosition="before" [value]="companionDataCountry">
          {{companionDataCountry}}
        </mat-list-option>
      </mat-selection-list>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Model Version Number
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-selection-list *ngIf="companionDataModelVersions?.values?.length > 0" [formControl]="companionDataModelVersionsFilterControl">
        <mat-list-option *ngFor="let companionDataModelVersion of companionDataModelVersions?.values" checkboxPosition="before" [value]="companionDataModelVersion">
          {{companionDataModelVersion}}
        </mat-list-option>
      </mat-selection-list>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Business Plan Case
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-selection-list *ngIf="companionDataBusinessPlanCases?.values?.length > 0" [formControl]="companionDataBusinessPlanCasesFilterControl">
        <mat-list-option *ngFor="let companionDataBusinessPlanCase of companionDataBusinessPlanCases?.values" checkboxPosition="before" [value]="companionDataBusinessPlanCase">
          {{companionDataBusinessPlanCase}}
        </mat-list-option>
      </mat-selection-list>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Reserves Case
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-selection-list *ngIf="companionDataReservesCases?.values?.length > 0" [formControl]="companionDataReservesCasesFilterControl">
        <mat-list-option *ngFor="let companionDataReservesCase of companionDataReservesCases?.values" checkboxPosition="before" [value]="companionDataReservesCase">
          {{companionDataReservesCase}}
        </mat-list-option>
      </mat-selection-list>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Reservoir
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-selection-list *ngIf="companionDataReservoirs?.values?.length > 0" [formControl]="companionDataReservoirsFilterControl">
        <mat-list-option *ngFor="let companionDataReservoir of companionDataReservoirs?.values" checkboxPosition="before" [value]="companionDataReservoir">
          {{companionDataReservoir}}
        </mat-list-option>
      </mat-selection-list>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Decision Frame
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-selection-list *ngIf="companionDataDecisionFrames?.values?.length > 0" [formControl]="companionDataDecisionFramesFilterControl">
        <mat-list-option *ngFor="let companionDataDecisionFrame of companionDataDecisionFrames?.values" checkboxPosition="before" [value]="companionDataDecisionFrame">
          {{companionDataDecisionFrame}}
        </mat-list-option>
      </mat-selection-list>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Model Objective
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-selection-list *ngIf="companionDataModelObjectives?.values?.length > 0" [formControl]="companionDataModelObjectivesFilterControl">
        <mat-list-option *ngFor="let companionDataModelObjective of companionDataModelObjectives?.values" checkboxPosition="before" [value]="companionDataModelObjective">
          {{companionDataModelObjective}}
        </mat-list-option>
      </mat-selection-list>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Project Phase
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-selection-list *ngIf="companionDataProjectPhases?.values?.length > 0" [formControl]="companionDataProjectPhasesFilterControl">
        <mat-list-option *ngFor="let companionDataProjectPhase of companionDataProjectPhases?.values" checkboxPosition="before" [value]="companionDataProjectPhase">
          {{companionDataProjectPhase}}
        </mat-list-option>
      </mat-selection-list>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Field
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-selection-list *ngIf="companionDataFields?.values?.length > 0" [formControl]="companionDataFieldsFilterControl">
        <mat-list-option *ngFor="let companionDataField of companionDataFields?.values" checkboxPosition="before" [value]="companionDataField">
          {{companionDataField}}
        </mat-list-option>
      </mat-selection-list>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Forecast Year
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-selection-list *ngIf="companionDataForecastYears?.values?.length > 0" [formControl]="companionDataForecastYearsFilterControl">
        <mat-list-option *ngFor="let companionDataForecastYear of companionDataForecastYears?.values" checkboxPosition="before" [value]="companionDataForecastYear">
          {{companionDataForecastYear}}
        </mat-list-option>
      </mat-selection-list>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Simulation Application
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-selection-list *ngIf="companionDataSimulationApplications?.values?.length > 0" [formControl]="companionDataSimulationApplicationsFilterControl">
        <mat-list-option *ngFor="let companionDataSimulationApplication of companionDataSimulationApplications?.values" checkboxPosition="before" [value]="companionDataSimulationApplication">
          {{companionDataSimulationApplication}}
        </mat-list-option>
      </mat-selection-list>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Simulation Application Version
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-selection-list *ngIf="companionDataSimulationApplicationVersions?.values?.length > 0" [formControl]="companionDataSimulationApplicationVersionsFilterControl">
        <mat-list-option
          *ngFor="let companionDataSimulationApplicationVersion of companionDataSimulationApplicationVersions?.values" checkboxPosition="before" [value]="companionDataSimulationApplicationVersion">
          {{companionDataSimulationApplicationVersion}}
        </mat-list-option>
      </mat-selection-list>
    </mat-expansion-panel>
  </mat-accordion>
</div>