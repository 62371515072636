import { NgModule } from '@angular/core';
import {SlbSharedModule} from "@slb-dls/angular-material/shared";
import {SlbButtonModule} from "@slb-dls/angular-material/button";
import {SlbFormFieldModule} from "@slb-dls/angular-material/form-field";
import {SlbPopoverModule} from "@slb-dls/angular-material/popover";
import {SlbNotificationModule} from "@slb-dls/angular-material/notification";
import {SlbNotificationsPanelModule} from "@slb-dls/angular-material/notifications-panel";
import {SlbNavigationFrameworkModule} from "@slb-dls/angular-material/navigation-framework";
import {SlbBreadcrumbsModule} from "@slb-dls/angular-material/breadcrumbs";
import {SlbLogoutModule} from "@slb-dls/angular-material/logout";
import {SlbDlsGridModule} from "@slb-dls/angular-material/ag-grid";
import {SlbLoadingModule} from "@slb-dls/angular-material/loading";
import {SlbPaginationControlModule} from "@slb-dls/angular-material/pagination-control";
import {SlbSearchModule} from "@slb-dls/angular-material/search";
import {SlbDropdownModule} from "@slb-dls/angular-material/dropdown";
import {SlbDatePickerRangeModule} from "@slb-dls/angular-material/date-range-picker";


@NgModule({
  declarations: [],
  imports: [
    SlbSharedModule,
    SlbButtonModule,
    SlbFormFieldModule,
    SlbPopoverModule,
    SlbNotificationModule,
    SlbNotificationsPanelModule,
    SlbNavigationFrameworkModule,
    SlbBreadcrumbsModule,
    SlbLogoutModule,
    SlbDlsGridModule,
    SlbLoadingModule,
    SlbPaginationControlModule,
    SlbSearchModule,
    SlbDropdownModule,
    SlbDatePickerRangeModule,
  ],
  exports:[
    SlbSharedModule,
    SlbButtonModule,
    SlbFormFieldModule,
    SlbPopoverModule,
    SlbNotificationModule,
    SlbNotificationsPanelModule,
    SlbNavigationFrameworkModule,
    SlbBreadcrumbsModule,
    SlbLogoutModule,
    SlbDlsGridModule,
    SlbLoadingModule,
    SlbPaginationControlModule,
    SlbSearchModule,
    SlbDropdownModule,
    SlbDatePickerRangeModule,
  ]
})
export class SlbModule { }
