import {Injectable} from "@angular/core";
import {Observable, of, tap, zip} from "rxjs";
import {ModelFile} from "../models/model-file";
import {map, switchMap} from "rxjs/operators";
import {FileUtilsService} from "./file-utils.service";


@Injectable({
  providedIn: 'root'
})
export class MbalModelService {
  public static readonly MbalExtension1 = 'mbi';
  public static readonly MbalExtension2 = 'mbl';
  public static readonly MbalExtension3 = 'gap';

  public static readonly mbalOutputFilesExtensions = [
    'stxu'
  ];

  constructor(private fileUtilsService: FileUtilsService) {
  }

  // this is specifically for mbal model
  public getModelsInFiles(files: File []) {
    const rootFiles = this.fileUtilsService.getFilesAtRootFolder(files);
    // all gap files
    const gapExtensions = [MbalModelService.MbalExtension3];
    const gapFiles = rootFiles.filter(p => gapExtensions.some(q => q === this.fileUtilsService.getFileExtension(p.name)));
    
    // all mbi files
    const mbalExtensions = [MbalModelService.MbalExtension1, MbalModelService.MbalExtension2];
    const mbalFiles = rootFiles.filter(p => mbalExtensions.some(q => q === this.fileUtilsService.getFileExtension(p.name)));
    
    if(gapFiles.length > 0 && mbalFiles.length > 0) {
      return gapFiles;
    }
    // should we show the mbi files if gap does not exists
    else if(mbalFiles.length > 0){
     return mbalFiles;
    }
    else {
      return [] as File[];
    }

  }

  public getModelFilesInfoFromFiles(modelFile: File, files: File[]): Observable<ModelFile[]> {
    return zip([this.getInputs(modelFile, files), this.getOutputs(files)]).pipe(switchMap(([iFiles, oFiles]) => {
      const ioFiles = iFiles.concat(oFiles);
      const filesLeftOut = files
        .filter(p => !ioFiles.some(x => x.updateInfo.updatedFile.webkitRelativePath === p.webkitRelativePath))
        .map(p => {
          return this.fileUtilsService.getModelFileForAdd(p, 'output', false).pipe(tap(p => (p.updateInfo.optional = true)));
        });
      return zip(...ioFiles.map(p => of(p)).concat(filesLeftOut));
    }));
  }

  // this is the missing file, it should return just []
  public getMissingInputFiles(files: File[]): Observable<File[]> {
    return of([]);
  }

  public getInputs(modelFile: File, files: File[]): Observable<ModelFile[]> {
    const inputFiles = files.filter(p => {
      const extension = this.fileUtilsService.getFileExtension(p.webkitRelativePath);
      return MbalModelService.mbalOutputFilesExtensions.includes(extension) === false;
    });
    
    const finalInputs = inputFiles.map(p => {
      return this.fileUtilsService.getModelFileForAdd(p, 'input', false).pipe(
        tap(mFile => {
          if (mFile.path.toLocaleLowerCase() === this.fileUtilsService.getNameNoSpecialCharacter(modelFile.webkitRelativePath).toLocaleLowerCase())
            mFile.isPrimaryFile = true;
        })
      );
    });
    if (finalInputs.length > 0) return zip(...finalInputs).pipe(map(inputs => inputs.filter(this.getUnique)));
    else of([]);
  }

  private getUnique(value: ModelFile, index: number, arr: ModelFile[]) {
    return arr.findIndex(p => p.path === value.path && p.type === value.type) === index;
  }

  public getOutputs(files: File[]): Observable<ModelFile[]> {
    const outputFiles = files.filter(p => {
      const extension = this.fileUtilsService.getFileExtension(p.webkitRelativePath);
      return MbalModelService.mbalOutputFilesExtensions.includes(extension);
    });
    
    const finalOutputs = outputFiles.map(p => {
      return this.fileUtilsService.getModelFileForAdd(p, 'output', false).pipe(tap(p => (p.updateInfo.optional = true)));
    });
    if (finalOutputs.length > 0) return zip(...finalOutputs).pipe(map(inputs => inputs.filter(this.getUnique)));
    else return of([]);
  }
}
