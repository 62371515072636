<ng-template #pageHeaderOptions>
  <div class="page-header-toolbar">
    <div class="toolbar-row">
      <div class="toolbar-flex-container">
        <div class="toolbar-button-container">
          <button mat-mini-fab matTooltip="create a new collection" color="primary" aria-label="create a new collection"
            (click)="createCollection()">
            <mat-icon svgIcon="add" aria-hidden="false"></mat-icon>
          </button>
        </div>
        <div class="toolbar-button-container">
          <button mat-mini-fab color="primary" matTooltip="refresh collection" aria-label="refresh collection"
            (click)="onRefresh()">
            <mat-icon svgIcon="refresh" aria-hidden="false"></mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<mat-drawer-container class="drawer-container" autosize>
  <mat-drawer #drawer class="drawer-sidenav" style="width:100%" position="end" mode="side">
    <mat-toolbar>
      <div class="button-container">
        <button mat-raised-button type="button" [disabled]="(listItems?.length ?? 0) <= 1" (click)="onReset()">
          <mat-icon>clear</mat-icon><span> Reset</span>
        </button>
      </div>
      <div class="button-container">
        <button mat-raised-button color="primary" type="button" [disabled]="(listItems?.length ?? 0) <= 1"
          (click)="onApplyFilter()">
          <mat-icon>search</mat-icon><span> Apply </span>
        </button>
      </div>
      <div class="button-container">
        <button mat-raised-button type="button" (click)="onCloseDrawer()">
          <mat-icon>arrow_right_alt</mat-icon><span> Close</span>
        </button>
      </div>
    </mat-toolbar>
    <div fxLayout="column" *ngIf="listItems?.length > 1">
      <app-search-collections-filters [searchText]="currentSearchTextSelected" [reset]="reset"
        (filtersChange)="onFiltersChanged($event)"></app-search-collections-filters>
    </div>
  </mat-drawer>

  <div class="container">
    <div class="search-item-container">
      <mat-form-field appearance="fill" subscriptSizing="dynamic">
        <mat-label>Search</mat-label>
        <input matInput (keyup.enter)="onSearch()" placeholder="enter search text" [(ngModel)]="searchText" #input>
        <button matSuffix mat-button (click)="onSearch()">
          <mat-icon>search</mat-icon>
        </button>
        <button matSuffix mat-button *ngIf="searchText" (click)="onClearSearch()">
          <mat-icon>close</mat-icon>
        </button>
        <button matSuffix mat-button (click)="onSlideDrawer()">
          <mat-icon>filter_list</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="search-results-item-container mat-elevation-z8">
      <div class="search-result-item-header-container">
        <div class="search-result-item-header search-result-item-header-actions">Actions</div>
        <div class="search-result-item-header search-result-item-header-name">Name</div>
        <div class="search-result-item-header search-result-item-header-date-created">Created Date</div>
        <div class="search-result-item-header search-result-item-header-date-created-by">Created By</div>
        <div class="search-result-item-header search-result-item-header-date-modified">Modified Date</div>
        <div class="search-result-item-header search-result-item-header-date-modified-by">Modified By</div>
      </div>
      <cdk-virtual-scroll-viewport #scroller itemSize="50" class="scroll-container">
        <div *cdkVirtualFor="let item of listItems" class="search-result-item-container"
          [ngClass]="{'arm': item.isFromArmWorkFlow, 'not-arm': !item.isFromArmWorkFlow}">
          <div class="search-result-item search-result-item-actions"><button mat-mini-fab color="primary"
              (click)="onViewCollection(item)">
              <mat-icon>remove_red_eye</mat-icon>
            </button>
            <button mat-mini-fab color="primary" (click)="onModifyCollection(item.id)">
              <mat-icon>edit</mat-icon>
            </button>
            <button mat-mini-fab color="warn" (click)="onDeleteCollection(item.id)">
              <mat-icon>delete</mat-icon>
            </button>
            <div class="petrel" *ngIf="item.isFromArmWorkFlow">
              <img class="petrel-img" src="/assets/images/petrel.png" matTooltip="ARM">
            </div>
          </div>
          <div class="search-result-item search-result-item-name">{{item.name}}</div>
          <div class="search-result-item search-result-item-date-created">{{item.createdDateTime | date:'short'}}</div>
          <div class="search-result-item search-result-item-date-created-by">{{item.createdUserId}}</div>
          <div class="search-result-item search-result-item-date-modified"><span
              *ngIf="item.createdUserId !== item.modifiedUserId || !areInSameMinute(item.createdDateTime, item.modifiedDateTime)">{{item.modifiedDateTime
              | date:'short'}}</span></div>
          <div class="search-result-item search-result-item-date-modified-by"><span
              *ngIf="item.createdUserId !== item.modifiedUserId || !areInSameMinute(item.createdDateTime, item.modifiedDateTime)">{{item.modifiedUserId}}</span>
          </div>
        </div>
      </cdk-virtual-scroll-viewport>
    </div>
  </div>


</mat-drawer-container>