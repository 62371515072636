import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CalAngularModule } from '@cvx/cal-angular';
import { HttpCallInterceptor } from './interceptors/http-call-interceptor.service';
import {AuthService} from "./auth/auth.service";

@NgModule({
  imports: [CommonModule, RouterModule, HttpClientModule],
  exports: [RouterModule, HttpClientModule],
  declarations: [
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: HttpCallInterceptor, multi: true }]
})
export class CoreModule {}
