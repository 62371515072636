import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, SimpleChanges} from "@angular/core";
import {ModelService} from "../../../core/api/model.service";
import {SlbLoadingService} from "@slb-dls/angular-material/loading";
import {MatDialog} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {BehaviorSubject, Observable, of, Subscription, switchMap, tap} from "rxjs";
import {FileVersion} from "../../../core/models/file-version";
import {ModelVersion} from "../../../core/models/model-version";
import {debounceTime, map} from "rxjs/operators";
import {OpenRevisionChangesData, OpenRevisionChangesDialog} from "../dialogs/open-revision-changes.dialog";
import {CompareCollectionsDialog, CompareCollectionsDialogData} from "../dialogs/compare-collections.dialog";
import {CompareModelsDialog, CompareModelsDialogData} from "../dialogs/compare-models.dialog";
import { environment } from "src/environments/environment";
import { ToastrService } from "ngx-toastr";
import {Clipboard} from "@angular/cdk/clipboard";

@Component({
  selector: 'app-model-timeline',
  templateUrl: './model-timeline.component.html',
  styleUrls: ['./model-timeline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModelTimelineComponent {

  @Input() modelId: string;
  @Input() collectionId: string;

  private inputChange = new BehaviorSubject(false);
  private inputChange$: Subscription;


  inProgress: boolean;
  modelVersions: ModelVersion[];

  constructor(private modelsService: ModelService,
              private cdr: ChangeDetectorRef,
              private clipboard: Clipboard,
              private dialog: MatDialog,
              private router: Router,
              public toastrService: ToastrService) {
    this.inputChange$ = this.inputChange.pipe(tap(() => {
      this.inProgress = true;
    }), debounceTime(100), switchMap(hasValue => {
      if(!hasValue) return of(false);
      return this.getVersions().pipe(map(p => {
        this.modelVersions = [...p].sort((a, b) => a.versionNumber - b.versionNumber).map((value, idx) => {
          return Object.assign({idx: idx + 1}, value);
        });
        this.inProgress = false;
        this.cdr.detectChanges();
        return true;
      }));
    })).subscribe();
  }


  getVersions(): Observable<ModelVersion[]> {
    return this.modelsService.getVersions(this.modelId);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.inputChange.next(!!this.collectionId && !!this.modelId);
  }

  ngOnDestroy(): void {
    console.log('ModelTimelineComponent OnDestroy');
  }

  onView(modelVersion: ModelVersion) {
    this.router.navigateByUrl(`/collection/${this.collectionId}?r=${modelVersion.revisionId}&m=${modelVersion.id}`);
  }

  onViewRevisionChanges(modelVersion: ModelVersion) {
    this.dialog.open(OpenRevisionChangesDialog, {
      data: {
        revisionId: modelVersion.revisionId
      } as OpenRevisionChangesData,
    });
  }

  getVersionsThatCanBeCompared(modelVersion: ModelVersion): ModelVersion [] {
    return this.modelVersions.filter(p => p.id !== modelVersion.id);
  }

  onCompare(modelVersion: ModelVersion, compareVersion: ModelVersion): void {
    let leftVersion = modelVersion, rightVersion = compareVersion;
    if(leftVersion.createdDateTime > rightVersion.createdDateTime) {
      leftVersion = compareVersion;
      rightVersion = modelVersion;
    }
    this.dialog.open(CompareModelsDialog, {
      width: '80vw',
      height: '80vh',
      data: {
        leftVersion: leftVersion,
        rightVersion: rightVersion
      } as CompareModelsDialogData,
    });
  }

  copySimulationLink(id: string): void {
    const simulationId = id;
    const simulationLink = environment.simulationLink;
    const simulationLinkToCopy = simulationLink.concat(simulationId);
    this.clipboard.copy(simulationLinkToCopy);
    this.toastrService.success(`simulation link has been copied to the clipboard`);
  }
}
