import { Inject, Injectable } from '@angular/core';
import { CalAngularService } from "@cvx/cal-angular";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, first, firstValueFrom, interval, lastValueFrom, Observable, of, Subscription, tap } from "rxjs";
import { AuthStatus } from "../models/auth-status";
import { LOCAL_STORAGE, SESSION_STORAGE, StorageService } from "ngx-webstorage-service";
import { SlbTokenResponse } from "../models/slb-token-response";
import { environment } from "../../../environments/environment";
import { catchError, map, switchMap } from "rxjs/operators";
import { BuService } from "../api/bu.service";
@Injectable({ providedIn: 'root' })
export class AuthService {

  static calConfig = {
    autoSignIn: false,
    popupForLogin: false,
    cacheLocation: "localStorage",
    instance: "https://login.microsoftonline.com/",
    tenantId: environment.calTenantId,
    clientId: environment.calClientId,
    redirectUri: (() => {
      const url = new URL(window.location.href);
      const port = !!url.port ? `:${url.port}` : "";
      return `${url.protocol}//${url.hostname}${port}`;
    })(),
    oidcScopes: ["openid", "profile", "User.Read", "offline_access"],
    graphScopes: [".default"]
  };


  private _currentAuthStatus = new BehaviorSubject<AuthStatus>(null);
  currentUser: import("@azure/msal-browser").AccountInfo = null;

  get currentAuthStatus(): Observable<AuthStatus> {
    return this._currentAuthStatus.asObservable();
  }

  constructor(
    private httpClient: HttpClient,
    private calAngularService: CalAngularService,
    @Inject(SESSION_STORAGE) private sessionStorage: StorageService<string>,
    @Inject(LOCAL_STORAGE) private localStorage: StorageService<string>,
    private window: Window,
    private buService: BuService
  ) {
    this._currentAuthStatus.next({ userLoggedIn: false, statusMessage: 'authentication not attempted yet' });
    this.currentAuthStatus.pipe(
      switchMap(status => {
        if (status.userLoggedIn) {
          return buService.setDefaultCurrentBuIfNotSet().pipe(map(status => status));
        }
        return of(status);
      }),
      switchMap(status => {
        return interval(environment.refreshTokenIntervalInMillis).pipe(switchMap(s => {
          return this.tryAuthenticateFromRefreshToken();
        }));
      })).subscribe(console.log);
  }

  authenticateUser(): Observable<boolean> {
    return this.calAngularService.isUserSignedIn().pipe(switchMap(isUserSignedIn => {
      if (isUserSignedIn === true) {
        this.currentUser = this.calAngularService.getAccount();
        return this.tryAuthenticateFromRefreshToken();
      } else {
        this._currentAuthStatus.next({ userLoggedIn: false, statusMessage: 'cal authentication failed! initiating sign-in' });
        return this.calAngularService.userInitiatedSignIn().pipe(map(p => {
          throw new Error('cal authentication being done');
        }));
      }
    }));
  }


  tryAuthenticateFromRefreshToken(): Observable<boolean> {
    const refreshToken = this.localStorage.get('slbRefreshToken');
    if (!!refreshToken) {
      return this.httpClient
        .post<SlbTokenResponse>(`${environment.apiEndpoint}/authentication/RefreshSlbToken`,
          { refreshToken: refreshToken, redirectUrl: AuthService.calConfig.redirectUri }
        ).pipe(switchMap(refreshSlbToken => {
          this.localStorage.set('slbToken', refreshSlbToken?.authToken);
          this.localStorage.set('slbRefreshToken', refreshSlbToken?.refreshToken);
          return this.handleRefreshTokenResponse(!!refreshSlbToken?.authToken && !!refreshSlbToken?.refreshToken);
        }), catchError(err => {
          console.error({ err });
          return this.handleRefreshTokenResponse(false);
        }));
    }
    return this.handleRefreshTokenResponse(false);
  }



  handleRefreshTokenResponse(authenticateFromRefreshToken: boolean): Observable<boolean> {
    if (authenticateFromRefreshToken) {
      this._currentAuthStatus.next({ userLoggedIn: true, statusMessage: 'authenticated from slb refresh token' });
      return of(true);
    }
    return this.tryAuthenticateFromCodeInUrl();
  }


  tryAuthenticateFromCodeInUrl(): Observable<boolean> {
    const url = new URL(this.window.location.href);
    const code = url.searchParams.get('code');
    if (!!code) {
      return this.httpClient
        .post<SlbTokenResponse>(`${environment.apiEndpoint}/authentication/SlbToken`,
          { code: code, redirectUrl: AuthService.calConfig.redirectUri }
        ).pipe(switchMap(slbToken => {
          this.localStorage.set('slbToken', slbToken.authToken);
          this.localStorage.set('slbRefreshToken', slbToken.refreshToken);
          if (!!slbToken?.authToken && !!slbToken?.refreshToken) {
            return this.handleCodeResponse(true);
          } else {
            this._currentAuthStatus.next({ userLoggedIn: false, statusMessage: 'authentication from slb code failed!' });
            return of(false);
          }
        }), catchError(err => {
          console.error({ err });
          this.localStorage.set('slbToken', '');
          this.localStorage.set('slbRefreshToken', '');
          this._currentAuthStatus.next({ userLoggedIn: false, statusMessage: 'authentication from slb code gave error!' });
          return of(false);
        }));
    }
    return this.handleCodeResponse(false);
  }

  handleCodeResponse(authenticateFromCode: boolean): Observable<boolean> {
    if (authenticateFromCode) {
      const url = new URL(this.window.location.href);
      const state = url.searchParams.get('state');
      const search = this.sessionStorage.get(state);
      this._currentAuthStatus.next({ userLoggedIn: true, statusMessage: 'authenticated from slb code', redirectTo: `${state}${!!search ? search : ''}` });
      return of(true);
    }
    return this.redirectToSlbAuth();
  }

  redirectToSlbAuth(): Observable<boolean> {
    const url = new URL(this.window.location.href);
    const currentPathRequested = `${url.pathname}`;
    this.sessionStorage.set(currentPathRequested, `${url.search}`);
    return this.httpClient.post<{ authCodeUrl: string }>(
      `${environment.apiEndpoint}/authentication/SlbAuthCodeUrl`,
      { redirectUrl: AuthService.calConfig.redirectUri, state: currentPathRequested }
    ).pipe(map(authCodeUrlRes => {
      this._currentAuthStatus.next({ userLoggedIn: false, statusMessage: 'redirecting to slb for auth!' });
      this.window.location.href = authCodeUrlRes.authCodeUrl.replace(/"/g, '');
      return true;
    }));
  }

  public getScopes(): string[] {
    return [`${environment.calClientId}/user_impersonation`];
  }

}
