import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { CompanionDataService } from '../../../core/api/companion-data.service';

export interface ModelLinkageDialogData {
  modelName: string,
  modelID: string,
  collectionName: string
  collectionID: string;
};

@Component({
  selector: 'app-model-link-dialog',
  templateUrl: './model-linkage.dialog.html',
  styleUrls: ["./model-linkage.dialog.scss"]
})
export class ModelLinkageDialog implements OnInit{
  modelLinkageForm: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: ModelLinkageDialogData,
              private dialogRef: MatDialogRef<ModelLinkageDialog>,
              private companionDataService: CompanionDataService) {
  }

  
  ngOnInit() {
    this.modelLinkageForm = new FormGroup({
      currentModelName: new FormControl(""),
      currentModelID: new FormControl(""),
      currentCollectionName: new FormControl(""),
      currentCollectionID: new FormControl(""),
      relationship: new FormControl(""),
      linkedModelName: new FormControl(""),
      linkedModelID: new FormControl(""),
      linkedCollectionName: new FormControl(""),
      linkedCollectionID: new FormControl(""),
    });
  }

  // write the relationship
  onApply() {
    // write the relationship
  }

  // write relationship and close
  onClose() {
    this.onApply();
    this.dialogRef.close({result: 'success'});
  }
}
