import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { AfterViewInit, Component, ElementRef, NgZone, OnDestroy, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatDrawer } from "@angular/material/sidenav";
import { Router } from "@angular/router";
import { SlbLoadingService } from "@slb-dls/angular-material/loading";
import * as moment from "moment";
import { filter, first, map, Observable, pairwise, Subscription, tap, throttleTime } from "rxjs";
import { CollectionsService } from "src/app/core/api/collections.service";
import { CompanionDataService } from "src/app/core/api/companion-data.service";
import { SearchCollectionsResults, SearchCollectionsService, SearchForCollectionsCompanionDataFilters, SearchForCollectionsFilters, SearchForCollectionsModelFilters, SearchForCollectionsRequest } from "src/app/core/api/search-collections.service";
import { autoMonitorPageView } from "src/app/core/decorators/auto-monitor-page-view.decorator";
import { autoUnsubscribe } from "src/app/core/decorators/auto-unsubscribe.decorator";
import { Collection } from "src/app/core/models/collection";
import { MonitoringService } from "src/app/core/services/monitoring.service";
import { CollectionsNeededService } from "../collectionsneeded.service";
import { UpdateCollectionComponent } from "../create-update-collection/update-collection.component";


@autoMonitorPageView({ name: 'Search Collections', trackOnInitToAfterInit: false })
@autoUnsubscribe({ autoInclude: true })
@Component({
  selector: 'app-search-collections-models',
  templateUrl: './search-collections.html',
  styleUrls: ['./search-collections.scss']
})

export class SearchCollectionsComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('scroller') scroller: CdkVirtualScrollViewport;
  @ViewChild('drawer') drawer: MatDrawer;
  @ViewChild("pageHeaderOptions")
  private pageHeaderOptionsTpl: TemplateRef<any>;
  listItems: (Collection & { color: string, simulationIdsMatched?: string[] })[] = [];
  nextCursor: { nextPageCollectionsQueryCursor: string, nextPageModelsQueryCursor: string, nextIndexForCompanionDataQuery: number } = null;
  isGrid = true;
  searchText = '';
  currentDate = new Date();
  currentSearchTextSelected = '';
  drawerClosed: boolean = true;
  reset: number = 1;

  companionDataService$: Subscription;

  startDateRange = new FormGroup({
    start: new FormControl(null),
    end: new FormControl(null),
  });

  modifiedDateRange = new FormGroup({
    start: new FormControl(null),
    end: new FormControl(null),
  });

  nameFilterControl = new FormControl('');
  filteredNames: Observable<string[]>;

  createdUserFilterControl = new FormControl('');
  filteredCreatedUsers: Observable<string[]>;

  updatedUserFilterControl = new FormControl('');
  filteredUpdatedUsers: Observable<string[]>;

  separatorKeysCodes: number[] = [ENTER, COMMA];

  selectedOwners: string[] = [];
  ownerFilterControl = new FormControl();
  filteredOwners: Observable<string[]>;

  selectedViewers: string[] = [];
  viewerFilterControl = new FormControl();
  filteredViewers: Observable<string[]>;

  modelNameFilterControl = new FormControl('');
  filteredModelNames: Observable<string[]>;

  genOptFileNameFilterControl = new FormControl('');
  filteredGenOptFileNames: Observable<string[]>;

  genOptFileIdFilterControl = new FormControl('');
  filteredGenOptFileIds: Observable<string[]>;

  remarkFilterControl = new FormControl('');
  filteredRemarks: Observable<string[]>;

  modelVersionNumberFilterControl = new FormControl('');
  filteredModelVersionNumbers: Observable<string[]>;

  businessPlanCaseFilterControl = new FormControl('');
  filteredBusinessPlanCases: Observable<string[]>;

  reservesCaseFilterControl = new FormControl('');
  filteredReservesCases: Observable<string[]>;

  reservoirFilterControl = new FormControl('');
  filteredReservoirs: Observable<string[]>;

  decisionFrameFilterControl = new FormControl('');
  filteredDecisionFrames: Observable<string[]>;

  modelObjectiveFilterControl = new FormControl('');
  filteredModelObjectives: Observable<string[]>;

  projectPhaseFilterControl = new FormControl('');
  filteredProjectPhases: Observable<string[]>;

  fieldFilterControl = new FormControl('');
  filteredFields: Observable<string[]>;

  forecastYearFilterControl = new FormControl('');
  filteredForecastYears: Observable<string[]>;

  simulationApplicationFilterControl = new FormControl('');
  filteredSimulationApplications: Observable<string[]>;

  simulationApplicationVersionFilterControl = new FormControl('');
  filteredSimulationApplicationVersions: Observable<string[]>;

  genOptTemplateUsedFilterControl = new FormControl('');
  filteredGenOptTemplateUsed: Observable<string[]>;

  genOptGeneralUsedFilterControl = new FormControl('');
  filteredGenOptGeneralUsed: Observable<string[]>;

  currentFiltersSelected: {
    collectionFilters?: SearchForCollectionsFilters,
    modelFilters?: SearchForCollectionsModelFilters,
    companionDataFilters?: SearchForCollectionsCompanionDataFilters
  };

  currentFilters: {
    collectionFilters?: SearchForCollectionsFilters,
    modelFilters?: SearchForCollectionsModelFilters,
    companionDataFilters?: SearchForCollectionsCompanionDataFilters
  };


  @ViewChild('ownerFilterInput') ownerFilterInput: ElementRef<HTMLInputElement>;
  @ViewChild('viewerFilterInput') viewerFilterInput: ElementRef<HTMLInputElement>;

  constructor(
    private ngZone: NgZone,
    private oneService: CollectionsNeededService,
    private collectionsService: CollectionsService,
    public loadingService: SlbLoadingService,
    private companionDataService: CompanionDataService,
    private router: Router,
    public dialog: MatDialog,
    private searchCollectionsService: SearchCollectionsService,
    private monitoringService: MonitoringService
  ) {
    this.oneService.breadcrumbsService.setCurrentBreadcrumbItems(
      [
      ]);
  }


  ngOnInit(): void {
    const searchRequest = {
      searchText: ''
    } as SearchForCollectionsRequest;
    this.fetchMore(true, searchRequest);

  }


  ngAfterViewInit(): void {

    this.oneService.pageHeaderService.setCurrentToolbarTemplate(this.pageHeaderOptionsTpl);
    this.scroller.elementScrolled().pipe(
      map(() => this.scroller.measureScrollOffset('bottom')),
      pairwise(),
      filter(([y1, y2]) => {
        const r = (y2 < y1 && y2 < 140);
        return r;
      }),
      throttleTime(200)
    ).subscribe(() => {
      this.ngZone.run(() => {
        if (this.nextCursor) {
          const searchRequest = Object.assign({
            searchText: this.currentSearchTextSelected,
            collectionsQueryCursor: this.nextCursor.nextPageCollectionsQueryCursor,
            modelsQueryCursor: this.nextCursor.nextPageModelsQueryCursor,
            indexOnClient: this.nextCursor.nextIndexForCompanionDataQuery
          }, this.currentFiltersSelected) as SearchForCollectionsRequest;
          this.fetchMore(false, searchRequest);
        }
      });
    }
    );


  }

  fetchMore(clearExisting: boolean, searchRequest?: SearchForCollectionsRequest): void {
    this.loadingService.showSpinner();
    this.searchCollectionsService.search(searchRequest).pipe(first(), tap((searchResults: SearchCollectionsResults) => {
      this.loadingService.closeSpinner();
      this.nextCursor = {
        nextPageCollectionsQueryCursor: searchResults.nextPageCollectionsQueryCursor,
        nextPageModelsQueryCursor: searchResults.nextPageModelsQueryCursor,
        nextIndexForCompanionDataQuery: searchResults.nextIndexForCompanionDataQuery
      };
      const newItems = searchResults.results.map(p => {
        return Object.assign({ color: p.moniker.split(' ')[0] }, p);
      });
      this.listItems = [...(clearExisting ? [] : this.listItems), ...newItems];
    })).subscribe();
  }

  onSearch(): void {
    this.currentSearchTextSelected = this.searchText;
    const searchRequest = {
      searchText: this.currentSearchTextSelected
    } as SearchForCollectionsRequest;
    this.fetchMore(true, searchRequest);
  }

  onClearSearch(): void {
    this.searchText = '';
    this.currentSearchTextSelected = this.searchText;
    const searchRequest = {
      searchText: ''
    } as SearchForCollectionsRequest;
    this.fetchMore(true, searchRequest);
  }


  createCollection(): void {

    let dialogRef = this.dialog.open(UpdateCollectionComponent, {
      width: "50vw",
      maxHeight: '100vh',
      panelClass: "dgPanel"
    });

    dialogRef.afterClosed().subscribe(collectionId => {
      if (collectionId) {
        this.router.navigate(["/collection", collectionId]);
      }
    });
  }


  onRefresh() {
    const searchRequest = { searchText: '' } as SearchForCollectionsRequest;
    this.fetchMore(true, searchRequest);
  }


  ngOnDestroy(): void {
    console.log('on destroy being called for search collections component');
  }

  areInSameMinute(a: Date, b: Date) {
    return moment(a).diff(moment(b), 'minutes') === 0;
  }

  onViewCollection(item: (Collection & { color: string, simulationIdsMatched: string[] })): void {
    let simulation = '';
    if (item.simulationIdsMatched && item.simulationIdsMatched.length === 1)
      simulation = item.simulationIdsMatched[0];
    this.router.navigate(["/collection", item.id],
      { queryParams: { m:simulation  } });
  }

  onModifyCollection(id: string): void {
    const dialogRef = this.dialog.open(UpdateCollectionComponent, {
      width: "50vw",
      maxHeight: '80vh',
      panelClass: "dgPanel",
      data: id
    });

    dialogRef.afterClosed().subscribe(collection => {
      if (collection) {
        console.log("collection exist");
      }
    });
  }


  onCollectionClick() {

  }

  onDeleteCollection(selectedCollectionId: string) {
    this.oneService.dialogService.deleteCollectionConfirmation().subscribe(confirmed => {
      if (confirmed) {
        this.loadingService.showSpinner({ text: "Deletion Request is being Sent..." });
        this.collectionsService.deleteCollectionsById({ collectionId: selectedCollectionId }).subscribe(deleteResponse => {
          if (deleteResponse.response?.isSuccessStatusCode === true) {
            this.listItems = [...this.listItems.filter(p => p.id !== selectedCollectionId)];
          } else {
            this.oneService.toastrService.error("Could not delete collection!. You might not have delete permissions")
          }
          this.loadingService.closeSpinner();
        });
      }
    });
  }

  onSlideDrawer(): void {
    if (this.drawerClosed)
      this.drawer.open().then(_ => {
        this.drawerClosed = false;
      });
    else
      this.onCloseDrawer();
  }

  onCloseDrawer(): void {
    this.drawer.close().then(_ => {
      this.drawerClosed = true;
    });
  }

  onFiltersChanged(filters: {
    collectionFilters?: SearchForCollectionsFilters,
    modelFilters?: SearchForCollectionsModelFilters,
    companionDataFilters?: SearchForCollectionsCompanionDataFilters
  }): void {
    this.currentFilters = filters;
  }

  onApplyFilter(): void {
    this.currentFiltersSelected = this.currentFilters;
    const searchRequest = Object.assign({ searchText: this.currentSearchTextSelected }, this.currentFiltersSelected) as SearchForCollectionsRequest;
    this.fetchMore(true, searchRequest);
  }

  onReset(): void {
    this.reset += 1;
  }

  getToolTipForCollection(coll: Collection): string {
    return `
    Description:${coll.description}
    Owners: ${coll.owners.join(",")}
    Viewers: ${coll.viewers.join(",")}
    Tags: ${(coll.tags ?? []).join(",")}
    `;
  }



}