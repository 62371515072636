import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ModelFile } from "../models/model-file";
import { QueryModelsResponse, CompanionDataQueryFields, ModelFilterBy, ModelFromQuery } from "../models/query-models-response";
import { ModelVersion } from "../models/model-version";

@Injectable({
  providedIn: 'root'
})
export class ModelService {
  constructor(protected _http: HttpClient) {
  }

  

  getFilesForModel(id: string): Observable<ModelFile[]> {
    const url = `${environment.apiEndpoint}/model/${id}/files`;
    return this._http.get<ModelFile[]>(url);
  }

  getModels(request: { cursor?: string, searchText?: string, limit: number, sort?: string, filterBy?: ModelFilterBy, companionDataFields: CompanionDataQueryFields }): Observable<QueryModelsResponse> {
    const url = `${environment.apiEndpoint}/models/query`;
    return this._http.post<QueryModelsResponse>(url, request);
  }

  pushToFDPlan(id: string, request: { studyId: string, dataTypeId: string, modelUrl: string }): Observable<{ id: string }> {
    const url = `${environment.apiEndpoint}/model/${id}/pushToFDPlan`;
    return this._http.post<{ id: string }>(url, request);
  }

  getVersions(id: string): Observable<ModelVersion[]> {
    const url = `${environment.apiEndpoint}/model/${id}/versions`;
    return this._http.get<ModelVersion[]>(url);
  }

  updateModel(model: ModelFromQuery): Observable<ModelFromQuery> {
    const url = `${environment.apiEndpoint}/models/${model.id}`;
    return this._http.put<ModelFromQuery>(url, model);
  }

}
