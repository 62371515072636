<button mat-button mat-raised-button color="primary" [matMenuTriggerFor]="menu">
    <mat-icon>compare_arrows</mat-icon>
    <span>Model Comparison</span>
</button>
<mat-menu #menu="matMenu">
    <button mat-menu-item *ngIf="currentModelsToCompare.length === 2" (click)="openCompare()">
        <mat-icon>remove_red_eye</mat-icon>
        <span>View Model Comparison</span>
    </button>
    <button *ngFor="let model of currentModelsToCompare" matTooltip="{{model.collectionName}} - {{model.modelName}}" mat-menu-item (click)="removeFromCompare(model.simulationId)">
        <mat-icon>close</mat-icon>
        {{model.collectionName}} - {{model.modelName}}
    </button>
    <button mat-menu-item *ngIf="currentModelToCompare" (click)="addToCompare()">
        <mat-icon>add</mat-icon>
        <span>Add to Comparison</span>
    </button>
</mat-menu>