import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { SlbBreadcrumbsItem } from "@slb-dls/angular-material/breadcrumbs";
import { AuthStatus } from "../models/auth-status";

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbsService {
  private breadcrumbItems: BehaviorSubject<SlbBreadcrumbsItem[]> = new BehaviorSubject([]);

  static get HOME(): SlbBreadcrumbsItem {
    return { id: 0, url: '/home', title: 'Home', label: 'Home' } as SlbBreadcrumbsItem;
  }

  static get COLLECTIONS(): SlbBreadcrumbsItem {
    return { id: 1, url: '/collections', title: 'Collections', label: 'Collections' } as SlbBreadcrumbsItem;
  }

  static get MODELS(): SlbBreadcrumbsItem {
    return { id: 2, url: '/models', title: 'Models', label: 'Models' } as SlbBreadcrumbsItem;
  }


  static get COLLECTION(): SlbBreadcrumbsItem {
    return { id: 3, url: '/collection', title: 'Collection', label: 'Collection' } as SlbBreadcrumbsItem;
  }
  
  static get COLLECTION_REVISION(): SlbBreadcrumbsItem {
    return { id: 31, url: '/collection', title: 'Revision', label: 'Revision' } as SlbBreadcrumbsItem;
  }

  public setCurrentBreadcrumbItems(val: SlbBreadcrumbsItem[]): void {
    this.breadcrumbItems.next(val || []);
  }

  get currentBreadcrumbItems(): Observable<SlbBreadcrumbsItem[]> {
    return this.breadcrumbItems.asObservable();
  }

}
