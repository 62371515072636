import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { ModelComparisonService, ModelToCompare } from "src/app/core/api/model-comparison.service";
import { autoUnsubscribe } from "src/app/core/decorators/auto-unsubscribe.decorator";
import { ModelComparisonTreeDialog } from "./dialogs/model-comparison-tree.dialog";



@Component({
    selector: 'app-model-comparison-button',
    templateUrl: './model-comparison-button.component.html',
    styleUrls: ['./model-comparison-button.component.scss'],
})
@autoUnsubscribe({ autoInclude: true })
export class ModelComparisonButtonComponent implements OnDestroy {


    @Input() currentModelToCompare: ModelToCompare;

    currentModelsToCompare: ModelToCompare[];
    currentModelsForComparison$: Subscription;

    constructor(private modelComparisonService: ModelComparisonService,
        private dialog: MatDialog) {
        this.currentModelsForComparison$ = modelComparisonService.currentModelsForComparison.subscribe((models => {
            this.currentModelsToCompare = models;
        }));
    }

    ngOnDestroy(): void {
        console.log('on destroy of ModelComparisonButtonComponent');
    }


    addToCompare() {
        this.modelComparisonService.addToCompare(this.currentModelToCompare);
    }

    removeFromCompare(simulationId: string) {
        this.modelComparisonService.removeFromCompare(simulationId);
    }

    openCompare() {
        this.dialog.open(ModelComparisonTreeDialog, {
            width: '95vw',
            height: '95vh',
            maxWidth: '95vw',
            maxHeight: '95vh'
        });
    }

}