import {AfterViewInit, Component, HostListener, Inject, OnDestroy, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {catchError, EMPTY, expand, first, of, Subject, Subscription, timeout, zip} from "rxjs";
import {SafeResourceUrl} from "@angular/platform-browser";
import {environment} from "../../../../../../environments/environment";
import {DOCUMENT} from "@angular/common";
import {switchMap} from "rxjs/operators";
import {FileUtilsService} from "../../../../../core/services/file-utils.service";
import {CollectionFilesService} from "../../../../../core/api/collection-files.service";
import {autoUnsubscribe} from "../../../../../core/decorators/auto-unsubscribe.decorator";
import { DomSanitizerService } from "src/app/core/services/domsanitizer.service";
import { autoMonitorPageView } from "src/app/core/decorators/auto-monitor-page-view.decorator";
import { MonitoringService } from "src/app/core/services/monitoring.service";

export interface CompareFileDialogData {
  collectionId: string,
  filePath: string,
  fileName: string,
  updatedFile: File
}

@autoMonitorPageView({name: 'Compare File Dialog', trackOnInitToAfterInit: false })
@Component({
  selector: 'app-compare-file-dialog',
  templateUrl: './compare-file.dialog.html',
  styleUrls: ['./compare-file.dialog.scss']
})
@autoUnsubscribe({autoInclude: true})
export class CompareFileDialog implements OnInit, AfterViewInit, OnDestroy{

  inProgress = true;
  content: string = '';
  webEditorUrlSafe: SafeResourceUrl; //need safe url for iframe src
  doc: Document;

  updatedContent = new Subject<string>();
  private loadContent$: Subscription;

  constructor(@Inject(MAT_DIALOG_DATA) public data: CompareFileDialogData, private fileUtilsService: FileUtilsService,
              @Inject(DOCUMENT) doc: Document,
              private sanitizer: DomSanitizerService,
              private collectionFilesService: CollectionFilesService,
              private monitoringService: MonitoringService) {

    this.webEditorUrlSafe = sanitizer.webDiffEditorUrlSafe; //environment.webDiffEditorUrl
    this.doc = doc;
    this.getContent();
  }
  ngAfterViewInit(): void {
    // do nothing
  }
  ngOnInit(): void {
    // do nothing
  }

  ngOnDestroy(): void {
        console.log('on destroy CompareFileDialog');
    }

  private getContent() {
    this.loadContent$ = zip(this.fileUtilsService.readFile(this.data.updatedFile), this.collectionFilesService.getFileContent(this.data.collectionId, this.data.filePath))
      .pipe(switchMap(([updatedFileContent, existingFileContent]) => {
        const iframe = this.doc.getElementById('ifrm') as HTMLIFrameElement;
        let retryAttempts = 0;
        return of('').pipe(expand(contentOnEditor => {
          if(contentOnEditor === updatedFileContent || retryAttempts > 5) {
            this.inProgress = false;
            this.content = existingFileContent.content;
            return EMPTY;
          }
          retryAttempts++;
          iframe.contentWindow.postMessage(
            {
              vsEditorData: {
                left: existingFileContent.content,
                right: updatedFileContent
              },
              updateOptions: {readOnly: true}
            },
            this.getDomain() // do not use '*'
          );
          iframe.contentWindow.postMessage('getOriginalDiffModel', this.getDomain());
          return this.updatedContent.pipe(first(), timeout({first: 2000}), catchError(err => {
            console.error(err);
            return of('');
          }));
        }));
      }))
      .subscribe();
  }


  refresh(): void {
    this.getContent();
  }


  private getDomain(): string {
    const domain = new URL(environment.webEditorUrl);
    return `${domain.protocol}//${domain.hostname}`;
  }



  @HostListener('window:message', ['$event']) onPostMessage(event) {
    if (event.origin !== this.getDomain()) {
      return;
    }
    const responseType = event.data.requestType;
    if (responseType === 'getOriginalDiffModel') {
      const modifiedContent = event.data.response;
      this.updatedContent.next(modifiedContent);
    }
  }

}
