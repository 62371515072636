<div fxFill fxLayout="column" style="padding: 2rem;">
  <div fxFlex="10" fxLayout="row" fxLayoutAlign="start center">
    <mat-form-field fxFlex>
      <mat-label>Search Text</mat-label>
      <input matInput (keyup)="applySearch($event)" placeholder="Ex: ab1_gasinj_gsg.afi" #input>
    </mat-form-field>
    <div fxFlex="15" fxLayout="row" class="toggle-container">
      <div *ngIf="!!companionDataFilters" class="collapse-button" >
        <button mat-raised-button color="primary" (click)="showCompanionDataFilters()">
          <mat-icon>keyboard_arrow_right</mat-icon><span>Companion Filters</span>
        </button>
      </div>
      <div *ngIf="!companionDataFilters" class="collapse-button" >
        <button mat-raised-button color="primary" (click)="showCompanionDataFilters()">
          <mat-icon>keyboard_arrow_left</mat-icon><span>Companion Filters</span>
        </button>
      </div>
    </div>
  </div>
  
  <mat-drawer-container class="sidenav-container" [hasBackdrop]="false">
    <mat-drawer [mode]="'side'" [(opened)]="companionDataFilters" position="end" class="sidenav-sidenav">
      <div class="form-title" fxLayoutAlign="space-evenly center">
        <h4>Filter Criteria</h4>
      </div>
      <mat-accordion>
        <form class="form-content" [formGroup]="formGroup" (submit)="onFilterFormSubmit($event)"
          (keydown.enter)="onFilterFormSubmit($event)">
          <div fxLayout="row wrap" fxLayoutAlign="space-evenly center" >
            <div *ngFor="let field of companionFields">
              <div>
                <mat-form-field appearance="fill">
                <mat-label>{{ field.name }}</mat-label>
                <mat-select [id]="field.key" [formControlName]="field.modelField" [multiple]="true">
                  <mat-option *ngFor="let option of companionOptions[field.modelField]" [value]="option">
                    {{ option }}
                  </mat-option>
                </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div fxLayoutAlign="start space-evenly " class="div-spacing">
            <mat-label>Filter operator:</mat-label>
            <mat-radio-group aria-labelledby="group-radio" [id]="'CompanionOperator'" 
              [formControlName]="'CompanionOperator'" [(ngModel)]="selectedCompanionOperator">
              <mat-radio-button
                *ngFor="let option of companionOperators"
                [value]="option"
                (change) = "companionOperatorChanged(option)"
                color="primary"
              >
                {{ option }}
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <div fxLayoutAlign="space-evenly center" >
            <button type="button" slb-button (click)="applyFilters()">Apply</button>
            <button type="button" slb-secondary-button (click)="clearFilters()">Clear</button>
          </div>
        </form>
      </mat-accordion>
      
    </mat-drawer>
    <mat-drawer-content>
      <div class="mat-elevation-z8">
        <div class="table-container">
          <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort matSortActive="modifiedDateTime"
            matSortDisableClear matSortDirection="desc" aria-labelledby="models">
      
            <ng-container matColumnDef="index">
              <th mat-header-cell *matHeaderCellDef> No.</th>
              <td mat-cell *matCellDef="let row"> {{(dataPageIndex * paginator.pageSize) + row.index + 1}} </td>
            </ng-container>
      
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Name</th>
              <td mat-cell *matCellDef="let row">{{row.name}}</td>
            </ng-container>
      
            <ng-container matColumnDef="modelType">
              <th mat-header-cell *matHeaderCellDef>Type</th>
              <td mat-cell *matCellDef="let row" aria-labelledby="models">
                <ng-container [ngSwitch]="getSimulationType(row.name)">
                  <button class="icon-button" *ngSwitchCase="'rsl'">
                    <img class="icon" src="assets/icons/ipm.png" alt="rsl models"/>
                  </button>
                  <button class="icon-button" *ngSwitchCase="'mbal'">
                    <img class="icon" src="assets/icons/ipm.png" alt="mbal models"/>
                  </button>
                  <button class="icon-button" *ngSwitchCase="'afi'">
                    <img class="icon" src="assets/icons/ix.png" alt="ix models"/>
                  </button>
                  <span *ngSwitchDefault class="material-icons">
                    question_mark
                  </span>
                </ng-container>
              </td>
            </ng-container>
      
      
            <ng-container matColumnDef="collectionName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Collection</th>
              <td mat-cell *matCellDef="let row">{{row.collectionName}}</td>
            </ng-container>
      
            <ng-container matColumnDef="modelTags">
              <th mat-header-cell *matHeaderCellDef>Model Tags</th>
              <td mat-cell *matCellDef="let row">{{row.modelTags | json}}</td>
            </ng-container>
      
            <ng-container matColumnDef="modifiedUserId">
              <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Modified By</th>
              <td mat-cell *matCellDef="let row">{{row.modifiedUserId}}</td>
            </ng-container>
      
            <ng-container matColumnDef="modifiedDateTime">
              <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                Modified On
              </th>
              <td mat-cell *matCellDef="let row">{{row.modifiedDateTime | date:'medium'}}</td>
            </ng-container>
      
            <ng-container matColumnDef="expand">
              <th mat-header-cell *matHeaderCellDef aria-label="row actions">Details/Actions</th>
              <td mat-cell *matCellDef="let element">
                <button mat-icon-button aria-label="expand row"
                  (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
                  <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
                  <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
                </button>
              </td>
            </ng-container>
      
            <ng-container matColumnDef="expandedDetail">
              <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                <div class="element-detail" [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'">
                  <div fxflexfill fxLayout="row wrap">
                    <div class="element-detail-item">
                      <div class="element-detail-item-header" mat-line>Id</div>
                      <div mat-line> {{element.id}} </div>
                    </div>
      
                    <div class="element-detail-item">
                      <div class="element-detail-item-header" mat-line>Name</div>
                      <div mat-line> {{element.name}} </div>
                    </div>
      
                    <div class="element-detail-item">
                      <div class="element-detail-item-header" mat-line>Model Type</div>
                      <div mat-line> {{element.modelType}} </div>
                    </div>
      
                    <div class="element-detail-item">
                      <div class="element-detail-item-header" mat-line>Moniker</div>
                      <div mat-line> {{element.moniker}} </div>
                    </div>
      
      
                    <div class="element-detail-item">
                      <div class="element-detail-item-header" mat-line>Created By</div>
                      <div mat-line> {{element.createdUserId}} </div>
                    </div>
      
      
                    <div class="element-detail-item">
                      <div class="element-detail-item-header" mat-line>Created Date</div>
                      <div mat-line> {{element.createdDateTime | date:'medium'}} </div>
                    </div>
      
                    <div class="element-detail-item">
                      <div class="element-detail-item-header" mat-line>Modified By</div>
                      <div mat-line> {{element.modifiedUserId}} </div>
                    </div>
      
      
                    <div class="element-detail-item">
                      <div class="element-detail-item-header" mat-line>Modified Date</div>
                      <div mat-line> {{element.modifiedDateTime | date}} </div>
                    </div>
      
                    <div class="element-detail-item">
                      <div class="element-detail-item-header" mat-line>Model Tags</div>
                      <div mat-line> {{element.modelTags | json}} </div>
                    </div>
      
                    <div class="element-detail-item">
                      <div class="element-detail-item-header" mat-line>Collection Id</div>
                      <div mat-line> {{element.collectionId}} </div>
                    </div>
      
                    <div class="element-detail-item">
                      <div class="element-detail-item-header" mat-line>Collection Name</div>
                      <div mat-line> {{element.collectionName}} </div>
                    </div>
      
                    <div class="element-detail-item">
                      <div class="element-detail-item-header" mat-line>Collection Moniker</div>
                      <div mat-line> {{element.collectionMoniker}} </div>
                    </div>
      
                    <div class="element-detail-item">
                      <div class="element-detail-item-header" mat-line>Tags</div>
                      <div mat-line> {{element.tags | json}} </div>
                    </div>
      
                    <div class="element-detail-item">
                      <button mat-raised-button color="primary" (click)="viewModel(element)">
                        <mat-icon>remove_red_eye</mat-icon>
                        <span>View Model</span>
                      </button>
                    </div>
      
      
                    <div class="element-detail-item" *ngIf="studyId && dataTypeId">
                      <button mat-raised-button color="primary" (click)="pushToFDPlan(element)">
                        <mat-icon>publish</mat-icon>
                        <span>Push to FDPlan</span>
                      </button>
                    </div>
      
                    <div class="element-detail-item">
                      <button mat-raised-button color="primary" (click)="copyModelLink(element)">
                        <mat-icon>link</mat-icon><span>Copy Model Link</span>
                      </button>
                    </div>
      
                    <div class="element-detail-item">
                      <app-model-comparison-button [currentModelToCompare]="modelToCompare(element)"></app-model-comparison-button>
                    </div>

                  </div>
      
                </div>
              </td>
            </ng-container>
      
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="element-row"
              [class.expanded-row]="expandedElement === element"
              (click)="expandedElement = expandedElement === element ? null : element"></tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
          </table>
        </div>
        <mat-paginator [length]="resultsLength" [pageSizeOptions]="pageSizeOptions" [pageSize]="50"
          aria-label="Select page of search results"></mat-paginator>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>

</div>
