import {Injectable} from "@angular/core";
import {IntersectModelService} from "./intersect-model.service";
import {Observable, of, tap, zip} from "rxjs";
import {ModelFile} from "../models/model-file";
import {map, switchMap} from "rxjs/operators";
import {FileUtilsService} from "./file-utils.service";
import {ModelFilesService} from "./model-files.service";

@Injectable({
  providedIn: 'root'
})
export class CoupledModelService {
  constructor(private intersectModelService: IntersectModelService,
              private fileUtilsService: FileUtilsService,
  ) {
  }


  public getModelFilesInfoFromFiles(modelFile: File, files: File[]): Observable<ModelFile[]> {
    return zip([this.getAfiInputs(files), this.getAfiOutputs(files)]).pipe(switchMap(([iFiles, oFiles]) => {
      iFiles.filter(p => p.isPrimaryFile).forEach(p => { p.isPrimaryFile = false;});
      const afiInputOutputFiles = iFiles.concat(oFiles);
      //the below code needed if we need to ignore the files within afi folders
      // const afiFiles = this.getAfiFiles(files);
      // const afiFileParentPaths = afiFiles.map(afiFile => this.fileUtilsService.getParentFolderPath(afiFile.webkitRelativePath));
      //the below line has a bug in that it repeasts the files for each additional parentpath found
      // const restInputFiles = afiFileParentPaths.map(afiFileParentPath => files.filter(p => {
      //   if(this.fileUtilsService.getFileExtension(p.name) === `manifest`) return false;
      //   if(afiFileParentPath.indexOf('/') === -1) return true;         //only if the parent of afi file is a root folder then select all files
      //
      //   return p.webkitRelativePath.indexOf(`${afiFileParentPath}/`) === -1;
      // })).flat();
      const finalRestInputs = files.filter(restInputOutputFile => !afiInputOutputFiles.some(p => p.path === restInputOutputFile.webkitRelativePath)).map(p => {
        return this.fileUtilsService.getModelFileForAdd(p, 'input').pipe(tap(mFile => {
          if(mFile.path === modelFile.webkitRelativePath) mFile.isPrimaryFile = true;
        }))
      });
      if(finalRestInputs.length > 0)
        return zip(...finalRestInputs).pipe(map(ipFiles => ipFiles.concat(afiInputOutputFiles)));
      else return of(afiInputOutputFiles);
    }));
  }

  public getAfiFiles(files: File[]): File[] {
    return files.filter(p => this.fileUtilsService.getFileExtension(p.name) === ModelFilesService.IntersectExtension);
  }

  public getAfiInputs(files: File[]): Observable<ModelFile[]> {
    return of(true).pipe(switchMap(() => {
      const afiFiles = this.getAfiFiles(files);
      if(afiFiles.length > 0) {
        return zip(afiFiles.map(afiFile => this.intersectModelService.getInputs(afiFile, files))).pipe(map((p: ModelFile[][]) => {
          return p.flat().filter(this.getUnique);
        }));
      } else {
        return of([]);
      }
    }));
  }

  private getUnique(value: ModelFile, index: number, arr: ModelFile[]) {
    return arr.findIndex(p => p.path === value.path && p.type === value.type) === index;
  }

  public getAfiOutputs(files: File[]): Observable<ModelFile[]> {
    return of(true).pipe(switchMap(() => {
      const afiFiles = this.getAfiFiles(files);
      if(afiFiles.length > 0) {
        return zip(afiFiles.map(afiFile => this.intersectModelService.getOutputs(afiFile, files))).pipe(map((p: ModelFile[][]) => {
          return p.flat().filter(this.getUnique);
        }));
      } else {
        return of([]);
      }
    }));
  }
}
