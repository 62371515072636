import {autoUnsubscribe} from "../../../core/decorators/auto-unsubscribe.decorator";
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Inject, OnDestroy} from "@angular/core";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {catchError, EMPTY, expand, first, of, Subject, Subscription, timeout} from "rxjs";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {environment} from "../../../../environments/environment";
import {DOCUMENT} from "@angular/common";
import {CollectionFilesService} from "../../../core/api/collection-files.service";
import {map, switchMap} from "rxjs/operators";
import { DomSanitizerService } from "src/app/core/services/domsanitizer.service";



export interface OpenFileDialogData {
  fileId: string,
  fileName: string,
  allowEdit: boolean
}
@autoUnsubscribe({autoInclude: true})
@Component({
  selector: 'app-open-file-dialog',
  templateUrl: './open-file.dialog.html',
  styleUrls: ['./open-file.dialog.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OpenFileDialog implements OnDestroy {

  webEditorUrlSafe: SafeResourceUrl; //need safe url for iframe src
  inProgress = true;
  content: string = '';
  doc: Document;

  updatedContent = new Subject<string>();
  private collectionFilesService$: Subscription;

  constructor(@Inject(MAT_DIALOG_DATA) public data: OpenFileDialogData, private collectionFilesService: CollectionFilesService,
              private cdr: ChangeDetectorRef, public sanitizer: DomSanitizerService, @Inject(DOCUMENT) doc: Document,
  ) {
    this.doc = doc;
    this.webEditorUrlSafe = this.sanitizer.webEditorUrlSafe;  //bypassSecurityTrustResourceUrl(environment.webEditorUrl)
    this.bindContent();
  }
  private bindContent(): void {
    this.collectionFilesService$ = this.collectionFilesService.getFileContentByFileId(this.data.fileId).pipe(
      map(response => response.content),
      switchMap(content => {
        const iframe = this.doc.getElementById('ifrm') as HTMLIFrameElement;
        let retryAttempts = 0;
        return of('').pipe(expand(contentOnEditor => {
          if(contentOnEditor === content || retryAttempts > 5) {
            this.inProgress = false;
            return EMPTY;
          }
          retryAttempts++;
          iframe.contentWindow.postMessage(
            {
              vsEditorData: content,
              updateOptions: {readOnly: true}
            },
            this.getDomain() // not use '*'
          );
          iframe.contentWindow.postMessage('getEditorModel', this.getDomain());
          return this.updatedContent.pipe(first(), timeout({first: 2000}), catchError(err => {
            console.error(err);
            return of('');
          }));
        }));
      })
    ).subscribe();
  }

  refresh(): void {
    this.bindContent();
  }

  ngOnDestroy(): void {
        console.log('ondestroy OpenFileDialog');
    }



  private getDomain(): string {
    const domain = new URL(environment.webEditorUrl);
    return `${domain.protocol}//${domain.hostname}`;
  }


  @HostListener('window:message', ['$event']) onPostMessage(event) {
    if (event.origin !== this.getDomain()) {
      return;
    }

    const responseType = event.data.requestType;
    if (responseType === 'getEditorModel') {
      const modifiedContent = event.data.response;
      this.updatedContent.next(modifiedContent);
    }
  }

}
