import {Injectable} from "@angular/core";
import {EntitlementsService} from "../../../core/api/entitlements.service";
import { BreadcrumbsService } from "../../../core/services/breadcrumbs.service";
import { PageHeaderService } from "../../../core/services/page-header.service";
import { SlbLoadingService } from "@slb-dls/angular-material/loading";
import {MessageService} from "@slb-dls/angular-material/notification";
import { ToastrService } from "ngx-toastr";
import { ConfirmDialogService } from "src/app/components/shared/confirm-dialog/confirm.service";


@Injectable({
    providedIn: 'root'
})
export class CollectionsNeededService {
  constructor(public toastrService: ToastrService,
    public dialogService: ConfirmDialogService,
    public loadingService: SlbLoadingService,
    public messageService: MessageService,
    public pageHeaderService: PageHeaderService,
    public breadcrumbsService: BreadcrumbsService,
    public entitlementsService: EntitlementsService )
  {}
}