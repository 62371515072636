import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ConfirmDialogService } from './confirm-dialog/confirm.service';
import { MaterialModule } from 'src/app/core/module/material.module';
import { SlbFormFieldModule } from '@slb-dls/angular-material/form-field';
import { SlbNotificationModule } from '@slb-dls/angular-material/notification';
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { SlbButtonModule } from "@slb-dls/angular-material/button";
import {FlexModule} from "@angular/flex-layout";
import { CompanionDataComponent } from './companion-data/companion-data.component';
import {FileTimelineComponent} from "./timeline/file-timeline.component";
import {ModelTimelineComponent} from "./timeline/model-timeline.component";
import {OpenFileDialog} from "./dialogs/open-file.dialog";
import {CompareFilesDialog} from "./dialogs/compare-files.dialog";
import {OpenRevisionChangesDialog} from "./dialogs/open-revision-changes.dialog";
import {CollectionTimelineComponent} from "./timeline/collection-timeline.component";
import { NgCircleProgressModule } from 'ng-circle-progress';
import {CompareCollectionsDialog} from "./dialogs/compare-collections.dialog";
import {CompareModelsDialog} from "./dialogs/compare-models.dialog";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ModelLinkageComponent } from './linkages/model-linkage.component';
import { ModelLinkageDialog } from './dialogs/model-linkage.dialog';
import { ModelComparisonButtonComponent } from './model-comparison-button.component';
import { ModelComparisonTreeDialog } from './dialogs/model-comparison-tree.dialog';

@NgModule({
  declarations: [
    ConfirmDialogComponent,
    CompanionDataComponent,
    FileTimelineComponent,
    ModelTimelineComponent,
    CollectionTimelineComponent,
    OpenFileDialog,
    CompareFilesDialog,
    CompareCollectionsDialog,
    CompareModelsDialog,
    OpenRevisionChangesDialog,
    ModelLinkageComponent,
    ModelLinkageDialog,
    ModelComparisonButtonComponent,
    ModelComparisonTreeDialog
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MaterialModule,
    SlbFormFieldModule,
    SlbNotificationModule,
    SlbButtonModule,
    FlexModule,
    NgCircleProgressModule.forRoot({
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: "#78C000",
      innerStrokeColor: "#C7E596",
      animationDuration: 300,
    }),
    ScrollingModule 
  ],
  exports: [
    ConfirmDialogComponent,
    FileTimelineComponent,
    ModelTimelineComponent,
    CollectionTimelineComponent,
    OpenFileDialog,
    CompareFilesDialog,
    CompareCollectionsDialog,
    CompareModelsDialog,
    OpenRevisionChangesDialog,
    ModelLinkageComponent,
    ModelLinkageDialog,
    ModelComparisonButtonComponent,
    ModelComparisonTreeDialog
  ],
  entryComponents: [ConfirmDialogComponent],
  providers: [ConfirmDialogService],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class SharedModule { }
